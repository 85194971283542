import { useCallback, useEffect, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { apolloClient } from 'api/apollo';
import { ChannelCollection, useHideChannelMutation } from 'api/graphql';
import { ActionModal, Button, Tooltip } from 'components/ui/general';
import { Paths } from 'consts/router';
import { useToast } from 'hooks';

import { texts } from './HideChannelModal.text';

import styles from './HideChannelModal.module.scss';

type HideChannelModalProps = {
  channelId: string | number;
  disabled?: boolean;
};

export const HideChannelModal = ({
  channelId,
  disabled,
}: HideChannelModalProps) => {
  const { addToast } = useToast();
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [hideChannel, { loading, error }] = useHideChannelMutation();

  const hideChannelMutationCallback = useCallback(async () => {
    const { data: hideChannelResponse } = await hideChannel({
      variables: { id: channelId },
    });

    if (hideChannelResponse?.hideChannel.success) {
      navigate(Paths.Chat);

      apolloClient.cache.modify({
        fields: {
          channels(existingChannels: any, { readField }) {
            const channels = existingChannels as ChannelCollection;
            return {
              ...channels,
              edges: channels.edges.filter(
                (existingChannel) =>
                  channelId !== readField('id', existingChannel)
              ),
              meta: {
                ...channels.meta,
                total: channels.meta.total - 1,
              },
            } as ChannelCollection;
          },
        },
      });

      addToast({
        title: formatMessage(texts.hideChatChannelSuccess),
        type: 'success',
      });
    }
  }, [addToast, channelId, formatMessage, hideChannel, navigate]);

  useEffect(() => {
    if (error) {
      addToast({
        title: formatMessage(texts.hideChatChannelError),
        message: error.message,
        type: 'error',
      });
    }
  }, [addToast, error, formatMessage]);

  return (
    <ActionModal
      heading={<FormattedMessage {...texts.hideChannelTitle} />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      trigger={
        <Tooltip tip={<FormattedMessage {...texts.tooltip} />} trigger="hover">
          <Button
            fullWidth
            size="lg"
            className={styles.hideChannelButton}
            color="secondary"
            iconRight={{ name: 'eye-slash' }}
            as="div"
            onClick={() => setIsOpen(!isOpen)}
            disabled={disabled}
          />
        </Tooltip>
      }
      triggerDisabled={disabled}
      button={{
        children: <FormattedMessage {...texts.buttonHideChannel} />,
        type: 'button',
        loading,
        disabled: loading,
        onClick: hideChannelMutationCallback,
      }}
    />
  );
};
