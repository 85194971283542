import { useCallback, useEffect, useMemo, useRef } from 'react';

import {
  CustomChatSubscribeDocument,
  CustomChatSubscribeSubscription,
  CustomChatSubscribeSubscriptionVariables,
  useCustomChannelsQuery,
  useCustomChatSubscribeSubscription,
} from 'api/graphql';
import { Icon } from 'components/ui/general';
import { DefaultFilterChannels } from 'consts/chat';
import { useAuth } from 'hooks';
import { format, typeGuards } from 'utils';

import styles from '../Header.module.scss';

export const Chat = () => {
  const { user } = useAuth();
  const hasLoadedEverythingForTheFirstTime = useRef<boolean>(false);
  const { data, subscribeToMore, fetchMore, loading, error } =
    useCustomChannelsQuery({
      fetchPolicy: 'cache-and-network',
      nextFetchPolicy: 'cache-first',
      variables: {
        filter: { userId: user?.id, limit: DefaultFilterChannels.Limit },
      },
    });
  const channels = useMemo(() => data?.channels, [data]);

  // This hook creates a cache really early, so upcoming channels/messages will
  // be asserted within the cache
  useCustomChatSubscribeSubscription();

  const onFetchMore = useCallback(async () => {
    if (channels) {
      await fetchMore({
        variables: {
          filter: {
            userId: user?.id,
            limit: DefaultFilterChannels.Limit,
            offset: channels.edges.length,
          },
        },
      });

      const { meta, edges } = channels;
      if (edges.length >= meta.total) {
        hasLoadedEverythingForTheFirstTime.current = true;
      }
    }
  }, [channels, fetchMore, user]);

  useEffect(() => {
    if (
      channels &&
      !hasLoadedEverythingForTheFirstTime.current &&
      !loading &&
      !error
    ) {
      const { total, offset } = channels.meta;
      if (total >= offset) {
        onFetchMore();
      }
    }
  }, [channels, onFetchMore, loading, error]);

  const channelsWithUnreadMessages = useMemo(
    () =>
      data?.channels.edges.filter((channel) => {
        const meAsParticipant = channel.participants?.find(
          (participant) => participant.user.id === user?.id
        );

        if (
          format
            .date({ date: meAsParticipant?.lastReadAt })
            .diff(format.date({ date: channel.lastSentAt })) < 0 ||
          (!!channel.lastSentAt && !meAsParticipant?.lastReadAt)
        ) {
          return channel;
        }

        return null;
      }),
    [data, user]
  );

  useEffect(() => {
    const unsubscribe = subscribeToMore<
      CustomChatSubscribeSubscription,
      CustomChatSubscribeSubscriptionVariables
    >({
      document: CustomChatSubscribeDocument,
      updateQuery: (prev, { subscriptionData }) => {
        console.log(
          'chat sub',
          subscriptionData,
          subscriptionData.data.chatSubscribe.__typename
        );
        if (!subscriptionData.data) return prev;
        const channel = typeGuards.channel(subscriptionData.data.chatSubscribe);

        if (!channel) {
          return prev;
        }

        const channelIsAlreadyRendered = prev.channels?.edges?.find(
          ({ id }) => id === channel.id
        );

        if (!channelIsAlreadyRendered) {
          return {
            ...prev,
            channels: {
              ...prev.channels,
              edges: [channel, ...prev.channels.edges],
              meta: {
                ...prev.channels.meta,
                total: prev.channels.meta.total + 1, // Will not work if you can delete a channel
              },
            },
          };
        }

        return prev;
      },
    });

    return () => unsubscribe?.();
  }, [subscribeToMore]);

  return (
    <div className={styles.chat}>
      <Icon name="comment-lines" font="nuuk" />
      {!!channelsWithUnreadMessages?.length && (
        <div className={styles.chatHasUnreadMessages} />
      )}
    </div>
  );
};
