import { useEffect } from 'react';

import { useDispatch } from 'react-redux';

import { useMeQuery, User } from 'api/graphql';
import { Router, SEO } from 'components/tools';
import { useAuth } from 'hooks';
import { setUser } from 'redux/auth';

export const App = () => {
  const dispatch = useDispatch();

  const { token, user } = useAuth();
  const { data, refetch } = useMeQuery({
    fetchPolicy: 'network-only',
    refetchWritePolicy: 'overwrite',
  });

  useEffect(() => {
    const userFromData = data?.me as User;

    if (
      user &&
      userFromData &&
      user.id === userFromData.id &&
      user !== userFromData
    ) {
      dispatch(setUser({ user: userFromData }));
    }
  }, [dispatch, data, user]);

  useEffect(() => {
    if (token) {
      refetch();
    }
  }, [token, refetch]);

  return (
    <>
      <SEO titleTemplate="%s | Joglo" defaultTitle="Joglo" />
      <Router />
    </>
  );
};
