import { ReactNode, useCallback, useState } from 'react';

import { FormattedMessage, useIntl } from 'react-intl';

import {
  Location,
  LocationDocument,
  LocationCollection,
  useDeleteLocationMutation,
  UserRole,
} from 'api/graphql';
import { Button, Tooltip, ActionModal } from 'components/ui/general';
import { DeleteLocationModalSelectors } from 'consts/cypress';
import { useAuth, useToast } from 'hooks';

import { texts } from './DeleteLocationModal.text';

type DeleteLocationModalProps = {
  location: Location;
  onDeleted?: Function;
  trigger?: ReactNode;
};

export const DeleteLocationModal = ({
  location,
  onDeleted,
  trigger,
}: DeleteLocationModalProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const { formatMessage } = useIntl();
  const { addToast } = useToast();
  const { role } = useAuth();

  const [deleteLocation, { error, loading }] = useDeleteLocationMutation({
    refetchQueries: [LocationDocument],
    update(cache, { data }) {
      if (!data) return;

      cache.modify({
        fields: {
          locations(existingLocations: any, { readField }) {
            const locations = existingLocations as LocationCollection;
            return {
              ...locations,
              edges: locations.edges.filter(
                (existingLocation) =>
                  location.id !== readField('id', existingLocation)
              ),
              meta: {
                ...locations.meta,
                total: locations.meta.total - 1,
              },
            } as LocationCollection;
          },
        },
      });
    },
  });

  const onClick = useCallback(async () => {
    const { data } = await deleteLocation({
      variables: {
        id: location.id,
      },
    });

    if (data?.deleteLocation) {
      setIsOpen(false);
      addToast({
        title: formatMessage(texts.deleteCompanyLocationSuccess),
        type: 'success',
      });
      onDeleted?.();
    }
  }, [deleteLocation, location.id, addToast, formatMessage, onDeleted]);

  return (
    <ActionModal
      {...{ isOpen, setIsOpen, error }}
      heading={<FormattedMessage {...texts.heading} />}
      buttonCancel={{}}
      button={
        role === UserRole.Admin
          ? {
              children: <FormattedMessage {...texts.button} />,
              loading,
              disabled: loading,
              onClick,
              'data-cy': DeleteLocationModalSelectors.Button,
              color: 'error',
            }
          : undefined
      }
      trigger={
        trigger || (
          <Tooltip
            tip={<FormattedMessage {...texts.tooltip} />}
            trigger="hover"
          >
            <Button
              color="secondary"
              iconRight={{ name: 'trash-alt' }}
              as="div"
              data-cy={DeleteLocationModalSelectors.Trigger}
              size="sm"
            />
          </Tooltip>
        )
      }
    >
      <p>
        <FormattedMessage
          {...texts[
            role === UserRole.Admin ? 'paragraphAdmin' : 'paragraphNotAdmin'
          ]}
          values={{
            companyName: location.company.name,
            locationName: location.name,
          }}
        />
      </p>
    </ActionModal>
  );
};
