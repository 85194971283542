import {
  ApolloClient,
  ApolloLink,
  from,
  DefaultOptions,
  split,
} from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { onError, ErrorResponse } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { fromPromise, ServerError } from '@apollo/client/link/utils';
import { WebSocketLink } from '@apollo/client/link/ws';
import { getMainDefinition } from '@apollo/client/utilities';
import { Store } from '@reduxjs/toolkit';
import { createUploadLink } from 'apollo-upload-client';
// import { catchError, from, mergeMap, of } from 'rxjs';

import { cache } from 'api/cache';
import { getEnvironment } from 'api/environment';
import {
  getLastRefresh,
  getRefreshing,
  getRefreshToken,
  getToken,
  getUser,
  refreshSessionError,
  refreshSessionStart,
} from 'redux/auth';
import { sentry } from 'utils';

if (process.env.NODE_ENV === 'development') {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const apiEnvironment = getEnvironment();

const uploadHttpLink = createUploadLink({ uri: `${apiEnvironment.apiUrl}` });

// Delay promise
function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function getNewToken(reduxStore: Store, increment = 1): Promise<string> {
  const state = reduxStore.getState();
  const token = getToken(state);
  const refreshToken = getRefreshToken(state);
  const refreshing = getRefreshing(state);
  const lastRefresh = getLastRefresh(state);
  const user = getUser(state);

  if (!refreshToken || !user) {
    reduxStore.dispatch({
      type: refreshSessionError.type,
    });
    return Promise.reject(new Error('No refresh token'));
  }

  if (!refreshing && lastRefresh != null && token != null) {
    const timeSinceLastRefresh = new Date().getTime() - lastRefresh;
    if (timeSinceLastRefresh < 12000) {
      return Promise.resolve(token);
    }
  }

  if (!refreshing && refreshToken != null) {
    reduxStore.dispatch({
      type: refreshSessionStart.type,
      payload: { refreshToken, userId: user.id },
    });
  }

  return delay(200 * increment).then(() => {
    return getNewToken(reduxStore, increment + 1);
  });
}

// eslint-disable-next-line import/no-mutable-exports
export let ws: WebSocketLink;
const wsLink = (store: Store) => {
  ws = new WebSocketLink({
    uri: apiEnvironment.wssUrl || '',
    options: {
      reconnect: true,
      reconnectionAttempts: 5,
      lazy: true,
      connectionParams: async () => {
        const token = await getToken(store.getState());

        if (token) {
          return {
            Authorization: `jwt ${token}`,
          };
        }
        return {};
      },
    },
  });
  return ws;
};

// Inject the authentication token into the requests made by apollo
const authMiddleware = (store: Store) =>
  new ApolloLink((operation, forward) => {
    const token = getToken(store.getState());
    if (token && operation.operationName !== 'RefreshSession') {
      operation.setContext({
        headers: {
          authorization: `jwt ${token}`,
        },
      });
    } else {
      operation.setContext({
        headers: {},
      });
    }

    return forward(operation);
  });

// Utility function to check if the error is due to an expired token
function isUnauthorizedError(
  networkError: ErrorResponse['networkError']
): boolean {
  return (networkError as ServerError)?.statusCode === 401;
}

// Checks the incoming error from the Apollo client and if it's a 401 attempts
// to refresh the token
const errorHandler = (reduxStore: Store) => {
  // @ts-ignore
  return onError(
    ({ networkError, operation, graphQLErrors, forward }: ErrorResponse) => {
      // Check if error is a token expired error
      if (isUnauthorizedError(networkError)) {
        // Attempt to get a new token
        return fromPromise(getNewToken(reduxStore)).flatMap((_newToken) => {
          return forward(operation);
        });
      }
      // Notify via sentry if something breaks
      else if (networkError?.message !== 'Network request failed') {
        sentry.withScope((scope) => {
          scope.setExtra('exception', {
            networkError,
            operation,
            graphQLErrors,
          });
          scope.setExtra('operation', operation);
          scope.setExtra('graphQLErrors', graphQLErrors);

          const opName = operation.operationName
            ? operation.operationName
            : 'unknown operation';

          sentry.captureMessage(
            `Apollo error: ${opName}`,
            sentry.Severity.Warning
          );
        });
      }
    }
  );
};

const retryLink = new RetryLink({
  attempts: { max: 2 },
});

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'none',
  },
  query: {
    fetchPolicy: 'cache-first',
    errorPolicy: 'none',
  },
  mutate: {
    errorPolicy: 'none',
  },
};

const splitLink = (store: Store) =>
  split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink(store),
    uploadHttpLink as unknown as ApolloLink
  );

// eslint-disable-next-line import/no-mutable-exports
export let apolloClient: ApolloClient<any>;

export const factory = (reduxStore: Store) => {
  apolloClient = new ApolloClient({
    cache,
    link: from([
      retryLink,
      authMiddleware(reduxStore),
      errorHandler(reduxStore),
      authMiddleware(reduxStore),
      splitLink(reduxStore),
    ]),
    defaultOptions,
  });

  return apolloClient;
};
