import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}

      export type PossibleTypesResultData = {
  "possibleTypes": {
    "ChatUpdate": [
      "Channel",
      "Message"
    ],
    "ExtraData": [
      "Booking"
    ],
    "UserProfile": [
      "CompanyProfile",
      "RestaurantProfile"
    ]
  }
};
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "ChatUpdate": [
      "Channel",
      "Message"
    ],
    "ExtraData": [
      "Booking"
    ],
    "UserProfile": [
      "CompanyProfile",
      "RestaurantProfile"
    ]
  }
};
      export default result;
    
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string | number;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Clock takes a timestamp like 18:15:59 */
  Clock: any;
  Time: any;
  Uint32: any;
  Uint64: any;
  Upload: any;
};

export type AcceptApplyInvitationsInput = {
  accept?: InputMaybe<Array<Scalars['ID']>>;
  apply?: InputMaybe<Array<ApplyJobInput>>;
};

export type AcceptApplyInvitationsResult = {
  __typename?: 'AcceptApplyInvitationsResult';
  error?: Maybe<Scalars['String']>;
  /** failed contain ids for Booking that was not possible to set to 'job_accepted' or 'interested'. */
  failed?: Maybe<Array<Scalars['ID']>>;
  /**
   * mandatoryMissing contain ids for Booking that was required to Accept or Confirm interest for, but was not provided
   * in input lists of ids. Reason for being required is sameResources requirement on workShift.
   */
  mandatoryMissing?: Maybe<Array<Scalars['ID']>>;
  /**
   * success tells if the request was successfully fulfilled or not. If not true 'mandatoryMissing' and/or 'failed' contains id
   * of failed bookings and/or Bookings that was required but missing.
   */
  success: Scalars['Boolean'];
  updated: Array<Booking>;
};

export type AcceptSuggestedTimesResult = {
  __typename?: 'AcceptSuggestedTimesResult';
  error?: Maybe<Scalars['String']>;
  /** notAvailable contain ids of bookings for which the user was not available on suggested times. */
  notAvailable?: Maybe<Array<Scalars['ID']>>;
  /**
   * success tells if the request was successfully fulfilled or not. If not true 'notAvailable'
   * contains ids of bookings for which the user was not available on suggested times and/or
   * 'timesNotMatch' contains ids of bookings for which the confirmed times does not match suggested,
   * which may be because the suggested times was changed after booking was fetched by client.
   */
  success: Scalars['Boolean'];
  /** timesNotMatch contain ids of bookings for which the confirmed times does not match booking suggested. */
  timesNotMatch?: Maybe<Array<Scalars['ID']>>;
  updated: Array<Booking>;
};

export type AcceptWith = {
  __typename?: 'AcceptWith';
  bookings: Array<RelatedBooking>;
  total: Scalars['Uint32'];
};

export type ApplyJobInput = {
  booking: Scalars['ID'];
  end?: InputMaybe<Scalars['Time']>;
  start?: InputMaybe<Scalars['Time']>;
};

export type AuthenticationResponse = {
  __typename?: 'AuthenticationResponse';
  jwt: Scalars['String'];
  message: Scalars['String'];
  newAccount: Scalars['Boolean'];
  refreshToken: Scalars['String'];
  success: Scalars['Boolean'];
  user?: Maybe<User>;
  userId: Scalars['String'];
};

/**
 * Status intended to simplify reading and filtering of booking statuses. Its cases are
 * further specified with BackOfficeSubBookingStatus
 */
export enum BackOfficeMainBookingStatus {
  /** Corresponds to the booking status: job_accepted */
  Accepted = 'accepted',
  /**
   * Corresponds to the booking statuses: employer_reported_no_show, rfi_declined_by_resource,
   * job_offer_declined_by_resource, passed_due_no_response, resource_reported_sick. Also
   * represents bookings where cancelledAt is not null, i.e. cancelled by customer
   */
  Canceled = 'canceled',
  /**
   * Corresponds to the booking statuses: awaiting_contact_approval, approved_by_contact,
   * contact_declined_time_report. Also represents any non-canceled booking that has
   * a non null timerStart and a non null timerEnd, i.e. has tracked time
   */
  Completed = 'completed',
  /** Corresponds to the booking statuses: request_for_interest, job_offer, interested */
  Requested = 'requested',
  /**
   * Does not correspond to a specific booking status. It represents any booking that
   * has a non null timerStart and a null timerEnd, i.e. an ongoing timer.
   */
  Started = 'started'
}

export enum BackOfficeSubBookingStatus {
  /** BackOfficeMainBookingStatus: canceled */
  CanceledByCustomer = 'canceled_by_customer',
  /** BackOfficeMainBookingStatus: canceled */
  ConfirmedCanceled = 'confirmed_canceled',
  /** BackOfficeMainBookingStatus: canceled */
  DeclinedByResource = 'declined_by_resource',
  /** BackOfficeMainBookingStatus: canceled */
  DeniedByCustomer = 'denied_by_customer',
  /** BackOfficeMainBookingStatus: requested */
  Interested = 'interested',
  /** BackOfficeMainBookingStatus: requested */
  JobOffer = 'job_offer',
  /** BackOfficeMainBookingStatus: canceled */
  NoApplicants = 'no_applicants',
  /** BackOfficeMainBookingStatus: canceled */
  NoShow = 'no_show',
  /** BackOfficeMainBookingStatus: accepted */
  NotTimeReported = 'not_time_reported',
  /** BackOfficeMainBookingStatus: canceled */
  ReportedSick = 'reported_sick',
  /** BackOfficeMainBookingStatus: requested */
  RequestForInterest = 'request_for_interest',
  /** BackOfficeMainBookingStatus: accepted */
  TimeReportApproved = 'time_report_approved',
  /** BackOfficeMainBookingStatus: accepted */
  TimeReportAwaitingApproval = 'time_report_awaiting_approval',
  /** BackOfficeMainBookingStatus: accepted */
  TimeReportDeclined = 'time_report_declined'
}

export type BankIdVerification = {
  __typename?: 'BankIDVerification';
  autoStartToken: Scalars['String'];
  orderRef: Scalars['String'];
};

export type BaseFilter = {
  limit: Scalars['Int'];
  offset: Scalars['Int'];
};

export type Booking = {
  __typename?: 'Booking';
  /**
   * AcceptWith is a list of other bookings that need to be accepted at the same time due to
   * sameResources requirement set on workShift.
   *
   * This list only resolved when user is making the request, and only relevant when sameResources
   * requirement is set to true on workShift
   */
  acceptWith?: Maybe<AcceptWith>;
  acceptedAt?: Maybe<Scalars['Time']>;
  approvedAt?: Maybe<Scalars['Time']>;
  approvedBy?: Maybe<User>;
  approvedByResourceAt?: Maybe<Scalars['Time']>;
  backOfficeMainStatus: BackOfficeMainBookingStatus;
  backOfficeSubStatus?: Maybe<BackOfficeSubBookingStatus>;
  breakMinutes: Scalars['Uint32'];
  cancelPolicyPct?: Maybe<Scalars['Float']>;
  cancelledAt?: Maybe<Scalars['Time']>;
  /** null if not relevant, false if it is canceled but not marked, otherwise true. */
  confirmedCanceled?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Time'];
  /** createdBy only relate to payhour admin/company user actions, not resource */
  createdBy: User;
  declinedAt?: Maybe<Scalars['Time']>;
  endTime: Scalars['Time'];
  extraStatus: ExtraBookingStatus;
  history?: Maybe<Array<HistoryBooking>>;
  id: Scalars['ID'];
  invoiced?: Maybe<Scalars['Boolean']>;
  legacyId?: Maybe<Scalars['Int']>;
  pricePoint: BookingPricePoint;
  resource: User;
  resourceCategoryData: ResourceCategoryData;
  startTime: Scalars['Time'];
  status: BookingStatus;
  suggestedEnd?: Maybe<Scalars['Time']>;
  suggestedStart?: Maybe<Scalars['Time']>;
  suggestionAcceptedAt?: Maybe<Scalars['Time']>;
  timeReport?: Maybe<TimeReport>;
  timerTracking?: Maybe<TimerTracking>;
  updatedAt: Scalars['Time'];
  updatedBy: User;
  workShiftId: Scalars['ID'];
  workShiftSlot: WorkShiftSlot;
};

export type BookingCollection = {
  __typename?: 'BookingCollection';
  data?: Maybe<BookingData>;
  edges: Array<Booking>;
  meta: PageInfo;
};

export type BookingConfirmTimesInput = {
  booking: Scalars['ID'];
  end: Scalars['Time'];
  start: Scalars['Time'];
};

export type BookingData = {
  __typename?: 'BookingData';
  preliminaryTotalPrice: Scalars['Float'];
  totalPrice: Scalars['Float'];
  totalTime: Scalars['Float'];
};

export type BookingFilter = {
  acceptanceTimePassedDue?: InputMaybe<Scalars['Boolean']>;
  approvedTimeReport?: InputMaybe<Scalars['Boolean']>;
  /**
   * awaitingTimeReport set true will return bookings where end time have passed due,
   * but resource have yet not reported actual time allowing for the Contact to approve the booking.
   * Set false will be ignored.
   */
  awaitingTimeReport?: InputMaybe<Scalars['Boolean']>;
  backOfficeMainStatus?: InputMaybe<Array<BackOfficeMainBookingStatus>>;
  bookingIds?: InputMaybe<Array<Scalars['ID']>>;
  /**
   * openToAcceptAsResource modify other filter values as well as ads it's own conditions
   * to assure that only Bookings that is eligible to be Accepted (if status job_offer) Or to
   * Confirm interest for (if status request_for_interest) is returned.
   *
   * IMPORTANT! openToAcceptAsResource == 'false' will be ignored!
   *
   * Validation is made on:
   *
   * 1. acceptance time derived from workShift.acceptanceTime or workShift.minutesPriorStart is not passed due.
   *
   * 2. booking.status is either 'job_offer' OR 'request_for_interest' (= enum list BookingStatusesOpenForAcceptance).
   *
   * 3. related workShiftSlot is not fully booked.
   *
   * Other criteria being modified:
   *
   * 1. filter.AcceptanceTimePassedDue: If not provided (=null), it will be set 'false'. IF provided as 'true',
   * an error will be thrown.
   *
   * 2. filter.Status: If not provided (=null) it will be set to enum list 'BookingStatusesOpenForAcceptance'.
   * IF provided, the statuses will be filtered to include only relevant statuses, and if no relevant statuses
   * provided, an error will be thrown.
   */
  cancelledAt?: InputMaybe<TimeRangeFilter>;
  companyId?: InputMaybe<Scalars['ID']>;
  confirmedCancelled?: InputMaybe<Scalars['Boolean']>;
  createdById?: InputMaybe<Scalars['ID']>;
  endTime?: InputMaybe<TimeRangeFilter>;
  exclude?: InputMaybe<Array<Scalars['ID']>>;
  extraStatus?: InputMaybe<Array<ExtraBookingStatus>>;
  hasTimeReport?: InputMaybe<Scalars['Boolean']>;
  includeCancelled?: InputMaybe<Scalars['Boolean']>;
  invoiced?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  locationId?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  onlyCancelled?: InputMaybe<Scalars['Boolean']>;
  openToAcceptAsContact?: InputMaybe<Scalars['Boolean']>;
  openToAcceptAsResource?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Region>;
  resourceId?: InputMaybe<Scalars['ID']>;
  resources?: InputMaybe<Array<Scalars['ID']>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  slotFullyBooked?: InputMaybe<Scalars['Boolean']>;
  startAfter?: InputMaybe<Scalars['Time']>;
  startTime?: InputMaybe<TimeRangeFilter>;
  status?: InputMaybe<Array<BookingStatus>>;
  suggestedTimes?: InputMaybe<Scalars['Boolean']>;
  timerEnded?: InputMaybe<Scalars['Boolean']>;
  timerStarted?: InputMaybe<Scalars['Boolean']>;
  updatedById?: InputMaybe<Scalars['ID']>;
  workShiftId?: InputMaybe<Scalars['ID']>;
  workShiftSlots?: InputMaybe<Array<Scalars['ID']>>;
};

export type BookingPricePoint = {
  __typename?: 'BookingPricePoint';
  hourlySalaryBase: Scalars['Float'];
  hourlySalaryBoost: Scalars['Float'];
  hours: Scalars['Float'];
  totalPrice?: Maybe<Scalars['Float']>;
  totalSalary: Scalars['Float'];
};

export type BookingSlot = {
  __typename?: 'BookingSlot';
  booking?: Maybe<Booking>;
  breakMinutes: Scalars['Uint32'];
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
  workShiftId: Scalars['ID'];
  workShiftSlot: WorkShiftSlot;
};

export type BookingSlotsCollection = {
  __typename?: 'BookingSlotsCollection';
  edges: Array<BookingSlot>;
  meta: PageInfo;
};

export type BookingSlotsFilter = {
  date?: InputMaybe<Scalars['Time']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<BookingStatus>;
};

export enum BookingStatus {
  ApprovedByContact = 'approved_by_contact',
  /**
   * awaiting_contact_approval means booking have been completed and resource have reported time,
   * and final approval is expected from Contact
   */
  AwaitingContactApproval = 'awaiting_contact_approval',
  ContactDeclinedTimeReport = 'contact_declined_time_report',
  EmployerReportedNoShow = 'employer_reported_no_show',
  Interested = 'interested',
  JobAccepted = 'job_accepted',
  JobOffer = 'job_offer',
  /**
   * job_offer_declined_by_resource means that resource have indicated they're not
   * interested to accept 'job_offer'
   */
  JobOfferDeclinedByResource = 'job_offer_declined_by_resource',
  Paid = 'paid',
  /**
   * passed_due_no_response is set by cron job, meaning that if an Booking
   * has this status, it's always true. BUT it's not certain that all Bookings that has
   * fallen due to lack of response within the last 24 hours has this status. If cron
   * job has not yet been run, the status is still job_offer OR request_for_interest.
   */
  PassedDueNoResponse = 'passed_due_no_response',
  RequestForInterest = 'request_for_interest',
  ResourceReportedSick = 'resource_reported_sick',
  /**
   * rfi_declined_by_resource means that resource have indicated they're not
   * interested to accept 'job_offer'
   */
  RfiDeclinedByResource = 'rfi_declined_by_resource'
}

export enum BookingType {
  JobOffer = 'job_offer',
  RequestForInterest = 'request_for_interest'
}

export type CancelInput = {
  resources?: InputMaybe<Array<Scalars['ID']>>;
  slots?: InputMaybe<Array<Scalars['ID']>>;
  workShift: Scalars['ID'];
};

export type Channel = {
  __typename?: 'Channel';
  chatGroup?: Maybe<ChatGroup>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  lastSentAt?: Maybe<Scalars['Time']>;
  messages: Array<Message>;
  name?: Maybe<Scalars['String']>;
  origin: ChannelOrigin;
  /** empty for system messages */
  participants?: Maybe<Array<Participant>>;
  updatedAt: Scalars['Time'];
  workShift?: Maybe<WorkShift>;
};


export type ChannelMessagesArgs = {
  filter?: InputMaybe<MessageFilter>;
};


export type ChannelParticipantsArgs = {
  filter?: InputMaybe<BaseFilter>;
};

export type ChannelCollection = {
  __typename?: 'ChannelCollection';
  edges: Array<Channel>;
  meta: PageInfo;
};

export type ChannelFilter = {
  chatGroupId?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<TimeRangeFilter>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  /** exclude a specific users channel participant */
  excludeUserId?: InputMaybe<Scalars['ID']>;
  includeClosed?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  origin?: InputMaybe<ChannelOrigin>;
  searchTerm?: InputMaybe<Scalars['String']>;
  /** a specific users channel participant */
  userId?: InputMaybe<Scalars['ID']>;
  /** looks for a channel where a particular group of users are participating */
  users?: InputMaybe<Array<Scalars['ID']>>;
  workShiftId?: InputMaybe<Scalars['ID']>;
};

export enum ChannelOrigin {
  Broadcast = 'Broadcast',
  Group = 'Group',
  OneToOne = 'OneToOne',
  Public = 'Public',
  System = 'System'
}

/**
 * ChatGroup is a group of users with whom a user can initiate chat with without knowing the individual members of that group.
 * Example; A user can initiate a chat with the chatGroup "Support" by only providing its ID and the message.
 * That is done by using mutation messageChatGroup(id: ID!, message: String!)
 */
export type ChatGroup = {
  __typename?: 'ChatGroup';
  closed: Scalars['Boolean'];
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  /**
   * members are the permanent users of a chat group that will be added to a chat channel on
   * usage.
   */
  members: Array<User>;
  messages: Array<Scalars['String']>;
  name: Scalars['String'];
  receivers: Scalars['Int'];
  responses: Scalars['Int'];
  updatedAt: Scalars['Time'];
};

export type ChatGroupCollection = {
  __typename?: 'ChatGroupCollection';
  edges: Array<ChatGroup>;
  meta: PageInfo;
};

export type ChatGroupFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  member?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
  /** looks for a chat group with the exakt name */
  origin?: InputMaybe<ChannelOrigin>;
  /** looks for any chat group with name matching searchTerm */
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type ChatUpdate = Channel | Message;

export type Company = {
  __typename?: 'Company';
  active: Scalars['Boolean'];
  billingCity: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingReference: Scalars['String'];
  billingStreet: Scalars['String'];
  category: VerticalCategory;
  city: Scalars['String'];
  commission?: Maybe<Scalars['Float']>;
  contactUser: User;
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  eInvoice?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organisationNumber: Scalars['String'];
  postalCode: Scalars['String'];
  resourceCategories: Array<ResourceCategory>;
  street: Scalars['String'];
  updatedAt: Scalars['Time'];
  web?: Maybe<Scalars['String']>;
};

export type CompanyCollection = {
  __typename?: 'CompanyCollection';
  edges: Array<Company>;
  meta: PageInfo;
};

export type CompanyFilter = {
  active?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type CompanyProfile = {
  __typename?: 'CompanyProfile';
  company: Company;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  locations: Array<Location>;
};


export type CompanyProfileLocationsArgs = {
  filter?: InputMaybe<BaseFilter>;
};

export type Coordinates = {
  lat: Scalars['String'];
  lng: Scalars['String'];
};

export type CreateChannelInput = {
  name?: InputMaybe<Scalars['String']>;
  origin: ChannelOrigin;
  userIds?: InputMaybe<Array<Scalars['ID']>>;
};

export type CreateCompanyInput = {
  billingCity: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingReference: Scalars['String'];
  billingStreet: Scalars['String'];
  category: VerticalCategory;
  city: Scalars['String'];
  contactUser: CreateCompanyUserInput;
  description?: InputMaybe<Scalars['String']>;
  eInvoice?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  location: CreateCompanyLocationInput;
  longitude?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organisationNumber: Scalars['String'];
  postalCode: Scalars['String'];
  resourceCategories?: InputMaybe<Array<Scalars['ID']>>;
  street: Scalars['String'];
  web?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyLocationInput = {
  billingCity: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingReference: Scalars['String'];
  billingStreet: Scalars['String'];
  city: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  eInvoice?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<Scalars['Upload']>>;
  image?: InputMaybe<Scalars['Upload']>;
  latitude?: InputMaybe<Scalars['String']>;
  legacyCustomerId?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['Upload']>;
  longitude?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organisationNumber: Scalars['String'];
  postalCode: Scalars['String'];
  region: Region;
  street: Scalars['String'];
  web?: InputMaybe<Scalars['String']>;
};

export type CreateCompanyUserInput = {
  description?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  lastName: Scalars['String'];
  legacyId?: InputMaybe<Scalars['Int']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  /** ignored if used to create company */
  password?: InputMaybe<Scalars['String']>;
  /** ignored if used to create company */
  role?: InputMaybe<UserRole>;
};

export type CreateLocationInput = {
  billingCity: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingReference: Scalars['String'];
  billingStreet: Scalars['String'];
  city: Scalars['String'];
  companyId: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  eInvoice?: InputMaybe<Scalars['String']>;
  files?: InputMaybe<Array<Scalars['Upload']>>;
  image?: InputMaybe<Scalars['Upload']>;
  latitude?: InputMaybe<Scalars['String']>;
  legacyCustomerId?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['Upload']>;
  longitude?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organisationNumber: Scalars['String'];
  postalCode: Scalars['String'];
  region: Region;
  street: Scalars['String'];
  /** either an userId is provided or a new user should be created */
  user?: InputMaybe<CreateCompanyUserInput>;
  /** either an userId is provided or a new user should be created */
  userId?: InputMaybe<Scalars['ID']>;
  web?: InputMaybe<Scalars['String']>;
};

export type CreateResourceCategory = {
  defaultSalary: Scalars['Uint32'];
  levelId: Scalars['ID'];
  /** must be string matching RestaurantVerticalCategory */
  name: Scalars['String'];
  vertical: VerticalCategory;
};

export type CreateResourceRegionalCategory = {
  modifier: Scalars['Int'];
  region: Region;
  resourceCategoryId: Scalars['ID'];
};

export type CreateUserInput = {
  city?: InputMaybe<Scalars['String']>;
  /** will check if email is available, else return error */
  email: Scalars['String'];
  firstName: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  lastName: Scalars['String'];
  legacyId?: InputMaybe<Scalars['Int']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  nextOfKinName?: InputMaybe<Scalars['String']>;
  nextOfKinPhone?: InputMaybe<Scalars['String']>;
  /** min length 8 */
  password: Scalars['String'];
  postalCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  /** must be in format YYYYYMMDDXXXX */
  socialSecurity?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export type CreateWorkShiftInput = {
  acceptanceTime?: InputMaybe<Scalars['Time']>;
  contactPersonId?: InputMaybe<Scalars['ID']>;
  customContactPerson?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  locationId: Scalars['ID'];
  mealDeduction?: InputMaybe<Scalars['Boolean']>;
  minutesPriorStart?: InputMaybe<Scalars['Uint32']>;
  resourceCategories: WorkShiftResourceCategoryInput;
  resourceQuantity: Scalars['Uint32'];
  salaryBoost?: InputMaybe<Scalars['Float']>;
  sameResources: Scalars['Boolean'];
  status: WorkShiftStatus;
  temporaryAddress?: InputMaybe<TemporaryAddressInput>;
  tips?: InputMaybe<Scalars['Boolean']>;
  travelExpenseCompensation?: InputMaybe<Scalars['Boolean']>;
};

export type CreateWorkShiftSlot = {
  contactPersonId?: InputMaybe<Scalars['ID']>;
  customContactPerson?: InputMaybe<Scalars['String']>;
  endTime: Scalars['Time'];
  resourceQuantity: Scalars['Uint32'];
  startTime: Scalars['Time'];
  workShiftId: Scalars['ID'];
};

export type DeclineResult = {
  __typename?: 'DeclineResult';
  error?: Maybe<Scalars['String']>;
  /**
   * mandatoryMissing contain ids for Booking that was required to Decline or Undo Decline for, but was not provided
   * in input lists of ids. Reason for being required is sameResources requirement on workShift.
   */
  mandatoryMissing?: Maybe<Array<Scalars['ID']>>;
  /**
   * success tells if the request was successfully fulfilled or not. If not true 'mandatoryMissing' contains id
   * of Bookings that was required but missing.
   */
  success: Scalars['Boolean'];
  updated: Array<Booking>;
};

export type Device = {
  __typename?: 'Device';
  createdAt: Scalars['Time'];
  deviceId: Scalars['String'];
  id: Scalars['ID'];
  updatedAt: Scalars['Time'];
};

export type DeviceInput = {
  deviceId: Scalars['String'];
  fcmToken?: InputMaybe<Scalars['String']>;
  pushKitToken?: InputMaybe<Scalars['String']>;
};

export enum DeviceOperation {
  Add = 'add',
  Remove = 'remove'
}

export type DistributeUserToSlotsInput = {
  resource: Scalars['ID'];
  workShiftId: Scalars['ID'];
  /** workShiftSlots takes an array if ids for slots that the user should be distributed among. */
  workShiftSlots: Array<Scalars['ID']>;
};

export enum ExtraBookingStatus {
  /** Real status is approved_by_contact */
  ApprovedByContact = 'approved_by_contact',
  /** Real status is awaiting_contact_approval */
  AwaitingContactApproval = 'awaiting_contact_approval',
  /** Real status is job_accepted, booking status was job_accepted and the booking is canceled */
  Canceled = 'canceled',
  /** Real status is job_accepted, booking status was job_accepted and the booking is canceled and resource has confirmed canceled */
  ConfirmedCanceled = 'confirmed_canceled',
  /** Real status is contact_declined_time_report */
  ContactDeclinedTimeReport = 'contact_declined_time_report',
  /**
   * Real status is rfi_declined_by_resource or job_offer_declined_by_resource, means that resource have indicated they're not
   * interested to accept 'job_offer'
   */
  DeclinedByResource = 'declined_by_resource',
  /** Real status is request_for_interest, interested or job_offer and the company contact cancels the booking. */
  Denied = 'denied',
  /** Real status is employer_reported_no_show */
  EmployerReportedNoShow = 'employer_reported_no_show',
  /** Real status is interested */
  Interested = 'interested',
  /** Real status is job_accepted */
  JobAccepted = 'job_accepted',
  /** Real status is job_offer */
  JobOffer = 'job_offer',
  /** Real status is job_accepted where timer tracking end is not null but time report is not sent */
  NotReported = 'not_reported',
  /** Real status is job_accepted where the booking startTime has passed, but the Resource has not started the job in the ResourceApp */
  NotStarted = 'not_started',
  /** Real status is job_accepted and the resource has started the job in the resource app. */
  Ongoing = 'ongoing',
  /** Real status is paid */
  Paid = 'paid',
  /**
   * real status is passed_due_no_response. Passed_due_no_response is set by cron job, meaning that if an Booking
   * has this status, it's always true. BUT it's not certain that all Bookings that has
   * fallen due to lack of response within the last 24 hours has this status. If cron
   * job has not yet been run, the status is still job_offer OR request_for_interest.
   */
  PassedDueNoResponse = 'passed_due_no_response',
  /** Real status is request_for_interest.  Resource has been invited with a RFI by CompanyContact */
  RequestForInterest = 'request_for_interest',
  /** Real status is resource_reported_sick */
  ResourceReportedSick = 'resource_reported_sick'
}

export type ExtraData = Booking;

export type File = {
  __typename?: 'File';
  contentType: Scalars['String'];
  createdAt: Scalars['Time'];
  filename: Scalars['String'];
  id: Scalars['ID'];
  originalName: Scalars['String'];
  sizeInBytes: Scalars['Uint64'];
  uri: Scalars['String'];
};

export type FileCollection = {
  __typename?: 'FileCollection';
  edges: Array<File>;
  meta: PageInfo;
};

export type FileFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type FileUpdate = {
  file?: InputMaybe<Scalars['Upload']>;
  id?: InputMaybe<Scalars['ID']>;
};

export type FilesUpdate = {
  files: Array<FileUpdate>;
};

export type FloatRangeFilter = {
  from?: InputMaybe<Scalars['Float']>;
  to?: InputMaybe<Scalars['Float']>;
};

export type HistoryBooking = {
  __typename?: 'HistoryBooking';
  breakMinutes: Scalars['Uint32'];
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
  status: BookingStatus;
  suggestedEnd?: Maybe<Scalars['Time']>;
  suggestedStart?: Maybe<Scalars['Time']>;
  timeReport?: Maybe<TimeReport>;
  timerTracking?: Maybe<TimerTracking>;
  updatedAt: Scalars['Time'];
  updatedBy?: Maybe<User>;
  updatedById: Scalars['ID'];
};

export type Image = {
  __typename?: 'Image';
  contentType: Scalars['String'];
  createdAt: Scalars['Time'];
  filename: Scalars['String'];
  height: Scalars['Uint32'];
  id: Scalars['ID'];
  originalName: Scalars['String'];
  sizeInBytes: Scalars['Uint64'];
  uri: Scalars['String'];
  width: Scalars['Uint32'];
};

export type ImageCollection = {
  __typename?: 'ImageCollection';
  edges: Array<Image>;
  meta: PageInfo;
};

export type ImageFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type ImageUpdate = {
  id?: InputMaybe<Scalars['ID']>;
  image?: InputMaybe<Scalars['Upload']>;
};

export type IntRangeFilter = {
  from?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Scalars['Int']>;
};

export enum Language {
  English = 'english',
  Swedish = 'swedish'
}

/**
 * LegacyImport must only include one of the documents;
 *
 * customers, resources, acceptedBookings (by resource accepted job offers)
 */
export type LegacyImport = {
  acceptedBookings?: InputMaybe<Scalars['Upload']>;
  customers?: InputMaybe<Scalars['Upload']>;
  resources?: InputMaybe<Scalars['Upload']>;
};

export type Location = {
  __typename?: 'Location';
  billingCity: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingReference: Scalars['String'];
  billingStreet: Scalars['String'];
  city: Scalars['String'];
  company: Company;
  contactUser: User;
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  eInvoice?: Maybe<Scalars['String']>;
  files: Array<File>;
  id: Scalars['ID'];
  image?: Maybe<Image>;
  latitude?: Maybe<Scalars['String']>;
  legacyCustomerId?: Maybe<Scalars['Int']>;
  logo?: Maybe<Image>;
  longitude?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  organisationNumber: Scalars['String'];
  postalCode: Scalars['String'];
  region: Region;
  street: Scalars['String'];
  updatedAt: Scalars['Time'];
  web?: Maybe<Scalars['String']>;
};

export type LocationCollection = {
  __typename?: 'LocationCollection';
  edges: Array<Location>;
  meta: PageInfo;
};

export type LocationFilter = {
  companyId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type Message = {
  __typename?: 'Message';
  channel: Channel;
  createdAt: Scalars['Time'];
  /** null for system messages */
  createdBy?: Maybe<User>;
  extra?: Maybe<ExtraData>;
  files: Array<File>;
  id: Scalars['ID'];
  images: Array<Image>;
  message: Scalars['String'];
  position: Scalars['Uint32'];
  senderName: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
};

export type MessageCollection = {
  __typename?: 'MessageCollection';
  edges: Array<Message>;
  meta: PageInfo;
};

export type MessageFilter = {
  channelId: Scalars['ID'];
  deleted?: InputMaybe<Scalars['Boolean']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
};

export type MessageInput = {
  channelId: Scalars['ID'];
  files?: InputMaybe<Array<Scalars['Upload']>>;
  images?: InputMaybe<Array<Scalars['Upload']>>;
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * acceptApplyInvitations will validate provided ids - and if all ids are valid - set booking status to either
   * 'job_accepted' or 'interested' depending on requested action.
   *
   * provided ids can only relate to one single user, else an error will be returned.
   */
  acceptApplyInvitations: AcceptApplyInvitationsResult;
  /**
   * acceptSuggestedTimes will accept suggested times by replacing booking start and end times with suggested times
   * given that (1) user is available on suggested times, (2) the input confirmed times matching suggested times,
   * (3) bookings has status 'job_accepted'. For bookings where the user is not available or times does not match, id for
   * such bookings will be returned in the response object.
   */
  acceptSuggestedTimes: AcceptSuggestedTimesResult;
  /** deleteCompany, deletes a company and belonging user. roles:[admin] */
  activateCompany?: Maybe<Company>;
  /** addRestaurantProfileFile, updates a user restaurant profile files, taking the user id as the id param */
  addRestaurantProfileFiles?: Maybe<RestaurantProfile>;
  addUsersToResourceCategory?: Maybe<ResourceCategory>;
  /** approveTimeReport takes a list of booking ids to approve setting the status to approved_by_contact. */
  approveTimeReport: Array<Booking>;
  /** broadcast, creates and sends a broadcast taking user ids as parameter */
  broadcast?: Maybe<ChatGroup>;
  /**
   * broadcastMessage, used for admins or company users to initiate conversation to users,
   * creates a new channel if none exists or previous channel if found.
   * Returns success if message is sent to all users, an error means message may not have
   * been sent to all users.
   * Use subscription to check for channel update.
   * requires either a work shift id or a chat group id to originate from.
   */
  broadcastMessage: NoContent;
  /**
   * cancelBookings sets cancelAt time stamp on cancelable bookings for provided workShift, slots and users.
   *
   * If no user ids or slots is provided, the whole workShift and any not yet started slot will be set as cancelled.
   *
   * If no users ids is provided - but slots are - only those slots will be set as cancelled, not the workShift itself.
   *
   * If workShift have same resources requirement set true, param 'slots' cannot be provided along with param
   * 'users', since all not started slots within the workShift shall be cancelled for any provided resources.
   */
  cancelBookings: Scalars['Int'];
  /** cancelNonAcceptedBookings, delete all non accepted bookings on a work shift slot */
  cancelNonAcceptedBookings: NoContent;
  /**
   * closeChatGroup closes a chat group, taking id of that group
   * If no message is provided it will simply close the chat group for all users, removing it from their feed
   */
  closeChatGroup?: Maybe<ChatGroup>;
  /** confirmBookingCanceled, takes id of booking, marks that the user has confirmed that the booking is canceled */
  confirmBookingCanceled?: Maybe<Booking>;
  /** createChannel creates a chat channel */
  createChannel?: Maybe<Channel>;
  /**
   * createCompany, Creates a new company with beloning user
   * If user is not an admin, the created user will be blocked and company active
   * will be set as false, util activateCompany is called upon the company
   */
  createCompany?: Maybe<Company>;
  /** createCompanyUser creates a user for company,  id is company id. roles:[company_admin, admin] */
  createCompanyUser?: Maybe<User>;
  /** createLocation, Creates a new location with beloning user */
  createLocation?: Maybe<Location>;
  createResourceCategory?: Maybe<ResourceCategory>;
  createResourceLevel?: Maybe<ResourceLevel>;
  createResourceRegionalCategory?: Maybe<ResourceRegionalCategory>;
  /** createUser, create a new user */
  createUser: AuthenticationResponse;
  createUserEvent?: Maybe<UserEvent>;
  /** createWorkShift, Creates a new WorkShift. */
  createWorkShift?: Maybe<WorkShift>;
  /** createWorkShiftTemplate, Creates a new WorkShiftTemplate. */
  createWorkShiftTemplate?: Maybe<WorkShiftTemplate>;
  /**
   * declineBookings will validate provided ids - and if all ids are valid - set booking status to either
   * 'job_offer_declined_by_resource' or 'rfi_declined_by_resource' depending on current status. Only
   * bookings with current status 'job_offer', 'request_for_interest', or 'interested' can be declined.
   * If booking provided in input is already declined it will just be ignored.
   *
   * provided ids can only relate to one single user, else an error will be returned.
   */
  declineBookings: DeclineResult;
  /** declineSuggestedTimes declines the suggested time removing it. */
  declineSuggestedTimes?: Maybe<Booking>;
  /** declineTimeReport takes a booking id to decline setting the status to contact_declined_time_report. */
  declineTimeReport?: Maybe<Booking>;
  /** deleteChannel deletes a chat channel */
  deleteChannel: NoContent;
  /** deleteChatGroup deletes a chat group, existing channels created will not be affected. */
  deleteChatGroup: NoContent;
  /** deleteCompany, deletes a company and belonging user. roles:[admin] */
  deleteCompany?: Maybe<NoContent>;
  /** deleteLocation, deletes a location and belonging user. roles:[company_admin, admin] */
  deleteLocation?: Maybe<NoContent>;
  /** deleteLocationRecommendation, removes a location, only possible if user wrote the recommendation or is admin for company or location */
  deleteLocationRecommendation: NoContent;
  deleteResourceCategory: NoContent;
  deleteResourceLevel: NoContent;
  /** deleteRestaurantProfileExperience, deletes an user restaurant profile work experience, taking the id of the user as id param and experienceId to be deleted */
  deleteRestaurantProfileExperience?: Maybe<RestaurantProfile>;
  /** deleteRestaurantProfileFiles, deletes a user restaurant profile files for which id is provided in input array, taking the user id as the id param */
  deleteRestaurantProfileFiles?: Maybe<RestaurantProfile>;
  /** deleteRestaurantProfileReferences, deletes an user restaurant profile work reference, taking the id of the user as id param and referenceId to be deleted */
  deleteRestaurantProfileReference?: Maybe<RestaurantProfile>;
  /** deleteRestaurantProfileTags, deletes the current user restaurant profile selected tags */
  deleteRestaurantProfileTags?: Maybe<User>;
  /** deleteTag deletes a tag */
  deleteTag: NoContent;
  /** deleteUser, removes a user in the system */
  deleteUser: NoContent;
  deleteUserEvent: NoContent;
  deleteWorkShift: NoContent;
  /** deleteWorkShiftSlot, deletes a WorkShiftSlot. */
  deleteWorkShiftSlot: NoContent;
  deleteWorkShiftTemplate: NoContent;
  endTimer: Booking;
  /** exportBookingsCSV takes a booking filter and exports all of them to a csv file, returning the download link */
  exportBookingsCSV?: Maybe<Scalars['String']>;
  /** hideChannel, hides the channel for the current user taking id of the channel */
  hideChannel: NoContent;
  /** upsertRecommendation, hides a users recommendation taking the id of the recommendation to hide */
  hideRecommendation: NoContent;
  /**
   * initChatGroup, used for resources to initiate channel to a chat group,
   * returns a new channel if none exists or previous channel if found.
   * Used for starting conversation with a chat group
   * ID is chat group id
   */
  initChatGroupChannel?: Maybe<Channel>;
  /** invertBroadcast, creates and sends a broadcast taking user ids as parameter */
  invertBroadcast?: Maybe<ChatGroup>;
  /** loginDevice, ame as loginEmail but takes device id as param as well */
  loginDevice: AuthenticationResponse;
  /** loginEmail, This endpoint is used by users with a valid email and password. Get access to the website with the jwt token */
  loginEmail: AuthenticationResponse;
  /** manageUserDevices, adds or removes a user device */
  manageUserDevices: NoContent;
  /**
   * messageChatGroup, used for resources to initiate conversation to a chat group,
   * returns a new channel if none exists or previous channel if found.
   * Used for starting conversation with a chat group
   * ID is chat group id
   */
  messageChatGroup?: Maybe<Channel>;
  /**
   * messageWorkShift, used for resources to initiate conversation to a work shift,
   * returns a new channel if none exists or previous channel if found.
   * Used to start conversation with a work shift
   * ID is work shift id
   */
  messageWorkShift?: Maybe<Channel>;
  /** reSendUserInvite, resend the activation email */
  reSendUserInvite: NoContent;
  /** upsertRecommendation, creates or updates a recommendation, id is the user to apply recommendation on */
  recommendLocation?: Maybe<Recommendation>;
  /** refreshSession, takes a refresh token and returns a new AuthenticationResponse if valid */
  refreshSession: AuthenticationResponse;
  removeUsersFromResourceCategory?: Maybe<ResourceCategory>;
  /** reportNoShow will set booking to status 'employer_reported_no_show'. */
  reportNoShow: Booking;
  /**
   * reportSick will set booking to status 'resource_reported_sick' in 'undo' null or false.
   *
   * If 'undo' is true, the booking will be set back to 'job_accepted', and resource is expected to work or to provide
   * time report if already completed. If booking was previously cancelled, the pay will be calculated according to
   * CancelPolicy as if the Resource never was reported sick.
   *
   * If 'undo' is true but the slot is fully booked, 'undoOption' is required, if not error will be returned.
   */
  reportSick: Booking;
  /**
   * resendBroadcast, adds a new message to the broadcast and sends to existing group, taking chatGroup id as parameter
   * if param new is true, a new broadcast is created to the same selection. Else a follow up message is sent to current broadcast.
   */
  resendBroadcast?: Maybe<ChatGroup>;
  /** resendVerifyEmail, resends the users email */
  resendVerifyEmail?: Maybe<User>;
  /** resetPasswordFinalize, finilize the reset of password and returns a jwt token. */
  resetPasswordFinalize: AuthenticationResponse;
  /** resetPasswordInitiate, sends an email to the user for initiation of reset password. */
  resetPasswordInitiate: NoContent;
  /** sendMessage sends a chat message to chat channel */
  sendMessage: NoContent;
  sendNotification: NoContent;
  /**
   * sendWorkShiftSlotInvitations, creates an invitation for relevant resources to either 'Accept' or 'Confirm Interest' depending on
   * type of workShift ('job_offer' OR 'request_for_interest').
   */
  sendWorkShiftSlotInvitations: NoContent;
  /** ignoreLocation, sets a location to be ignored for this user in searches, id is the location */
  setIgnoreLocation?: Maybe<User>;
  /** setInvoiced takes a list of booking ids that are to be set as invoiced */
  setInvoiced: Array<Booking>;
  /** setLocationFavourite, adds a user to a locations favourite list, takes id for location and userId for user, bool param to set or unset */
  setLocationFavourite?: Maybe<User>;
  /** setLocationHidden, adds a user to a locations hidden list, takes id for location and userId for user, bool param to set or unset */
  setLocationHidden?: Maybe<User>;
  /** setMessagesAsRead updates when the participant last read a message */
  setMessagesAsRead: NoContent;
  startTimer: Booking;
  /**
   * suggestNewTimes applies suggested times and return updated bookings given that user is available on suggested times
   * and bookings has status 'job_accepted' or 'interested'. If booking has status 'interested', the booking
   * will change status to 'job_offer'.
   */
  suggestNewTimes: SuggestNewTimesResult;
  /** toggleBlockUser, takes the id of the user and toggles its current blocked state */
  toggleBlockUser: User;
  /**
   * undoDeclineBookings will validate provided ids - and if all ids are valid - set booking status to either
   * 'job_offer' or 'request_for_interest' depending on current status. Only bookings not passed due, with current status
   * 'job_offer_declined_by_resource' or 'rfi_declined_by_resource' can be declined.
   * If booking provided in input is not declined it will just be ignored.
   *
   * provided ids can only relate to one single user, else an error will be returned.
   */
  undoDeclineBookings: UndoDeclineResult;
  /** updateChannel updates a chat channel */
  updateChannel?: Maybe<Channel>;
  /** updateCompany, updates a company, optional to update user. roles:[company_admin, admin] */
  updateCompany?: Maybe<Company>;
  /** updateLocation, updates a location, optional to update user. roles:[company_admin, admin] */
  updateLocation?: Maybe<Location>;
  /** updateLocationFiles, updates a locations files empty array deletes all images, roles:[company_user, company_admin, admin] */
  updateLocationFiles?: Maybe<Location>;
  /** updateLocationImages updates a locations image and/or logo empty removes image. roles:[company_user, company_admin, admin] */
  updateLocationImages?: Maybe<Location>;
  updateResourceCategory?: Maybe<ResourceCategory>;
  updateResourceLevel?: Maybe<ResourceLevel>;
  updateResourceRegionalCategory?: Maybe<ResourceRegionalCategory>;
  /**
   * updateSlotsForWorkShift, Updates, and Create according to the list of workShiftSlots provided.
   * Existing slots that are left out from list of workShiftSlots will be deleted.
   * Empty array will delete any existing.
   */
  updateSlotsForWorkShift: WorkShift;
  /** updateUser, update a user in the system */
  updateUser?: Maybe<User>;
  updateUserEvent?: Maybe<UserEvent>;
  /** updateWorkShift, Updates a WorkShift. */
  updateWorkShift?: Maybe<WorkShift>;
  /** updateWorkShiftTemplate, Updates an exsting WorkShiftTemplate. */
  updateWorkShiftTemplate?: Maybe<WorkShiftTemplate>;
  /**
   * upsertChatGroup creates a chat group, mediator for creating channels.
   * If id is null, a new group will be created, otherwise it will be updated
   */
  upsertChatGroup?: Maybe<ChatGroup>;
  /** upsertCompanyProfile, creates or updates a user company profile, taking the user id as the id param */
  upsertCompanyProfile?: Maybe<User>;
  /** upsertCompanyResourceCategories takes id of company and list of resource categories to assign a company, removing any not in list */
  upsertCompanyResourceCategories?: Maybe<Company>;
  /** upsertLegacyData creates companies, resources, or accepted bookings (with corresponding workShift and workShiftSlot) */
  upsertLegacyData: NoContent;
  /** upsertRecommendation, creates or updates a recommendation, id is the user to apply recommendation on */
  upsertRecommendation?: Maybe<Recommendation>;
  /**
   * upsertResourceCategoriesFor, updates the resource categories assigned for a resource, for which it can be invited
   * to work as. If array empty, no resource category will longer be assigned for the resource.
   */
  upsertResourceCategoriesFor: Array<ResourceCategory>;
  /** upsertRestaurantProfile, creates or updates a user restaurant profile, taking the user id as the id param */
  upsertRestaurantProfile?: Maybe<User>;
  /** upsertRestaurantProfileExperience, updates or creates an user restaurant profile work experience, taking the user id as the id param */
  upsertRestaurantProfileExperience?: Maybe<RestaurantProfile>;
  /** updateRestaurantProfileReferences, updates a user restaurant profile work references, taking the user id as the id param */
  upsertRestaurantProfileReferences?: Maybe<RestaurantProfile>;
  /** upsertRestaurantProfileTags, adds selected tags to the current user restaurant profile, removing all tags that are not in the list of ids */
  upsertRestaurantProfileTags?: Maybe<User>;
  /** upsertTag, creates or updates a tag */
  upsertTag?: Maybe<Tag>;
  upsertTimeReport: Booking;
  /** upsertUserIdentification, creates or updates a users identification image */
  upsertUserIdentification?: Maybe<User>;
  /** userChangeImage */
  userChangeImage?: Maybe<User>;
  /** userChangePassword, change the password of the user */
  userChangePassword: NoContent;
  /**
   * verifyBankIdInit, takes the current user and triggers bankId verification start with the users social security,
   * returning the auth token for bank id app open
   */
  verifyBankIdInit?: Maybe<BankIdVerification>;
  /**
   * verifyBankIdResolve, takes the current user and polls bank id verification.
   * The mutation will poll bankId for authentication for 20 seconds, after witch it will fail.
   * A success response updates the users verified field.
   * Any other outcome will return an error
   */
  verifyBankIdResolve?: Maybe<User>;
  /** verifyEmail, sets user  email to verified, consuming nonce */
  verifyEmail?: Maybe<User>;
  /** verifyUser verifies a users identification */
  verifyUser?: Maybe<User>;
};


export type MutationAcceptApplyInvitationsArgs = {
  input: AcceptApplyInvitationsInput;
};


export type MutationAcceptSuggestedTimesArgs = {
  input: Array<BookingConfirmTimesInput>;
};


export type MutationActivateCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationAddRestaurantProfileFilesArgs = {
  id: Scalars['ID'];
  input: Array<Scalars['Upload']>;
};


export type MutationAddUsersToResourceCategoryArgs = {
  id: Scalars['ID'];
  input: UpdateResourcesInput;
};


export type MutationApproveTimeReportArgs = {
  bookingIds: Array<Scalars['ID']>;
};


export type MutationBroadcastArgs = {
  message: Scalars['String'];
  title: Scalars['String'];
  userIds: Array<Scalars['ID']>;
};


export type MutationBroadcastMessageArgs = {
  chatGroupId?: InputMaybe<Scalars['ID']>;
  filter: UserFilter;
  message: Scalars['String'];
  slotId?: InputMaybe<Scalars['ID']>;
  workShiftId?: InputMaybe<Scalars['ID']>;
};


export type MutationCancelBookingsArgs = {
  input: CancelInput;
};


export type MutationCancelNonAcceptedBookingsArgs = {
  id: Scalars['ID'];
};


export type MutationCloseChatGroupArgs = {
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
};


export type MutationConfirmBookingCanceledArgs = {
  id: Scalars['ID'];
};


export type MutationCreateChannelArgs = {
  input: CreateChannelInput;
};


export type MutationCreateCompanyArgs = {
  input: CreateCompanyInput;
};


export type MutationCreateCompanyUserArgs = {
  id: Scalars['ID'];
  input: CreateCompanyUserInput;
};


export type MutationCreateLocationArgs = {
  input: CreateLocationInput;
};


export type MutationCreateResourceCategoryArgs = {
  input: CreateResourceCategory;
};


export type MutationCreateResourceLevelArgs = {
  input: UpsertResourceLevel;
};


export type MutationCreateResourceRegionalCategoryArgs = {
  input: CreateResourceRegionalCategory;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserEventArgs = {
  input: UserEventInput;
};


export type MutationCreateWorkShiftArgs = {
  input: CreateWorkShiftInput;
};


export type MutationCreateWorkShiftTemplateArgs = {
  input: WorkShiftTemplateInput;
};


export type MutationDeclineBookingsArgs = {
  input: Array<Scalars['ID']>;
};


export type MutationDeclineSuggestedTimesArgs = {
  id: Scalars['ID'];
};


export type MutationDeclineTimeReportArgs = {
  bookingId: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationDeleteChannelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteChatGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteCompanyArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLocationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteLocationRecommendationArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteResourceCategoryArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteResourceLevelArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteRestaurantProfileExperienceArgs = {
  experienceId: Scalars['ID'];
  id: Scalars['ID'];
};


export type MutationDeleteRestaurantProfileFilesArgs = {
  id: Scalars['ID'];
  input: Array<Scalars['ID']>;
};


export type MutationDeleteRestaurantProfileReferenceArgs = {
  id: Scalars['ID'];
  referenceId: Scalars['ID'];
};


export type MutationDeleteRestaurantProfileTagsArgs = {
  input: Array<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationDeleteTagArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteUserEventArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkShiftArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkShiftSlotArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteWorkShiftTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationEndTimerArgs = {
  bookingId: Scalars['ID'];
  input: TimerTrackingInput;
};


export type MutationExportBookingsCsvArgs = {
  filter?: InputMaybe<BookingFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type MutationHideChannelArgs = {
  id: Scalars['ID'];
};


export type MutationHideRecommendationArgs = {
  id: Scalars['ID'];
};


export type MutationInitChatGroupChannelArgs = {
  id: Scalars['ID'];
};


export type MutationInvertBroadcastArgs = {
  excludedUserIds: Array<Scalars['ID']>;
  filter: UserFilter;
  message: Scalars['String'];
  title: Scalars['String'];
};


export type MutationLoginDeviceArgs = {
  device: DeviceInput;
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationLoginEmailArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationManageUserDevicesArgs = {
  device: DeviceInput;
  operation: DeviceOperation;
};


export type MutationMessageChatGroupArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationMessageWorkShiftArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
};


export type MutationReSendUserInviteArgs = {
  id: Scalars['ID'];
};


export type MutationRecommendLocationArgs = {
  input: UpsertRecommendation;
};


export type MutationRefreshSessionArgs = {
  refreshToken: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationRemoveUsersFromResourceCategoryArgs = {
  id: Scalars['ID'];
  input: UpdateResourcesInput;
};


export type MutationReportNoShowArgs = {
  bookingId: Scalars['ID'];
  undo?: InputMaybe<Scalars['Boolean']>;
};


export type MutationReportSickArgs = {
  bookingId: Scalars['ID'];
  undo?: InputMaybe<Scalars['Boolean']>;
  undoOption?: InputMaybe<UndoReportSickSlotFullOptions>;
};


export type MutationResendBroadcastArgs = {
  id: Scalars['ID'];
  message: Scalars['String'];
  new: Scalars['Boolean'];
  title?: InputMaybe<Scalars['String']>;
};


export type MutationResendVerifyEmailArgs = {
  id: Scalars['ID'];
};


export type MutationResetPasswordFinalizeArgs = {
  input: ResetPasswordFinalizeInput;
};


export type MutationResetPasswordInitiateArgs = {
  email: Scalars['String'];
};


export type MutationSendMessageArgs = {
  input: MessageInput;
};


export type MutationSendNotificationArgs = {
  admins: Scalars['Boolean'];
  companyId?: InputMaybe<Scalars['ID']>;
  input: NotificationInput;
  locationId?: InputMaybe<Scalars['ID']>;
  receiver: Scalars['ID'];
};


export type MutationSendWorkShiftSlotInvitationsArgs = {
  input: WorkShiftSlotsInvitationInput;
};


export type MutationSetIgnoreLocationArgs = {
  id: Scalars['ID'];
  ignore: Scalars['Boolean'];
};


export type MutationSetInvoicedArgs = {
  bookingIds: Array<Scalars['ID']>;
};


export type MutationSetLocationFavouriteArgs = {
  favourite: Scalars['Boolean'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationSetLocationHiddenArgs = {
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
};


export type MutationSetMessagesAsReadArgs = {
  channelId: Scalars['ID'];
};


export type MutationStartTimerArgs = {
  bookingId: Scalars['ID'];
  input: TimerTrackingInput;
};


export type MutationSuggestNewTimesArgs = {
  input: Array<SuggestTimeInput>;
};


export type MutationToggleBlockUserArgs = {
  id: Scalars['ID'];
};


export type MutationUndoDeclineBookingsArgs = {
  input: Array<Scalars['ID']>;
};


export type MutationUpdateChannelArgs = {
  id: Scalars['ID'];
  input: UpdateChannelInput;
};


export type MutationUpdateCompanyArgs = {
  id: Scalars['ID'];
  input: UpdateCompanyInput;
};


export type MutationUpdateLocationArgs = {
  id: Scalars['ID'];
  input: UpdateLocationInput;
};


export type MutationUpdateLocationFilesArgs = {
  id: Scalars['ID'];
  input: FilesUpdate;
};


export type MutationUpdateLocationImagesArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<UpdateLocationImages>;
};


export type MutationUpdateResourceCategoryArgs = {
  id: Scalars['ID'];
  input: UpdateResourceCategory;
};


export type MutationUpdateResourceLevelArgs = {
  id: Scalars['ID'];
  input: UpsertResourceLevel;
};


export type MutationUpdateResourceRegionalCategoryArgs = {
  id: Scalars['ID'];
  input: UpdateResourceRegionalCategory;
};


export type MutationUpdateSlotsForWorkShiftArgs = {
  id: Scalars['ID'];
  workShiftSlots: Array<UpsertWorkShiftSlot>;
};


export type MutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};


export type MutationUpdateUserEventArgs = {
  id: Scalars['ID'];
  input: UserEventInput;
};


export type MutationUpdateWorkShiftArgs = {
  id: Scalars['ID'];
  input: UpdateWorkShiftInput;
};


export type MutationUpdateWorkShiftTemplateArgs = {
  id: Scalars['ID'];
  input: WorkShiftTemplateInput;
};


export type MutationUpsertChatGroupArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: UpsertChatGroupInput;
};


export type MutationUpsertCompanyProfileArgs = {
  id: Scalars['ID'];
  input: UpsertCompanyProfile;
};


export type MutationUpsertCompanyResourceCategoriesArgs = {
  categories: Array<Scalars['ID']>;
  id: Scalars['ID'];
};


export type MutationUpsertLegacyDataArgs = {
  input: LegacyImport;
};


export type MutationUpsertRecommendationArgs = {
  input: UpsertRecommendation;
  userId: Scalars['ID'];
};


export type MutationUpsertResourceCategoriesForArgs = {
  categories: Array<Scalars['ID']>;
  resourceId: Scalars['ID'];
};


export type MutationUpsertRestaurantProfileArgs = {
  id: Scalars['ID'];
  input: UpsertRestaurantProfile;
};


export type MutationUpsertRestaurantProfileExperienceArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: Array<UpsertRestaurantWorkExperience>;
};


export type MutationUpsertRestaurantProfileReferencesArgs = {
  id?: InputMaybe<Scalars['ID']>;
  input: UpsertWorkReference;
};


export type MutationUpsertRestaurantProfileTagsArgs = {
  input: Array<Scalars['ID']>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type MutationUpsertTagArgs = {
  input?: InputMaybe<UpsertTag>;
};


export type MutationUpsertTimeReportArgs = {
  bookingId: Scalars['ID'];
  input: TimeReportInput;
};


export type MutationUpsertUserIdentificationArgs = {
  input: UserIdentification;
};


export type MutationUserChangeImageArgs = {
  id: Scalars['ID'];
  input?: InputMaybe<ImageUpdate>;
};


export type MutationUserChangePasswordArgs = {
  input: UserChangePasswordInput;
};


export type MutationVerifyBankIdInitArgs = {
  socialSecurity: Scalars['String'];
};


export type MutationVerifyBankIdResolveArgs = {
  orderRef: Scalars['String'];
};


export type MutationVerifyEmailArgs = {
  email: Scalars['String'];
  nonce: Scalars['String'];
};


export type MutationVerifyUserArgs = {
  id: Scalars['ID'];
};

export type NoContent = {
  __typename?: 'NoContent';
  success: Scalars['Boolean'];
};

export type Notification = {
  __typename?: 'Notification';
  background: Scalars['Boolean'];
  booking?: Maybe<Booking>;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  message: Scalars['String'];
  referenceId?: Maybe<Scalars['ID']>;
  referenceType?: Maybe<NotificationReferenceType>;
  sender?: Maybe<User>;
  title: Scalars['String'];
  type: NotificationType;
};

export type NotificationCollection = {
  __typename?: 'NotificationCollection';
  edges: Array<Notification>;
  meta: PageInfo;
};

export type NotificationFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  sender?: InputMaybe<Scalars['ID']>;
  type?: InputMaybe<Array<NotificationType>>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type NotificationInput = {
  background: Scalars['Boolean'];
  message: Scalars['String'];
  referenceId?: InputMaybe<Scalars['ID']>;
  referenceType?: InputMaybe<NotificationReferenceType>;
  title: Scalars['String'];
  type: NotificationType;
};

export enum NotificationReferenceType {
  BookingId = 'booking_id',
  CompanyId = 'company_id',
  UserId = 'user_id',
  WorkShiftId = 'work_shift_id',
  WorkShiftSlotId = 'work_shift_slot_id'
}

export enum NotificationType {
  AcceptedJobOffer = 'accepted_job_offer',
  BookingCanceled = 'booking_canceled',
  BookingStartSoon = 'booking_start_soon',
  BookingStarted = 'booking_started',
  ContactTimeChange = 'contact_time_change',
  DeclineJobOffer = 'decline_job_offer',
  DeclineRfi = 'decline_rfi',
  JobOfferAllDeclined = 'job_offer_all_declined',
  NewCompany = 'new_company',
  NewJobOffer = 'new_job_offer',
  NewResource = 'new_resource',
  NoShow = 'no_show',
  RequestForInterest = 'request_for_interest',
  ResourceDeclineTimeChange = 'resource_decline_time_change',
  ResourceTimeChange = 'resource_time_change',
  RfiAccepted = 'rfi_accepted',
  RfiAllDeclined = 'rfi_all_declined',
  TimeReportSubmitted = 'time_report_submitted',
  UserReportedSick = 'user_reported_sick'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  total: Scalars['Int'];
};

export type Participant = {
  __typename?: 'Participant';
  lastReadAt?: Maybe<Scalars['Time']>;
  user: User;
};

export type Query = {
  __typename?: 'Query';
  booking: Booking;
  bookingSlots: BookingSlotsCollection;
  bookings: BookingCollection;
  channels: ChannelCollection;
  chatGroupReceivers: UserCollection;
  chatGroupResponders: UserCollection;
  chatGroups: ChatGroupCollection;
  companies: CompanyCollection;
  company?: Maybe<Company>;
  /**
   * distributeUserToAnyAvailableSlot should be used when a Contact ads a Resource for invitation whenever the list of
   * Resources is filtered according to "Any of the slots".
   * The response contains an array of ids for the workShiftSlots that a resource is available to receive invitation for,
   * meaning resource is not already invited AND is available (in calendar).
   *
   * Note: No validation is made in regards to any workShift criteria such as resourceCategory or Region.
   * That is expected to be done by client already.
   */
  distributeUserToAnyAvailableSlots: Array<Scalars['ID']>;
  location?: Maybe<Location>;
  locations: LocationCollection;
  me?: Maybe<User>;
  messages: MessageCollection;
  myCompanyProfile?: Maybe<CompanyProfile>;
  myRestaurantProfile?: Maybe<RestaurantProfile>;
  notifications: NotificationCollection;
  recommendation?: Maybe<Recommendation>;
  recommendations: RecommendationCollection;
  resourceCategories: ResourceCategoryCollection;
  resourceCategory?: Maybe<ResourceCategory>;
  resourceLevels: ResourceLevelCollection;
  resourceRegionalCategories: ResourceRegionalCategoryCollection;
  resourceRegionalCategory?: Maybe<ResourceRegionalCategory>;
  tag?: Maybe<Tag>;
  tags: TagCollection;
  unreadMessages: Scalars['Int'];
  user?: Maybe<User>;
  userEvent?: Maybe<UserEvent>;
  userEvents: UserEventCollection;
  /** users, required role [company_user, company_admin, admin] */
  users: UserCollection;
  workShift?: Maybe<WorkShift>;
  workShiftSlot?: Maybe<WorkShiftSlot>;
  workShiftSlots: WorkShiftSlotCollection;
  workShiftTemplate?: Maybe<WorkShiftTemplate>;
  workShiftTemplates: WorkShiftTemplateCollection;
  workShifts: WorkShiftCollection;
};


export type QueryBookingArgs = {
  id: Scalars['ID'];
};


export type QueryBookingSlotsArgs = {
  filter?: InputMaybe<BookingSlotsFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryBookingsArgs = {
  filter?: InputMaybe<BookingFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryChannelsArgs = {
  filter?: InputMaybe<ChannelFilter>;
};


export type QueryChatGroupReceiversArgs = {
  filter?: InputMaybe<BaseFilter>;
  id: Scalars['ID'];
};


export type QueryChatGroupRespondersArgs = {
  filter?: InputMaybe<BaseFilter>;
  id: Scalars['ID'];
};


export type QueryChatGroupsArgs = {
  filter?: InputMaybe<ChatGroupFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryCompaniesArgs = {
  filter?: InputMaybe<CompanyFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryCompanyArgs = {
  id: Scalars['ID'];
};


export type QueryDistributeUserToAnyAvailableSlotsArgs = {
  input: DistributeUserToSlotsInput;
};


export type QueryLocationArgs = {
  id: Scalars['ID'];
};


export type QueryLocationsArgs = {
  filter?: InputMaybe<LocationFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryMessagesArgs = {
  filter: MessageFilter;
};


export type QueryNotificationsArgs = {
  filter?: InputMaybe<NotificationFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryRecommendationArgs = {
  id: Scalars['ID'];
};


export type QueryRecommendationsArgs = {
  filter?: InputMaybe<RecommendationFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryResourceCategoriesArgs = {
  filter?: InputMaybe<ResourceCategoryFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryResourceCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryResourceLevelsArgs = {
  filter?: InputMaybe<ResourceLevelFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryResourceRegionalCategoriesArgs = {
  filter?: InputMaybe<ResourceRegionalFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryResourceRegionalCategoryArgs = {
  id: Scalars['ID'];
};


export type QueryTagArgs = {
  id: Scalars['ID'];
};


export type QueryTagsArgs = {
  filter?: InputMaybe<TagFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserEventArgs = {
  id: Scalars['ID'];
};


export type QueryUserEventsArgs = {
  filter: UserEventFilter;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryWorkShiftArgs = {
  id: Scalars['ID'];
};


export type QueryWorkShiftSlotArgs = {
  id: Scalars['ID'];
};


export type QueryWorkShiftSlotsArgs = {
  filter?: InputMaybe<WorkShiftSlotFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryWorkShiftTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryWorkShiftTemplatesArgs = {
  filter?: InputMaybe<WorkShiftTemplateFilter>;
  sorting?: InputMaybe<SortOrdering>;
};


export type QueryWorkShiftsArgs = {
  filter?: InputMaybe<WorkShiftFilter>;
  sorting?: InputMaybe<SortOrdering>;
};

export type Recommendation = {
  __typename?: 'Recommendation';
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  location: Location;
  updatedAt: Scalars['Time'];
};

export type RecommendationCollection = {
  __typename?: 'RecommendationCollection';
  edges: Array<Recommendation>;
  meta: PageInfo;
};

export type RecommendationFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['ID']>;
  offset?: InputMaybe<Scalars['Int']>;
  recommendedBy?: InputMaybe<Scalars['ID']>;
  /** will default do current user if not set */
  user?: InputMaybe<Scalars['ID']>;
};

export enum Region {
  Alvsborg = 'Alvsborg',
  Blekinge = 'Blekinge',
  Dalarna = 'Dalarna',
  Gavleborg = 'Gavleborg',
  Goteborg = 'Goteborg',
  Gotland = 'Gotland',
  Halland = 'Halland',
  Jamtland = 'Jamtland',
  Jonkoping = 'Jonkoping',
  Kalmar = 'Kalmar',
  Kronoberg = 'Kronoberg',
  Malmo = 'Malmo',
  Norrbotten = 'Norrbotten',
  Oland = 'Oland',
  Orebro = 'Orebro',
  Ostergotland = 'Ostergotland',
  Skane = 'Skane',
  Skaraborg = 'Skaraborg',
  Smaland = 'Smaland',
  Sodermanland = 'Sodermanland',
  Stockholm = 'Stockholm',
  Uppsala = 'Uppsala',
  Varmland = 'Varmland',
  Vasterbotten = 'Vasterbotten',
  Vasternorrland = 'Vasternorrland',
  Vastmanland = 'Vastmanland'
}

/**
 * RelatedBooking is a Booking that needs to be accepted at the same time as the parent booking due to
 * sameResource requirement set on workShift
 */
export type RelatedBooking = {
  __typename?: 'RelatedBooking';
  breakMinutes: Scalars['Uint32'];
  createdAt: Scalars['Time'];
  declinedAt?: Maybe<Scalars['Time']>;
  endTime: Scalars['Time'];
  id: Scalars['ID'];
  pricePoint: BookingPricePoint;
  startTime: Scalars['Time'];
  status: BookingStatus;
  updatedAt: Scalars['Time'];
  workShiftId: Scalars['ID'];
};

export type ResetPasswordFinalizeInput = {
  email: Scalars['String'];
  nonce: Scalars['String'];
  password: Scalars['String'];
};

export type ResourceCategory = {
  __typename?: 'ResourceCategory';
  createdAt: Scalars['Time'];
  defaultSalary: Scalars['Uint32'];
  id: Scalars['ID'];
  level: ResourceLevel;
  name: Scalars['String'];
  regional: Array<ResourceRegionalCategory>;
  updatedAt: Scalars['Time'];
  vertical: VerticalCategory;
};


export type ResourceCategoryRegionalArgs = {
  filter?: InputMaybe<BaseFilter>;
};

export type ResourceCategoryCollection = {
  __typename?: 'ResourceCategoryCollection';
  edges: Array<ResourceCategory>;
  meta: PageInfo;
};

export type ResourceCategoryData = {
  __typename?: 'ResourceCategoryData';
  resourceLevel?: Maybe<ResourceLevel>;
  verticalProfileCategory: Scalars['String'];
};

export type ResourceCategoryFilter = {
  /** groupOnName selects distinct names of ResourceCategories */
  groupOnName?: InputMaybe<Scalars['Boolean']>;
  levels?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  names?: InputMaybe<Array<Scalars['String']>>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

/**
 * ResourceInviteFilter will overwrite any value in:
 *
 * 1) UserFilter.resourceCategories (will match workShift)
 *
 * 2) UserFilter.Regions (will match workShift.Location.Region)
 */
export type ResourceInviteFilter = {
  /**
   * inviteToAnyOfSlots return users that is available in their calendar for ALL provided slots, as well as not
   * previously invited to ANY of the provided slots.
   *
   * inviteToAllSlots is mandatory (and only possible) to use for workShift with 'sameResources == true'.
   *
   * inviteToAllSlots should always contain id of ALL slots with 'fullyBooked == false' related to the workShift. The
   * evaluation of which slots are not yet fully booked should be made by client - only once - when the booking session
   * is initialized. (To support a user friendly UX This is not validated in backend!)
   */
  inviteToAllSlots?: InputMaybe<Array<Scalars['ID']>>;
  /**
   * inviteToAnyOfSlots return users that is available in their calendar for at least one of provided slots, for which
   * they are also not already invited.
   *
   * inviteToAnyOfSlots mandatory (and is only possible) to be used for workShift with 'sameResources == false'.
   *
   * If a single slot is selected, only that slot id should be provided.
   *
   * When selection "Any of the slots" have been selected, inviteToAnyOfSlots should always contain id of ALL slots
   * with 'fullyBooked == false' related to the workShift. The evaluation of which slots are not yet fully booked should
   * be made by client - only once - when the booking session is initialized. (To support a user friendly UX this is not
   * validated in backend!)
   */
  inviteToAnyOfSlots?: InputMaybe<Array<Scalars['ID']>>;
  /** workShiftId for the workShift invites are being made. */
  workShiftId: Scalars['ID'];
};

export type ResourceLevel = {
  __typename?: 'ResourceLevel';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['Time'];
};

export type ResourceLevelCollection = {
  __typename?: 'ResourceLevelCollection';
  edges: Array<ResourceLevel>;
  meta: PageInfo;
};

export type ResourceLevelFilter = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  vertical?: InputMaybe<VerticalCategory>;
  /** must be strings matching RestaurantVerticalCategory */
  verticalProfileCategories?: InputMaybe<Array<Scalars['String']>>;
};

export type ResourceRegionalCategory = {
  __typename?: 'ResourceRegionalCategory';
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  modifier: Scalars['Int'];
  price: Scalars['Uint32'];
  region: Region;
  salary: Scalars['Uint32'];
  updatedAt: Scalars['Time'];
};

export type ResourceRegionalCategoryCollection = {
  __typename?: 'ResourceRegionalCategoryCollection';
  edges: Array<ResourceRegionalCategory>;
  meta: PageInfo;
};

export type ResourceRegionalFilter = {
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  resourceCategory?: InputMaybe<Scalars['ID']>;
  searchTerm?: InputMaybe<Scalars['String']>;
};

export type RestaurantProfile = {
  __typename?: 'RestaurantProfile';
  adjacentRegions: Scalars['Boolean'];
  approvedBookings: Scalars['Int'];
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  emergencyContactName?: Maybe<Scalars['String']>;
  emergencyContactPhone?: Maybe<Scalars['String']>;
  favouriteCount: Scalars['Int'];
  files: Array<File>;
  id: Scalars['ID'];
  languages?: Maybe<Array<Language>>;
  rating?: Maybe<Scalars['Int']>;
  region: Region;
  status: Scalars['String'];
  tags: Array<Tag>;
  updatedAt: Scalars['Time'];
  workExperience: Array<RestaurantWorkExperience>;
  workReferences: Array<WorkReference>;
};


export type RestaurantProfileFilesArgs = {
  filter?: InputMaybe<BaseFilter>;
};


export type RestaurantProfileTagsArgs = {
  filter?: InputMaybe<BaseFilter>;
};


export type RestaurantProfileWorkExperienceArgs = {
  filter?: InputMaybe<BaseFilter>;
};


export type RestaurantProfileWorkReferencesArgs = {
  filter?: InputMaybe<BaseFilter>;
};

export enum RestaurantVerticalCategory {
  Bar = 'bar',
  Chef = 'chef',
  Event = 'event',
  Other = 'other',
  Service = 'service',
  Wash = 'wash'
}

export type RestaurantWorkExperience = {
  __typename?: 'RestaurantWorkExperience';
  category: RestaurantVerticalCategory;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  otherWorkExperience?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
  years: Scalars['Uint32'];
};

export type SortOrdering = {
  sorting?: InputMaybe<Array<SortOrderingInput>>;
};

export type SortOrderingInput = {
  direction: Scalars['String'];
  field: Scalars['String'];
};

export type Subscription = {
  __typename?: 'Subscription';
  /** chatPrivateChannels: Subscribe to users channel updates */
  chatPrivateChannels: Channel;
  /** chatPrivateMessages: Subscribe to users channel messages */
  chatPrivateMessages: Message;
  /** chatPublicChannels: Subscribe to public channel updates */
  chatPublicChannels: Channel;
  /** chatPublicMessages: Subscribe to public channel messages */
  chatPublicMessages: Message;
  /** chatSubscribe: Subscribe all updates from all channels and messages */
  chatSubscribe: ChatUpdate;
  /** notificationSubscribe: Subscribe to new notifications */
  notificationSubscribe: Notification;
};

export type SuggestNewTimesResult = {
  __typename?: 'SuggestNewTimesResult';
  error?: Maybe<Scalars['String']>;
  /** notAvailable contain ids of bookings for which the user was not available on suggested times. */
  notAvailable?: Maybe<Array<Scalars['ID']>>;
  /**
   * success tells if the request was successfully fulfilled or not. If not true 'notAvailable'
   * contains ids of bookings for which the user was not available on suggested times.
   */
  success: Scalars['Boolean'];
  updated: Array<Booking>;
};

export type SuggestTimeInput = {
  booking: Scalars['ID'];
  end?: InputMaybe<Scalars['Time']>;
  start?: InputMaybe<Scalars['Time']>;
};

export type Tag = {
  __typename?: 'Tag';
  category: VerticalCategory;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  name: Scalars['String'];
  subcategory: RestaurantVerticalCategory;
  updatedAt: Scalars['Time'];
};

export type TagCollection = {
  __typename?: 'TagCollection';
  edges: Array<Tag>;
  meta: PageInfo;
};

export type TagFilter = {
  category?: InputMaybe<VerticalCategory>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  subcategory?: InputMaybe<Array<RestaurantVerticalCategory>>;
};

export type TemporaryAddress = {
  __typename?: 'TemporaryAddress';
  city: Scalars['String'];
  id: Scalars['ID'];
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  postalCode: Scalars['String'];
  region: Region;
  street: Scalars['String'];
};

export type TemporaryAddressInput = {
  city: Scalars['String'];
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  region?: InputMaybe<Region>;
  street: Scalars['String'];
};

export type TimeRangeFilter = {
  from: Scalars['Time'];
  to: Scalars['Time'];
};

export type TimeReport = {
  __typename?: 'TimeReport';
  reportedBreak?: Maybe<Scalars['Uint32']>;
  reportedEnd?: Maybe<Scalars['Time']>;
  reportedStart?: Maybe<Scalars['Time']>;
};

export type TimeReportInput = {
  reportedBreak: Scalars['Uint32'];
  reportedEnd: Scalars['Time'];
  reportedStart: Scalars['Time'];
};

export type TimerTracking = {
  __typename?: 'TimerTracking';
  timerEnd?: Maybe<Scalars['Time']>;
  timerLat?: Maybe<Scalars['String']>;
  timerLng?: Maybe<Scalars['String']>;
  timerStart?: Maybe<Scalars['Time']>;
};

export type TimerTrackingInput = {
  coordinates?: InputMaybe<Coordinates>;
  time: Scalars['Time'];
};

export type UndoDeclineResult = {
  __typename?: 'UndoDeclineResult';
  error?: Maybe<Scalars['String']>;
  /**
   * mandatoryMissing contain ids for Booking that was required to Decline or Undo Decline for, but was not provided
   * in input lists of ids. Reason for being required is sameResources requirement on workShift.
   */
  mandatoryMissing?: Maybe<Array<Scalars['ID']>>;
  /**
   * passedDue contain ids for Booking that was not possible to set to Undo Decline since the acceptance time have passed
   * due, and thus shall remain with decline status.
   */
  passedDue?: Maybe<Array<Scalars['ID']>>;
  /**
   * success tells if the request was successfully fulfilled or not. If not true 'mandatoryMissing' and/or 'passedDue' contains id
   * of Bookings that was required but missing and/or passedDue bookings.
   */
  success: Scalars['Boolean'];
  updated: Array<Booking>;
};

/** UndoReportSickSlotFullOptions is only required when sick report is undone, and slot is already full. */
export enum UndoReportSickSlotFullOptions {
  /** set_cancelled means the booking will be cancelled as of now (current time) and pay set according to cancel policy. */
  SetCancelled = 'set_cancelled',
  /**
   * shall_or_have_worked should be provided if the recourse shall (non started) or actually have worked (started).
   * The slot 'resource quantity' will then be increased by one and the booking be available for resource to
   * provide time report for.
   */
  ShallOrHaveWorked = 'shall_or_have_worked'
}

export type UpdateChannelInput = {
  name?: InputMaybe<Scalars['String']>;
  userIds: Array<Scalars['ID']>;
};

export type UpdateCompanyInput = {
  billingCity: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingReference: Scalars['String'];
  billingStreet: Scalars['String'];
  category?: InputMaybe<VerticalCategory>;
  city: Scalars['String'];
  commission?: InputMaybe<Scalars['Float']>;
  contactUserId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  eInvoice?: InputMaybe<Scalars['String']>;
  latitude?: InputMaybe<Scalars['String']>;
  longitude?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organisationNumber?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  resourceCategories?: InputMaybe<Array<Scalars['ID']>>;
  street: Scalars['String'];
  web?: InputMaybe<Scalars['String']>;
};

export type UpdateLocationImages = {
  image?: InputMaybe<ImageUpdate>;
  logo?: InputMaybe<ImageUpdate>;
};

export type UpdateLocationInput = {
  billingCity: Scalars['String'];
  billingPostalCode: Scalars['String'];
  billingReference: Scalars['String'];
  billingStreet: Scalars['String'];
  city: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  eInvoice?: InputMaybe<Scalars['String']>;
  image?: InputMaybe<Scalars['Upload']>;
  latitude?: InputMaybe<Scalars['String']>;
  legacyCustomerId?: InputMaybe<Scalars['Int']>;
  logo?: InputMaybe<Scalars['Upload']>;
  longitude?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  organisationNumber?: InputMaybe<Scalars['String']>;
  postalCode: Scalars['String'];
  region: Region;
  street: Scalars['String'];
  userId?: InputMaybe<Scalars['ID']>;
  web?: InputMaybe<Scalars['String']>;
};

export type UpdateResourceCategory = {
  defaultSalary: Scalars['Uint32'];
  levelId: Scalars['ID'];
  /** must be string matching RestaurantVerticalCategory */
  name: Scalars['String'];
};

export type UpdateResourceRegionalCategory = {
  modifier: Scalars['Int'];
};

export type UpdateResourcesInput = {
  userIds: Array<Scalars['ID']>;
};

export type UpdateUserInput = {
  city?: InputMaybe<Scalars['String']>;
  email: Scalars['String'];
  firstName: Scalars['String'];
  image?: InputMaybe<Scalars['Upload']>;
  /** internalComment will throw error if user is not admin when trying to set */
  internalComment?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  legacyId?: InputMaybe<Scalars['Int']>;
  mobileNumber?: InputMaybe<Scalars['String']>;
  nextOfKinName?: InputMaybe<Scalars['String']>;
  nextOfKinPhone?: InputMaybe<Scalars['String']>;
  postalCode?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<UserRole>;
  /** must be in format YYYYYMMDDXXXX */
  socialSecurity?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
};

export type UpdateWorkShiftInput = {
  acceptanceTime?: InputMaybe<Scalars['Time']>;
  contactPersonId?: InputMaybe<Scalars['ID']>;
  customContactPerson?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  internalName?: InputMaybe<Scalars['String']>;
  locationId: Scalars['ID'];
  mealDeduction?: InputMaybe<Scalars['Boolean']>;
  minutesPriorStart?: InputMaybe<Scalars['Uint32']>;
  /**
   * If resourceCategories is null, no resourceRegionalCategories will be updated.
   * If resourceCategories is provided, provided input will replace the exiting resourceRegionalCategories.
   */
  resourceCategories?: InputMaybe<WorkShiftResourceCategoryInput>;
  resourceQuantity: Scalars['Uint32'];
  salaryBoost?: InputMaybe<Scalars['Float']>;
  sameResources: Scalars['Boolean'];
  status: WorkShiftStatus;
  temporaryAddress?: InputMaybe<TemporaryAddressInput>;
  tips?: InputMaybe<Scalars['Boolean']>;
  travelExpenseCompensation?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateWorkShiftSlot = {
  contactPersonId?: InputMaybe<Scalars['ID']>;
  customContactPerson?: InputMaybe<Scalars['String']>;
  endTime: Scalars['Time'];
  resourceQuantity: Scalars['Uint32'];
  startTime: Scalars['Time'];
};

export type UpsertChatGroupInput = {
  /** user ids, the permanent members of chat group, with which the user creates a shared channel with */
  members: Array<Scalars['ID']>;
  name: Scalars['String'];
};

export type UpsertCompanyProfile = {
  company: Scalars['ID'];
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  locations: Array<Scalars['ID']>;
};

export type UpsertRecommendation = {
  description?: InputMaybe<Scalars['String']>;
  /** id is current recommendation to update, if empty creates a new one */
  id?: InputMaybe<Scalars['ID']>;
  locationId: Scalars['ID'];
};

export type UpsertResourceLevel = {
  name: Scalars['String'];
};

export type UpsertRestaurantProfile = {
  adjacentRegions?: InputMaybe<Scalars['Boolean']>;
  description?: InputMaybe<Scalars['String']>;
  emergencyContactName?: InputMaybe<Scalars['String']>;
  emergencyContactPhone?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  languages?: InputMaybe<Array<Language>>;
  rating?: InputMaybe<Scalars['Int']>;
  region: Region;
  status: Scalars['String'];
};

export type UpsertRestaurantWorkExperience = {
  category: RestaurantVerticalCategory;
  /** if empty, will create a new work experience */
  id?: InputMaybe<Scalars['ID']>;
  otherWorkExperience?: InputMaybe<Scalars['String']>;
  years: Scalars['Uint32'];
};

export type UpsertTag = {
  category: VerticalCategory;
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
  subcategory: RestaurantVerticalCategory;
};

export type UpsertWorkReference = {
  company: Scalars['String'];
  contactName?: InputMaybe<Scalars['String']>;
  contactPhone?: InputMaybe<Scalars['String']>;
  /** if empty, will create a new work reference */
  id?: InputMaybe<Scalars['ID']>;
  role?: InputMaybe<Scalars['String']>;
};

export type UpsertWorkShiftSlot = {
  contactPersonId?: InputMaybe<Scalars['ID']>;
  customContactPerson?: InputMaybe<Scalars['String']>;
  endTime: Scalars['Time'];
  /** If ID provided an existing WorkShiftSlot will updated and mutation fail if WorkShiftSlot does not exist. */
  id?: InputMaybe<Scalars['ID']>;
  resourceQuantity: Scalars['Uint32'];
  startTime: Scalars['Time'];
};

export type User = {
  __typename?: 'User';
  active: Scalars['Boolean'];
  approved: Scalars['Boolean'];
  /**
   * availableForSlots only has valid data whenever query 'users' is used and UserFilter.inviteFilter.inviteToAnyOfSlots
   * has more than one element.
   *
   * The array represents an array of ids for the workShiftSlots that a resource is available to receive invitaiton for,
   * meaning resource is not already invited AND is available (in calendar).
   *
   * Note: No validation is made in regards to any workShift criteria such as resourceCategory or Region.
   * That is expected to be done by client already.
   */
  availableForSlots?: Maybe<Array<Scalars['ID']>>;
  blocked: Scalars['Boolean'];
  city?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  deletedAt?: Maybe<Scalars['Time']>;
  email: Scalars['String'];
  emailVerified: Scalars['Boolean'];
  /** favourite, set true if the location in current UserFilter has set the user to favourite */
  favourite: Scalars['Boolean'];
  firstName: Scalars['String'];
  /** hidden, set true if the location in current UserFilter has set the user to hidden */
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  identification?: Maybe<Image>;
  image?: Maybe<Image>;
  /** is only visable to admin */
  internalComment?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  legacyId?: Maybe<Scalars['Int']>;
  mobileNumber?: Maybe<Scalars['String']>;
  nextOfKinName?: Maybe<Scalars['String']>;
  nextOfKinPhone?: Maybe<Scalars['String']>;
  postalCode?: Maybe<Scalars['String']>;
  resourceCategories?: Maybe<Array<Maybe<ResourceCategory>>>;
  role: UserRole;
  socialSecurity?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
  userProfile: Array<UserProfile>;
  verificationMethod?: Maybe<VerificationMethod>;
  verified: Scalars['Boolean'];
  verifiedBy?: Maybe<User>;
};

export type UserChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
};

export type UserCollection = {
  __typename?: 'UserCollection';
  edges: Array<User>;
  meta: PageInfo;
};

export type UserEvent = {
  __typename?: 'UserEvent';
  booking?: Maybe<Booking>;
  createdAt: Scalars['Time'];
  description?: Maybe<Scalars['String']>;
  endTime: Scalars['Time'];
  id: Scalars['ID'];
  startTime: Scalars['Time'];
  title?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
  user: User;
};

export type UserEventCollection = {
  __typename?: 'UserEventCollection';
  edges: Array<UserEvent>;
  meta: PageInfo;
};

export type UserEventFilter = {
  endTime?: InputMaybe<Scalars['Time']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<Scalars['Time']>;
  userId?: InputMaybe<Scalars['ID']>;
};

export type UserEventInput = {
  bookingId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  endTime: Scalars['Time'];
  startTime: Scalars['Time'];
  title?: InputMaybe<Scalars['String']>;
};

export type UserFilter = {
  adjacentRegions?: InputMaybe<Scalars['Boolean']>;
  approved?: InputMaybe<Scalars['Boolean']>;
  /** availableAll requires matching users to have not have a userEvent, aka "be available", at ALL of the provided times */
  availableAll?: InputMaybe<Array<TimeRangeFilter>>;
  /** availableAny requires matching users to have not have a userEvent, aka "be available", at minimum ONE of provided times */
  availableAny?: InputMaybe<Array<TimeRangeFilter>>;
  blocked?: InputMaybe<Scalars['Boolean']>;
  company?: InputMaybe<Scalars['ID']>;
  createdAt?: InputMaybe<TimeRangeFilter>;
  deleted?: InputMaybe<Scalars['Boolean']>;
  emailVerified?: InputMaybe<Scalars['Boolean']>;
  excludeResourceCategories?: InputMaybe<Array<Scalars['ID']>>;
  includePassive?: InputMaybe<Scalars['Boolean']>;
  inviteFilter?: InputMaybe<ResourceInviteFilter>;
  languages?: InputMaybe<Array<Language>>;
  limit?: InputMaybe<Scalars['Int']>;
  location?: InputMaybe<Scalars['ID']>;
  locationFavourite?: InputMaybe<Scalars['ID']>;
  locationHidden?: InputMaybe<Scalars['ID']>;
  /** notInvitedToAllSlots requires matching users to that do not already have a Booking ("invitation") for ALL of provided workShiftSlot ids. */
  notInvitedToAllSlots?: InputMaybe<Array<Scalars['ID']>>;
  /** notInvitedToAnySlot requires matching users to that do not already have a Booking ("invitation") for any of provided workShiftSlot ids. */
  notInvitedToAnySlot?: InputMaybe<Array<Scalars['ID']>>;
  offset?: InputMaybe<Scalars['Int']>;
  /** filters all favourites if true returning only favourites, false will remove all favourites from result */
  onlyFavourites?: InputMaybe<Scalars['Boolean']>;
  region?: InputMaybe<Array<Region>>;
  resourceCategories?: InputMaybe<Array<Scalars['ID']>>;
  resourceCategoryLevels?: InputMaybe<Array<Scalars['ID']>>;
  resourceCategoryNames?: InputMaybe<Array<Scalars['String']>>;
  roles?: InputMaybe<Array<UserRole>>;
  searchTerm?: InputMaybe<Scalars['String']>;
  tags?: InputMaybe<Array<Scalars['ID']>>;
  /** userIds delimits the scope of search to only match users matching the provided list of ids */
  userIds?: InputMaybe<Array<Scalars['ID']>>;
  verified?: InputMaybe<Scalars['Boolean']>;
  verticalCategory?: InputMaybe<VerticalCategory>;
};

export type UserIdentification = {
  image: Scalars['Upload'];
  socialSecurity: Scalars['String'];
};

export type UserProfile = CompanyProfile | RestaurantProfile;

export enum UserRole {
  Admin = 'admin',
  CompanyAdmin = 'company_admin',
  CompanyUser = 'company_user',
  User = 'user'
}

export enum VerificationMethod {
  Admin = 'admin',
  BankId = 'bankId'
}

export enum VerticalCategory {
  Restaurant = 'restaurant'
}

export type WorkReference = {
  __typename?: 'WorkReference';
  company: Scalars['String'];
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  createdAt: Scalars['Time'];
  id: Scalars['ID'];
  role?: Maybe<Scalars['String']>;
  updatedAt: Scalars['Time'];
};

export type WorkShift = {
  __typename?: 'WorkShift';
  acceptanceTime?: Maybe<Scalars['Time']>;
  bookingType: BookingType;
  cancelledAt?: Maybe<Scalars['Time']>;
  city: Scalars['String'];
  company: Company;
  contactPerson?: Maybe<User>;
  createdAt: Scalars['Time'];
  createdBy: User;
  customContactPerson?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  firstStartTime?: Maybe<Scalars['Time']>;
  id: Scalars['ID'];
  internalName?: Maybe<Scalars['String']>;
  lastEndTime?: Maybe<Scalars['Time']>;
  location: Location;
  mealDeduction: Scalars['Boolean'];
  minutesPriorStart?: Maybe<Scalars['Uint32']>;
  postalCode: Scalars['String'];
  region: Region;
  resourceQuantity: Scalars['Uint32'];
  resourceRegionalCategories: Array<ResourceRegionalCategory>;
  salaryBoost: Scalars['Float'];
  sameResources: Scalars['Boolean'];
  sentAt?: Maybe<Scalars['Time']>;
  slots: Array<WorkShiftSlot>;
  status: WorkShiftStatus;
  street: Scalars['String'];
  templateId?: Maybe<Scalars['ID']>;
  temporaryAddress?: Maybe<TemporaryAddress>;
  tips: Scalars['Boolean'];
  totalPrice: Scalars['Float'];
  travelExpenseCompensation: Scalars['Boolean'];
  updatedAt: Scalars['Time'];
  updatedBy: User;
  workShiftResourceCategories: WorkShiftResourceCategories;
};


export type WorkShiftResourceRegionalCategoriesArgs = {
  filter?: InputMaybe<BaseFilter>;
};


export type WorkShiftSlotsArgs = {
  filter?: InputMaybe<BaseFilter>;
};


export type WorkShiftWorkShiftResourceCategoriesArgs = {
  filter?: InputMaybe<BaseFilter>;
};

export type WorkShiftCollection = {
  __typename?: 'WorkShiftCollection';
  edges: Array<WorkShift>;
  meta: PageInfo;
};

export type WorkShiftFilter = {
  bookingType?: InputMaybe<BookingType>;
  companyId?: InputMaybe<Scalars['ID']>;
  createdById?: InputMaybe<Scalars['ID']>;
  firstStartTime?: InputMaybe<TimeRangeFilter>;
  isSent?: InputMaybe<Scalars['Boolean']>;
  lastEndTime?: InputMaybe<TimeRangeFilter>;
  limit?: InputMaybe<Scalars['Int']>;
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  mealDeduction?: InputMaybe<Scalars['Boolean']>;
  offset?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Region>;
  salaryBoost?: InputMaybe<FloatRangeFilter>;
  sameResources?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<WorkShiftStatus>;
  tips?: InputMaybe<Scalars['Boolean']>;
  travelExpenseCompensation?: InputMaybe<Scalars['Boolean']>;
  updatedById?: InputMaybe<Scalars['ID']>;
};

export type WorkShiftResourceCategories = {
  __typename?: 'WorkShiftResourceCategories';
  levelIds: Array<Scalars['ID']>;
  levels: Array<ResourceLevel>;
  resourceCategories: Array<WorkShiftResourceCategory>;
  verticalProfileCategory: Scalars['String'];
};

export type WorkShiftResourceCategory = {
  __typename?: 'WorkShiftResourceCategory';
  id: Scalars['ID'];
  levelName: Scalars['String'];
};

export type WorkShiftResourceCategoryInput = {
  /** Empty array will delete any existing resourceRegionalCategories. */
  levelIds: Array<Scalars['ID']>;
  /** must be string matching RestaurantVerticalCategory */
  verticalProfileCategory: Scalars['String'];
};

export type WorkShiftSlot = {
  __typename?: 'WorkShiftSlot';
  bookedQuantity: Scalars['Uint32'];
  /** An array of unique statuses held by related bookings */
  bookingStatuses: Array<BackOfficeMainBookingStatus>;
  breakMinutes: Scalars['Uint32'];
  /** calendarStatus is deprecated and will always return an empty array. Use bookingStatuses instead */
  calendarStatus: Array<Scalars['String']>;
  cancelledAt?: Maybe<Scalars['Time']>;
  contactPerson?: Maybe<User>;
  createdAt: Scalars['Time'];
  createdBy: User;
  customContactPerson?: Maybe<Scalars['String']>;
  /**
   * defaultFilter returns the requested and accepted statuses if the WorkShiftSlot contains a booking
   * with the BackOfficeMainBookingStatus requested. Returns an empty array in other cases
   */
  defaultFilter: Array<BackOfficeMainBookingStatus>;
  endTime: Scalars['Time'];
  fullyBooked: Scalars['Boolean'];
  id: Scalars['ID'];
  name: Scalars['String'];
  price: Scalars['Float'];
  resourceQuantity: Scalars['Uint32'];
  startTime: Scalars['Time'];
  status: WorkShiftStatus;
  /**
   * summarizedBookingStatus summarizes the state of all bookings on the workshiftslot.
   * The returned status is based on the statuses of related bookings and requested number of resources
   */
  summarizedBookingStatus: BackOfficeMainBookingStatus;
  updatedAt: Scalars['Time'];
  updatedBy: User;
  workShift: WorkShift;
};

export type WorkShiftSlotCollection = {
  __typename?: 'WorkShiftSlotCollection';
  data?: Maybe<WorkShiftSlotData>;
  edges: Array<WorkShiftSlot>;
  meta: PageInfo;
};

export type WorkShiftSlotData = {
  __typename?: 'WorkShiftSlotData';
  attest: Scalars['Uint32'];
  available: Scalars['Uint32'];
  booked: Scalars['Uint32'];
  ongoing: Scalars['Uint32'];
};

export type WorkShiftSlotFilter = {
  companyId?: InputMaybe<Scalars['ID']>;
  createdById?: InputMaybe<Scalars['ID']>;
  endTime?: InputMaybe<TimeRangeFilter>;
  /**
   * fullyBooked is validated by comparing bookedQuantity and resourceQuantity.
   *
   * if bookedQuantity >= resourceQuantity the slot considered to be 'fullyBooked'
   */
  fullyBooked?: InputMaybe<Scalars['Boolean']>;
  /** list of workShiftSlot ids to delimit the search for */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  limit?: InputMaybe<Scalars['Int']>;
  locationIds?: InputMaybe<Array<Scalars['ID']>>;
  mealDeduction?: InputMaybe<Scalars['Boolean']>;
  occurWithin?: InputMaybe<TimeRangeFilter>;
  offset?: InputMaybe<Scalars['Int']>;
  region?: InputMaybe<Region>;
  salaryBoost?: InputMaybe<FloatRangeFilter>;
  sameResources?: InputMaybe<Scalars['Boolean']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  startTime?: InputMaybe<TimeRangeFilter>;
  status?: InputMaybe<Array<WorkShiftStatus>>;
  tips?: InputMaybe<Scalars['Boolean']>;
  travelExpenseCompensation?: InputMaybe<Scalars['Boolean']>;
  updatedById?: InputMaybe<Scalars['ID']>;
  workShiftId?: InputMaybe<Scalars['ID']>;
};

export type WorkShiftSlotInvitationInput = {
  /**
   * A provided list of resources acts as the raw selection group to receive invitations - if empty or nil; no
   * invitations will be sent for the slot.
   *
   * From the given selection group, invitations will be sent:
   *
   * - if workShift.sameResources == "false": to resources not already invited AND available (in calendar) at the same time as the workShiftSlot
   *
   * - if workShift.sameResources == "true": to resources not already invited AND is available for ALL the workShiftSlots provided in the mutation.
   */
  resources?: InputMaybe<Array<Scalars['ID']>>;
  workShiftSlotId: Scalars['ID'];
};

export type WorkShiftSlotTemplate = {
  __typename?: 'WorkShiftSlotTemplate';
  endHhMm: Scalars['Clock'];
  resourceQuantity?: Maybe<Scalars['Uint32']>;
  startHhMm: Scalars['Clock'];
  workShiftTemplateId: Scalars['ID'];
};

export type WorkShiftSlotTemplateInput = {
  endHhMm: Scalars['Clock'];
  resourceQuantity?: InputMaybe<Scalars['Uint32']>;
  startHhMm: Scalars['Clock'];
};

export type WorkShiftSlotsInvitationInput = {
  onlyFavourites?: InputMaybe<Scalars['Boolean']>;
  workShiftId: Scalars['ID'];
  /**
   * If workShiftSlots is provided, invitations will be only be sent for provided list of slots (not yet fully booked),
   *
   * If workShiftSlots is Empty or NULL, invitations will be sent:
   *
   * 1. for ALL workShiftSlot (not yet fully booked) related to provided workShiftId
   *
   * 2. to all resources not already invited AND available (in calendar) for each workShiftSlot individually, or if
   * workShift.sameResources == "true", to resources resources not already invited that is available for ALL the workShiftSlots (not yet fully booked).
   */
  workShiftSlots?: InputMaybe<Array<WorkShiftSlotInvitationInput>>;
};

export enum WorkShiftStatus {
  Completed = 'completed',
  JobOffer = 'job_offer',
  Paid = 'paid',
  RequestForInterest = 'request_for_interest',
  SourcingComplete = 'sourcing_complete'
}

export type WorkShiftTemplate = {
  __typename?: 'WorkShiftTemplate';
  company: Company;
  createdAt: Scalars['Time'];
  description: Scalars['String'];
  id: Scalars['ID'];
  locations: Array<Location>;
  mealDeduction: Scalars['Boolean'];
  name: Scalars['String'];
  salaryBoost: Scalars['Float'];
  slotTemplates: Array<WorkShiftSlotTemplate>;
  tips: Scalars['Boolean'];
  travelExpenseCompensation: Scalars['Boolean'];
  updatedAt: Scalars['Time'];
  verticalProfileCategory: Scalars['String'];
};

export type WorkShiftTemplateCollection = {
  __typename?: 'WorkShiftTemplateCollection';
  edges: Array<WorkShiftTemplate>;
  meta: PageInfo;
};

export type WorkShiftTemplateFilter = {
  companyId?: InputMaybe<Scalars['ID']>;
  limit?: InputMaybe<Scalars['Int']>;
  locations?: InputMaybe<Array<Scalars['ID']>>;
  offset?: InputMaybe<Scalars['Int']>;
  searchTerm?: InputMaybe<Scalars['String']>;
  verticalProfileCategories?: InputMaybe<Array<Scalars['String']>>;
};

export type WorkShiftTemplateInput = {
  companyId?: InputMaybe<Scalars['ID']>;
  description?: InputMaybe<Scalars['String']>;
  locations: Array<Scalars['ID']>;
  mealDeduction?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  salaryBoost?: InputMaybe<Scalars['Float']>;
  slotTemplates: Array<WorkShiftSlotTemplateInput>;
  tips?: InputMaybe<Scalars['Boolean']>;
  travelExpenseCompensation?: InputMaybe<Scalars['Boolean']>;
  /** must be string matching RestaurantVerticalCategory */
  verticalProfileCategory: Scalars['String'];
};

export type CustomBookingSlotsQueryVariables = Exact<{
  filter?: InputMaybe<BookingSlotsFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomBookingSlotsQuery = (
  { __typename?: 'Query' }
  & { bookingSlots: (
    { __typename?: 'BookingSlotsCollection' }
    & { edges: Array<(
      { __typename?: 'BookingSlot' }
      & Pick<BookingSlot, 'breakMinutes' | 'endTime' | 'startTime' | 'workShiftId'>
      & { booking?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'extraStatus' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, resource: (
          { __typename?: 'User' }
          & Pick<User, 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
          & { resourceLevel?: Maybe<(
            { __typename?: 'ResourceLevel' }
            & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
          )> }
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'breakMinutes' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'endTime' | 'fullyBooked' | 'id' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'updatedAt'>
        ) }
      )>, workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'breakMinutes' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'endTime' | 'fullyBooked' | 'id' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verified'>
        )>, workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ), location: (
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'name'>
          ) }
        ) }
      ) }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomBookingsQueryVariables = Exact<{
  filter?: InputMaybe<BookingFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomBookingsQuery = (
  { __typename?: 'Query' }
  & { bookings: (
    { __typename?: 'BookingCollection' }
    & { data?: Maybe<(
      { __typename?: 'BookingData' }
      & Pick<BookingData, 'preliminaryTotalPrice' | 'totalPrice' | 'totalTime'>
    )>, edges: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
          & { company: (
            { __typename?: 'Company' }
            & Pick<Company, 'id' | 'name'>
          ), location: (
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'name'>
          ) }
        ) }
      ) }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomBookingsWorkShiftSlotModalQueryVariables = Exact<{
  filter?: InputMaybe<BookingFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomBookingsWorkShiftSlotModalQuery = (
  { __typename?: 'Query' }
  & { bookings: (
    { __typename?: 'BookingCollection' }
    & { edges: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'cancelledAt' | 'id' | 'status'>
      & { resource: (
        { __typename?: 'User' }
        & Pick<User, 'id' | 'firstName' | 'lastName'>
        & { image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'originalName' | 'uri'>
        )> }
      ) }
    )> }
  ) }
);

export type CustomBroadcastChatGroupReceiversQueryVariables = Exact<{
  filter?: InputMaybe<BaseFilter>;
  id: Scalars['ID'];
}>;


export type CustomBroadcastChatGroupReceiversQuery = (
  { __typename?: 'Query' }
  & { chatGroupReceivers: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'id'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomBroadcastChatGroupRespondersQueryVariables = Exact<{
  filter?: InputMaybe<BaseFilter>;
  id: Scalars['ID'];
}>;


export type CustomBroadcastChatGroupRespondersQuery = (
  { __typename?: 'Query' }
  & { chatGroupResponders: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'lastName' | 'id'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomChannelsQueryVariables = Exact<{
  filter?: InputMaybe<ChannelFilter>;
}>;


export type CustomChannelsQuery = (
  { __typename?: 'Query' }
  & { channels: (
    { __typename?: 'ChannelCollection' }
    & { edges: Array<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id' | 'lastSentAt' | 'name' | 'origin'>
      & { messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'id' | 'message' | 'title'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'id'>
        )>, images: Array<(
          { __typename?: 'Image' }
          & Pick<Image, 'id'>
        )>, createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'id'>
        )> }
      )>, participants?: Maybe<Array<(
        { __typename?: 'Participant' }
        & Pick<Participant, 'lastReadAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'firstName' | 'id' | 'lastName' | 'role'>
          & { image?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'height' | 'id' | 'originalName' | 'uri' | 'width'>
          )> }
        ) }
      )>> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomBroadcastChannelsQueryVariables = Exact<{
  filter?: InputMaybe<ChannelFilter>;
}>;


export type CustomBroadcastChannelsQuery = (
  { __typename?: 'Query' }
  & { channels: (
    { __typename?: 'ChannelCollection' }
    & { edges: Array<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'id'>
    )> }
  ) }
);

export type CustomChatSubscribeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CustomChatSubscribeSubscription = (
  { __typename?: 'Subscription' }
  & { chatSubscribe: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'id' | 'lastSentAt' | 'name' | 'origin'>
    & { messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'id' | 'message' | 'title'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'id'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'id'>
      )>, createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    )>, participants?: Maybe<Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'lastReadAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'id' | 'lastName'>
        & { image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'height' | 'id' | 'originalName' | 'uri' | 'width'>
        )> }
      ) }
    )>> }
  ) | (
    { __typename?: 'Message' }
    & Pick<Message, 'createdAt' | 'id' | 'message' | 'title'>
    & { channel: (
      { __typename?: 'Channel' }
      & Pick<Channel, 'id' | 'lastSentAt'>
      & { participants?: Maybe<Array<(
        { __typename?: 'Participant' }
        & Pick<Participant, 'lastReadAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'id'>
        ) }
      )>> }
    ), createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'id' | 'lastName'>
    )>, extra?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'endTime' | 'id' | 'startTime' | 'workShiftId'>
      & { workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'id'>
        & { workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'id'>
          & { location: (
            { __typename?: 'Location' }
            & Pick<Location, 'id' | 'name'>
            & { image?: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'originalName' | 'height' | 'id' | 'uri' | 'width'>
            )> }
          ) }
        ) }
      ) }
    )>, files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'originalName' | 'id' | 'uri'>
    )>, images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'originalName' | 'height' | 'id' | 'uri' | 'width'>
    )> }
  ) }
);

export type CustomCompaniesQueryVariables = Exact<{
  filter?: InputMaybe<CompanyFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomCompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: (
    { __typename?: 'CompanyCollection' }
    & { edges: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'id' | 'name'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomLocationsQueryVariables = Exact<{
  filter?: InputMaybe<LocationFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomLocationsQuery = (
  { __typename?: 'Query' }
  & { locations: (
    { __typename?: 'LocationCollection' }
    & { edges: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'id' | 'name'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomMessagesQueryVariables = Exact<{
  filter: MessageFilter;
}>;


export type CustomMessagesQuery = (
  { __typename?: 'Query' }
  & { messages: (
    { __typename?: 'MessageCollection' }
    & { edges: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'title'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'id' | 'lastSentAt'>
        & { participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
          & { user: (
            { __typename?: 'User' }
            & Pick<User, 'id'>
          ) }
        )>> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'id' | 'lastName'>
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'endTime' | 'id' | 'startTime' | 'workShiftId'>
        & { workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'id'>
          & { workShift: (
            { __typename?: 'WorkShift' }
            & Pick<WorkShift, 'id'>
            & { location: (
              { __typename?: 'Location' }
              & Pick<Location, 'id' | 'name'>
              & { image?: Maybe<(
                { __typename?: 'Image' }
                & Pick<Image, 'originalName' | 'height' | 'id' | 'uri' | 'width'>
              )> }
            ) }
          ) }
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'originalName' | 'id' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'originalName' | 'height' | 'id' | 'uri' | 'width'>
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomNotificationSubscribeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type CustomNotificationSubscribeSubscription = (
  { __typename?: 'Subscription' }
  & { notificationSubscribe: (
    { __typename?: 'Notification' }
    & Pick<Notification, 'background' | 'createdAt' | 'id' | 'message' | 'referenceId' | 'referenceType' | 'title' | 'type'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'id' | 'startTime' | 'workShiftId'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'id'>
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'id'>
        & { workShift: (
          { __typename?: 'WorkShift' }
          & { location: (
            { __typename?: 'Location' }
            & Pick<Location, 'id'>
          ) }
        ) }
      ) }
    )>, sender?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email' | 'firstName' | 'id' | 'lastName'>
    )> }
  ) }
);

export type CustomNotificationsQueryVariables = Exact<{
  filter?: InputMaybe<NotificationFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomNotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationCollection' }
    & { edges: Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'background' | 'createdAt' | 'id' | 'message' | 'referenceId' | 'referenceType' | 'title' | 'type'>
      & { booking?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'id' | 'startTime' | 'workShiftId'>
        & { createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'id'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'id'>
          & { workShift: (
            { __typename?: 'WorkShift' }
            & { location: (
              { __typename?: 'Location' }
              & Pick<Location, 'id'>
            ) }
          ) }
        ) }
      )>, sender?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'email' | 'firstName' | 'id' | 'lastName'>
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomRecommendationsQueryVariables = Exact<{
  filter?: InputMaybe<RecommendationFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomRecommendationsQuery = (
  { __typename?: 'Query' }
  & { recommendations: (
    { __typename?: 'RecommendationCollection' }
    & { meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'total'>
    ), edges: Array<(
      { __typename?: 'Recommendation' }
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'id' | 'name'>
      ) }
    )> }
  ) }
);

export type CustomReportNoShowMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  undo?: InputMaybe<Scalars['Boolean']>;
}>;


export type CustomReportNoShowMutation = (
  { __typename?: 'Mutation' }
  & { reportNoShow: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'status'>
  ) }
);

export type CustomResourcesQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomResourcesQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'blocked' | 'firstName' | 'id' | 'internalComment' | 'lastName' | 'mobileNumber' | 'verified'>
      & { userProfile: Array<{ __typename?: 'CompanyProfile' } | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'createdAt' | 'id' | 'region'>
        & { workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'id'>
        )> }
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomResourcesBroadcastQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomResourcesBroadcastQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'id' | 'lastName'>
      & { userProfile: Array<{ __typename?: 'CompanyProfile' } | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'region'>
        & { workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'id'>
        )> }
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomCompaniesBroadcastQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomCompaniesBroadcastQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'firstName' | 'id' | 'lastName'>
      & { userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'id' | 'name'>
        ) }
      ) | { __typename?: 'RestaurantProfile' }> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomSetLocationHiddenMutationVariables = Exact<{
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type CustomSetLocationHiddenMutation = (
  { __typename?: 'Mutation' }
  & { setLocationHidden?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type CustomTagsQueryVariables = Exact<{
  filter?: InputMaybe<TagFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomTagsQuery = (
  { __typename?: 'Query' }
  & { tags: (
    { __typename?: 'TagCollection' }
    & { edges: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'id' | 'name'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CustomRestaurantUsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomRestaurantUsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'firstName' | 'lastName' | 'mobileNumber' | 'favourite'>
      & { image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description'>
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )> }
    )> }
  ) }
);

export type CustomWorkShiftSlotsQueryVariables = Exact<{
  filter?: InputMaybe<WorkShiftSlotFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CustomWorkShiftSlotsQuery = (
  { __typename?: 'Query' }
  & { workShiftSlots: (
    { __typename?: 'WorkShiftSlotCollection' }
    & { data?: Maybe<(
      { __typename?: 'WorkShiftSlotData' }
      & Pick<WorkShiftSlotData, 'attest' | 'available' | 'booked' | 'ongoing'>
    )>, edges: Array<(
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'calendarStatus' | 'endTime' | 'fullyBooked' | 'id' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus'>
      & { createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'firstName' | 'id' | 'lastName'>
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'bookingType' | 'id' | 'internalName'>
        & { location: (
          { __typename?: 'Location' }
          & Pick<Location, 'id' | 'name'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
          & { levels: Array<(
            { __typename?: 'ResourceLevel' }
            & Pick<ResourceLevel, 'id' | 'name'>
          )> }
        ) }
      ) }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type BookingQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type BookingQuery = (
  { __typename?: 'Query' }
  & { booking: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  ) }
);

export type BookingSlotsQueryVariables = Exact<{
  filter?: InputMaybe<BookingSlotsFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type BookingSlotsQuery = (
  { __typename?: 'Query' }
  & { bookingSlots: (
    { __typename?: 'BookingSlotsCollection' }
    & { edges: Array<(
      { __typename?: 'BookingSlot' }
      & Pick<BookingSlot, 'breakMinutes' | 'endTime' | 'startTime' | 'workShiftId'>
      & { booking?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type BookingsQueryVariables = Exact<{
  filter?: InputMaybe<BookingFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type BookingsQuery = (
  { __typename?: 'Query' }
  & { bookings: (
    { __typename?: 'BookingCollection' }
    & { data?: Maybe<(
      { __typename?: 'BookingData' }
      & Pick<BookingData, 'preliminaryTotalPrice' | 'totalPrice' | 'totalTime'>
    )>, edges: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ChannelsQueryVariables = Exact<{
  filter?: InputMaybe<ChannelFilter>;
}>;


export type ChannelsQuery = (
  { __typename?: 'Query' }
  & { channels: (
    { __typename?: 'ChannelCollection' }
    & { edges: Array<(
      { __typename?: 'Channel' }
      & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
      & { chatGroup?: Maybe<(
        { __typename?: 'ChatGroup' }
        & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        & { members: Array<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        & { channel: (
          { __typename?: 'Channel' }
          & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        ), createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, extra?: Maybe<(
          { __typename?: 'Booking' }
          & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        )>, files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, images: Array<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )>, participants?: Maybe<Array<(
        { __typename?: 'Participant' }
        & Pick<Participant, 'lastReadAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ) }
      )>>, workShift?: Maybe<(
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ChatGroupReceiversQueryVariables = Exact<{
  filter?: InputMaybe<BaseFilter>;
  id: Scalars['ID'];
}>;


export type ChatGroupReceiversQuery = (
  { __typename?: 'Query' }
  & { chatGroupReceivers: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ChatGroupRespondersQueryVariables = Exact<{
  filter?: InputMaybe<BaseFilter>;
  id: Scalars['ID'];
}>;


export type ChatGroupRespondersQuery = (
  { __typename?: 'Query' }
  & { chatGroupResponders: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ChatGroupsQueryVariables = Exact<{
  filter?: InputMaybe<ChatGroupFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type ChatGroupsQuery = (
  { __typename?: 'Query' }
  & { chatGroups: (
    { __typename?: 'ChatGroupCollection' }
    & { edges: Array<(
      { __typename?: 'ChatGroup' }
      & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CompaniesQueryVariables = Exact<{
  filter?: InputMaybe<CompanyFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type CompaniesQuery = (
  { __typename?: 'Query' }
  & { companies: (
    { __typename?: 'CompanyCollection' }
    & { edges: Array<(
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type CompanyQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CompanyQuery = (
  { __typename?: 'Query' }
  & { company?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
    & { contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategories: Array<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type DistributeUserToAnyAvailableSlotsQueryVariables = Exact<{
  input: DistributeUserToSlotsInput;
}>;


export type DistributeUserToAnyAvailableSlotsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'distributeUserToAnyAvailableSlots'>
);

export type LocationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type LocationQuery = (
  { __typename?: 'Query' }
  & { location?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, logo?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type LocationsQueryVariables = Exact<{
  filter?: InputMaybe<LocationFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type LocationsQuery = (
  { __typename?: 'Query' }
  & { locations: (
    { __typename?: 'LocationCollection' }
    & { edges: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type MessagesQueryVariables = Exact<{
  filter: MessageFilter;
}>;


export type MessagesQuery = (
  { __typename?: 'Query' }
  & { messages: (
    { __typename?: 'MessageCollection' }
    & { edges: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        & { chatGroup?: Maybe<(
          { __typename?: 'ChatGroup' }
          & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        )>, participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
        )>>, workShift?: Maybe<(
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        )> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type MyCompanyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyCompanyProfileQuery = (
  { __typename?: 'Query' }
  & { myCompanyProfile?: Maybe<(
    { __typename?: 'CompanyProfile' }
    & Pick<CompanyProfile, 'description' | 'id'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )> }
  )> }
);

export type MyRestaurantProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyRestaurantProfileQuery = (
  { __typename?: 'Query' }
  & { myRestaurantProfile?: Maybe<(
    { __typename?: 'RestaurantProfile' }
    & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
    )>, workExperience: Array<(
      { __typename?: 'RestaurantWorkExperience' }
      & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
    )>, workReferences: Array<(
      { __typename?: 'WorkReference' }
      & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
    )> }
  )> }
);

export type NotificationsQueryVariables = Exact<{
  filter?: InputMaybe<NotificationFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type NotificationsQuery = (
  { __typename?: 'Query' }
  & { notifications: (
    { __typename?: 'NotificationCollection' }
    & { edges: Array<(
      { __typename?: 'Notification' }
      & Pick<Notification, 'background' | 'createdAt' | 'id' | 'message' | 'referenceId' | 'referenceType' | 'title' | 'type'>
      & { booking?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, sender?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type RecommendationQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RecommendationQuery = (
  { __typename?: 'Query' }
  & { recommendation?: Maybe<(
    { __typename?: 'Recommendation' }
    & Pick<Recommendation, 'createdAt' | 'description' | 'hidden' | 'id' | 'updatedAt'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    ) }
  )> }
);

export type RecommendationsQueryVariables = Exact<{
  filter?: InputMaybe<RecommendationFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type RecommendationsQuery = (
  { __typename?: 'Query' }
  & { recommendations: (
    { __typename?: 'RecommendationCollection' }
    & { edges: Array<(
      { __typename?: 'Recommendation' }
      & Pick<Recommendation, 'createdAt' | 'description' | 'hidden' | 'id' | 'updatedAt'>
      & { location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ) }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ResourceCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<ResourceCategoryFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type ResourceCategoriesQuery = (
  { __typename?: 'Query' }
  & { resourceCategories: (
    { __typename?: 'ResourceCategoryCollection' }
    & { edges: Array<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ResourceCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceCategoryQuery = (
  { __typename?: 'Query' }
  & { resourceCategory?: Maybe<(
    { __typename?: 'ResourceCategory' }
    & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
    & { level: (
      { __typename?: 'ResourceLevel' }
      & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    ), regional: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )> }
  )> }
);

export type ResourceLevelsQueryVariables = Exact<{
  filter?: InputMaybe<ResourceLevelFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type ResourceLevelsQuery = (
  { __typename?: 'Query' }
  & { resourceLevels: (
    { __typename?: 'ResourceLevelCollection' }
    & { edges: Array<(
      { __typename?: 'ResourceLevel' }
      & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ResourceRegionalCategoriesQueryVariables = Exact<{
  filter?: InputMaybe<ResourceRegionalFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type ResourceRegionalCategoriesQuery = (
  { __typename?: 'Query' }
  & { resourceRegionalCategories: (
    { __typename?: 'ResourceRegionalCategoryCollection' }
    & { edges: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type ResourceRegionalCategoryQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResourceRegionalCategoryQuery = (
  { __typename?: 'Query' }
  & { resourceRegionalCategory?: Maybe<(
    { __typename?: 'ResourceRegionalCategory' }
    & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
  )> }
);

export type TagQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TagQuery = (
  { __typename?: 'Query' }
  & { tag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
  )> }
);

export type TagsQueryVariables = Exact<{
  filter?: InputMaybe<TagFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type TagsQuery = (
  { __typename?: 'Query' }
  & { tags: (
    { __typename?: 'TagCollection' }
    & { edges: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type UnreadMessagesQueryVariables = Exact<{ [key: string]: never; }>;


export type UnreadMessagesQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'unreadMessages'>
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type UserEventQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserEventQuery = (
  { __typename?: 'Query' }
  & { userEvent?: Maybe<(
    { __typename?: 'UserEvent' }
    & Pick<UserEvent, 'createdAt' | 'description' | 'endTime' | 'id' | 'startTime' | 'title' | 'updatedAt'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ) }
  )> }
);

export type UserEventsQueryVariables = Exact<{
  filter: UserEventFilter;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type UserEventsQuery = (
  { __typename?: 'Query' }
  & { userEvents: (
    { __typename?: 'UserEventCollection' }
    & { edges: Array<(
      { __typename?: 'UserEvent' }
      & Pick<UserEvent, 'createdAt' | 'description' | 'endTime' | 'id' | 'startTime' | 'title' | 'updatedAt'>
      & { booking?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, user: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ) }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type UsersQueryVariables = Exact<{
  filter?: InputMaybe<UserFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type UsersQuery = (
  { __typename?: 'Query' }
  & { users: (
    { __typename?: 'UserCollection' }
    & { edges: Array<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type WorkShiftQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WorkShiftQuery = (
  { __typename?: 'Query' }
  & { workShift?: Maybe<(
    { __typename?: 'WorkShift' }
    & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), contactPerson?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), location: (
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    ), resourceRegionalCategories: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )>, slots: Array<(
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    )>, temporaryAddress?: Maybe<(
      { __typename?: 'TemporaryAddress' }
      & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftResourceCategories: (
      { __typename?: 'WorkShiftResourceCategories' }
      & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
      & { levels: Array<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )>, resourceCategories: Array<(
        { __typename?: 'WorkShiftResourceCategory' }
        & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
      )> }
    ) }
  )> }
);

export type WorkShiftSlotQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WorkShiftSlotQuery = (
  { __typename?: 'Query' }
  & { workShiftSlot?: Maybe<(
    { __typename?: 'WorkShiftSlot' }
    & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
    & { contactPerson?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShift: (
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    ) }
  )> }
);

export type WorkShiftSlotsQueryVariables = Exact<{
  filter?: InputMaybe<WorkShiftSlotFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type WorkShiftSlotsQuery = (
  { __typename?: 'Query' }
  & { workShiftSlots: (
    { __typename?: 'WorkShiftSlotCollection' }
    & { data?: Maybe<(
      { __typename?: 'WorkShiftSlotData' }
      & Pick<WorkShiftSlotData, 'attest' | 'available' | 'booked' | 'ongoing'>
    )>, edges: Array<(
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type WorkShiftTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type WorkShiftTemplateQuery = (
  { __typename?: 'Query' }
  & { workShiftTemplate?: Maybe<(
    { __typename?: 'WorkShiftTemplate' }
    & Pick<WorkShiftTemplate, 'createdAt' | 'description' | 'id' | 'mealDeduction' | 'name' | 'salaryBoost' | 'tips' | 'travelExpenseCompensation' | 'updatedAt' | 'verticalProfileCategory'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, slotTemplates: Array<(
      { __typename?: 'WorkShiftSlotTemplate' }
      & Pick<WorkShiftSlotTemplate, 'endHhMm' | 'resourceQuantity' | 'startHhMm' | 'workShiftTemplateId'>
    )> }
  )> }
);

export type WorkShiftTemplatesQueryVariables = Exact<{
  filter?: InputMaybe<WorkShiftTemplateFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type WorkShiftTemplatesQuery = (
  { __typename?: 'Query' }
  & { workShiftTemplates: (
    { __typename?: 'WorkShiftTemplateCollection' }
    & { edges: Array<(
      { __typename?: 'WorkShiftTemplate' }
      & Pick<WorkShiftTemplate, 'createdAt' | 'description' | 'id' | 'mealDeduction' | 'name' | 'salaryBoost' | 'tips' | 'travelExpenseCompensation' | 'updatedAt' | 'verticalProfileCategory'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )>, slotTemplates: Array<(
        { __typename?: 'WorkShiftSlotTemplate' }
        & Pick<WorkShiftSlotTemplate, 'endHhMm' | 'resourceQuantity' | 'startHhMm' | 'workShiftTemplateId'>
      )> }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type WorkShiftsQueryVariables = Exact<{
  filter?: InputMaybe<WorkShiftFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type WorkShiftsQuery = (
  { __typename?: 'Query' }
  & { workShifts: (
    { __typename?: 'WorkShiftCollection' }
    & { edges: Array<(
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    )>, meta: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'limit' | 'offset' | 'total'>
    ) }
  ) }
);

export type AcceptApplyInvitationsMutationVariables = Exact<{
  input: AcceptApplyInvitationsInput;
}>;


export type AcceptApplyInvitationsMutation = (
  { __typename?: 'Mutation' }
  & { acceptApplyInvitations: (
    { __typename?: 'AcceptApplyInvitationsResult' }
    & Pick<AcceptApplyInvitationsResult, 'error' | 'failed' | 'mandatoryMissing' | 'success'>
    & { updated: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )> }
  ) }
);

export type AcceptSuggestedTimesMutationVariables = Exact<{
  input: Array<BookingConfirmTimesInput> | BookingConfirmTimesInput;
}>;


export type AcceptSuggestedTimesMutation = (
  { __typename?: 'Mutation' }
  & { acceptSuggestedTimes: (
    { __typename?: 'AcceptSuggestedTimesResult' }
    & Pick<AcceptSuggestedTimesResult, 'error' | 'notAvailable' | 'success' | 'timesNotMatch'>
    & { updated: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )> }
  ) }
);

export type ActivateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ActivateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { activateCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
    & { contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategories: Array<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type AddRestaurantProfileFilesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: Array<Scalars['Upload']> | Scalars['Upload'];
}>;


export type AddRestaurantProfileFilesMutation = (
  { __typename?: 'Mutation' }
  & { addRestaurantProfileFiles?: Maybe<(
    { __typename?: 'RestaurantProfile' }
    & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
    )>, workExperience: Array<(
      { __typename?: 'RestaurantWorkExperience' }
      & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
    )>, workReferences: Array<(
      { __typename?: 'WorkReference' }
      & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
    )> }
  )> }
);

export type AddUsersToResourceCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateResourcesInput;
}>;


export type AddUsersToResourceCategoryMutation = (
  { __typename?: 'Mutation' }
  & { addUsersToResourceCategory?: Maybe<(
    { __typename?: 'ResourceCategory' }
    & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
    & { level: (
      { __typename?: 'ResourceLevel' }
      & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    ), regional: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )> }
  )> }
);

export type ApproveTimeReportMutationVariables = Exact<{
  bookingIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type ApproveTimeReportMutation = (
  { __typename?: 'Mutation' }
  & { approveTimeReport: Array<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  )> }
);

export type BroadcastMutationVariables = Exact<{
  message: Scalars['String'];
  title: Scalars['String'];
  userIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type BroadcastMutation = (
  { __typename?: 'Mutation' }
  & { broadcast?: Maybe<(
    { __typename?: 'ChatGroup' }
    & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type BroadcastMessageMutationVariables = Exact<{
  chatGroupId?: InputMaybe<Scalars['ID']>;
  filter: UserFilter;
  message: Scalars['String'];
  slotId?: InputMaybe<Scalars['ID']>;
  workShiftId?: InputMaybe<Scalars['ID']>;
}>;


export type BroadcastMessageMutation = (
  { __typename?: 'Mutation' }
  & { broadcastMessage: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type CancelBookingsMutationVariables = Exact<{
  input: CancelInput;
}>;


export type CancelBookingsMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelBookings'>
);

export type CancelNonAcceptedBookingsMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelNonAcceptedBookingsMutation = (
  { __typename?: 'Mutation' }
  & { cancelNonAcceptedBookings: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type CloseChatGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
}>;


export type CloseChatGroupMutation = (
  { __typename?: 'Mutation' }
  & { closeChatGroup?: Maybe<(
    { __typename?: 'ChatGroup' }
    & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type ConfirmBookingCanceledMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ConfirmBookingCanceledMutation = (
  { __typename?: 'Mutation' }
  & { confirmBookingCanceled?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  )> }
);

export type CreateChannelMutationVariables = Exact<{
  input: CreateChannelInput;
}>;


export type CreateChannelMutation = (
  { __typename?: 'Mutation' }
  & { createChannel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
    & { chatGroup?: Maybe<(
      { __typename?: 'ChatGroup' }
      & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        & { chatGroup?: Maybe<(
          { __typename?: 'ChatGroup' }
          & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        )>, participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
        )>>, workShift?: Maybe<(
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        )> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, participants?: Maybe<Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'lastReadAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ) }
    )>>, workShift?: Maybe<(
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    )> }
  )> }
);

export type CreateCompanyMutationVariables = Exact<{
  input: CreateCompanyInput;
}>;


export type CreateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { createCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
    & { contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategories: Array<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type CreateCompanyUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CreateCompanyUserInput;
}>;


export type CreateCompanyUserMutation = (
  { __typename?: 'Mutation' }
  & { createCompanyUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type CreateLocationMutationVariables = Exact<{
  input: CreateLocationInput;
}>;


export type CreateLocationMutation = (
  { __typename?: 'Mutation' }
  & { createLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, logo?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type CreateResourceCategoryMutationVariables = Exact<{
  input: CreateResourceCategory;
}>;


export type CreateResourceCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createResourceCategory?: Maybe<(
    { __typename?: 'ResourceCategory' }
    & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
    & { level: (
      { __typename?: 'ResourceLevel' }
      & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    ), regional: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )> }
  )> }
);

export type CreateResourceLevelMutationVariables = Exact<{
  input: UpsertResourceLevel;
}>;


export type CreateResourceLevelMutation = (
  { __typename?: 'Mutation' }
  & { createResourceLevel?: Maybe<(
    { __typename?: 'ResourceLevel' }
    & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
  )> }
);

export type CreateResourceRegionalCategoryMutationVariables = Exact<{
  input: CreateResourceRegionalCategory;
}>;


export type CreateResourceRegionalCategoryMutation = (
  { __typename?: 'Mutation' }
  & { createResourceRegionalCategory?: Maybe<(
    { __typename?: 'ResourceRegionalCategory' }
    & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
  )> }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'Mutation' }
  & { createUser: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'jwt' | 'message' | 'newAccount' | 'refreshToken' | 'success' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  ) }
);

export type CreateUserEventMutationVariables = Exact<{
  input: UserEventInput;
}>;


export type CreateUserEventMutation = (
  { __typename?: 'Mutation' }
  & { createUserEvent?: Maybe<(
    { __typename?: 'UserEvent' }
    & Pick<UserEvent, 'createdAt' | 'description' | 'endTime' | 'id' | 'startTime' | 'title' | 'updatedAt'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ) }
  )> }
);

export type CreateWorkShiftMutationVariables = Exact<{
  input: CreateWorkShiftInput;
}>;


export type CreateWorkShiftMutation = (
  { __typename?: 'Mutation' }
  & { createWorkShift?: Maybe<(
    { __typename?: 'WorkShift' }
    & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), contactPerson?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), location: (
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    ), resourceRegionalCategories: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )>, slots: Array<(
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    )>, temporaryAddress?: Maybe<(
      { __typename?: 'TemporaryAddress' }
      & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftResourceCategories: (
      { __typename?: 'WorkShiftResourceCategories' }
      & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
      & { levels: Array<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )>, resourceCategories: Array<(
        { __typename?: 'WorkShiftResourceCategory' }
        & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
      )> }
    ) }
  )> }
);

export type CreateWorkShiftTemplateMutationVariables = Exact<{
  input: WorkShiftTemplateInput;
}>;


export type CreateWorkShiftTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createWorkShiftTemplate?: Maybe<(
    { __typename?: 'WorkShiftTemplate' }
    & Pick<WorkShiftTemplate, 'createdAt' | 'description' | 'id' | 'mealDeduction' | 'name' | 'salaryBoost' | 'tips' | 'travelExpenseCompensation' | 'updatedAt' | 'verticalProfileCategory'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, slotTemplates: Array<(
      { __typename?: 'WorkShiftSlotTemplate' }
      & Pick<WorkShiftSlotTemplate, 'endHhMm' | 'resourceQuantity' | 'startHhMm' | 'workShiftTemplateId'>
    )> }
  )> }
);

export type DeclineBookingsMutationVariables = Exact<{
  input: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeclineBookingsMutation = (
  { __typename?: 'Mutation' }
  & { declineBookings: (
    { __typename?: 'DeclineResult' }
    & Pick<DeclineResult, 'error' | 'mandatoryMissing' | 'success'>
    & { updated: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )> }
  ) }
);

export type DeclineSuggestedTimesMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeclineSuggestedTimesMutation = (
  { __typename?: 'Mutation' }
  & { declineSuggestedTimes?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  )> }
);

export type DeclineTimeReportMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  message: Scalars['String'];
}>;


export type DeclineTimeReportMutation = (
  { __typename?: 'Mutation' }
  & { declineTimeReport?: Maybe<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  )> }
);

export type DeleteChannelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteChannelMutation = (
  { __typename?: 'Mutation' }
  & { deleteChannel: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteChatGroupMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
}>;


export type DeleteChatGroupMutation = (
  { __typename?: 'Mutation' }
  & { deleteChatGroup: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteCompanyMutation = (
  { __typename?: 'Mutation' }
  & { deleteCompany?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type DeleteLocationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLocationMutation = (
  { __typename?: 'Mutation' }
  & { deleteLocation?: Maybe<(
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  )> }
);

export type DeleteLocationRecommendationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteLocationRecommendationMutation = (
  { __typename?: 'Mutation' }
  & { deleteLocationRecommendation: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteResourceCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteResourceCategoryMutation = (
  { __typename?: 'Mutation' }
  & { deleteResourceCategory: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteResourceLevelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteResourceLevelMutation = (
  { __typename?: 'Mutation' }
  & { deleteResourceLevel: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteRestaurantProfileExperienceMutationVariables = Exact<{
  experienceId: Scalars['ID'];
  id: Scalars['ID'];
}>;


export type DeleteRestaurantProfileExperienceMutation = (
  { __typename?: 'Mutation' }
  & { deleteRestaurantProfileExperience?: Maybe<(
    { __typename?: 'RestaurantProfile' }
    & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
    )>, workExperience: Array<(
      { __typename?: 'RestaurantWorkExperience' }
      & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
    )>, workReferences: Array<(
      { __typename?: 'WorkReference' }
      & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
    )> }
  )> }
);

export type DeleteRestaurantProfileFilesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type DeleteRestaurantProfileFilesMutation = (
  { __typename?: 'Mutation' }
  & { deleteRestaurantProfileFiles?: Maybe<(
    { __typename?: 'RestaurantProfile' }
    & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
    )>, workExperience: Array<(
      { __typename?: 'RestaurantWorkExperience' }
      & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
    )>, workReferences: Array<(
      { __typename?: 'WorkReference' }
      & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
    )> }
  )> }
);

export type DeleteRestaurantProfileReferenceMutationVariables = Exact<{
  id: Scalars['ID'];
  referenceId: Scalars['ID'];
}>;


export type DeleteRestaurantProfileReferenceMutation = (
  { __typename?: 'Mutation' }
  & { deleteRestaurantProfileReference?: Maybe<(
    { __typename?: 'RestaurantProfile' }
    & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
    )>, workExperience: Array<(
      { __typename?: 'RestaurantWorkExperience' }
      & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
    )>, workReferences: Array<(
      { __typename?: 'WorkReference' }
      & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
    )> }
  )> }
);

export type DeleteRestaurantProfileTagsMutationVariables = Exact<{
  input: Array<Scalars['ID']> | Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
}>;


export type DeleteRestaurantProfileTagsMutation = (
  { __typename?: 'Mutation' }
  & { deleteRestaurantProfileTags?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type DeleteTagMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTagMutation = (
  { __typename?: 'Mutation' }
  & { deleteTag: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'Mutation' }
  & { deleteUser: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteUserEventMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserEventMutation = (
  { __typename?: 'Mutation' }
  & { deleteUserEvent: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteWorkShiftMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWorkShiftMutation = (
  { __typename?: 'Mutation' }
  & { deleteWorkShift: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteWorkShiftSlotMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWorkShiftSlotMutation = (
  { __typename?: 'Mutation' }
  & { deleteWorkShiftSlot: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type DeleteWorkShiftTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteWorkShiftTemplateMutation = (
  { __typename?: 'Mutation' }
  & { deleteWorkShiftTemplate: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type EndTimerMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  input: TimerTrackingInput;
}>;


export type EndTimerMutation = (
  { __typename?: 'Mutation' }
  & { endTimer: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  ) }
);

export type ExportBookingsCsvMutationVariables = Exact<{
  filter?: InputMaybe<BookingFilter>;
  sorting?: InputMaybe<SortOrdering>;
}>;


export type ExportBookingsCsvMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'exportBookingsCSV'>
);

export type HideChannelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type HideChannelMutation = (
  { __typename?: 'Mutation' }
  & { hideChannel: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type HideRecommendationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type HideRecommendationMutation = (
  { __typename?: 'Mutation' }
  & { hideRecommendation: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type InitChatGroupChannelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type InitChatGroupChannelMutation = (
  { __typename?: 'Mutation' }
  & { initChatGroupChannel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
    & { chatGroup?: Maybe<(
      { __typename?: 'ChatGroup' }
      & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        & { chatGroup?: Maybe<(
          { __typename?: 'ChatGroup' }
          & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        )>, participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
        )>>, workShift?: Maybe<(
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        )> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, participants?: Maybe<Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'lastReadAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ) }
    )>>, workShift?: Maybe<(
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    )> }
  )> }
);

export type InvertBroadcastMutationVariables = Exact<{
  excludedUserIds: Array<Scalars['ID']> | Scalars['ID'];
  filter: UserFilter;
  message: Scalars['String'];
  title: Scalars['String'];
}>;


export type InvertBroadcastMutation = (
  { __typename?: 'Mutation' }
  & { invertBroadcast?: Maybe<(
    { __typename?: 'ChatGroup' }
    & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type LoginDeviceMutationVariables = Exact<{
  device: DeviceInput;
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginDeviceMutation = (
  { __typename?: 'Mutation' }
  & { loginDevice: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'jwt' | 'message' | 'newAccount' | 'refreshToken' | 'success' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  ) }
);

export type LoginEmailMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginEmailMutation = (
  { __typename?: 'Mutation' }
  & { loginEmail: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'jwt' | 'message' | 'newAccount' | 'refreshToken' | 'success' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  ) }
);

export type ManageUserDevicesMutationVariables = Exact<{
  device: DeviceInput;
  operation: DeviceOperation;
}>;


export type ManageUserDevicesMutation = (
  { __typename?: 'Mutation' }
  & { manageUserDevices: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type MessageChatGroupMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
}>;


export type MessageChatGroupMutation = (
  { __typename?: 'Mutation' }
  & { messageChatGroup?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
    & { chatGroup?: Maybe<(
      { __typename?: 'ChatGroup' }
      & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        & { chatGroup?: Maybe<(
          { __typename?: 'ChatGroup' }
          & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        )>, participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
        )>>, workShift?: Maybe<(
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        )> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, participants?: Maybe<Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'lastReadAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ) }
    )>>, workShift?: Maybe<(
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    )> }
  )> }
);

export type MessageWorkShiftMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
}>;


export type MessageWorkShiftMutation = (
  { __typename?: 'Mutation' }
  & { messageWorkShift?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
    & { chatGroup?: Maybe<(
      { __typename?: 'ChatGroup' }
      & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        & { chatGroup?: Maybe<(
          { __typename?: 'ChatGroup' }
          & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        )>, participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
        )>>, workShift?: Maybe<(
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        )> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, participants?: Maybe<Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'lastReadAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ) }
    )>>, workShift?: Maybe<(
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    )> }
  )> }
);

export type ReSendUserInviteMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ReSendUserInviteMutation = (
  { __typename?: 'Mutation' }
  & { reSendUserInvite: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type RecommendLocationMutationVariables = Exact<{
  input: UpsertRecommendation;
}>;


export type RecommendLocationMutation = (
  { __typename?: 'Mutation' }
  & { recommendLocation?: Maybe<(
    { __typename?: 'Recommendation' }
    & Pick<Recommendation, 'createdAt' | 'description' | 'hidden' | 'id' | 'updatedAt'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    ) }
  )> }
);

export type RefreshSessionMutationVariables = Exact<{
  refreshToken: Scalars['String'];
  userId: Scalars['String'];
}>;


export type RefreshSessionMutation = (
  { __typename?: 'Mutation' }
  & { refreshSession: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'jwt' | 'message' | 'newAccount' | 'refreshToken' | 'success' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  ) }
);

export type RemoveUsersFromResourceCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateResourcesInput;
}>;


export type RemoveUsersFromResourceCategoryMutation = (
  { __typename?: 'Mutation' }
  & { removeUsersFromResourceCategory?: Maybe<(
    { __typename?: 'ResourceCategory' }
    & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
    & { level: (
      { __typename?: 'ResourceLevel' }
      & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    ), regional: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )> }
  )> }
);

export type ReportNoShowMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  undo?: InputMaybe<Scalars['Boolean']>;
}>;


export type ReportNoShowMutation = (
  { __typename?: 'Mutation' }
  & { reportNoShow: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  ) }
);

export type ReportSickMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  undo?: InputMaybe<Scalars['Boolean']>;
  undoOption?: InputMaybe<UndoReportSickSlotFullOptions>;
}>;


export type ReportSickMutation = (
  { __typename?: 'Mutation' }
  & { reportSick: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  ) }
);

export type ResendBroadcastMutationVariables = Exact<{
  id: Scalars['ID'];
  message: Scalars['String'];
  new: Scalars['Boolean'];
  title?: InputMaybe<Scalars['String']>;
}>;


export type ResendBroadcastMutation = (
  { __typename?: 'Mutation' }
  & { resendBroadcast?: Maybe<(
    { __typename?: 'ChatGroup' }
    & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type ResendVerifyEmailMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ResendVerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { resendVerifyEmail?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type ResetPasswordFinalizeMutationVariables = Exact<{
  input: ResetPasswordFinalizeInput;
}>;


export type ResetPasswordFinalizeMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordFinalize: (
    { __typename?: 'AuthenticationResponse' }
    & Pick<AuthenticationResponse, 'jwt' | 'message' | 'newAccount' | 'refreshToken' | 'success' | 'userId'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  ) }
);

export type ResetPasswordInitiateMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordInitiateMutation = (
  { __typename?: 'Mutation' }
  & { resetPasswordInitiate: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type SendMessageMutationVariables = Exact<{
  input: MessageInput;
}>;


export type SendMessageMutation = (
  { __typename?: 'Mutation' }
  & { sendMessage: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type SendNotificationMutationVariables = Exact<{
  admins: Scalars['Boolean'];
  companyId?: InputMaybe<Scalars['ID']>;
  input: NotificationInput;
  locationId?: InputMaybe<Scalars['ID']>;
  receiver: Scalars['ID'];
}>;


export type SendNotificationMutation = (
  { __typename?: 'Mutation' }
  & { sendNotification: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type SendWorkShiftSlotInvitationsMutationVariables = Exact<{
  input: WorkShiftSlotsInvitationInput;
}>;


export type SendWorkShiftSlotInvitationsMutation = (
  { __typename?: 'Mutation' }
  & { sendWorkShiftSlotInvitations: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type SetIgnoreLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  ignore: Scalars['Boolean'];
}>;


export type SetIgnoreLocationMutation = (
  { __typename?: 'Mutation' }
  & { setIgnoreLocation?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type SetInvoicedMutationVariables = Exact<{
  bookingIds: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type SetInvoicedMutation = (
  { __typename?: 'Mutation' }
  & { setInvoiced: Array<(
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  )> }
);

export type SetLocationFavouriteMutationVariables = Exact<{
  favourite: Scalars['Boolean'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type SetLocationFavouriteMutation = (
  { __typename?: 'Mutation' }
  & { setLocationFavourite?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type SetLocationHiddenMutationVariables = Exact<{
  hidden: Scalars['Boolean'];
  id: Scalars['ID'];
  userId: Scalars['ID'];
}>;


export type SetLocationHiddenMutation = (
  { __typename?: 'Mutation' }
  & { setLocationHidden?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type SetMessagesAsReadMutationVariables = Exact<{
  channelId: Scalars['ID'];
}>;


export type SetMessagesAsReadMutation = (
  { __typename?: 'Mutation' }
  & { setMessagesAsRead: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type StartTimerMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  input: TimerTrackingInput;
}>;


export type StartTimerMutation = (
  { __typename?: 'Mutation' }
  & { startTimer: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  ) }
);

export type SuggestNewTimesMutationVariables = Exact<{
  input: Array<SuggestTimeInput> | SuggestTimeInput;
}>;


export type SuggestNewTimesMutation = (
  { __typename?: 'Mutation' }
  & { suggestNewTimes: (
    { __typename?: 'SuggestNewTimesResult' }
    & Pick<SuggestNewTimesResult, 'error' | 'notAvailable' | 'success'>
    & { updated: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )> }
  ) }
);

export type ToggleBlockUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ToggleBlockUserMutation = (
  { __typename?: 'Mutation' }
  & { toggleBlockUser: (
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  ) }
);

export type UndoDeclineBookingsMutationVariables = Exact<{
  input: Array<Scalars['ID']> | Scalars['ID'];
}>;


export type UndoDeclineBookingsMutation = (
  { __typename?: 'Mutation' }
  & { undoDeclineBookings: (
    { __typename?: 'UndoDeclineResult' }
    & Pick<UndoDeclineResult, 'error' | 'mandatoryMissing' | 'passedDue' | 'success'>
    & { updated: Array<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )> }
  ) }
);

export type UpdateChannelMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateChannelInput;
}>;


export type UpdateChannelMutation = (
  { __typename?: 'Mutation' }
  & { updateChannel?: Maybe<(
    { __typename?: 'Channel' }
    & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
    & { chatGroup?: Maybe<(
      { __typename?: 'ChatGroup' }
      & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        & { chatGroup?: Maybe<(
          { __typename?: 'ChatGroup' }
          & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        )>, participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
        )>>, workShift?: Maybe<(
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        )> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, participants?: Maybe<Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'lastReadAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ) }
    )>>, workShift?: Maybe<(
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    )> }
  )> }
);

export type UpdateCompanyMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateCompanyInput;
}>;


export type UpdateCompanyMutation = (
  { __typename?: 'Mutation' }
  & { updateCompany?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
    & { contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategories: Array<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateLocationInput;
}>;


export type UpdateLocationMutation = (
  { __typename?: 'Mutation' }
  & { updateLocation?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, logo?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type UpdateLocationFilesMutationVariables = Exact<{
  id: Scalars['ID'];
  input: FilesUpdate;
}>;


export type UpdateLocationFilesMutation = (
  { __typename?: 'Mutation' }
  & { updateLocationFiles?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, logo?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type UpdateLocationImagesMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<UpdateLocationImages>;
}>;


export type UpdateLocationImagesMutation = (
  { __typename?: 'Mutation' }
  & { updateLocationImages?: Maybe<(
    { __typename?: 'Location' }
    & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, logo?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  )> }
);

export type UpdateResourceCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateResourceCategory;
}>;


export type UpdateResourceCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateResourceCategory?: Maybe<(
    { __typename?: 'ResourceCategory' }
    & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
    & { level: (
      { __typename?: 'ResourceLevel' }
      & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    ), regional: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )> }
  )> }
);

export type UpdateResourceLevelMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpsertResourceLevel;
}>;


export type UpdateResourceLevelMutation = (
  { __typename?: 'Mutation' }
  & { updateResourceLevel?: Maybe<(
    { __typename?: 'ResourceLevel' }
    & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
  )> }
);

export type UpdateResourceRegionalCategoryMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateResourceRegionalCategory;
}>;


export type UpdateResourceRegionalCategoryMutation = (
  { __typename?: 'Mutation' }
  & { updateResourceRegionalCategory?: Maybe<(
    { __typename?: 'ResourceRegionalCategory' }
    & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
  )> }
);

export type UpdateSlotsForWorkShiftMutationVariables = Exact<{
  id: Scalars['ID'];
  workShiftSlots: Array<UpsertWorkShiftSlot> | UpsertWorkShiftSlot;
}>;


export type UpdateSlotsForWorkShiftMutation = (
  { __typename?: 'Mutation' }
  & { updateSlotsForWorkShift: (
    { __typename?: 'WorkShift' }
    & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), contactPerson?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), location: (
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    ), resourceRegionalCategories: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )>, slots: Array<(
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    )>, temporaryAddress?: Maybe<(
      { __typename?: 'TemporaryAddress' }
      & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftResourceCategories: (
      { __typename?: 'WorkShiftResourceCategories' }
      & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
      & { levels: Array<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )>, resourceCategories: Array<(
        { __typename?: 'WorkShiftResourceCategory' }
        & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
      )> }
    ) }
  ) }
);

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type UpdateUserEventMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UserEventInput;
}>;


export type UpdateUserEventMutation = (
  { __typename?: 'Mutation' }
  & { updateUserEvent?: Maybe<(
    { __typename?: 'UserEvent' }
    & Pick<UserEvent, 'createdAt' | 'description' | 'endTime' | 'id' | 'startTime' | 'title' | 'updatedAt'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ) }
  )> }
);

export type UpdateWorkShiftMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateWorkShiftInput;
}>;


export type UpdateWorkShiftMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkShift?: Maybe<(
    { __typename?: 'WorkShift' }
    & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), contactPerson?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), location: (
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    ), resourceRegionalCategories: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )>, slots: Array<(
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    )>, temporaryAddress?: Maybe<(
      { __typename?: 'TemporaryAddress' }
      & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftResourceCategories: (
      { __typename?: 'WorkShiftResourceCategories' }
      & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
      & { levels: Array<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )>, resourceCategories: Array<(
        { __typename?: 'WorkShiftResourceCategory' }
        & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
      )> }
    ) }
  )> }
);

export type UpdateWorkShiftTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
  input: WorkShiftTemplateInput;
}>;


export type UpdateWorkShiftTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updateWorkShiftTemplate?: Maybe<(
    { __typename?: 'WorkShiftTemplate' }
    & Pick<WorkShiftTemplate, 'createdAt' | 'description' | 'id' | 'mealDeduction' | 'name' | 'salaryBoost' | 'tips' | 'travelExpenseCompensation' | 'updatedAt' | 'verticalProfileCategory'>
    & { company: (
      { __typename?: 'Company' }
      & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
      & { contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategories: Array<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )> }
    ), locations: Array<(
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, slotTemplates: Array<(
      { __typename?: 'WorkShiftSlotTemplate' }
      & Pick<WorkShiftSlotTemplate, 'endHhMm' | 'resourceQuantity' | 'startHhMm' | 'workShiftTemplateId'>
    )> }
  )> }
);

export type UpsertChatGroupMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: UpsertChatGroupInput;
}>;


export type UpsertChatGroupMutation = (
  { __typename?: 'Mutation' }
  & { upsertChatGroup?: Maybe<(
    { __typename?: 'ChatGroup' }
    & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
    & { members: Array<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type UpsertCompanyProfileMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpsertCompanyProfile;
}>;


export type UpsertCompanyProfileMutation = (
  { __typename?: 'Mutation' }
  & { upsertCompanyProfile?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type UpsertCompanyResourceCategoriesMutationVariables = Exact<{
  categories: Array<Scalars['ID']> | Scalars['ID'];
  id: Scalars['ID'];
}>;


export type UpsertCompanyResourceCategoriesMutation = (
  { __typename?: 'Mutation' }
  & { upsertCompanyResourceCategories?: Maybe<(
    { __typename?: 'Company' }
    & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
    & { contactUser: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategories: Array<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )> }
  )> }
);

export type UpsertLegacyDataMutationVariables = Exact<{
  input: LegacyImport;
}>;


export type UpsertLegacyDataMutation = (
  { __typename?: 'Mutation' }
  & { upsertLegacyData: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type UpsertRecommendationMutationVariables = Exact<{
  input: UpsertRecommendation;
  userId: Scalars['ID'];
}>;


export type UpsertRecommendationMutation = (
  { __typename?: 'Mutation' }
  & { upsertRecommendation?: Maybe<(
    { __typename?: 'Recommendation' }
    & Pick<Recommendation, 'createdAt' | 'description' | 'hidden' | 'id' | 'updatedAt'>
    & { location: (
      { __typename?: 'Location' }
      & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactUser: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, logo?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    ) }
  )> }
);

export type UpsertResourceCategoriesForMutationVariables = Exact<{
  categories: Array<Scalars['ID']> | Scalars['ID'];
  resourceId: Scalars['ID'];
}>;


export type UpsertResourceCategoriesForMutation = (
  { __typename?: 'Mutation' }
  & { upsertResourceCategoriesFor: Array<(
    { __typename?: 'ResourceCategory' }
    & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
    & { level: (
      { __typename?: 'ResourceLevel' }
      & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
    ), regional: Array<(
      { __typename?: 'ResourceRegionalCategory' }
      & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
    )> }
  )> }
);

export type UpsertRestaurantProfileMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpsertRestaurantProfile;
}>;


export type UpsertRestaurantProfileMutation = (
  { __typename?: 'Mutation' }
  & { upsertRestaurantProfile?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type UpsertRestaurantProfileExperienceMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: Array<UpsertRestaurantWorkExperience> | UpsertRestaurantWorkExperience;
}>;


export type UpsertRestaurantProfileExperienceMutation = (
  { __typename?: 'Mutation' }
  & { upsertRestaurantProfileExperience?: Maybe<(
    { __typename?: 'RestaurantProfile' }
    & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
    )>, workExperience: Array<(
      { __typename?: 'RestaurantWorkExperience' }
      & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
    )>, workReferences: Array<(
      { __typename?: 'WorkReference' }
      & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
    )> }
  )> }
);

export type UpsertRestaurantProfileReferencesMutationVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  input: UpsertWorkReference;
}>;


export type UpsertRestaurantProfileReferencesMutation = (
  { __typename?: 'Mutation' }
  & { upsertRestaurantProfileReferences?: Maybe<(
    { __typename?: 'RestaurantProfile' }
    & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
    & { files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, tags: Array<(
      { __typename?: 'Tag' }
      & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
    )>, workExperience: Array<(
      { __typename?: 'RestaurantWorkExperience' }
      & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
    )>, workReferences: Array<(
      { __typename?: 'WorkReference' }
      & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
    )> }
  )> }
);

export type UpsertRestaurantProfileTagsMutationVariables = Exact<{
  input: Array<Scalars['ID']> | Scalars['ID'];
  userId?: InputMaybe<Scalars['ID']>;
}>;


export type UpsertRestaurantProfileTagsMutation = (
  { __typename?: 'Mutation' }
  & { upsertRestaurantProfileTags?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type UpsertTagMutationVariables = Exact<{
  input?: InputMaybe<UpsertTag>;
}>;


export type UpsertTagMutation = (
  { __typename?: 'Mutation' }
  & { upsertTag?: Maybe<(
    { __typename?: 'Tag' }
    & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
  )> }
);

export type UpsertTimeReportMutationVariables = Exact<{
  bookingId: Scalars['ID'];
  input: TimeReportInput;
}>;


export type UpsertTimeReportMutation = (
  { __typename?: 'Mutation' }
  & { upsertTimeReport: (
    { __typename?: 'Booking' }
    & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
    & { acceptWith?: Maybe<(
      { __typename?: 'AcceptWith' }
      & Pick<AcceptWith, 'total'>
      & { bookings: Array<(
        { __typename?: 'RelatedBooking' }
        & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        & { pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ) }
      )> }
    )>, approvedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, createdBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), history?: Maybe<Array<(
      { __typename?: 'HistoryBooking' }
      & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
      & { timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>>, pricePoint: (
      { __typename?: 'BookingPricePoint' }
      & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
    ), resource: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), resourceCategoryData: (
      { __typename?: 'ResourceCategoryData' }
      & Pick<ResourceCategoryData, 'verticalProfileCategory'>
      & { resourceLevel?: Maybe<(
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      )> }
    ), timeReport?: Maybe<(
      { __typename?: 'TimeReport' }
      & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
    )>, timerTracking?: Maybe<(
      { __typename?: 'TimerTracking' }
      & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
    )>, updatedBy: (
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    ), workShiftSlot: (
      { __typename?: 'WorkShiftSlot' }
      & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
      & { contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShift: (
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      ) }
    ) }
  ) }
);

export type UpsertUserIdentificationMutationVariables = Exact<{
  input: UserIdentification;
}>;


export type UpsertUserIdentificationMutation = (
  { __typename?: 'Mutation' }
  & { upsertUserIdentification?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type UserChangeImageMutationVariables = Exact<{
  id: Scalars['ID'];
  input?: InputMaybe<ImageUpdate>;
}>;


export type UserChangeImageMutation = (
  { __typename?: 'Mutation' }
  & { userChangeImage?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type UserChangePasswordMutationVariables = Exact<{
  input: UserChangePasswordInput;
}>;


export type UserChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & { userChangePassword: (
    { __typename?: 'NoContent' }
    & Pick<NoContent, 'success'>
  ) }
);

export type VerifyBankIdInitMutationVariables = Exact<{
  socialSecurity: Scalars['String'];
}>;


export type VerifyBankIdInitMutation = (
  { __typename?: 'Mutation' }
  & { verifyBankIdInit?: Maybe<(
    { __typename?: 'BankIDVerification' }
    & Pick<BankIdVerification, 'autoStartToken' | 'orderRef'>
  )> }
);

export type VerifyBankIdResolveMutationVariables = Exact<{
  orderRef: Scalars['String'];
}>;


export type VerifyBankIdResolveMutation = (
  { __typename?: 'Mutation' }
  & { verifyBankIdResolve?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type VerifyEmailMutationVariables = Exact<{
  email: Scalars['String'];
  nonce: Scalars['String'];
}>;


export type VerifyEmailMutation = (
  { __typename?: 'Mutation' }
  & { verifyEmail?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type VerifyUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type VerifyUserMutation = (
  { __typename?: 'Mutation' }
  & { verifyUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
    & { identification?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, image?: Maybe<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )>, resourceCategories?: Maybe<Array<Maybe<(
      { __typename?: 'ResourceCategory' }
      & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
      & { level: (
        { __typename?: 'ResourceLevel' }
        & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
      ), regional: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )> }
    )>>>, userProfile: Array<(
      { __typename?: 'CompanyProfile' }
      & Pick<CompanyProfile, 'description' | 'id'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), locations: Array<(
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )> }
    ) | (
      { __typename?: 'RestaurantProfile' }
      & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
      & { files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, tags: Array<(
        { __typename?: 'Tag' }
        & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
      )>, workExperience: Array<(
        { __typename?: 'RestaurantWorkExperience' }
        & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
      )>, workReferences: Array<(
        { __typename?: 'WorkReference' }
        & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
      )> }
    )>, verifiedBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  )> }
);

export type ChatPrivateChannelsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatPrivateChannelsSubscription = (
  { __typename?: 'Subscription' }
  & { chatPrivateChannels: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
    & { chatGroup?: Maybe<(
      { __typename?: 'ChatGroup' }
      & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        & { chatGroup?: Maybe<(
          { __typename?: 'ChatGroup' }
          & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        )>, participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
        )>>, workShift?: Maybe<(
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        )> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, participants?: Maybe<Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'lastReadAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ) }
    )>>, workShift?: Maybe<(
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    )> }
  ) }
);

export type ChatPrivateMessagesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatPrivateMessagesSubscription = (
  { __typename?: 'Subscription' }
  & { chatPrivateMessages: (
    { __typename?: 'Message' }
    & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
    & { channel: (
      { __typename?: 'Channel' }
      & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
      & { chatGroup?: Maybe<(
        { __typename?: 'ChatGroup' }
        & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        & { members: Array<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        & { channel: (
          { __typename?: 'Channel' }
          & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        ), createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, extra?: Maybe<(
          { __typename?: 'Booking' }
          & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        )>, files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, images: Array<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )>, participants?: Maybe<Array<(
        { __typename?: 'Participant' }
        & Pick<Participant, 'lastReadAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ) }
      )>>, workShift?: Maybe<(
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      )> }
    ), createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, extra?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )>, files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  ) }
);

export type ChatPublicChannelsSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatPublicChannelsSubscription = (
  { __typename?: 'Subscription' }
  & { chatPublicChannels: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
    & { chatGroup?: Maybe<(
      { __typename?: 'ChatGroup' }
      & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        & { chatGroup?: Maybe<(
          { __typename?: 'ChatGroup' }
          & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        )>, participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
        )>>, workShift?: Maybe<(
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        )> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, participants?: Maybe<Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'lastReadAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ) }
    )>>, workShift?: Maybe<(
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    )> }
  ) }
);

export type ChatPublicMessagesSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatPublicMessagesSubscription = (
  { __typename?: 'Subscription' }
  & { chatPublicMessages: (
    { __typename?: 'Message' }
    & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
    & { channel: (
      { __typename?: 'Channel' }
      & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
      & { chatGroup?: Maybe<(
        { __typename?: 'ChatGroup' }
        & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        & { members: Array<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        & { channel: (
          { __typename?: 'Channel' }
          & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        ), createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, extra?: Maybe<(
          { __typename?: 'Booking' }
          & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        )>, files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, images: Array<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )>, participants?: Maybe<Array<(
        { __typename?: 'Participant' }
        & Pick<Participant, 'lastReadAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ) }
      )>>, workShift?: Maybe<(
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      )> }
    ), createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, extra?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )>, files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  ) }
);

export type ChatSubscribeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type ChatSubscribeSubscription = (
  { __typename?: 'Subscription' }
  & { chatSubscribe: (
    { __typename?: 'Channel' }
    & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
    & { chatGroup?: Maybe<(
      { __typename?: 'ChatGroup' }
      & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
      & { members: Array<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, messages: Array<(
      { __typename?: 'Message' }
      & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
      & { channel: (
        { __typename?: 'Channel' }
        & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        & { chatGroup?: Maybe<(
          { __typename?: 'ChatGroup' }
          & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        )>, messages: Array<(
          { __typename?: 'Message' }
          & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        )>, participants?: Maybe<Array<(
          { __typename?: 'Participant' }
          & Pick<Participant, 'lastReadAt'>
        )>>, workShift?: Maybe<(
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        )> }
      ), createdBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, extra?: Maybe<(
        { __typename?: 'Booking' }
        & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        & { acceptWith?: Maybe<(
          { __typename?: 'AcceptWith' }
          & Pick<AcceptWith, 'total'>
        )>, approvedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), history?: Maybe<Array<(
          { __typename?: 'HistoryBooking' }
          & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        )>>, pricePoint: (
          { __typename?: 'BookingPricePoint' }
          & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
        ), resource: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategoryData: (
          { __typename?: 'ResourceCategoryData' }
          & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        ), timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftSlot: (
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        ) }
      )>, files: Array<(
        { __typename?: 'File' }
        & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
      )>, images: Array<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )> }
    )>, participants?: Maybe<Array<(
      { __typename?: 'Participant' }
      & Pick<Participant, 'lastReadAt'>
      & { user: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ) }
    )>>, workShift?: Maybe<(
      { __typename?: 'WorkShift' }
      & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
      & { company: (
        { __typename?: 'Company' }
        & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        & { contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), resourceCategories: Array<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )> }
      ), contactPerson?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), location: (
        { __typename?: 'Location' }
        & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactUser: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, logo?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      ), resourceRegionalCategories: Array<(
        { __typename?: 'ResourceRegionalCategory' }
        & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
      )>, slots: Array<(
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      )>, temporaryAddress?: Maybe<(
        { __typename?: 'TemporaryAddress' }
        & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftResourceCategories: (
        { __typename?: 'WorkShiftResourceCategories' }
        & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        & { levels: Array<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )>, resourceCategories: Array<(
          { __typename?: 'WorkShiftResourceCategory' }
          & Pick<WorkShiftResourceCategory, 'id' | 'levelName'>
        )> }
      ) }
    )> }
  ) | (
    { __typename?: 'Message' }
    & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
    & { channel: (
      { __typename?: 'Channel' }
      & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
      & { chatGroup?: Maybe<(
        { __typename?: 'ChatGroup' }
        & Pick<ChatGroup, 'closed' | 'createdAt' | 'id' | 'messages' | 'name' | 'receivers' | 'responses' | 'updatedAt'>
        & { members: Array<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, messages: Array<(
        { __typename?: 'Message' }
        & Pick<Message, 'createdAt' | 'id' | 'message' | 'position' | 'senderName' | 'title' | 'updatedAt'>
        & { channel: (
          { __typename?: 'Channel' }
          & Pick<Channel, 'createdAt' | 'deletedAt' | 'id' | 'lastSentAt' | 'name' | 'origin' | 'updatedAt'>
        ), createdBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, extra?: Maybe<(
          { __typename?: 'Booking' }
          & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
        )>, files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, images: Array<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )> }
      )>, participants?: Maybe<Array<(
        { __typename?: 'Participant' }
        & Pick<Participant, 'lastReadAt'>
        & { user: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ) }
      )>>, workShift?: Maybe<(
        { __typename?: 'WorkShift' }
        & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), location: (
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        ), resourceRegionalCategories: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )>, slots: Array<(
          { __typename?: 'WorkShiftSlot' }
          & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        )>, temporaryAddress?: Maybe<(
          { __typename?: 'TemporaryAddress' }
          & Pick<TemporaryAddress, 'city' | 'id' | 'latitude' | 'longitude' | 'name' | 'postalCode' | 'region' | 'street'>
        )>, updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShiftResourceCategories: (
          { __typename?: 'WorkShiftResourceCategories' }
          & Pick<WorkShiftResourceCategories, 'levelIds' | 'verticalProfileCategory'>
        ) }
      )> }
    ), createdBy?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )>, extra?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )>, files: Array<(
      { __typename?: 'File' }
      & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
    )>, images: Array<(
      { __typename?: 'Image' }
      & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
    )> }
  ) }
);

export type NotificationSubscribeSubscriptionVariables = Exact<{ [key: string]: never; }>;


export type NotificationSubscribeSubscription = (
  { __typename?: 'Subscription' }
  & { notificationSubscribe: (
    { __typename?: 'Notification' }
    & Pick<Notification, 'background' | 'createdAt' | 'id' | 'message' | 'referenceId' | 'referenceType' | 'title' | 'type'>
    & { booking?: Maybe<(
      { __typename?: 'Booking' }
      & Pick<Booking, 'acceptedAt' | 'approvedAt' | 'approvedByResourceAt' | 'backOfficeMainStatus' | 'backOfficeSubStatus' | 'breakMinutes' | 'cancelPolicyPct' | 'cancelledAt' | 'confirmedCanceled' | 'createdAt' | 'declinedAt' | 'endTime' | 'extraStatus' | 'id' | 'invoiced' | 'legacyId' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'suggestionAcceptedAt' | 'updatedAt' | 'workShiftId'>
      & { acceptWith?: Maybe<(
        { __typename?: 'AcceptWith' }
        & Pick<AcceptWith, 'total'>
        & { bookings: Array<(
          { __typename?: 'RelatedBooking' }
          & Pick<RelatedBooking, 'breakMinutes' | 'createdAt' | 'declinedAt' | 'endTime' | 'id' | 'startTime' | 'status' | 'updatedAt' | 'workShiftId'>
        )> }
      )>, approvedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>, createdBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), history?: Maybe<Array<(
        { __typename?: 'HistoryBooking' }
        & Pick<HistoryBooking, 'breakMinutes' | 'endTime' | 'startTime' | 'status' | 'suggestedEnd' | 'suggestedStart' | 'updatedAt' | 'updatedById'>
        & { timeReport?: Maybe<(
          { __typename?: 'TimeReport' }
          & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
        )>, timerTracking?: Maybe<(
          { __typename?: 'TimerTracking' }
          & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
        )>, updatedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )>>, pricePoint: (
        { __typename?: 'BookingPricePoint' }
        & Pick<BookingPricePoint, 'hourlySalaryBase' | 'hourlySalaryBoost' | 'hours' | 'totalPrice' | 'totalSalary'>
      ), resource: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), resourceCategoryData: (
        { __typename?: 'ResourceCategoryData' }
        & Pick<ResourceCategoryData, 'verticalProfileCategory'>
        & { resourceLevel?: Maybe<(
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        )> }
      ), timeReport?: Maybe<(
        { __typename?: 'TimeReport' }
        & Pick<TimeReport, 'reportedBreak' | 'reportedEnd' | 'reportedStart'>
      )>, timerTracking?: Maybe<(
        { __typename?: 'TimerTracking' }
        & Pick<TimerTracking, 'timerEnd' | 'timerLat' | 'timerLng' | 'timerStart'>
      )>, updatedBy: (
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      ), workShiftSlot: (
        { __typename?: 'WorkShiftSlot' }
        & Pick<WorkShiftSlot, 'bookedQuantity' | 'bookingStatuses' | 'breakMinutes' | 'calendarStatus' | 'cancelledAt' | 'createdAt' | 'customContactPerson' | 'defaultFilter' | 'endTime' | 'fullyBooked' | 'id' | 'name' | 'price' | 'resourceQuantity' | 'startTime' | 'status' | 'summarizedBookingStatus' | 'updatedAt'>
        & { contactPerson?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )>, createdBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), updatedBy: (
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        ), workShift: (
          { __typename?: 'WorkShift' }
          & Pick<WorkShift, 'acceptanceTime' | 'bookingType' | 'cancelledAt' | 'city' | 'createdAt' | 'customContactPerson' | 'description' | 'firstStartTime' | 'id' | 'internalName' | 'lastEndTime' | 'mealDeduction' | 'minutesPriorStart' | 'postalCode' | 'region' | 'resourceQuantity' | 'salaryBoost' | 'sameResources' | 'sentAt' | 'status' | 'street' | 'templateId' | 'tips' | 'totalPrice' | 'travelExpenseCompensation' | 'updatedAt'>
        ) }
      ) }
    )>, sender?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
      & { identification?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, image?: Maybe<(
        { __typename?: 'Image' }
        & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
      )>, resourceCategories?: Maybe<Array<Maybe<(
        { __typename?: 'ResourceCategory' }
        & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        & { level: (
          { __typename?: 'ResourceLevel' }
          & Pick<ResourceLevel, 'createdAt' | 'id' | 'name' | 'updatedAt'>
        ), regional: Array<(
          { __typename?: 'ResourceRegionalCategory' }
          & Pick<ResourceRegionalCategory, 'createdAt' | 'id' | 'modifier' | 'price' | 'region' | 'salary' | 'updatedAt'>
        )> }
      )>>>, userProfile: Array<(
        { __typename?: 'CompanyProfile' }
        & Pick<CompanyProfile, 'description' | 'id'>
        & { company: (
          { __typename?: 'Company' }
          & Pick<Company, 'active' | 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'category' | 'city' | 'commission' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'street' | 'updatedAt' | 'web'>
        ), locations: Array<(
          { __typename?: 'Location' }
          & Pick<Location, 'billingCity' | 'billingPostalCode' | 'billingReference' | 'billingStreet' | 'city' | 'createdAt' | 'description' | 'eInvoice' | 'id' | 'latitude' | 'legacyCustomerId' | 'longitude' | 'name' | 'organisationNumber' | 'postalCode' | 'region' | 'street' | 'updatedAt' | 'web'>
        )> }
      ) | (
        { __typename?: 'RestaurantProfile' }
        & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        & { files: Array<(
          { __typename?: 'File' }
          & Pick<File, 'contentType' | 'createdAt' | 'filename' | 'id' | 'originalName' | 'sizeInBytes' | 'uri'>
        )>, tags: Array<(
          { __typename?: 'Tag' }
          & Pick<Tag, 'category' | 'createdAt' | 'id' | 'name' | 'subcategory' | 'updatedAt'>
        )>, workExperience: Array<(
          { __typename?: 'RestaurantWorkExperience' }
          & Pick<RestaurantWorkExperience, 'category' | 'createdAt' | 'id' | 'otherWorkExperience' | 'updatedAt' | 'years'>
        )>, workReferences: Array<(
          { __typename?: 'WorkReference' }
          & Pick<WorkReference, 'company' | 'contactName' | 'contactPhone' | 'createdAt' | 'id' | 'role' | 'updatedAt'>
        )> }
      )>, verifiedBy?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        & { identification?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, image?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'contentType' | 'createdAt' | 'filename' | 'height' | 'id' | 'originalName' | 'sizeInBytes' | 'uri' | 'width'>
        )>, resourceCategories?: Maybe<Array<Maybe<(
          { __typename?: 'ResourceCategory' }
          & Pick<ResourceCategory, 'createdAt' | 'defaultSalary' | 'id' | 'name' | 'updatedAt' | 'vertical'>
        )>>>, userProfile: Array<(
          { __typename?: 'CompanyProfile' }
          & Pick<CompanyProfile, 'description' | 'id'>
        ) | (
          { __typename?: 'RestaurantProfile' }
          & Pick<RestaurantProfile, 'adjacentRegions' | 'approvedBookings' | 'createdAt' | 'description' | 'emergencyContactName' | 'emergencyContactPhone' | 'favouriteCount' | 'id' | 'languages' | 'rating' | 'region' | 'status' | 'updatedAt'>
        )>, verifiedBy?: Maybe<(
          { __typename?: 'User' }
          & Pick<User, 'active' | 'approved' | 'availableForSlots' | 'blocked' | 'city' | 'createdAt' | 'deletedAt' | 'email' | 'emailVerified' | 'favourite' | 'firstName' | 'hidden' | 'id' | 'internalComment' | 'lastName' | 'legacyId' | 'mobileNumber' | 'nextOfKinName' | 'nextOfKinPhone' | 'postalCode' | 'role' | 'socialSecurity' | 'street' | 'updatedAt' | 'verificationMethod' | 'verified'>
        )> }
      )> }
    )> }
  ) }
);

export const ProfileWithDescriptionFragmentDoc = gql`
    fragment ProfileWithDescription on UserProfile {
  ... on RestaurantProfile {
    adjacentRegions
    approvedBookings
    createdAt
    description
    emergencyContactName
    emergencyContactPhone
    favouriteCount
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    languages
    rating
    region
    status
    tags {
      category
      createdAt
      id
      name
      subcategory
      updatedAt
    }
    updatedAt
    workExperience {
      category
      createdAt
      id
      updatedAt
      years
    }
    workReferences {
      company
      contactName
      contactPhone
      createdAt
      id
      role
      updatedAt
    }
  }
  ... on CompanyProfile {
    description
  }
}
    `;
export const CustomBookingSlotsDocument = gql`
    query CustomBookingSlots($filter: BookingSlotsFilter, $sorting: SortOrdering) {
  bookingSlots(filter: $filter, sorting: $sorting) {
    edges {
      booking {
        acceptWith {
          total
        }
        acceptedAt
        approvedAt
        approvedByResourceAt
        breakMinutes
        cancelPolicyPct
        cancelledAt
        createdAt
        declinedAt
        endTime
        id
        invoiced
        legacyId
        resource {
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verified
        }
        resourceCategoryData {
          resourceLevel {
            createdAt
            id
            name
            updatedAt
          }
          verticalProfileCategory
        }
        startTime
        status
        extraStatus
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        workShiftId
        workShiftSlot {
          bookedQuantity
          breakMinutes
          cancelledAt
          createdAt
          customContactPerson
          endTime
          fullyBooked
          id
          price
          resourceQuantity
          startTime
          status
          updatedAt
        }
      }
      breakMinutes
      endTime
      startTime
      workShiftId
      workShiftSlot {
        bookedQuantity
        breakMinutes
        cancelledAt
        contactPerson {
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verified
        }
        createdAt
        customContactPerson
        endTime
        fullyBooked
        id
        price
        resourceQuantity
        startTime
        status
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          company {
            id
            name
          }
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          location {
            id
            name
          }
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomBookingSlotsQuery__
 *
 * To run a query within a React component, call `useCustomBookingSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomBookingSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomBookingSlotsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomBookingSlotsQuery(baseOptions?: Apollo.QueryHookOptions<CustomBookingSlotsQuery, CustomBookingSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomBookingSlotsQuery, CustomBookingSlotsQueryVariables>(CustomBookingSlotsDocument, options);
      }
export function useCustomBookingSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomBookingSlotsQuery, CustomBookingSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomBookingSlotsQuery, CustomBookingSlotsQueryVariables>(CustomBookingSlotsDocument, options);
        }
export type CustomBookingSlotsQueryHookResult = ReturnType<typeof useCustomBookingSlotsQuery>;
export type CustomBookingSlotsLazyQueryHookResult = ReturnType<typeof useCustomBookingSlotsLazyQuery>;
export type CustomBookingSlotsQueryResult = Apollo.QueryResult<CustomBookingSlotsQuery, CustomBookingSlotsQueryVariables>;
export const CustomBookingsDocument = gql`
    query CustomBookings($filter: BookingFilter, $sorting: SortOrdering) {
  bookings(filter: $filter, sorting: $sorting) {
    data {
      preliminaryTotalPrice
      totalPrice
      totalTime
    }
    edges {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          company {
            id
            name
          }
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          location {
            id
            name
          }
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomBookingsQuery__
 *
 * To run a query within a React component, call `useCustomBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomBookingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomBookingsQuery(baseOptions?: Apollo.QueryHookOptions<CustomBookingsQuery, CustomBookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomBookingsQuery, CustomBookingsQueryVariables>(CustomBookingsDocument, options);
      }
export function useCustomBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomBookingsQuery, CustomBookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomBookingsQuery, CustomBookingsQueryVariables>(CustomBookingsDocument, options);
        }
export type CustomBookingsQueryHookResult = ReturnType<typeof useCustomBookingsQuery>;
export type CustomBookingsLazyQueryHookResult = ReturnType<typeof useCustomBookingsLazyQuery>;
export type CustomBookingsQueryResult = Apollo.QueryResult<CustomBookingsQuery, CustomBookingsQueryVariables>;
export const CustomBookingsWorkShiftSlotModalDocument = gql`
    query CustomBookingsWorkShiftSlotModal($filter: BookingFilter, $sorting: SortOrdering) {
  bookings(filter: $filter, sorting: $sorting) {
    edges {
      backOfficeMainStatus
      backOfficeSubStatus
      cancelledAt
      id
      resource {
        id
        firstName
        image {
          originalName
          uri
        }
        lastName
      }
      status
    }
  }
}
    `;

/**
 * __useCustomBookingsWorkShiftSlotModalQuery__
 *
 * To run a query within a React component, call `useCustomBookingsWorkShiftSlotModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomBookingsWorkShiftSlotModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomBookingsWorkShiftSlotModalQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomBookingsWorkShiftSlotModalQuery(baseOptions?: Apollo.QueryHookOptions<CustomBookingsWorkShiftSlotModalQuery, CustomBookingsWorkShiftSlotModalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomBookingsWorkShiftSlotModalQuery, CustomBookingsWorkShiftSlotModalQueryVariables>(CustomBookingsWorkShiftSlotModalDocument, options);
      }
export function useCustomBookingsWorkShiftSlotModalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomBookingsWorkShiftSlotModalQuery, CustomBookingsWorkShiftSlotModalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomBookingsWorkShiftSlotModalQuery, CustomBookingsWorkShiftSlotModalQueryVariables>(CustomBookingsWorkShiftSlotModalDocument, options);
        }
export type CustomBookingsWorkShiftSlotModalQueryHookResult = ReturnType<typeof useCustomBookingsWorkShiftSlotModalQuery>;
export type CustomBookingsWorkShiftSlotModalLazyQueryHookResult = ReturnType<typeof useCustomBookingsWorkShiftSlotModalLazyQuery>;
export type CustomBookingsWorkShiftSlotModalQueryResult = Apollo.QueryResult<CustomBookingsWorkShiftSlotModalQuery, CustomBookingsWorkShiftSlotModalQueryVariables>;
export const CustomBroadcastChatGroupReceiversDocument = gql`
    query CustomBroadcastChatGroupReceivers($filter: BaseFilter, $id: ID!) {
  chatGroupReceivers(filter: $filter, id: $id) {
    edges {
      firstName
      lastName
      id
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomBroadcastChatGroupReceiversQuery__
 *
 * To run a query within a React component, call `useCustomBroadcastChatGroupReceiversQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomBroadcastChatGroupReceiversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomBroadcastChatGroupReceiversQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomBroadcastChatGroupReceiversQuery(baseOptions: Apollo.QueryHookOptions<CustomBroadcastChatGroupReceiversQuery, CustomBroadcastChatGroupReceiversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomBroadcastChatGroupReceiversQuery, CustomBroadcastChatGroupReceiversQueryVariables>(CustomBroadcastChatGroupReceiversDocument, options);
      }
export function useCustomBroadcastChatGroupReceiversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomBroadcastChatGroupReceiversQuery, CustomBroadcastChatGroupReceiversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomBroadcastChatGroupReceiversQuery, CustomBroadcastChatGroupReceiversQueryVariables>(CustomBroadcastChatGroupReceiversDocument, options);
        }
export type CustomBroadcastChatGroupReceiversQueryHookResult = ReturnType<typeof useCustomBroadcastChatGroupReceiversQuery>;
export type CustomBroadcastChatGroupReceiversLazyQueryHookResult = ReturnType<typeof useCustomBroadcastChatGroupReceiversLazyQuery>;
export type CustomBroadcastChatGroupReceiversQueryResult = Apollo.QueryResult<CustomBroadcastChatGroupReceiversQuery, CustomBroadcastChatGroupReceiversQueryVariables>;
export const CustomBroadcastChatGroupRespondersDocument = gql`
    query CustomBroadcastChatGroupResponders($filter: BaseFilter, $id: ID!) {
  chatGroupResponders(filter: $filter, id: $id) {
    edges {
      firstName
      lastName
      id
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomBroadcastChatGroupRespondersQuery__
 *
 * To run a query within a React component, call `useCustomBroadcastChatGroupRespondersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomBroadcastChatGroupRespondersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomBroadcastChatGroupRespondersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomBroadcastChatGroupRespondersQuery(baseOptions: Apollo.QueryHookOptions<CustomBroadcastChatGroupRespondersQuery, CustomBroadcastChatGroupRespondersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomBroadcastChatGroupRespondersQuery, CustomBroadcastChatGroupRespondersQueryVariables>(CustomBroadcastChatGroupRespondersDocument, options);
      }
export function useCustomBroadcastChatGroupRespondersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomBroadcastChatGroupRespondersQuery, CustomBroadcastChatGroupRespondersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomBroadcastChatGroupRespondersQuery, CustomBroadcastChatGroupRespondersQueryVariables>(CustomBroadcastChatGroupRespondersDocument, options);
        }
export type CustomBroadcastChatGroupRespondersQueryHookResult = ReturnType<typeof useCustomBroadcastChatGroupRespondersQuery>;
export type CustomBroadcastChatGroupRespondersLazyQueryHookResult = ReturnType<typeof useCustomBroadcastChatGroupRespondersLazyQuery>;
export type CustomBroadcastChatGroupRespondersQueryResult = Apollo.QueryResult<CustomBroadcastChatGroupRespondersQuery, CustomBroadcastChatGroupRespondersQueryVariables>;
export const CustomChannelsDocument = gql`
    query CustomChannels($filter: ChannelFilter) {
  channels(filter: $filter) {
    edges {
      id
      lastSentAt
      messages {
        files {
          id
        }
        id
        images {
          id
        }
        message
        title
        createdBy {
          id
        }
      }
      name
      origin
      participants {
        lastReadAt
        user {
          firstName
          id
          image {
            height
            id
            originalName
            uri
            width
          }
          lastName
          role
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomChannelsQuery__
 *
 * To run a query within a React component, call `useCustomChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomChannelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomChannelsQuery(baseOptions?: Apollo.QueryHookOptions<CustomChannelsQuery, CustomChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomChannelsQuery, CustomChannelsQueryVariables>(CustomChannelsDocument, options);
      }
export function useCustomChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomChannelsQuery, CustomChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomChannelsQuery, CustomChannelsQueryVariables>(CustomChannelsDocument, options);
        }
export type CustomChannelsQueryHookResult = ReturnType<typeof useCustomChannelsQuery>;
export type CustomChannelsLazyQueryHookResult = ReturnType<typeof useCustomChannelsLazyQuery>;
export type CustomChannelsQueryResult = Apollo.QueryResult<CustomChannelsQuery, CustomChannelsQueryVariables>;
export const CustomBroadcastChannelsDocument = gql`
    query CustomBroadcastChannels($filter: ChannelFilter) {
  channels(filter: $filter) {
    edges {
      id
    }
  }
}
    `;

/**
 * __useCustomBroadcastChannelsQuery__
 *
 * To run a query within a React component, call `useCustomBroadcastChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomBroadcastChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomBroadcastChannelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomBroadcastChannelsQuery(baseOptions?: Apollo.QueryHookOptions<CustomBroadcastChannelsQuery, CustomBroadcastChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomBroadcastChannelsQuery, CustomBroadcastChannelsQueryVariables>(CustomBroadcastChannelsDocument, options);
      }
export function useCustomBroadcastChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomBroadcastChannelsQuery, CustomBroadcastChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomBroadcastChannelsQuery, CustomBroadcastChannelsQueryVariables>(CustomBroadcastChannelsDocument, options);
        }
export type CustomBroadcastChannelsQueryHookResult = ReturnType<typeof useCustomBroadcastChannelsQuery>;
export type CustomBroadcastChannelsLazyQueryHookResult = ReturnType<typeof useCustomBroadcastChannelsLazyQuery>;
export type CustomBroadcastChannelsQueryResult = Apollo.QueryResult<CustomBroadcastChannelsQuery, CustomBroadcastChannelsQueryVariables>;
export const CustomChatSubscribeDocument = gql`
    subscription CustomChatSubscribe {
  chatSubscribe {
    ... on Channel {
      id
      lastSentAt
      messages {
        files {
          id
        }
        id
        images {
          id
        }
        message
        title
        createdBy {
          id
        }
      }
      name
      origin
      participants {
        lastReadAt
        user {
          firstName
          id
          image {
            height
            id
            originalName
            uri
            width
          }
          lastName
        }
      }
    }
    ... on Message {
      channel {
        id
        lastSentAt
        participants {
          lastReadAt
          user {
            id
          }
        }
      }
      createdAt
      createdBy {
        firstName
        id
        lastName
      }
      extra {
        ... on Booking {
          endTime
          id
          startTime
          workShiftId
          workShiftSlot {
            id
            workShift {
              id
              location {
                id
                image {
                  originalName
                  height
                  id
                  uri
                  width
                }
                name
              }
            }
          }
        }
      }
      files {
        contentType
        originalName
        id
        uri
      }
      id
      images {
        contentType
        originalName
        height
        id
        uri
        width
      }
      message
      title
    }
  }
}
    `;

/**
 * __useCustomChatSubscribeSubscription__
 *
 * To run a query within a React component, call `useCustomChatSubscribeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCustomChatSubscribeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomChatSubscribeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCustomChatSubscribeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CustomChatSubscribeSubscription, CustomChatSubscribeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CustomChatSubscribeSubscription, CustomChatSubscribeSubscriptionVariables>(CustomChatSubscribeDocument, options);
      }
export type CustomChatSubscribeSubscriptionHookResult = ReturnType<typeof useCustomChatSubscribeSubscription>;
export type CustomChatSubscribeSubscriptionResult = Apollo.SubscriptionResult<CustomChatSubscribeSubscription>;
export const CustomCompaniesDocument = gql`
    query CustomCompanies($filter: CompanyFilter, $sorting: SortOrdering) {
  companies(filter: $filter, sorting: $sorting) {
    edges {
      id
      name
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomCompaniesQuery__
 *
 * To run a query within a React component, call `useCustomCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CustomCompaniesQuery, CustomCompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomCompaniesQuery, CustomCompaniesQueryVariables>(CustomCompaniesDocument, options);
      }
export function useCustomCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomCompaniesQuery, CustomCompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomCompaniesQuery, CustomCompaniesQueryVariables>(CustomCompaniesDocument, options);
        }
export type CustomCompaniesQueryHookResult = ReturnType<typeof useCustomCompaniesQuery>;
export type CustomCompaniesLazyQueryHookResult = ReturnType<typeof useCustomCompaniesLazyQuery>;
export type CustomCompaniesQueryResult = Apollo.QueryResult<CustomCompaniesQuery, CustomCompaniesQueryVariables>;
export const CustomLocationsDocument = gql`
    query CustomLocations($filter: LocationFilter, $sorting: SortOrdering) {
  locations(filter: $filter, sorting: $sorting) {
    edges {
      id
      name
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomLocationsQuery__
 *
 * To run a query within a React component, call `useCustomLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomLocationsQuery(baseOptions?: Apollo.QueryHookOptions<CustomLocationsQuery, CustomLocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomLocationsQuery, CustomLocationsQueryVariables>(CustomLocationsDocument, options);
      }
export function useCustomLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomLocationsQuery, CustomLocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomLocationsQuery, CustomLocationsQueryVariables>(CustomLocationsDocument, options);
        }
export type CustomLocationsQueryHookResult = ReturnType<typeof useCustomLocationsQuery>;
export type CustomLocationsLazyQueryHookResult = ReturnType<typeof useCustomLocationsLazyQuery>;
export type CustomLocationsQueryResult = Apollo.QueryResult<CustomLocationsQuery, CustomLocationsQueryVariables>;
export const CustomMessagesDocument = gql`
    query CustomMessages($filter: MessageFilter!) {
  messages(filter: $filter) {
    edges {
      channel {
        id
        lastSentAt
        participants {
          lastReadAt
          user {
            id
          }
        }
      }
      createdAt
      createdBy {
        firstName
        id
        lastName
      }
      extra {
        ... on Booking {
          endTime
          id
          startTime
          workShiftId
          workShiftSlot {
            id
            workShift {
              id
              location {
                id
                image {
                  originalName
                  height
                  id
                  uri
                  width
                }
                name
              }
            }
          }
        }
      }
      files {
        contentType
        originalName
        id
        uri
      }
      id
      images {
        contentType
        originalName
        height
        id
        uri
        width
      }
      message
      title
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomMessagesQuery__
 *
 * To run a query within a React component, call `useCustomMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomMessagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useCustomMessagesQuery(baseOptions: Apollo.QueryHookOptions<CustomMessagesQuery, CustomMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomMessagesQuery, CustomMessagesQueryVariables>(CustomMessagesDocument, options);
      }
export function useCustomMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomMessagesQuery, CustomMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomMessagesQuery, CustomMessagesQueryVariables>(CustomMessagesDocument, options);
        }
export type CustomMessagesQueryHookResult = ReturnType<typeof useCustomMessagesQuery>;
export type CustomMessagesLazyQueryHookResult = ReturnType<typeof useCustomMessagesLazyQuery>;
export type CustomMessagesQueryResult = Apollo.QueryResult<CustomMessagesQuery, CustomMessagesQueryVariables>;
export const CustomNotificationSubscribeDocument = gql`
    subscription CustomNotificationSubscribe {
  notificationSubscribe {
    background
    booking {
      createdBy {
        id
      }
      id
      startTime
      workShiftId
      workShiftSlot {
        id
        workShift {
          location {
            id
          }
        }
      }
    }
    createdAt
    id
    message
    referenceId
    referenceType
    sender {
      email
      firstName
      id
      lastName
    }
    title
    type
  }
}
    `;

/**
 * __useCustomNotificationSubscribeSubscription__
 *
 * To run a query within a React component, call `useCustomNotificationSubscribeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useCustomNotificationSubscribeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomNotificationSubscribeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useCustomNotificationSubscribeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<CustomNotificationSubscribeSubscription, CustomNotificationSubscribeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<CustomNotificationSubscribeSubscription, CustomNotificationSubscribeSubscriptionVariables>(CustomNotificationSubscribeDocument, options);
      }
export type CustomNotificationSubscribeSubscriptionHookResult = ReturnType<typeof useCustomNotificationSubscribeSubscription>;
export type CustomNotificationSubscribeSubscriptionResult = Apollo.SubscriptionResult<CustomNotificationSubscribeSubscription>;
export const CustomNotificationsDocument = gql`
    query CustomNotifications($filter: NotificationFilter, $sorting: SortOrdering) {
  notifications(filter: $filter, sorting: $sorting) {
    edges {
      background
      booking {
        createdBy {
          id
        }
        id
        startTime
        workShiftId
        workShiftSlot {
          id
          workShift {
            location {
              id
            }
          }
        }
      }
      createdAt
      id
      message
      referenceId
      referenceType
      sender {
        email
        firstName
        id
        lastName
      }
      title
      type
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomNotificationsQuery__
 *
 * To run a query within a React component, call `useCustomNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<CustomNotificationsQuery, CustomNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomNotificationsQuery, CustomNotificationsQueryVariables>(CustomNotificationsDocument, options);
      }
export function useCustomNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomNotificationsQuery, CustomNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomNotificationsQuery, CustomNotificationsQueryVariables>(CustomNotificationsDocument, options);
        }
export type CustomNotificationsQueryHookResult = ReturnType<typeof useCustomNotificationsQuery>;
export type CustomNotificationsLazyQueryHookResult = ReturnType<typeof useCustomNotificationsLazyQuery>;
export type CustomNotificationsQueryResult = Apollo.QueryResult<CustomNotificationsQuery, CustomNotificationsQueryVariables>;
export const CustomRecommendationsDocument = gql`
    query CustomRecommendations($filter: RecommendationFilter, $sorting: SortOrdering) {
  recommendations(filter: $filter, sorting: $sorting) {
    meta {
      total
    }
    edges {
      location {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useCustomRecommendationsQuery__
 *
 * To run a query within a React component, call `useCustomRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomRecommendationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomRecommendationsQuery(baseOptions?: Apollo.QueryHookOptions<CustomRecommendationsQuery, CustomRecommendationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomRecommendationsQuery, CustomRecommendationsQueryVariables>(CustomRecommendationsDocument, options);
      }
export function useCustomRecommendationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomRecommendationsQuery, CustomRecommendationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomRecommendationsQuery, CustomRecommendationsQueryVariables>(CustomRecommendationsDocument, options);
        }
export type CustomRecommendationsQueryHookResult = ReturnType<typeof useCustomRecommendationsQuery>;
export type CustomRecommendationsLazyQueryHookResult = ReturnType<typeof useCustomRecommendationsLazyQuery>;
export type CustomRecommendationsQueryResult = Apollo.QueryResult<CustomRecommendationsQuery, CustomRecommendationsQueryVariables>;
export const CustomReportNoShowDocument = gql`
    mutation CustomReportNoShow($bookingId: ID!, $undo: Boolean) {
  reportNoShow(bookingId: $bookingId, undo: $undo) {
    status
  }
}
    `;
export type CustomReportNoShowMutationFn = Apollo.MutationFunction<CustomReportNoShowMutation, CustomReportNoShowMutationVariables>;

/**
 * __useCustomReportNoShowMutation__
 *
 * To run a mutation, you first call `useCustomReportNoShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomReportNoShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customReportNoShowMutation, { data, loading, error }] = useCustomReportNoShowMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      undo: // value for 'undo'
 *   },
 * });
 */
export function useCustomReportNoShowMutation(baseOptions?: Apollo.MutationHookOptions<CustomReportNoShowMutation, CustomReportNoShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomReportNoShowMutation, CustomReportNoShowMutationVariables>(CustomReportNoShowDocument, options);
      }
export type CustomReportNoShowMutationHookResult = ReturnType<typeof useCustomReportNoShowMutation>;
export type CustomReportNoShowMutationResult = Apollo.MutationResult<CustomReportNoShowMutation>;
export type CustomReportNoShowMutationOptions = Apollo.BaseMutationOptions<CustomReportNoShowMutation, CustomReportNoShowMutationVariables>;
export const CustomResourcesDocument = gql`
    query CustomResources($filter: UserFilter, $sorting: SortOrdering) {
  users(filter: $filter, sorting: $sorting) {
    edges {
      active
      approved
      blocked
      firstName
      id
      internalComment
      lastName
      mobileNumber
      verified
      userProfile {
        ... on RestaurantProfile {
          createdAt
          id
          region
          workExperience {
            category
            id
          }
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomResourcesQuery__
 *
 * To run a query within a React component, call `useCustomResourcesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomResourcesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomResourcesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomResourcesQuery(baseOptions?: Apollo.QueryHookOptions<CustomResourcesQuery, CustomResourcesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomResourcesQuery, CustomResourcesQueryVariables>(CustomResourcesDocument, options);
      }
export function useCustomResourcesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomResourcesQuery, CustomResourcesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomResourcesQuery, CustomResourcesQueryVariables>(CustomResourcesDocument, options);
        }
export type CustomResourcesQueryHookResult = ReturnType<typeof useCustomResourcesQuery>;
export type CustomResourcesLazyQueryHookResult = ReturnType<typeof useCustomResourcesLazyQuery>;
export type CustomResourcesQueryResult = Apollo.QueryResult<CustomResourcesQuery, CustomResourcesQueryVariables>;
export const CustomResourcesBroadcastDocument = gql`
    query CustomResourcesBroadcast($filter: UserFilter, $sorting: SortOrdering) {
  users(filter: $filter, sorting: $sorting) {
    edges {
      firstName
      id
      lastName
      userProfile {
        ... on RestaurantProfile {
          region
          workExperience {
            category
            id
          }
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomResourcesBroadcastQuery__
 *
 * To run a query within a React component, call `useCustomResourcesBroadcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomResourcesBroadcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomResourcesBroadcastQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomResourcesBroadcastQuery(baseOptions?: Apollo.QueryHookOptions<CustomResourcesBroadcastQuery, CustomResourcesBroadcastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomResourcesBroadcastQuery, CustomResourcesBroadcastQueryVariables>(CustomResourcesBroadcastDocument, options);
      }
export function useCustomResourcesBroadcastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomResourcesBroadcastQuery, CustomResourcesBroadcastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomResourcesBroadcastQuery, CustomResourcesBroadcastQueryVariables>(CustomResourcesBroadcastDocument, options);
        }
export type CustomResourcesBroadcastQueryHookResult = ReturnType<typeof useCustomResourcesBroadcastQuery>;
export type CustomResourcesBroadcastLazyQueryHookResult = ReturnType<typeof useCustomResourcesBroadcastLazyQuery>;
export type CustomResourcesBroadcastQueryResult = Apollo.QueryResult<CustomResourcesBroadcastQuery, CustomResourcesBroadcastQueryVariables>;
export const CustomCompaniesBroadcastDocument = gql`
    query CustomCompaniesBroadcast($filter: UserFilter, $sorting: SortOrdering) {
  users(filter: $filter, sorting: $sorting) {
    edges {
      firstName
      id
      lastName
      userProfile {
        ... on CompanyProfile {
          company {
            id
            name
          }
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomCompaniesBroadcastQuery__
 *
 * To run a query within a React component, call `useCustomCompaniesBroadcastQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomCompaniesBroadcastQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomCompaniesBroadcastQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomCompaniesBroadcastQuery(baseOptions?: Apollo.QueryHookOptions<CustomCompaniesBroadcastQuery, CustomCompaniesBroadcastQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomCompaniesBroadcastQuery, CustomCompaniesBroadcastQueryVariables>(CustomCompaniesBroadcastDocument, options);
      }
export function useCustomCompaniesBroadcastLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomCompaniesBroadcastQuery, CustomCompaniesBroadcastQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomCompaniesBroadcastQuery, CustomCompaniesBroadcastQueryVariables>(CustomCompaniesBroadcastDocument, options);
        }
export type CustomCompaniesBroadcastQueryHookResult = ReturnType<typeof useCustomCompaniesBroadcastQuery>;
export type CustomCompaniesBroadcastLazyQueryHookResult = ReturnType<typeof useCustomCompaniesBroadcastLazyQuery>;
export type CustomCompaniesBroadcastQueryResult = Apollo.QueryResult<CustomCompaniesBroadcastQuery, CustomCompaniesBroadcastQueryVariables>;
export const CustomSetLocationHiddenDocument = gql`
    mutation CustomSetLocationHidden($hidden: Boolean!, $id: ID!, $userId: ID!) {
  setLocationHidden(hidden: $hidden, id: $id, userId: $userId) {
    id
  }
}
    `;
export type CustomSetLocationHiddenMutationFn = Apollo.MutationFunction<CustomSetLocationHiddenMutation, CustomSetLocationHiddenMutationVariables>;

/**
 * __useCustomSetLocationHiddenMutation__
 *
 * To run a mutation, you first call `useCustomSetLocationHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCustomSetLocationHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [customSetLocationHiddenMutation, { data, loading, error }] = useCustomSetLocationHiddenMutation({
 *   variables: {
 *      hidden: // value for 'hidden'
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useCustomSetLocationHiddenMutation(baseOptions?: Apollo.MutationHookOptions<CustomSetLocationHiddenMutation, CustomSetLocationHiddenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CustomSetLocationHiddenMutation, CustomSetLocationHiddenMutationVariables>(CustomSetLocationHiddenDocument, options);
      }
export type CustomSetLocationHiddenMutationHookResult = ReturnType<typeof useCustomSetLocationHiddenMutation>;
export type CustomSetLocationHiddenMutationResult = Apollo.MutationResult<CustomSetLocationHiddenMutation>;
export type CustomSetLocationHiddenMutationOptions = Apollo.BaseMutationOptions<CustomSetLocationHiddenMutation, CustomSetLocationHiddenMutationVariables>;
export const CustomTagsDocument = gql`
    query CustomTags($filter: TagFilter, $sorting: SortOrdering) {
  tags(filter: $filter, sorting: $sorting) {
    edges {
      id
      name
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomTagsQuery__
 *
 * To run a query within a React component, call `useCustomTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomTagsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomTagsQuery(baseOptions?: Apollo.QueryHookOptions<CustomTagsQuery, CustomTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomTagsQuery, CustomTagsQueryVariables>(CustomTagsDocument, options);
      }
export function useCustomTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomTagsQuery, CustomTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomTagsQuery, CustomTagsQueryVariables>(CustomTagsDocument, options);
        }
export type CustomTagsQueryHookResult = ReturnType<typeof useCustomTagsQuery>;
export type CustomTagsLazyQueryHookResult = ReturnType<typeof useCustomTagsLazyQuery>;
export type CustomTagsQueryResult = Apollo.QueryResult<CustomTagsQuery, CustomTagsQueryVariables>;
export const CustomRestaurantUsersDocument = gql`
    query CustomRestaurantUsers($filter: UserFilter, $sorting: SortOrdering) {
  users(filter: $filter, sorting: $sorting) {
    edges {
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      firstName
      lastName
      mobileNumber
      favourite
      userProfile {
        ...ProfileWithDescription
      }
    }
  }
}
    ${ProfileWithDescriptionFragmentDoc}`;

/**
 * __useCustomRestaurantUsersQuery__
 *
 * To run a query within a React component, call `useCustomRestaurantUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomRestaurantUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomRestaurantUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomRestaurantUsersQuery(baseOptions?: Apollo.QueryHookOptions<CustomRestaurantUsersQuery, CustomRestaurantUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomRestaurantUsersQuery, CustomRestaurantUsersQueryVariables>(CustomRestaurantUsersDocument, options);
      }
export function useCustomRestaurantUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomRestaurantUsersQuery, CustomRestaurantUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomRestaurantUsersQuery, CustomRestaurantUsersQueryVariables>(CustomRestaurantUsersDocument, options);
        }
export type CustomRestaurantUsersQueryHookResult = ReturnType<typeof useCustomRestaurantUsersQuery>;
export type CustomRestaurantUsersLazyQueryHookResult = ReturnType<typeof useCustomRestaurantUsersLazyQuery>;
export type CustomRestaurantUsersQueryResult = Apollo.QueryResult<CustomRestaurantUsersQuery, CustomRestaurantUsersQueryVariables>;
export const CustomWorkShiftSlotsDocument = gql`
    query CustomWorkShiftSlots($filter: WorkShiftSlotFilter, $sorting: SortOrdering) {
  workShiftSlots(filter: $filter, sorting: $sorting) {
    data {
      attest
      available
      booked
      ongoing
    }
    edges {
      bookedQuantity
      bookingStatuses
      calendarStatus
      createdBy {
        firstName
        id
        lastName
      }
      endTime
      fullyBooked
      id
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      workShift {
        bookingType
        id
        internalName
        location {
          id
          name
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
          levels {
            id
            name
          }
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCustomWorkShiftSlotsQuery__
 *
 * To run a query within a React component, call `useCustomWorkShiftSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomWorkShiftSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomWorkShiftSlotsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCustomWorkShiftSlotsQuery(baseOptions?: Apollo.QueryHookOptions<CustomWorkShiftSlotsQuery, CustomWorkShiftSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CustomWorkShiftSlotsQuery, CustomWorkShiftSlotsQueryVariables>(CustomWorkShiftSlotsDocument, options);
      }
export function useCustomWorkShiftSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CustomWorkShiftSlotsQuery, CustomWorkShiftSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CustomWorkShiftSlotsQuery, CustomWorkShiftSlotsQueryVariables>(CustomWorkShiftSlotsDocument, options);
        }
export type CustomWorkShiftSlotsQueryHookResult = ReturnType<typeof useCustomWorkShiftSlotsQuery>;
export type CustomWorkShiftSlotsLazyQueryHookResult = ReturnType<typeof useCustomWorkShiftSlotsLazyQuery>;
export type CustomWorkShiftSlotsQueryResult = Apollo.QueryResult<CustomWorkShiftSlotsQuery, CustomWorkShiftSlotsQueryVariables>;
export const BookingDocument = gql`
    query Booking($id: ID!) {
  booking(id: $id) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;

/**
 * __useBookingQuery__
 *
 * To run a query within a React component, call `useBookingQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBookingQuery(baseOptions: Apollo.QueryHookOptions<BookingQuery, BookingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
      }
export function useBookingLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingQuery, BookingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingQuery, BookingQueryVariables>(BookingDocument, options);
        }
export type BookingQueryHookResult = ReturnType<typeof useBookingQuery>;
export type BookingLazyQueryHookResult = ReturnType<typeof useBookingLazyQuery>;
export type BookingQueryResult = Apollo.QueryResult<BookingQuery, BookingQueryVariables>;
export const BookingSlotsDocument = gql`
    query BookingSlots($filter: BookingSlotsFilter, $sorting: SortOrdering) {
  bookingSlots(filter: $filter, sorting: $sorting) {
    edges {
      booking {
        acceptWith {
          total
        }
        acceptedAt
        approvedAt
        approvedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        approvedByResourceAt
        backOfficeMainStatus
        backOfficeSubStatus
        breakMinutes
        cancelPolicyPct
        cancelledAt
        confirmedCanceled
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        declinedAt
        endTime
        extraStatus
        history {
          breakMinutes
          endTime
          startTime
          status
          suggestedEnd
          suggestedStart
          updatedAt
          updatedById
        }
        id
        invoiced
        legacyId
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        resource {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        resourceCategoryData {
          verticalProfileCategory
        }
        startTime
        status
        suggestedEnd
        suggestedStart
        suggestionAcceptedAt
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftId
        workShiftSlot {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
      }
      breakMinutes
      endTime
      startTime
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useBookingSlotsQuery__
 *
 * To run a query within a React component, call `useBookingSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingSlotsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useBookingSlotsQuery(baseOptions?: Apollo.QueryHookOptions<BookingSlotsQuery, BookingSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingSlotsQuery, BookingSlotsQueryVariables>(BookingSlotsDocument, options);
      }
export function useBookingSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingSlotsQuery, BookingSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingSlotsQuery, BookingSlotsQueryVariables>(BookingSlotsDocument, options);
        }
export type BookingSlotsQueryHookResult = ReturnType<typeof useBookingSlotsQuery>;
export type BookingSlotsLazyQueryHookResult = ReturnType<typeof useBookingSlotsLazyQuery>;
export type BookingSlotsQueryResult = Apollo.QueryResult<BookingSlotsQuery, BookingSlotsQueryVariables>;
export const BookingsDocument = gql`
    query Bookings($filter: BookingFilter, $sorting: SortOrdering) {
  bookings(filter: $filter, sorting: $sorting) {
    data {
      preliminaryTotalPrice
      totalPrice
      totalTime
    }
    edges {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useBookingsQuery__
 *
 * To run a query within a React component, call `useBookingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBookingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBookingsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useBookingsQuery(baseOptions?: Apollo.QueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
      }
export function useBookingsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BookingsQuery, BookingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BookingsQuery, BookingsQueryVariables>(BookingsDocument, options);
        }
export type BookingsQueryHookResult = ReturnType<typeof useBookingsQuery>;
export type BookingsLazyQueryHookResult = ReturnType<typeof useBookingsLazyQuery>;
export type BookingsQueryResult = Apollo.QueryResult<BookingsQuery, BookingsQueryVariables>;
export const ChannelsDocument = gql`
    query Channels($filter: ChannelFilter) {
  channels(filter: $filter) {
    edges {
      chatGroup {
        closed
        createdAt
        id
        members {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        messages
        name
        receivers
        responses
        updatedAt
      }
      createdAt
      deletedAt
      id
      lastSentAt
      messages {
        channel {
          createdAt
          deletedAt
          id
          lastSentAt
          name
          origin
          updatedAt
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        extra {
          ... on Booking {
            acceptedAt
            approvedAt
            approvedByResourceAt
            backOfficeMainStatus
            backOfficeSubStatus
            breakMinutes
            cancelPolicyPct
            cancelledAt
            confirmedCanceled
            createdAt
            declinedAt
            endTime
            extraStatus
            id
            invoiced
            legacyId
            startTime
            status
            suggestedEnd
            suggestedStart
            suggestionAcceptedAt
            updatedAt
            workShiftId
          }
        }
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        images {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        message
        position
        senderName
        title
        updatedAt
      }
      name
      origin
      participants {
        lastReadAt
        user {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedAt
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useChannelsQuery__
 *
 * To run a query within a React component, call `useChannelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChannelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChannelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useChannelsQuery(baseOptions?: Apollo.QueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
      }
export function useChannelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChannelsQuery, ChannelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChannelsQuery, ChannelsQueryVariables>(ChannelsDocument, options);
        }
export type ChannelsQueryHookResult = ReturnType<typeof useChannelsQuery>;
export type ChannelsLazyQueryHookResult = ReturnType<typeof useChannelsLazyQuery>;
export type ChannelsQueryResult = Apollo.QueryResult<ChannelsQuery, ChannelsQueryVariables>;
export const ChatGroupReceiversDocument = gql`
    query ChatGroupReceivers($filter: BaseFilter, $id: ID!) {
  chatGroupReceivers(filter: $filter, id: $id) {
    edges {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useChatGroupReceiversQuery__
 *
 * To run a query within a React component, call `useChatGroupReceiversQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatGroupReceiversQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatGroupReceiversQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatGroupReceiversQuery(baseOptions: Apollo.QueryHookOptions<ChatGroupReceiversQuery, ChatGroupReceiversQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatGroupReceiversQuery, ChatGroupReceiversQueryVariables>(ChatGroupReceiversDocument, options);
      }
export function useChatGroupReceiversLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatGroupReceiversQuery, ChatGroupReceiversQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatGroupReceiversQuery, ChatGroupReceiversQueryVariables>(ChatGroupReceiversDocument, options);
        }
export type ChatGroupReceiversQueryHookResult = ReturnType<typeof useChatGroupReceiversQuery>;
export type ChatGroupReceiversLazyQueryHookResult = ReturnType<typeof useChatGroupReceiversLazyQuery>;
export type ChatGroupReceiversQueryResult = Apollo.QueryResult<ChatGroupReceiversQuery, ChatGroupReceiversQueryVariables>;
export const ChatGroupRespondersDocument = gql`
    query ChatGroupResponders($filter: BaseFilter, $id: ID!) {
  chatGroupResponders(filter: $filter, id: $id) {
    edges {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useChatGroupRespondersQuery__
 *
 * To run a query within a React component, call `useChatGroupRespondersQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatGroupRespondersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatGroupRespondersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useChatGroupRespondersQuery(baseOptions: Apollo.QueryHookOptions<ChatGroupRespondersQuery, ChatGroupRespondersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatGroupRespondersQuery, ChatGroupRespondersQueryVariables>(ChatGroupRespondersDocument, options);
      }
export function useChatGroupRespondersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatGroupRespondersQuery, ChatGroupRespondersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatGroupRespondersQuery, ChatGroupRespondersQueryVariables>(ChatGroupRespondersDocument, options);
        }
export type ChatGroupRespondersQueryHookResult = ReturnType<typeof useChatGroupRespondersQuery>;
export type ChatGroupRespondersLazyQueryHookResult = ReturnType<typeof useChatGroupRespondersLazyQuery>;
export type ChatGroupRespondersQueryResult = Apollo.QueryResult<ChatGroupRespondersQuery, ChatGroupRespondersQueryVariables>;
export const ChatGroupsDocument = gql`
    query ChatGroups($filter: ChatGroupFilter, $sorting: SortOrdering) {
  chatGroups(filter: $filter, sorting: $sorting) {
    edges {
      closed
      createdAt
      id
      members {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      messages
      name
      receivers
      responses
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useChatGroupsQuery__
 *
 * To run a query within a React component, call `useChatGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatGroupsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useChatGroupsQuery(baseOptions?: Apollo.QueryHookOptions<ChatGroupsQuery, ChatGroupsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChatGroupsQuery, ChatGroupsQueryVariables>(ChatGroupsDocument, options);
      }
export function useChatGroupsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChatGroupsQuery, ChatGroupsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChatGroupsQuery, ChatGroupsQueryVariables>(ChatGroupsDocument, options);
        }
export type ChatGroupsQueryHookResult = ReturnType<typeof useChatGroupsQuery>;
export type ChatGroupsLazyQueryHookResult = ReturnType<typeof useChatGroupsLazyQuery>;
export type ChatGroupsQueryResult = Apollo.QueryResult<ChatGroupsQuery, ChatGroupsQueryVariables>;
export const CompaniesDocument = gql`
    query Companies($filter: CompanyFilter, $sorting: SortOrdering) {
  companies(filter: $filter, sorting: $sorting) {
    edges {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useCompaniesQuery__
 *
 * To run a query within a React component, call `useCompaniesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompaniesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompaniesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useCompaniesQuery(baseOptions?: Apollo.QueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
      }
export function useCompaniesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompaniesQuery, CompaniesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompaniesQuery, CompaniesQueryVariables>(CompaniesDocument, options);
        }
export type CompaniesQueryHookResult = ReturnType<typeof useCompaniesQuery>;
export type CompaniesLazyQueryHookResult = ReturnType<typeof useCompaniesLazyQuery>;
export type CompaniesQueryResult = Apollo.QueryResult<CompaniesQuery, CompaniesQueryVariables>;
export const CompanyDocument = gql`
    query Company($id: ID!) {
  company(id: $id) {
    active
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    category
    city
    commission
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    id
    latitude
    longitude
    name
    organisationNumber
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    street
    updatedAt
    web
  }
}
    `;

/**
 * __useCompanyQuery__
 *
 * To run a query within a React component, call `useCompanyQuery` and pass it any options that fit your needs.
 * When your component renders, `useCompanyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCompanyQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCompanyQuery(baseOptions: Apollo.QueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
      }
export function useCompanyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CompanyQuery, CompanyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CompanyQuery, CompanyQueryVariables>(CompanyDocument, options);
        }
export type CompanyQueryHookResult = ReturnType<typeof useCompanyQuery>;
export type CompanyLazyQueryHookResult = ReturnType<typeof useCompanyLazyQuery>;
export type CompanyQueryResult = Apollo.QueryResult<CompanyQuery, CompanyQueryVariables>;
export const DistributeUserToAnyAvailableSlotsDocument = gql`
    query DistributeUserToAnyAvailableSlots($input: DistributeUserToSlotsInput!) {
  distributeUserToAnyAvailableSlots(input: $input)
}
    `;

/**
 * __useDistributeUserToAnyAvailableSlotsQuery__
 *
 * To run a query within a React component, call `useDistributeUserToAnyAvailableSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDistributeUserToAnyAvailableSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDistributeUserToAnyAvailableSlotsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDistributeUserToAnyAvailableSlotsQuery(baseOptions: Apollo.QueryHookOptions<DistributeUserToAnyAvailableSlotsQuery, DistributeUserToAnyAvailableSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DistributeUserToAnyAvailableSlotsQuery, DistributeUserToAnyAvailableSlotsQueryVariables>(DistributeUserToAnyAvailableSlotsDocument, options);
      }
export function useDistributeUserToAnyAvailableSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DistributeUserToAnyAvailableSlotsQuery, DistributeUserToAnyAvailableSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DistributeUserToAnyAvailableSlotsQuery, DistributeUserToAnyAvailableSlotsQueryVariables>(DistributeUserToAnyAvailableSlotsDocument, options);
        }
export type DistributeUserToAnyAvailableSlotsQueryHookResult = ReturnType<typeof useDistributeUserToAnyAvailableSlotsQuery>;
export type DistributeUserToAnyAvailableSlotsLazyQueryHookResult = ReturnType<typeof useDistributeUserToAnyAvailableSlotsLazyQuery>;
export type DistributeUserToAnyAvailableSlotsQueryResult = Apollo.QueryResult<DistributeUserToAnyAvailableSlotsQuery, DistributeUserToAnyAvailableSlotsQueryVariables>;
export const LocationDocument = gql`
    query Location($id: ID!) {
  location(id: $id) {
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    city
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    latitude
    legacyCustomerId
    logo {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    longitude
    name
    organisationNumber
    postalCode
    region
    street
    updatedAt
    web
  }
}
    `;

/**
 * __useLocationQuery__
 *
 * To run a query within a React component, call `useLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLocationQuery(baseOptions: Apollo.QueryHookOptions<LocationQuery, LocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
      }
export function useLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationQuery, LocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationQuery, LocationQueryVariables>(LocationDocument, options);
        }
export type LocationQueryHookResult = ReturnType<typeof useLocationQuery>;
export type LocationLazyQueryHookResult = ReturnType<typeof useLocationLazyQuery>;
export type LocationQueryResult = Apollo.QueryResult<LocationQuery, LocationQueryVariables>;
export const LocationsDocument = gql`
    query Locations($filter: LocationFilter, $sorting: SortOrdering) {
  locations(filter: $filter, sorting: $sorting) {
    edges {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useLocationsQuery__
 *
 * To run a query within a React component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useLocationsQuery(baseOptions?: Apollo.QueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
      }
export function useLocationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LocationsQuery, LocationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, options);
        }
export type LocationsQueryHookResult = ReturnType<typeof useLocationsQuery>;
export type LocationsLazyQueryHookResult = ReturnType<typeof useLocationsLazyQuery>;
export type LocationsQueryResult = Apollo.QueryResult<LocationsQuery, LocationsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const MessagesDocument = gql`
    query Messages($filter: MessageFilter!) {
  messages(filter: $filter) {
    edges {
      channel {
        chatGroup {
          closed
          createdAt
          id
          messages
          name
          receivers
          responses
          updatedAt
        }
        createdAt
        deletedAt
        id
        lastSentAt
        messages {
          createdAt
          id
          message
          position
          senderName
          title
          updatedAt
        }
        name
        origin
        participants {
          lastReadAt
        }
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      extra {
        ... on Booking {
          acceptWith {
            total
          }
          acceptedAt
          approvedAt
          approvedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          approvedByResourceAt
          backOfficeMainStatus
          backOfficeSubStatus
          breakMinutes
          cancelPolicyPct
          cancelledAt
          confirmedCanceled
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          declinedAt
          endTime
          extraStatus
          history {
            breakMinutes
            endTime
            startTime
            status
            suggestedEnd
            suggestedStart
            updatedAt
            updatedById
          }
          id
          invoiced
          legacyId
          pricePoint {
            hourlySalaryBase
            hourlySalaryBoost
            hours
            totalPrice
            totalSalary
          }
          resource {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          resourceCategoryData {
            verticalProfileCategory
          }
          startTime
          status
          suggestedEnd
          suggestedStart
          suggestionAcceptedAt
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShiftId
          workShiftSlot {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            createdAt
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
          }
        }
      }
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      images {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      message
      position
      senderName
      title
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useMessagesQuery__
 *
 * To run a query within a React component, call `useMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessagesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useMessagesQuery(baseOptions: Apollo.QueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
      }
export function useMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MessagesQuery, MessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MessagesQuery, MessagesQueryVariables>(MessagesDocument, options);
        }
export type MessagesQueryHookResult = ReturnType<typeof useMessagesQuery>;
export type MessagesLazyQueryHookResult = ReturnType<typeof useMessagesLazyQuery>;
export type MessagesQueryResult = Apollo.QueryResult<MessagesQuery, MessagesQueryVariables>;
export const MyCompanyProfileDocument = gql`
    query MyCompanyProfile {
  myCompanyProfile {
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    description
    id
    locations {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
  }
}
    `;

/**
 * __useMyCompanyProfileQuery__
 *
 * To run a query within a React component, call `useMyCompanyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyCompanyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyCompanyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyCompanyProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyCompanyProfileQuery, MyCompanyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyCompanyProfileQuery, MyCompanyProfileQueryVariables>(MyCompanyProfileDocument, options);
      }
export function useMyCompanyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyCompanyProfileQuery, MyCompanyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyCompanyProfileQuery, MyCompanyProfileQueryVariables>(MyCompanyProfileDocument, options);
        }
export type MyCompanyProfileQueryHookResult = ReturnType<typeof useMyCompanyProfileQuery>;
export type MyCompanyProfileLazyQueryHookResult = ReturnType<typeof useMyCompanyProfileLazyQuery>;
export type MyCompanyProfileQueryResult = Apollo.QueryResult<MyCompanyProfileQuery, MyCompanyProfileQueryVariables>;
export const MyRestaurantProfileDocument = gql`
    query MyRestaurantProfile {
  myRestaurantProfile {
    adjacentRegions
    approvedBookings
    createdAt
    description
    emergencyContactName
    emergencyContactPhone
    favouriteCount
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    languages
    rating
    region
    status
    tags {
      category
      createdAt
      id
      name
      subcategory
      updatedAt
    }
    updatedAt
    workExperience {
      category
      createdAt
      id
      otherWorkExperience
      updatedAt
      years
    }
    workReferences {
      company
      contactName
      contactPhone
      createdAt
      id
      role
      updatedAt
    }
  }
}
    `;

/**
 * __useMyRestaurantProfileQuery__
 *
 * To run a query within a React component, call `useMyRestaurantProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyRestaurantProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyRestaurantProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyRestaurantProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyRestaurantProfileQuery, MyRestaurantProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyRestaurantProfileQuery, MyRestaurantProfileQueryVariables>(MyRestaurantProfileDocument, options);
      }
export function useMyRestaurantProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyRestaurantProfileQuery, MyRestaurantProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyRestaurantProfileQuery, MyRestaurantProfileQueryVariables>(MyRestaurantProfileDocument, options);
        }
export type MyRestaurantProfileQueryHookResult = ReturnType<typeof useMyRestaurantProfileQuery>;
export type MyRestaurantProfileLazyQueryHookResult = ReturnType<typeof useMyRestaurantProfileLazyQuery>;
export type MyRestaurantProfileQueryResult = Apollo.QueryResult<MyRestaurantProfileQuery, MyRestaurantProfileQueryVariables>;
export const NotificationsDocument = gql`
    query Notifications($filter: NotificationFilter, $sorting: SortOrdering) {
  notifications(filter: $filter, sorting: $sorting) {
    edges {
      background
      booking {
        acceptWith {
          total
        }
        acceptedAt
        approvedAt
        approvedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        approvedByResourceAt
        backOfficeMainStatus
        backOfficeSubStatus
        breakMinutes
        cancelPolicyPct
        cancelledAt
        confirmedCanceled
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        declinedAt
        endTime
        extraStatus
        history {
          breakMinutes
          endTime
          startTime
          status
          suggestedEnd
          suggestedStart
          updatedAt
          updatedById
        }
        id
        invoiced
        legacyId
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        resource {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        resourceCategoryData {
          verticalProfileCategory
        }
        startTime
        status
        suggestedEnd
        suggestedStart
        suggestionAcceptedAt
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftId
        workShiftSlot {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
      }
      createdAt
      id
      message
      referenceId
      referenceType
      sender {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      title
      type
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useNotificationsQuery__
 *
 * To run a query within a React component, call `useNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useNotificationsQuery(baseOptions?: Apollo.QueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
      }
export function useNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationsQuery, NotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationsQuery, NotificationsQueryVariables>(NotificationsDocument, options);
        }
export type NotificationsQueryHookResult = ReturnType<typeof useNotificationsQuery>;
export type NotificationsLazyQueryHookResult = ReturnType<typeof useNotificationsLazyQuery>;
export type NotificationsQueryResult = Apollo.QueryResult<NotificationsQuery, NotificationsQueryVariables>;
export const RecommendationDocument = gql`
    query Recommendation($id: ID!) {
  recommendation(id: $id) {
    createdAt
    description
    hidden
    id
    location {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    updatedAt
  }
}
    `;

/**
 * __useRecommendationQuery__
 *
 * To run a query within a React component, call `useRecommendationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRecommendationQuery(baseOptions: Apollo.QueryHookOptions<RecommendationQuery, RecommendationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecommendationQuery, RecommendationQueryVariables>(RecommendationDocument, options);
      }
export function useRecommendationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecommendationQuery, RecommendationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecommendationQuery, RecommendationQueryVariables>(RecommendationDocument, options);
        }
export type RecommendationQueryHookResult = ReturnType<typeof useRecommendationQuery>;
export type RecommendationLazyQueryHookResult = ReturnType<typeof useRecommendationLazyQuery>;
export type RecommendationQueryResult = Apollo.QueryResult<RecommendationQuery, RecommendationQueryVariables>;
export const RecommendationsDocument = gql`
    query Recommendations($filter: RecommendationFilter, $sorting: SortOrdering) {
  recommendations(filter: $filter, sorting: $sorting) {
    edges {
      createdAt
      description
      hidden
      id
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useRecommendationsQuery__
 *
 * To run a query within a React component, call `useRecommendationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecommendationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecommendationsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useRecommendationsQuery(baseOptions?: Apollo.QueryHookOptions<RecommendationsQuery, RecommendationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecommendationsQuery, RecommendationsQueryVariables>(RecommendationsDocument, options);
      }
export function useRecommendationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecommendationsQuery, RecommendationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecommendationsQuery, RecommendationsQueryVariables>(RecommendationsDocument, options);
        }
export type RecommendationsQueryHookResult = ReturnType<typeof useRecommendationsQuery>;
export type RecommendationsLazyQueryHookResult = ReturnType<typeof useRecommendationsLazyQuery>;
export type RecommendationsQueryResult = Apollo.QueryResult<RecommendationsQuery, RecommendationsQueryVariables>;
export const ResourceCategoriesDocument = gql`
    query ResourceCategories($filter: ResourceCategoryFilter, $sorting: SortOrdering) {
  resourceCategories(filter: $filter, sorting: $sorting) {
    edges {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useResourceCategoriesQuery__
 *
 * To run a query within a React component, call `useResourceCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useResourceCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ResourceCategoriesQuery, ResourceCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceCategoriesQuery, ResourceCategoriesQueryVariables>(ResourceCategoriesDocument, options);
      }
export function useResourceCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceCategoriesQuery, ResourceCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceCategoriesQuery, ResourceCategoriesQueryVariables>(ResourceCategoriesDocument, options);
        }
export type ResourceCategoriesQueryHookResult = ReturnType<typeof useResourceCategoriesQuery>;
export type ResourceCategoriesLazyQueryHookResult = ReturnType<typeof useResourceCategoriesLazyQuery>;
export type ResourceCategoriesQueryResult = Apollo.QueryResult<ResourceCategoriesQuery, ResourceCategoriesQueryVariables>;
export const ResourceCategoryDocument = gql`
    query ResourceCategory($id: ID!) {
  resourceCategory(id: $id) {
    createdAt
    defaultSalary
    id
    level {
      createdAt
      id
      name
      updatedAt
    }
    name
    regional {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    updatedAt
    vertical
  }
}
    `;

/**
 * __useResourceCategoryQuery__
 *
 * To run a query within a React component, call `useResourceCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceCategoryQuery(baseOptions: Apollo.QueryHookOptions<ResourceCategoryQuery, ResourceCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceCategoryQuery, ResourceCategoryQueryVariables>(ResourceCategoryDocument, options);
      }
export function useResourceCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceCategoryQuery, ResourceCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceCategoryQuery, ResourceCategoryQueryVariables>(ResourceCategoryDocument, options);
        }
export type ResourceCategoryQueryHookResult = ReturnType<typeof useResourceCategoryQuery>;
export type ResourceCategoryLazyQueryHookResult = ReturnType<typeof useResourceCategoryLazyQuery>;
export type ResourceCategoryQueryResult = Apollo.QueryResult<ResourceCategoryQuery, ResourceCategoryQueryVariables>;
export const ResourceLevelsDocument = gql`
    query ResourceLevels($filter: ResourceLevelFilter, $sorting: SortOrdering) {
  resourceLevels(filter: $filter, sorting: $sorting) {
    edges {
      createdAt
      id
      name
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useResourceLevelsQuery__
 *
 * To run a query within a React component, call `useResourceLevelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceLevelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceLevelsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useResourceLevelsQuery(baseOptions?: Apollo.QueryHookOptions<ResourceLevelsQuery, ResourceLevelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceLevelsQuery, ResourceLevelsQueryVariables>(ResourceLevelsDocument, options);
      }
export function useResourceLevelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceLevelsQuery, ResourceLevelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceLevelsQuery, ResourceLevelsQueryVariables>(ResourceLevelsDocument, options);
        }
export type ResourceLevelsQueryHookResult = ReturnType<typeof useResourceLevelsQuery>;
export type ResourceLevelsLazyQueryHookResult = ReturnType<typeof useResourceLevelsLazyQuery>;
export type ResourceLevelsQueryResult = Apollo.QueryResult<ResourceLevelsQuery, ResourceLevelsQueryVariables>;
export const ResourceRegionalCategoriesDocument = gql`
    query ResourceRegionalCategories($filter: ResourceRegionalFilter, $sorting: SortOrdering) {
  resourceRegionalCategories(filter: $filter, sorting: $sorting) {
    edges {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useResourceRegionalCategoriesQuery__
 *
 * To run a query within a React component, call `useResourceRegionalCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceRegionalCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceRegionalCategoriesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useResourceRegionalCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<ResourceRegionalCategoriesQuery, ResourceRegionalCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceRegionalCategoriesQuery, ResourceRegionalCategoriesQueryVariables>(ResourceRegionalCategoriesDocument, options);
      }
export function useResourceRegionalCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceRegionalCategoriesQuery, ResourceRegionalCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceRegionalCategoriesQuery, ResourceRegionalCategoriesQueryVariables>(ResourceRegionalCategoriesDocument, options);
        }
export type ResourceRegionalCategoriesQueryHookResult = ReturnType<typeof useResourceRegionalCategoriesQuery>;
export type ResourceRegionalCategoriesLazyQueryHookResult = ReturnType<typeof useResourceRegionalCategoriesLazyQuery>;
export type ResourceRegionalCategoriesQueryResult = Apollo.QueryResult<ResourceRegionalCategoriesQuery, ResourceRegionalCategoriesQueryVariables>;
export const ResourceRegionalCategoryDocument = gql`
    query ResourceRegionalCategory($id: ID!) {
  resourceRegionalCategory(id: $id) {
    createdAt
    id
    modifier
    price
    region
    salary
    updatedAt
  }
}
    `;

/**
 * __useResourceRegionalCategoryQuery__
 *
 * To run a query within a React component, call `useResourceRegionalCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useResourceRegionalCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResourceRegionalCategoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResourceRegionalCategoryQuery(baseOptions: Apollo.QueryHookOptions<ResourceRegionalCategoryQuery, ResourceRegionalCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResourceRegionalCategoryQuery, ResourceRegionalCategoryQueryVariables>(ResourceRegionalCategoryDocument, options);
      }
export function useResourceRegionalCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResourceRegionalCategoryQuery, ResourceRegionalCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResourceRegionalCategoryQuery, ResourceRegionalCategoryQueryVariables>(ResourceRegionalCategoryDocument, options);
        }
export type ResourceRegionalCategoryQueryHookResult = ReturnType<typeof useResourceRegionalCategoryQuery>;
export type ResourceRegionalCategoryLazyQueryHookResult = ReturnType<typeof useResourceRegionalCategoryLazyQuery>;
export type ResourceRegionalCategoryQueryResult = Apollo.QueryResult<ResourceRegionalCategoryQuery, ResourceRegionalCategoryQueryVariables>;
export const TagDocument = gql`
    query Tag($id: ID!) {
  tag(id: $id) {
    category
    createdAt
    id
    name
    subcategory
    updatedAt
  }
}
    `;

/**
 * __useTagQuery__
 *
 * To run a query within a React component, call `useTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTagQuery(baseOptions: Apollo.QueryHookOptions<TagQuery, TagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagQuery, TagQueryVariables>(TagDocument, options);
      }
export function useTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagQuery, TagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagQuery, TagQueryVariables>(TagDocument, options);
        }
export type TagQueryHookResult = ReturnType<typeof useTagQuery>;
export type TagLazyQueryHookResult = ReturnType<typeof useTagLazyQuery>;
export type TagQueryResult = Apollo.QueryResult<TagQuery, TagQueryVariables>;
export const TagsDocument = gql`
    query Tags($filter: TagFilter, $sorting: SortOrdering) {
  tags(filter: $filter, sorting: $sorting) {
    edges {
      category
      createdAt
      id
      name
      subcategory
      updatedAt
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useTagsQuery__
 *
 * To run a query within a React component, call `useTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTagsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useTagsQuery(baseOptions?: Apollo.QueryHookOptions<TagsQuery, TagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
      }
export function useTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TagsQuery, TagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TagsQuery, TagsQueryVariables>(TagsDocument, options);
        }
export type TagsQueryHookResult = ReturnType<typeof useTagsQuery>;
export type TagsLazyQueryHookResult = ReturnType<typeof useTagsLazyQuery>;
export type TagsQueryResult = Apollo.QueryResult<TagsQuery, TagsQueryVariables>;
export const UnreadMessagesDocument = gql`
    query UnreadMessages {
  unreadMessages
}
    `;

/**
 * __useUnreadMessagesQuery__
 *
 * To run a query within a React component, call `useUnreadMessagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnreadMessagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUnreadMessagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUnreadMessagesQuery(baseOptions?: Apollo.QueryHookOptions<UnreadMessagesQuery, UnreadMessagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UnreadMessagesQuery, UnreadMessagesQueryVariables>(UnreadMessagesDocument, options);
      }
export function useUnreadMessagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UnreadMessagesQuery, UnreadMessagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UnreadMessagesQuery, UnreadMessagesQueryVariables>(UnreadMessagesDocument, options);
        }
export type UnreadMessagesQueryHookResult = ReturnType<typeof useUnreadMessagesQuery>;
export type UnreadMessagesLazyQueryHookResult = ReturnType<typeof useUnreadMessagesLazyQuery>;
export type UnreadMessagesQueryResult = Apollo.QueryResult<UnreadMessagesQuery, UnreadMessagesQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const UserEventDocument = gql`
    query UserEvent($id: ID!) {
  userEvent(id: $id) {
    booking {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
    createdAt
    description
    endTime
    id
    startTime
    title
    updatedAt
    user {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;

/**
 * __useUserEventQuery__
 *
 * To run a query within a React component, call `useUserEventQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEventQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEventQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserEventQuery(baseOptions: Apollo.QueryHookOptions<UserEventQuery, UserEventQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEventQuery, UserEventQueryVariables>(UserEventDocument, options);
      }
export function useUserEventLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEventQuery, UserEventQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEventQuery, UserEventQueryVariables>(UserEventDocument, options);
        }
export type UserEventQueryHookResult = ReturnType<typeof useUserEventQuery>;
export type UserEventLazyQueryHookResult = ReturnType<typeof useUserEventLazyQuery>;
export type UserEventQueryResult = Apollo.QueryResult<UserEventQuery, UserEventQueryVariables>;
export const UserEventsDocument = gql`
    query UserEvents($filter: UserEventFilter!, $sorting: SortOrdering) {
  userEvents(filter: $filter, sorting: $sorting) {
    edges {
      booking {
        acceptWith {
          total
        }
        acceptedAt
        approvedAt
        approvedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        approvedByResourceAt
        backOfficeMainStatus
        backOfficeSubStatus
        breakMinutes
        cancelPolicyPct
        cancelledAt
        confirmedCanceled
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        declinedAt
        endTime
        extraStatus
        history {
          breakMinutes
          endTime
          startTime
          status
          suggestedEnd
          suggestedStart
          updatedAt
          updatedById
        }
        id
        invoiced
        legacyId
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        resource {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        resourceCategoryData {
          verticalProfileCategory
        }
        startTime
        status
        suggestedEnd
        suggestedStart
        suggestionAcceptedAt
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftId
        workShiftSlot {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
      }
      createdAt
      description
      endTime
      id
      startTime
      title
      updatedAt
      user {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useUserEventsQuery__
 *
 * To run a query within a React component, call `useUserEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEventsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useUserEventsQuery(baseOptions: Apollo.QueryHookOptions<UserEventsQuery, UserEventsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEventsQuery, UserEventsQueryVariables>(UserEventsDocument, options);
      }
export function useUserEventsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEventsQuery, UserEventsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEventsQuery, UserEventsQueryVariables>(UserEventsDocument, options);
        }
export type UserEventsQueryHookResult = ReturnType<typeof useUserEventsQuery>;
export type UserEventsLazyQueryHookResult = ReturnType<typeof useUserEventsLazyQuery>;
export type UserEventsQueryResult = Apollo.QueryResult<UserEventsQuery, UserEventsQueryVariables>;
export const UsersDocument = gql`
    query Users($filter: UserFilter, $sorting: SortOrdering) {
  users(filter: $filter, sorting: $sorting) {
    edges {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useUsersQuery__
 *
 * To run a query within a React component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsersQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useUsersQuery(baseOptions?: Apollo.QueryHookOptions<UsersQuery, UsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
      }
export function useUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsersQuery, UsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, options);
        }
export type UsersQueryHookResult = ReturnType<typeof useUsersQuery>;
export type UsersLazyQueryHookResult = ReturnType<typeof useUsersLazyQuery>;
export type UsersQueryResult = Apollo.QueryResult<UsersQuery, UsersQueryVariables>;
export const WorkShiftDocument = gql`
    query WorkShift($id: ID!) {
  workShift(id: $id) {
    acceptanceTime
    bookingType
    cancelledAt
    city
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    contactPerson {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    customContactPerson
    description
    firstStartTime
    id
    internalName
    lastEndTime
    location {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    mealDeduction
    minutesPriorStart
    postalCode
    region
    resourceQuantity
    resourceRegionalCategories {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    salaryBoost
    sameResources
    sentAt
    slots {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
    status
    street
    templateId
    temporaryAddress {
      city
      id
      latitude
      longitude
      name
      postalCode
      region
      street
    }
    tips
    totalPrice
    travelExpenseCompensation
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftResourceCategories {
      levelIds
      levels {
        createdAt
        id
        name
        updatedAt
      }
      resourceCategories {
        id
        levelName
      }
      verticalProfileCategory
    }
  }
}
    `;

/**
 * __useWorkShiftQuery__
 *
 * To run a query within a React component, call `useWorkShiftQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkShiftQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkShiftQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkShiftQuery(baseOptions: Apollo.QueryHookOptions<WorkShiftQuery, WorkShiftQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkShiftQuery, WorkShiftQueryVariables>(WorkShiftDocument, options);
      }
export function useWorkShiftLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkShiftQuery, WorkShiftQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkShiftQuery, WorkShiftQueryVariables>(WorkShiftDocument, options);
        }
export type WorkShiftQueryHookResult = ReturnType<typeof useWorkShiftQuery>;
export type WorkShiftLazyQueryHookResult = ReturnType<typeof useWorkShiftLazyQuery>;
export type WorkShiftQueryResult = Apollo.QueryResult<WorkShiftQuery, WorkShiftQueryVariables>;
export const WorkShiftSlotDocument = gql`
    query WorkShiftSlot($id: ID!) {
  workShiftSlot(id: $id) {
    bookedQuantity
    bookingStatuses
    breakMinutes
    calendarStatus
    cancelledAt
    contactPerson {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    customContactPerson
    defaultFilter
    endTime
    fullyBooked
    id
    name
    price
    resourceQuantity
    startTime
    status
    summarizedBookingStatus
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShift {
      acceptanceTime
      bookingType
      cancelledAt
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      description
      firstStartTime
      id
      internalName
      lastEndTime
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      minutesPriorStart
      postalCode
      region
      resourceQuantity
      resourceRegionalCategories {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      salaryBoost
      sameResources
      sentAt
      slots {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      status
      street
      templateId
      temporaryAddress {
        city
        id
        latitude
        longitude
        name
        postalCode
        region
        street
      }
      tips
      totalPrice
      travelExpenseCompensation
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftResourceCategories {
        levelIds
        levels {
          createdAt
          id
          name
          updatedAt
        }
        resourceCategories {
          id
          levelName
        }
        verticalProfileCategory
      }
    }
  }
}
    `;

/**
 * __useWorkShiftSlotQuery__
 *
 * To run a query within a React component, call `useWorkShiftSlotQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkShiftSlotQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkShiftSlotQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkShiftSlotQuery(baseOptions: Apollo.QueryHookOptions<WorkShiftSlotQuery, WorkShiftSlotQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkShiftSlotQuery, WorkShiftSlotQueryVariables>(WorkShiftSlotDocument, options);
      }
export function useWorkShiftSlotLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkShiftSlotQuery, WorkShiftSlotQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkShiftSlotQuery, WorkShiftSlotQueryVariables>(WorkShiftSlotDocument, options);
        }
export type WorkShiftSlotQueryHookResult = ReturnType<typeof useWorkShiftSlotQuery>;
export type WorkShiftSlotLazyQueryHookResult = ReturnType<typeof useWorkShiftSlotLazyQuery>;
export type WorkShiftSlotQueryResult = Apollo.QueryResult<WorkShiftSlotQuery, WorkShiftSlotQueryVariables>;
export const WorkShiftSlotsDocument = gql`
    query WorkShiftSlots($filter: WorkShiftSlotFilter, $sorting: SortOrdering) {
  workShiftSlots(filter: $filter, sorting: $sorting) {
    data {
      attest
      available
      booked
      ongoing
    }
    edges {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useWorkShiftSlotsQuery__
 *
 * To run a query within a React component, call `useWorkShiftSlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkShiftSlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkShiftSlotsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useWorkShiftSlotsQuery(baseOptions?: Apollo.QueryHookOptions<WorkShiftSlotsQuery, WorkShiftSlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkShiftSlotsQuery, WorkShiftSlotsQueryVariables>(WorkShiftSlotsDocument, options);
      }
export function useWorkShiftSlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkShiftSlotsQuery, WorkShiftSlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkShiftSlotsQuery, WorkShiftSlotsQueryVariables>(WorkShiftSlotsDocument, options);
        }
export type WorkShiftSlotsQueryHookResult = ReturnType<typeof useWorkShiftSlotsQuery>;
export type WorkShiftSlotsLazyQueryHookResult = ReturnType<typeof useWorkShiftSlotsLazyQuery>;
export type WorkShiftSlotsQueryResult = Apollo.QueryResult<WorkShiftSlotsQuery, WorkShiftSlotsQueryVariables>;
export const WorkShiftTemplateDocument = gql`
    query WorkShiftTemplate($id: ID!) {
  workShiftTemplate(id: $id) {
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    createdAt
    description
    id
    locations {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    mealDeduction
    name
    salaryBoost
    slotTemplates {
      endHhMm
      resourceQuantity
      startHhMm
      workShiftTemplateId
    }
    tips
    travelExpenseCompensation
    updatedAt
    verticalProfileCategory
  }
}
    `;

/**
 * __useWorkShiftTemplateQuery__
 *
 * To run a query within a React component, call `useWorkShiftTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkShiftTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkShiftTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkShiftTemplateQuery(baseOptions: Apollo.QueryHookOptions<WorkShiftTemplateQuery, WorkShiftTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkShiftTemplateQuery, WorkShiftTemplateQueryVariables>(WorkShiftTemplateDocument, options);
      }
export function useWorkShiftTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkShiftTemplateQuery, WorkShiftTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkShiftTemplateQuery, WorkShiftTemplateQueryVariables>(WorkShiftTemplateDocument, options);
        }
export type WorkShiftTemplateQueryHookResult = ReturnType<typeof useWorkShiftTemplateQuery>;
export type WorkShiftTemplateLazyQueryHookResult = ReturnType<typeof useWorkShiftTemplateLazyQuery>;
export type WorkShiftTemplateQueryResult = Apollo.QueryResult<WorkShiftTemplateQuery, WorkShiftTemplateQueryVariables>;
export const WorkShiftTemplatesDocument = gql`
    query WorkShiftTemplates($filter: WorkShiftTemplateFilter, $sorting: SortOrdering) {
  workShiftTemplates(filter: $filter, sorting: $sorting) {
    edges {
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      createdAt
      description
      id
      locations {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      name
      salaryBoost
      slotTemplates {
        endHhMm
        resourceQuantity
        startHhMm
        workShiftTemplateId
      }
      tips
      travelExpenseCompensation
      updatedAt
      verticalProfileCategory
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useWorkShiftTemplatesQuery__
 *
 * To run a query within a React component, call `useWorkShiftTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkShiftTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkShiftTemplatesQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useWorkShiftTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<WorkShiftTemplatesQuery, WorkShiftTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkShiftTemplatesQuery, WorkShiftTemplatesQueryVariables>(WorkShiftTemplatesDocument, options);
      }
export function useWorkShiftTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkShiftTemplatesQuery, WorkShiftTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkShiftTemplatesQuery, WorkShiftTemplatesQueryVariables>(WorkShiftTemplatesDocument, options);
        }
export type WorkShiftTemplatesQueryHookResult = ReturnType<typeof useWorkShiftTemplatesQuery>;
export type WorkShiftTemplatesLazyQueryHookResult = ReturnType<typeof useWorkShiftTemplatesLazyQuery>;
export type WorkShiftTemplatesQueryResult = Apollo.QueryResult<WorkShiftTemplatesQuery, WorkShiftTemplatesQueryVariables>;
export const WorkShiftsDocument = gql`
    query WorkShifts($filter: WorkShiftFilter, $sorting: SortOrdering) {
  workShifts(filter: $filter, sorting: $sorting) {
    edges {
      acceptanceTime
      bookingType
      cancelledAt
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      description
      firstStartTime
      id
      internalName
      lastEndTime
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      minutesPriorStart
      postalCode
      region
      resourceQuantity
      resourceRegionalCategories {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      salaryBoost
      sameResources
      sentAt
      slots {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      status
      street
      templateId
      temporaryAddress {
        city
        id
        latitude
        longitude
        name
        postalCode
        region
        street
      }
      tips
      totalPrice
      travelExpenseCompensation
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftResourceCategories {
        levelIds
        levels {
          createdAt
          id
          name
          updatedAt
        }
        resourceCategories {
          id
          levelName
        }
        verticalProfileCategory
      }
    }
    meta {
      limit
      offset
      total
    }
  }
}
    `;

/**
 * __useWorkShiftsQuery__
 *
 * To run a query within a React component, call `useWorkShiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkShiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkShiftsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useWorkShiftsQuery(baseOptions?: Apollo.QueryHookOptions<WorkShiftsQuery, WorkShiftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkShiftsQuery, WorkShiftsQueryVariables>(WorkShiftsDocument, options);
      }
export function useWorkShiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkShiftsQuery, WorkShiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkShiftsQuery, WorkShiftsQueryVariables>(WorkShiftsDocument, options);
        }
export type WorkShiftsQueryHookResult = ReturnType<typeof useWorkShiftsQuery>;
export type WorkShiftsLazyQueryHookResult = ReturnType<typeof useWorkShiftsLazyQuery>;
export type WorkShiftsQueryResult = Apollo.QueryResult<WorkShiftsQuery, WorkShiftsQueryVariables>;
export const AcceptApplyInvitationsDocument = gql`
    mutation AcceptApplyInvitations($input: AcceptApplyInvitationsInput!) {
  acceptApplyInvitations(input: $input) {
    error
    failed
    mandatoryMissing
    success
    updated {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
  }
}
    `;
export type AcceptApplyInvitationsMutationFn = Apollo.MutationFunction<AcceptApplyInvitationsMutation, AcceptApplyInvitationsMutationVariables>;

/**
 * __useAcceptApplyInvitationsMutation__
 *
 * To run a mutation, you first call `useAcceptApplyInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptApplyInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptApplyInvitationsMutation, { data, loading, error }] = useAcceptApplyInvitationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptApplyInvitationsMutation(baseOptions?: Apollo.MutationHookOptions<AcceptApplyInvitationsMutation, AcceptApplyInvitationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptApplyInvitationsMutation, AcceptApplyInvitationsMutationVariables>(AcceptApplyInvitationsDocument, options);
      }
export type AcceptApplyInvitationsMutationHookResult = ReturnType<typeof useAcceptApplyInvitationsMutation>;
export type AcceptApplyInvitationsMutationResult = Apollo.MutationResult<AcceptApplyInvitationsMutation>;
export type AcceptApplyInvitationsMutationOptions = Apollo.BaseMutationOptions<AcceptApplyInvitationsMutation, AcceptApplyInvitationsMutationVariables>;
export const AcceptSuggestedTimesDocument = gql`
    mutation AcceptSuggestedTimes($input: [BookingConfirmTimesInput!]!) {
  acceptSuggestedTimes(input: $input) {
    error
    notAvailable
    success
    timesNotMatch
    updated {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
  }
}
    `;
export type AcceptSuggestedTimesMutationFn = Apollo.MutationFunction<AcceptSuggestedTimesMutation, AcceptSuggestedTimesMutationVariables>;

/**
 * __useAcceptSuggestedTimesMutation__
 *
 * To run a mutation, you first call `useAcceptSuggestedTimesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAcceptSuggestedTimesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [acceptSuggestedTimesMutation, { data, loading, error }] = useAcceptSuggestedTimesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAcceptSuggestedTimesMutation(baseOptions?: Apollo.MutationHookOptions<AcceptSuggestedTimesMutation, AcceptSuggestedTimesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AcceptSuggestedTimesMutation, AcceptSuggestedTimesMutationVariables>(AcceptSuggestedTimesDocument, options);
      }
export type AcceptSuggestedTimesMutationHookResult = ReturnType<typeof useAcceptSuggestedTimesMutation>;
export type AcceptSuggestedTimesMutationResult = Apollo.MutationResult<AcceptSuggestedTimesMutation>;
export type AcceptSuggestedTimesMutationOptions = Apollo.BaseMutationOptions<AcceptSuggestedTimesMutation, AcceptSuggestedTimesMutationVariables>;
export const ActivateCompanyDocument = gql`
    mutation ActivateCompany($id: ID!) {
  activateCompany(id: $id) {
    active
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    category
    city
    commission
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    id
    latitude
    longitude
    name
    organisationNumber
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    street
    updatedAt
    web
  }
}
    `;
export type ActivateCompanyMutationFn = Apollo.MutationFunction<ActivateCompanyMutation, ActivateCompanyMutationVariables>;

/**
 * __useActivateCompanyMutation__
 *
 * To run a mutation, you first call `useActivateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useActivateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [activateCompanyMutation, { data, loading, error }] = useActivateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useActivateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<ActivateCompanyMutation, ActivateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ActivateCompanyMutation, ActivateCompanyMutationVariables>(ActivateCompanyDocument, options);
      }
export type ActivateCompanyMutationHookResult = ReturnType<typeof useActivateCompanyMutation>;
export type ActivateCompanyMutationResult = Apollo.MutationResult<ActivateCompanyMutation>;
export type ActivateCompanyMutationOptions = Apollo.BaseMutationOptions<ActivateCompanyMutation, ActivateCompanyMutationVariables>;
export const AddRestaurantProfileFilesDocument = gql`
    mutation AddRestaurantProfileFiles($id: ID!, $input: [Upload!]!) {
  addRestaurantProfileFiles(id: $id, input: $input) {
    adjacentRegions
    approvedBookings
    createdAt
    description
    emergencyContactName
    emergencyContactPhone
    favouriteCount
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    languages
    rating
    region
    status
    tags {
      category
      createdAt
      id
      name
      subcategory
      updatedAt
    }
    updatedAt
    workExperience {
      category
      createdAt
      id
      otherWorkExperience
      updatedAt
      years
    }
    workReferences {
      company
      contactName
      contactPhone
      createdAt
      id
      role
      updatedAt
    }
  }
}
    `;
export type AddRestaurantProfileFilesMutationFn = Apollo.MutationFunction<AddRestaurantProfileFilesMutation, AddRestaurantProfileFilesMutationVariables>;

/**
 * __useAddRestaurantProfileFilesMutation__
 *
 * To run a mutation, you first call `useAddRestaurantProfileFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRestaurantProfileFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRestaurantProfileFilesMutation, { data, loading, error }] = useAddRestaurantProfileFilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRestaurantProfileFilesMutation(baseOptions?: Apollo.MutationHookOptions<AddRestaurantProfileFilesMutation, AddRestaurantProfileFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddRestaurantProfileFilesMutation, AddRestaurantProfileFilesMutationVariables>(AddRestaurantProfileFilesDocument, options);
      }
export type AddRestaurantProfileFilesMutationHookResult = ReturnType<typeof useAddRestaurantProfileFilesMutation>;
export type AddRestaurantProfileFilesMutationResult = Apollo.MutationResult<AddRestaurantProfileFilesMutation>;
export type AddRestaurantProfileFilesMutationOptions = Apollo.BaseMutationOptions<AddRestaurantProfileFilesMutation, AddRestaurantProfileFilesMutationVariables>;
export const AddUsersToResourceCategoryDocument = gql`
    mutation AddUsersToResourceCategory($id: ID!, $input: UpdateResourcesInput!) {
  addUsersToResourceCategory(id: $id, input: $input) {
    createdAt
    defaultSalary
    id
    level {
      createdAt
      id
      name
      updatedAt
    }
    name
    regional {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    updatedAt
    vertical
  }
}
    `;
export type AddUsersToResourceCategoryMutationFn = Apollo.MutationFunction<AddUsersToResourceCategoryMutation, AddUsersToResourceCategoryMutationVariables>;

/**
 * __useAddUsersToResourceCategoryMutation__
 *
 * To run a mutation, you first call `useAddUsersToResourceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUsersToResourceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUsersToResourceCategoryMutation, { data, loading, error }] = useAddUsersToResourceCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddUsersToResourceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<AddUsersToResourceCategoryMutation, AddUsersToResourceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddUsersToResourceCategoryMutation, AddUsersToResourceCategoryMutationVariables>(AddUsersToResourceCategoryDocument, options);
      }
export type AddUsersToResourceCategoryMutationHookResult = ReturnType<typeof useAddUsersToResourceCategoryMutation>;
export type AddUsersToResourceCategoryMutationResult = Apollo.MutationResult<AddUsersToResourceCategoryMutation>;
export type AddUsersToResourceCategoryMutationOptions = Apollo.BaseMutationOptions<AddUsersToResourceCategoryMutation, AddUsersToResourceCategoryMutationVariables>;
export const ApproveTimeReportDocument = gql`
    mutation ApproveTimeReport($bookingIds: [ID!]!) {
  approveTimeReport(bookingIds: $bookingIds) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type ApproveTimeReportMutationFn = Apollo.MutationFunction<ApproveTimeReportMutation, ApproveTimeReportMutationVariables>;

/**
 * __useApproveTimeReportMutation__
 *
 * To run a mutation, you first call `useApproveTimeReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useApproveTimeReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [approveTimeReportMutation, { data, loading, error }] = useApproveTimeReportMutation({
 *   variables: {
 *      bookingIds: // value for 'bookingIds'
 *   },
 * });
 */
export function useApproveTimeReportMutation(baseOptions?: Apollo.MutationHookOptions<ApproveTimeReportMutation, ApproveTimeReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ApproveTimeReportMutation, ApproveTimeReportMutationVariables>(ApproveTimeReportDocument, options);
      }
export type ApproveTimeReportMutationHookResult = ReturnType<typeof useApproveTimeReportMutation>;
export type ApproveTimeReportMutationResult = Apollo.MutationResult<ApproveTimeReportMutation>;
export type ApproveTimeReportMutationOptions = Apollo.BaseMutationOptions<ApproveTimeReportMutation, ApproveTimeReportMutationVariables>;
export const BroadcastDocument = gql`
    mutation Broadcast($message: String!, $title: String!, $userIds: [ID!]!) {
  broadcast(message: $message, title: $title, userIds: $userIds) {
    closed
    createdAt
    id
    members {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    messages
    name
    receivers
    responses
    updatedAt
  }
}
    `;
export type BroadcastMutationFn = Apollo.MutationFunction<BroadcastMutation, BroadcastMutationVariables>;

/**
 * __useBroadcastMutation__
 *
 * To run a mutation, you first call `useBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastMutation, { data, loading, error }] = useBroadcastMutation({
 *   variables: {
 *      message: // value for 'message'
 *      title: // value for 'title'
 *      userIds: // value for 'userIds'
 *   },
 * });
 */
export function useBroadcastMutation(baseOptions?: Apollo.MutationHookOptions<BroadcastMutation, BroadcastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BroadcastMutation, BroadcastMutationVariables>(BroadcastDocument, options);
      }
export type BroadcastMutationHookResult = ReturnType<typeof useBroadcastMutation>;
export type BroadcastMutationResult = Apollo.MutationResult<BroadcastMutation>;
export type BroadcastMutationOptions = Apollo.BaseMutationOptions<BroadcastMutation, BroadcastMutationVariables>;
export const BroadcastMessageDocument = gql`
    mutation BroadcastMessage($chatGroupId: ID, $filter: UserFilter!, $message: String!, $slotId: ID, $workShiftId: ID) {
  broadcastMessage(
    chatGroupId: $chatGroupId
    filter: $filter
    message: $message
    slotId: $slotId
    workShiftId: $workShiftId
  ) {
    success
  }
}
    `;
export type BroadcastMessageMutationFn = Apollo.MutationFunction<BroadcastMessageMutation, BroadcastMessageMutationVariables>;

/**
 * __useBroadcastMessageMutation__
 *
 * To run a mutation, you first call `useBroadcastMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBroadcastMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [broadcastMessageMutation, { data, loading, error }] = useBroadcastMessageMutation({
 *   variables: {
 *      chatGroupId: // value for 'chatGroupId'
 *      filter: // value for 'filter'
 *      message: // value for 'message'
 *      slotId: // value for 'slotId'
 *      workShiftId: // value for 'workShiftId'
 *   },
 * });
 */
export function useBroadcastMessageMutation(baseOptions?: Apollo.MutationHookOptions<BroadcastMessageMutation, BroadcastMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<BroadcastMessageMutation, BroadcastMessageMutationVariables>(BroadcastMessageDocument, options);
      }
export type BroadcastMessageMutationHookResult = ReturnType<typeof useBroadcastMessageMutation>;
export type BroadcastMessageMutationResult = Apollo.MutationResult<BroadcastMessageMutation>;
export type BroadcastMessageMutationOptions = Apollo.BaseMutationOptions<BroadcastMessageMutation, BroadcastMessageMutationVariables>;
export const CancelBookingsDocument = gql`
    mutation CancelBookings($input: CancelInput!) {
  cancelBookings(input: $input)
}
    `;
export type CancelBookingsMutationFn = Apollo.MutationFunction<CancelBookingsMutation, CancelBookingsMutationVariables>;

/**
 * __useCancelBookingsMutation__
 *
 * To run a mutation, you first call `useCancelBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelBookingsMutation, { data, loading, error }] = useCancelBookingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCancelBookingsMutation(baseOptions?: Apollo.MutationHookOptions<CancelBookingsMutation, CancelBookingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelBookingsMutation, CancelBookingsMutationVariables>(CancelBookingsDocument, options);
      }
export type CancelBookingsMutationHookResult = ReturnType<typeof useCancelBookingsMutation>;
export type CancelBookingsMutationResult = Apollo.MutationResult<CancelBookingsMutation>;
export type CancelBookingsMutationOptions = Apollo.BaseMutationOptions<CancelBookingsMutation, CancelBookingsMutationVariables>;
export const CancelNonAcceptedBookingsDocument = gql`
    mutation CancelNonAcceptedBookings($id: ID!) {
  cancelNonAcceptedBookings(id: $id) {
    success
  }
}
    `;
export type CancelNonAcceptedBookingsMutationFn = Apollo.MutationFunction<CancelNonAcceptedBookingsMutation, CancelNonAcceptedBookingsMutationVariables>;

/**
 * __useCancelNonAcceptedBookingsMutation__
 *
 * To run a mutation, you first call `useCancelNonAcceptedBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelNonAcceptedBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelNonAcceptedBookingsMutation, { data, loading, error }] = useCancelNonAcceptedBookingsMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelNonAcceptedBookingsMutation(baseOptions?: Apollo.MutationHookOptions<CancelNonAcceptedBookingsMutation, CancelNonAcceptedBookingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelNonAcceptedBookingsMutation, CancelNonAcceptedBookingsMutationVariables>(CancelNonAcceptedBookingsDocument, options);
      }
export type CancelNonAcceptedBookingsMutationHookResult = ReturnType<typeof useCancelNonAcceptedBookingsMutation>;
export type CancelNonAcceptedBookingsMutationResult = Apollo.MutationResult<CancelNonAcceptedBookingsMutation>;
export type CancelNonAcceptedBookingsMutationOptions = Apollo.BaseMutationOptions<CancelNonAcceptedBookingsMutation, CancelNonAcceptedBookingsMutationVariables>;
export const CloseChatGroupDocument = gql`
    mutation CloseChatGroup($id: ID!, $message: String) {
  closeChatGroup(id: $id, message: $message) {
    closed
    createdAt
    id
    members {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    messages
    name
    receivers
    responses
    updatedAt
  }
}
    `;
export type CloseChatGroupMutationFn = Apollo.MutationFunction<CloseChatGroupMutation, CloseChatGroupMutationVariables>;

/**
 * __useCloseChatGroupMutation__
 *
 * To run a mutation, you first call `useCloseChatGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloseChatGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [closeChatGroupMutation, { data, loading, error }] = useCloseChatGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useCloseChatGroupMutation(baseOptions?: Apollo.MutationHookOptions<CloseChatGroupMutation, CloseChatGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CloseChatGroupMutation, CloseChatGroupMutationVariables>(CloseChatGroupDocument, options);
      }
export type CloseChatGroupMutationHookResult = ReturnType<typeof useCloseChatGroupMutation>;
export type CloseChatGroupMutationResult = Apollo.MutationResult<CloseChatGroupMutation>;
export type CloseChatGroupMutationOptions = Apollo.BaseMutationOptions<CloseChatGroupMutation, CloseChatGroupMutationVariables>;
export const ConfirmBookingCanceledDocument = gql`
    mutation ConfirmBookingCanceled($id: ID!) {
  confirmBookingCanceled(id: $id) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type ConfirmBookingCanceledMutationFn = Apollo.MutationFunction<ConfirmBookingCanceledMutation, ConfirmBookingCanceledMutationVariables>;

/**
 * __useConfirmBookingCanceledMutation__
 *
 * To run a mutation, you first call `useConfirmBookingCanceledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConfirmBookingCanceledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [confirmBookingCanceledMutation, { data, loading, error }] = useConfirmBookingCanceledMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useConfirmBookingCanceledMutation(baseOptions?: Apollo.MutationHookOptions<ConfirmBookingCanceledMutation, ConfirmBookingCanceledMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConfirmBookingCanceledMutation, ConfirmBookingCanceledMutationVariables>(ConfirmBookingCanceledDocument, options);
      }
export type ConfirmBookingCanceledMutationHookResult = ReturnType<typeof useConfirmBookingCanceledMutation>;
export type ConfirmBookingCanceledMutationResult = Apollo.MutationResult<ConfirmBookingCanceledMutation>;
export type ConfirmBookingCanceledMutationOptions = Apollo.BaseMutationOptions<ConfirmBookingCanceledMutation, ConfirmBookingCanceledMutationVariables>;
export const CreateChannelDocument = gql`
    mutation CreateChannel($input: CreateChannelInput!) {
  createChannel(input: $input) {
    chatGroup {
      closed
      createdAt
      id
      members {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      messages
      name
      receivers
      responses
      updatedAt
    }
    createdAt
    deletedAt
    id
    lastSentAt
    messages {
      channel {
        chatGroup {
          closed
          createdAt
          id
          messages
          name
          receivers
          responses
          updatedAt
        }
        createdAt
        deletedAt
        id
        lastSentAt
        messages {
          createdAt
          id
          message
          position
          senderName
          title
          updatedAt
        }
        name
        origin
        participants {
          lastReadAt
        }
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      extra {
        ... on Booking {
          acceptWith {
            total
          }
          acceptedAt
          approvedAt
          approvedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          approvedByResourceAt
          backOfficeMainStatus
          backOfficeSubStatus
          breakMinutes
          cancelPolicyPct
          cancelledAt
          confirmedCanceled
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          declinedAt
          endTime
          extraStatus
          history {
            breakMinutes
            endTime
            startTime
            status
            suggestedEnd
            suggestedStart
            updatedAt
            updatedById
          }
          id
          invoiced
          legacyId
          pricePoint {
            hourlySalaryBase
            hourlySalaryBoost
            hours
            totalPrice
            totalSalary
          }
          resource {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          resourceCategoryData {
            verticalProfileCategory
          }
          startTime
          status
          suggestedEnd
          suggestedStart
          suggestionAcceptedAt
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShiftId
          workShiftSlot {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            createdAt
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
          }
        }
      }
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      images {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      message
      position
      senderName
      title
      updatedAt
    }
    name
    origin
    participants {
      lastReadAt
      user {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    updatedAt
    workShift {
      acceptanceTime
      bookingType
      cancelledAt
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      description
      firstStartTime
      id
      internalName
      lastEndTime
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      minutesPriorStart
      postalCode
      region
      resourceQuantity
      resourceRegionalCategories {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      salaryBoost
      sameResources
      sentAt
      slots {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      status
      street
      templateId
      temporaryAddress {
        city
        id
        latitude
        longitude
        name
        postalCode
        region
        street
      }
      tips
      totalPrice
      travelExpenseCompensation
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftResourceCategories {
        levelIds
        levels {
          createdAt
          id
          name
          updatedAt
        }
        resourceCategories {
          id
          levelName
        }
        verticalProfileCategory
      }
    }
  }
}
    `;
export type CreateChannelMutationFn = Apollo.MutationFunction<CreateChannelMutation, CreateChannelMutationVariables>;

/**
 * __useCreateChannelMutation__
 *
 * To run a mutation, you first call `useCreateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createChannelMutation, { data, loading, error }] = useCreateChannelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateChannelMutation(baseOptions?: Apollo.MutationHookOptions<CreateChannelMutation, CreateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateChannelMutation, CreateChannelMutationVariables>(CreateChannelDocument, options);
      }
export type CreateChannelMutationHookResult = ReturnType<typeof useCreateChannelMutation>;
export type CreateChannelMutationResult = Apollo.MutationResult<CreateChannelMutation>;
export type CreateChannelMutationOptions = Apollo.BaseMutationOptions<CreateChannelMutation, CreateChannelMutationVariables>;
export const CreateCompanyDocument = gql`
    mutation CreateCompany($input: CreateCompanyInput!) {
  createCompany(input: $input) {
    active
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    category
    city
    commission
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    id
    latitude
    longitude
    name
    organisationNumber
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    street
    updatedAt
    web
  }
}
    `;
export type CreateCompanyMutationFn = Apollo.MutationFunction<CreateCompanyMutation, CreateCompanyMutationVariables>;

/**
 * __useCreateCompanyMutation__
 *
 * To run a mutation, you first call `useCreateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyMutation, { data, loading, error }] = useCreateCompanyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyMutation, CreateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyMutation, CreateCompanyMutationVariables>(CreateCompanyDocument, options);
      }
export type CreateCompanyMutationHookResult = ReturnType<typeof useCreateCompanyMutation>;
export type CreateCompanyMutationResult = Apollo.MutationResult<CreateCompanyMutation>;
export type CreateCompanyMutationOptions = Apollo.BaseMutationOptions<CreateCompanyMutation, CreateCompanyMutationVariables>;
export const CreateCompanyUserDocument = gql`
    mutation CreateCompanyUser($id: ID!, $input: CreateCompanyUserInput!) {
  createCompanyUser(id: $id, input: $input) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type CreateCompanyUserMutationFn = Apollo.MutationFunction<CreateCompanyUserMutation, CreateCompanyUserMutationVariables>;

/**
 * __useCreateCompanyUserMutation__
 *
 * To run a mutation, you first call `useCreateCompanyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCompanyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCompanyUserMutation, { data, loading, error }] = useCreateCompanyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCompanyUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateCompanyUserMutation, CreateCompanyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCompanyUserMutation, CreateCompanyUserMutationVariables>(CreateCompanyUserDocument, options);
      }
export type CreateCompanyUserMutationHookResult = ReturnType<typeof useCreateCompanyUserMutation>;
export type CreateCompanyUserMutationResult = Apollo.MutationResult<CreateCompanyUserMutation>;
export type CreateCompanyUserMutationOptions = Apollo.BaseMutationOptions<CreateCompanyUserMutation, CreateCompanyUserMutationVariables>;
export const CreateLocationDocument = gql`
    mutation CreateLocation($input: CreateLocationInput!) {
  createLocation(input: $input) {
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    city
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    latitude
    legacyCustomerId
    logo {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    longitude
    name
    organisationNumber
    postalCode
    region
    street
    updatedAt
    web
  }
}
    `;
export type CreateLocationMutationFn = Apollo.MutationFunction<CreateLocationMutation, CreateLocationMutationVariables>;

/**
 * __useCreateLocationMutation__
 *
 * To run a mutation, you first call `useCreateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createLocationMutation, { data, loading, error }] = useCreateLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateLocationMutation(baseOptions?: Apollo.MutationHookOptions<CreateLocationMutation, CreateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateLocationMutation, CreateLocationMutationVariables>(CreateLocationDocument, options);
      }
export type CreateLocationMutationHookResult = ReturnType<typeof useCreateLocationMutation>;
export type CreateLocationMutationResult = Apollo.MutationResult<CreateLocationMutation>;
export type CreateLocationMutationOptions = Apollo.BaseMutationOptions<CreateLocationMutation, CreateLocationMutationVariables>;
export const CreateResourceCategoryDocument = gql`
    mutation CreateResourceCategory($input: CreateResourceCategory!) {
  createResourceCategory(input: $input) {
    createdAt
    defaultSalary
    id
    level {
      createdAt
      id
      name
      updatedAt
    }
    name
    regional {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    updatedAt
    vertical
  }
}
    `;
export type CreateResourceCategoryMutationFn = Apollo.MutationFunction<CreateResourceCategoryMutation, CreateResourceCategoryMutationVariables>;

/**
 * __useCreateResourceCategoryMutation__
 *
 * To run a mutation, you first call `useCreateResourceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceCategoryMutation, { data, loading, error }] = useCreateResourceCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceCategoryMutation, CreateResourceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceCategoryMutation, CreateResourceCategoryMutationVariables>(CreateResourceCategoryDocument, options);
      }
export type CreateResourceCategoryMutationHookResult = ReturnType<typeof useCreateResourceCategoryMutation>;
export type CreateResourceCategoryMutationResult = Apollo.MutationResult<CreateResourceCategoryMutation>;
export type CreateResourceCategoryMutationOptions = Apollo.BaseMutationOptions<CreateResourceCategoryMutation, CreateResourceCategoryMutationVariables>;
export const CreateResourceLevelDocument = gql`
    mutation CreateResourceLevel($input: UpsertResourceLevel!) {
  createResourceLevel(input: $input) {
    createdAt
    id
    name
    updatedAt
  }
}
    `;
export type CreateResourceLevelMutationFn = Apollo.MutationFunction<CreateResourceLevelMutation, CreateResourceLevelMutationVariables>;

/**
 * __useCreateResourceLevelMutation__
 *
 * To run a mutation, you first call `useCreateResourceLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceLevelMutation, { data, loading, error }] = useCreateResourceLevelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceLevelMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceLevelMutation, CreateResourceLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceLevelMutation, CreateResourceLevelMutationVariables>(CreateResourceLevelDocument, options);
      }
export type CreateResourceLevelMutationHookResult = ReturnType<typeof useCreateResourceLevelMutation>;
export type CreateResourceLevelMutationResult = Apollo.MutationResult<CreateResourceLevelMutation>;
export type CreateResourceLevelMutationOptions = Apollo.BaseMutationOptions<CreateResourceLevelMutation, CreateResourceLevelMutationVariables>;
export const CreateResourceRegionalCategoryDocument = gql`
    mutation CreateResourceRegionalCategory($input: CreateResourceRegionalCategory!) {
  createResourceRegionalCategory(input: $input) {
    createdAt
    id
    modifier
    price
    region
    salary
    updatedAt
  }
}
    `;
export type CreateResourceRegionalCategoryMutationFn = Apollo.MutationFunction<CreateResourceRegionalCategoryMutation, CreateResourceRegionalCategoryMutationVariables>;

/**
 * __useCreateResourceRegionalCategoryMutation__
 *
 * To run a mutation, you first call `useCreateResourceRegionalCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateResourceRegionalCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createResourceRegionalCategoryMutation, { data, loading, error }] = useCreateResourceRegionalCategoryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateResourceRegionalCategoryMutation(baseOptions?: Apollo.MutationHookOptions<CreateResourceRegionalCategoryMutation, CreateResourceRegionalCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateResourceRegionalCategoryMutation, CreateResourceRegionalCategoryMutationVariables>(CreateResourceRegionalCategoryDocument, options);
      }
export type CreateResourceRegionalCategoryMutationHookResult = ReturnType<typeof useCreateResourceRegionalCategoryMutation>;
export type CreateResourceRegionalCategoryMutationResult = Apollo.MutationResult<CreateResourceRegionalCategoryMutation>;
export type CreateResourceRegionalCategoryMutationOptions = Apollo.BaseMutationOptions<CreateResourceRegionalCategoryMutation, CreateResourceRegionalCategoryMutationVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    jwt
    message
    newAccount
    refreshToken
    success
    user {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    userId
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const CreateUserEventDocument = gql`
    mutation CreateUserEvent($input: UserEventInput!) {
  createUserEvent(input: $input) {
    booking {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
    createdAt
    description
    endTime
    id
    startTime
    title
    updatedAt
    user {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type CreateUserEventMutationFn = Apollo.MutationFunction<CreateUserEventMutation, CreateUserEventMutationVariables>;

/**
 * __useCreateUserEventMutation__
 *
 * To run a mutation, you first call `useCreateUserEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserEventMutation, { data, loading, error }] = useCreateUserEventMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserEventMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserEventMutation, CreateUserEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserEventMutation, CreateUserEventMutationVariables>(CreateUserEventDocument, options);
      }
export type CreateUserEventMutationHookResult = ReturnType<typeof useCreateUserEventMutation>;
export type CreateUserEventMutationResult = Apollo.MutationResult<CreateUserEventMutation>;
export type CreateUserEventMutationOptions = Apollo.BaseMutationOptions<CreateUserEventMutation, CreateUserEventMutationVariables>;
export const CreateWorkShiftDocument = gql`
    mutation CreateWorkShift($input: CreateWorkShiftInput!) {
  createWorkShift(input: $input) {
    acceptanceTime
    bookingType
    cancelledAt
    city
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    contactPerson {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    customContactPerson
    description
    firstStartTime
    id
    internalName
    lastEndTime
    location {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    mealDeduction
    minutesPriorStart
    postalCode
    region
    resourceQuantity
    resourceRegionalCategories {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    salaryBoost
    sameResources
    sentAt
    slots {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
    status
    street
    templateId
    temporaryAddress {
      city
      id
      latitude
      longitude
      name
      postalCode
      region
      street
    }
    tips
    totalPrice
    travelExpenseCompensation
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftResourceCategories {
      levelIds
      levels {
        createdAt
        id
        name
        updatedAt
      }
      resourceCategories {
        id
        levelName
      }
      verticalProfileCategory
    }
  }
}
    `;
export type CreateWorkShiftMutationFn = Apollo.MutationFunction<CreateWorkShiftMutation, CreateWorkShiftMutationVariables>;

/**
 * __useCreateWorkShiftMutation__
 *
 * To run a mutation, you first call `useCreateWorkShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkShiftMutation, { data, loading, error }] = useCreateWorkShiftMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkShiftMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkShiftMutation, CreateWorkShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkShiftMutation, CreateWorkShiftMutationVariables>(CreateWorkShiftDocument, options);
      }
export type CreateWorkShiftMutationHookResult = ReturnType<typeof useCreateWorkShiftMutation>;
export type CreateWorkShiftMutationResult = Apollo.MutationResult<CreateWorkShiftMutation>;
export type CreateWorkShiftMutationOptions = Apollo.BaseMutationOptions<CreateWorkShiftMutation, CreateWorkShiftMutationVariables>;
export const CreateWorkShiftTemplateDocument = gql`
    mutation CreateWorkShiftTemplate($input: WorkShiftTemplateInput!) {
  createWorkShiftTemplate(input: $input) {
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    createdAt
    description
    id
    locations {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    mealDeduction
    name
    salaryBoost
    slotTemplates {
      endHhMm
      resourceQuantity
      startHhMm
      workShiftTemplateId
    }
    tips
    travelExpenseCompensation
    updatedAt
    verticalProfileCategory
  }
}
    `;
export type CreateWorkShiftTemplateMutationFn = Apollo.MutationFunction<CreateWorkShiftTemplateMutation, CreateWorkShiftTemplateMutationVariables>;

/**
 * __useCreateWorkShiftTemplateMutation__
 *
 * To run a mutation, you first call `useCreateWorkShiftTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateWorkShiftTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createWorkShiftTemplateMutation, { data, loading, error }] = useCreateWorkShiftTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateWorkShiftTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreateWorkShiftTemplateMutation, CreateWorkShiftTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateWorkShiftTemplateMutation, CreateWorkShiftTemplateMutationVariables>(CreateWorkShiftTemplateDocument, options);
      }
export type CreateWorkShiftTemplateMutationHookResult = ReturnType<typeof useCreateWorkShiftTemplateMutation>;
export type CreateWorkShiftTemplateMutationResult = Apollo.MutationResult<CreateWorkShiftTemplateMutation>;
export type CreateWorkShiftTemplateMutationOptions = Apollo.BaseMutationOptions<CreateWorkShiftTemplateMutation, CreateWorkShiftTemplateMutationVariables>;
export const DeclineBookingsDocument = gql`
    mutation DeclineBookings($input: [ID!]!) {
  declineBookings(input: $input) {
    error
    mandatoryMissing
    success
    updated {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
  }
}
    `;
export type DeclineBookingsMutationFn = Apollo.MutationFunction<DeclineBookingsMutation, DeclineBookingsMutationVariables>;

/**
 * __useDeclineBookingsMutation__
 *
 * To run a mutation, you first call `useDeclineBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineBookingsMutation, { data, loading, error }] = useDeclineBookingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeclineBookingsMutation(baseOptions?: Apollo.MutationHookOptions<DeclineBookingsMutation, DeclineBookingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineBookingsMutation, DeclineBookingsMutationVariables>(DeclineBookingsDocument, options);
      }
export type DeclineBookingsMutationHookResult = ReturnType<typeof useDeclineBookingsMutation>;
export type DeclineBookingsMutationResult = Apollo.MutationResult<DeclineBookingsMutation>;
export type DeclineBookingsMutationOptions = Apollo.BaseMutationOptions<DeclineBookingsMutation, DeclineBookingsMutationVariables>;
export const DeclineSuggestedTimesDocument = gql`
    mutation DeclineSuggestedTimes($id: ID!) {
  declineSuggestedTimes(id: $id) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type DeclineSuggestedTimesMutationFn = Apollo.MutationFunction<DeclineSuggestedTimesMutation, DeclineSuggestedTimesMutationVariables>;

/**
 * __useDeclineSuggestedTimesMutation__
 *
 * To run a mutation, you first call `useDeclineSuggestedTimesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineSuggestedTimesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineSuggestedTimesMutation, { data, loading, error }] = useDeclineSuggestedTimesMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeclineSuggestedTimesMutation(baseOptions?: Apollo.MutationHookOptions<DeclineSuggestedTimesMutation, DeclineSuggestedTimesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineSuggestedTimesMutation, DeclineSuggestedTimesMutationVariables>(DeclineSuggestedTimesDocument, options);
      }
export type DeclineSuggestedTimesMutationHookResult = ReturnType<typeof useDeclineSuggestedTimesMutation>;
export type DeclineSuggestedTimesMutationResult = Apollo.MutationResult<DeclineSuggestedTimesMutation>;
export type DeclineSuggestedTimesMutationOptions = Apollo.BaseMutationOptions<DeclineSuggestedTimesMutation, DeclineSuggestedTimesMutationVariables>;
export const DeclineTimeReportDocument = gql`
    mutation DeclineTimeReport($bookingId: ID!, $message: String!) {
  declineTimeReport(bookingId: $bookingId, message: $message) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type DeclineTimeReportMutationFn = Apollo.MutationFunction<DeclineTimeReportMutation, DeclineTimeReportMutationVariables>;

/**
 * __useDeclineTimeReportMutation__
 *
 * To run a mutation, you first call `useDeclineTimeReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeclineTimeReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [declineTimeReportMutation, { data, loading, error }] = useDeclineTimeReportMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useDeclineTimeReportMutation(baseOptions?: Apollo.MutationHookOptions<DeclineTimeReportMutation, DeclineTimeReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeclineTimeReportMutation, DeclineTimeReportMutationVariables>(DeclineTimeReportDocument, options);
      }
export type DeclineTimeReportMutationHookResult = ReturnType<typeof useDeclineTimeReportMutation>;
export type DeclineTimeReportMutationResult = Apollo.MutationResult<DeclineTimeReportMutation>;
export type DeclineTimeReportMutationOptions = Apollo.BaseMutationOptions<DeclineTimeReportMutation, DeclineTimeReportMutationVariables>;
export const DeleteChannelDocument = gql`
    mutation DeleteChannel($id: ID!) {
  deleteChannel(id: $id) {
    success
  }
}
    `;
export type DeleteChannelMutationFn = Apollo.MutationFunction<DeleteChannelMutation, DeleteChannelMutationVariables>;

/**
 * __useDeleteChannelMutation__
 *
 * To run a mutation, you first call `useDeleteChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChannelMutation, { data, loading, error }] = useDeleteChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChannelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChannelMutation, DeleteChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChannelMutation, DeleteChannelMutationVariables>(DeleteChannelDocument, options);
      }
export type DeleteChannelMutationHookResult = ReturnType<typeof useDeleteChannelMutation>;
export type DeleteChannelMutationResult = Apollo.MutationResult<DeleteChannelMutation>;
export type DeleteChannelMutationOptions = Apollo.BaseMutationOptions<DeleteChannelMutation, DeleteChannelMutationVariables>;
export const DeleteChatGroupDocument = gql`
    mutation DeleteChatGroup($id: ID) {
  deleteChatGroup(id: $id) {
    success
  }
}
    `;
export type DeleteChatGroupMutationFn = Apollo.MutationFunction<DeleteChatGroupMutation, DeleteChatGroupMutationVariables>;

/**
 * __useDeleteChatGroupMutation__
 *
 * To run a mutation, you first call `useDeleteChatGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteChatGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteChatGroupMutation, { data, loading, error }] = useDeleteChatGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteChatGroupMutation(baseOptions?: Apollo.MutationHookOptions<DeleteChatGroupMutation, DeleteChatGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteChatGroupMutation, DeleteChatGroupMutationVariables>(DeleteChatGroupDocument, options);
      }
export type DeleteChatGroupMutationHookResult = ReturnType<typeof useDeleteChatGroupMutation>;
export type DeleteChatGroupMutationResult = Apollo.MutationResult<DeleteChatGroupMutation>;
export type DeleteChatGroupMutationOptions = Apollo.BaseMutationOptions<DeleteChatGroupMutation, DeleteChatGroupMutationVariables>;
export const DeleteCompanyDocument = gql`
    mutation DeleteCompany($id: ID!) {
  deleteCompany(id: $id) {
    success
  }
}
    `;
export type DeleteCompanyMutationFn = Apollo.MutationFunction<DeleteCompanyMutation, DeleteCompanyMutationVariables>;

/**
 * __useDeleteCompanyMutation__
 *
 * To run a mutation, you first call `useDeleteCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCompanyMutation, { data, loading, error }] = useDeleteCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCompanyMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCompanyMutation, DeleteCompanyMutationVariables>(DeleteCompanyDocument, options);
      }
export type DeleteCompanyMutationHookResult = ReturnType<typeof useDeleteCompanyMutation>;
export type DeleteCompanyMutationResult = Apollo.MutationResult<DeleteCompanyMutation>;
export type DeleteCompanyMutationOptions = Apollo.BaseMutationOptions<DeleteCompanyMutation, DeleteCompanyMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($id: ID!) {
  deleteLocation(id: $id) {
    success
  }
}
    `;
export type DeleteLocationMutationFn = Apollo.MutationFunction<DeleteLocationMutation, DeleteLocationMutationVariables>;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationMutation, { data, loading, error }] = useDeleteLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationMutation, DeleteLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
      }
export type DeleteLocationMutationHookResult = ReturnType<typeof useDeleteLocationMutation>;
export type DeleteLocationMutationResult = Apollo.MutationResult<DeleteLocationMutation>;
export type DeleteLocationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const DeleteLocationRecommendationDocument = gql`
    mutation DeleteLocationRecommendation($id: ID!) {
  deleteLocationRecommendation(id: $id) {
    success
  }
}
    `;
export type DeleteLocationRecommendationMutationFn = Apollo.MutationFunction<DeleteLocationRecommendationMutation, DeleteLocationRecommendationMutationVariables>;

/**
 * __useDeleteLocationRecommendationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLocationRecommendationMutation, { data, loading, error }] = useDeleteLocationRecommendationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLocationRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<DeleteLocationRecommendationMutation, DeleteLocationRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteLocationRecommendationMutation, DeleteLocationRecommendationMutationVariables>(DeleteLocationRecommendationDocument, options);
      }
export type DeleteLocationRecommendationMutationHookResult = ReturnType<typeof useDeleteLocationRecommendationMutation>;
export type DeleteLocationRecommendationMutationResult = Apollo.MutationResult<DeleteLocationRecommendationMutation>;
export type DeleteLocationRecommendationMutationOptions = Apollo.BaseMutationOptions<DeleteLocationRecommendationMutation, DeleteLocationRecommendationMutationVariables>;
export const DeleteResourceCategoryDocument = gql`
    mutation DeleteResourceCategory($id: ID!) {
  deleteResourceCategory(id: $id) {
    success
  }
}
    `;
export type DeleteResourceCategoryMutationFn = Apollo.MutationFunction<DeleteResourceCategoryMutation, DeleteResourceCategoryMutationVariables>;

/**
 * __useDeleteResourceCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteResourceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceCategoryMutation, { data, loading, error }] = useDeleteResourceCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteResourceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResourceCategoryMutation, DeleteResourceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResourceCategoryMutation, DeleteResourceCategoryMutationVariables>(DeleteResourceCategoryDocument, options);
      }
export type DeleteResourceCategoryMutationHookResult = ReturnType<typeof useDeleteResourceCategoryMutation>;
export type DeleteResourceCategoryMutationResult = Apollo.MutationResult<DeleteResourceCategoryMutation>;
export type DeleteResourceCategoryMutationOptions = Apollo.BaseMutationOptions<DeleteResourceCategoryMutation, DeleteResourceCategoryMutationVariables>;
export const DeleteResourceLevelDocument = gql`
    mutation DeleteResourceLevel($id: ID!) {
  deleteResourceLevel(id: $id) {
    success
  }
}
    `;
export type DeleteResourceLevelMutationFn = Apollo.MutationFunction<DeleteResourceLevelMutation, DeleteResourceLevelMutationVariables>;

/**
 * __useDeleteResourceLevelMutation__
 *
 * To run a mutation, you first call `useDeleteResourceLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteResourceLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteResourceLevelMutation, { data, loading, error }] = useDeleteResourceLevelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteResourceLevelMutation(baseOptions?: Apollo.MutationHookOptions<DeleteResourceLevelMutation, DeleteResourceLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteResourceLevelMutation, DeleteResourceLevelMutationVariables>(DeleteResourceLevelDocument, options);
      }
export type DeleteResourceLevelMutationHookResult = ReturnType<typeof useDeleteResourceLevelMutation>;
export type DeleteResourceLevelMutationResult = Apollo.MutationResult<DeleteResourceLevelMutation>;
export type DeleteResourceLevelMutationOptions = Apollo.BaseMutationOptions<DeleteResourceLevelMutation, DeleteResourceLevelMutationVariables>;
export const DeleteRestaurantProfileExperienceDocument = gql`
    mutation DeleteRestaurantProfileExperience($experienceId: ID!, $id: ID!) {
  deleteRestaurantProfileExperience(experienceId: $experienceId, id: $id) {
    adjacentRegions
    approvedBookings
    createdAt
    description
    emergencyContactName
    emergencyContactPhone
    favouriteCount
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    languages
    rating
    region
    status
    tags {
      category
      createdAt
      id
      name
      subcategory
      updatedAt
    }
    updatedAt
    workExperience {
      category
      createdAt
      id
      otherWorkExperience
      updatedAt
      years
    }
    workReferences {
      company
      contactName
      contactPhone
      createdAt
      id
      role
      updatedAt
    }
  }
}
    `;
export type DeleteRestaurantProfileExperienceMutationFn = Apollo.MutationFunction<DeleteRestaurantProfileExperienceMutation, DeleteRestaurantProfileExperienceMutationVariables>;

/**
 * __useDeleteRestaurantProfileExperienceMutation__
 *
 * To run a mutation, you first call `useDeleteRestaurantProfileExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestaurantProfileExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestaurantProfileExperienceMutation, { data, loading, error }] = useDeleteRestaurantProfileExperienceMutation({
 *   variables: {
 *      experienceId: // value for 'experienceId'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRestaurantProfileExperienceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRestaurantProfileExperienceMutation, DeleteRestaurantProfileExperienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRestaurantProfileExperienceMutation, DeleteRestaurantProfileExperienceMutationVariables>(DeleteRestaurantProfileExperienceDocument, options);
      }
export type DeleteRestaurantProfileExperienceMutationHookResult = ReturnType<typeof useDeleteRestaurantProfileExperienceMutation>;
export type DeleteRestaurantProfileExperienceMutationResult = Apollo.MutationResult<DeleteRestaurantProfileExperienceMutation>;
export type DeleteRestaurantProfileExperienceMutationOptions = Apollo.BaseMutationOptions<DeleteRestaurantProfileExperienceMutation, DeleteRestaurantProfileExperienceMutationVariables>;
export const DeleteRestaurantProfileFilesDocument = gql`
    mutation DeleteRestaurantProfileFiles($id: ID!, $input: [ID!]!) {
  deleteRestaurantProfileFiles(id: $id, input: $input) {
    adjacentRegions
    approvedBookings
    createdAt
    description
    emergencyContactName
    emergencyContactPhone
    favouriteCount
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    languages
    rating
    region
    status
    tags {
      category
      createdAt
      id
      name
      subcategory
      updatedAt
    }
    updatedAt
    workExperience {
      category
      createdAt
      id
      otherWorkExperience
      updatedAt
      years
    }
    workReferences {
      company
      contactName
      contactPhone
      createdAt
      id
      role
      updatedAt
    }
  }
}
    `;
export type DeleteRestaurantProfileFilesMutationFn = Apollo.MutationFunction<DeleteRestaurantProfileFilesMutation, DeleteRestaurantProfileFilesMutationVariables>;

/**
 * __useDeleteRestaurantProfileFilesMutation__
 *
 * To run a mutation, you first call `useDeleteRestaurantProfileFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestaurantProfileFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestaurantProfileFilesMutation, { data, loading, error }] = useDeleteRestaurantProfileFilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteRestaurantProfileFilesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRestaurantProfileFilesMutation, DeleteRestaurantProfileFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRestaurantProfileFilesMutation, DeleteRestaurantProfileFilesMutationVariables>(DeleteRestaurantProfileFilesDocument, options);
      }
export type DeleteRestaurantProfileFilesMutationHookResult = ReturnType<typeof useDeleteRestaurantProfileFilesMutation>;
export type DeleteRestaurantProfileFilesMutationResult = Apollo.MutationResult<DeleteRestaurantProfileFilesMutation>;
export type DeleteRestaurantProfileFilesMutationOptions = Apollo.BaseMutationOptions<DeleteRestaurantProfileFilesMutation, DeleteRestaurantProfileFilesMutationVariables>;
export const DeleteRestaurantProfileReferenceDocument = gql`
    mutation DeleteRestaurantProfileReference($id: ID!, $referenceId: ID!) {
  deleteRestaurantProfileReference(id: $id, referenceId: $referenceId) {
    adjacentRegions
    approvedBookings
    createdAt
    description
    emergencyContactName
    emergencyContactPhone
    favouriteCount
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    languages
    rating
    region
    status
    tags {
      category
      createdAt
      id
      name
      subcategory
      updatedAt
    }
    updatedAt
    workExperience {
      category
      createdAt
      id
      otherWorkExperience
      updatedAt
      years
    }
    workReferences {
      company
      contactName
      contactPhone
      createdAt
      id
      role
      updatedAt
    }
  }
}
    `;
export type DeleteRestaurantProfileReferenceMutationFn = Apollo.MutationFunction<DeleteRestaurantProfileReferenceMutation, DeleteRestaurantProfileReferenceMutationVariables>;

/**
 * __useDeleteRestaurantProfileReferenceMutation__
 *
 * To run a mutation, you first call `useDeleteRestaurantProfileReferenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestaurantProfileReferenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestaurantProfileReferenceMutation, { data, loading, error }] = useDeleteRestaurantProfileReferenceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      referenceId: // value for 'referenceId'
 *   },
 * });
 */
export function useDeleteRestaurantProfileReferenceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRestaurantProfileReferenceMutation, DeleteRestaurantProfileReferenceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRestaurantProfileReferenceMutation, DeleteRestaurantProfileReferenceMutationVariables>(DeleteRestaurantProfileReferenceDocument, options);
      }
export type DeleteRestaurantProfileReferenceMutationHookResult = ReturnType<typeof useDeleteRestaurantProfileReferenceMutation>;
export type DeleteRestaurantProfileReferenceMutationResult = Apollo.MutationResult<DeleteRestaurantProfileReferenceMutation>;
export type DeleteRestaurantProfileReferenceMutationOptions = Apollo.BaseMutationOptions<DeleteRestaurantProfileReferenceMutation, DeleteRestaurantProfileReferenceMutationVariables>;
export const DeleteRestaurantProfileTagsDocument = gql`
    mutation DeleteRestaurantProfileTags($input: [ID!]!, $userId: ID) {
  deleteRestaurantProfileTags(input: $input, userId: $userId) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type DeleteRestaurantProfileTagsMutationFn = Apollo.MutationFunction<DeleteRestaurantProfileTagsMutation, DeleteRestaurantProfileTagsMutationVariables>;

/**
 * __useDeleteRestaurantProfileTagsMutation__
 *
 * To run a mutation, you first call `useDeleteRestaurantProfileTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRestaurantProfileTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRestaurantProfileTagsMutation, { data, loading, error }] = useDeleteRestaurantProfileTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteRestaurantProfileTagsMutation(baseOptions?: Apollo.MutationHookOptions<DeleteRestaurantProfileTagsMutation, DeleteRestaurantProfileTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteRestaurantProfileTagsMutation, DeleteRestaurantProfileTagsMutationVariables>(DeleteRestaurantProfileTagsDocument, options);
      }
export type DeleteRestaurantProfileTagsMutationHookResult = ReturnType<typeof useDeleteRestaurantProfileTagsMutation>;
export type DeleteRestaurantProfileTagsMutationResult = Apollo.MutationResult<DeleteRestaurantProfileTagsMutation>;
export type DeleteRestaurantProfileTagsMutationOptions = Apollo.BaseMutationOptions<DeleteRestaurantProfileTagsMutation, DeleteRestaurantProfileTagsMutationVariables>;
export const DeleteTagDocument = gql`
    mutation DeleteTag($id: ID!) {
  deleteTag(id: $id) {
    success
  }
}
    `;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTagMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
      }
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(id: $id) {
    success
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const DeleteUserEventDocument = gql`
    mutation DeleteUserEvent($id: ID!) {
  deleteUserEvent(id: $id) {
    success
  }
}
    `;
export type DeleteUserEventMutationFn = Apollo.MutationFunction<DeleteUserEventMutation, DeleteUserEventMutationVariables>;

/**
 * __useDeleteUserEventMutation__
 *
 * To run a mutation, you first call `useDeleteUserEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserEventMutation, { data, loading, error }] = useDeleteUserEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserEventMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserEventMutation, DeleteUserEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserEventMutation, DeleteUserEventMutationVariables>(DeleteUserEventDocument, options);
      }
export type DeleteUserEventMutationHookResult = ReturnType<typeof useDeleteUserEventMutation>;
export type DeleteUserEventMutationResult = Apollo.MutationResult<DeleteUserEventMutation>;
export type DeleteUserEventMutationOptions = Apollo.BaseMutationOptions<DeleteUserEventMutation, DeleteUserEventMutationVariables>;
export const DeleteWorkShiftDocument = gql`
    mutation DeleteWorkShift($id: ID!) {
  deleteWorkShift(id: $id) {
    success
  }
}
    `;
export type DeleteWorkShiftMutationFn = Apollo.MutationFunction<DeleteWorkShiftMutation, DeleteWorkShiftMutationVariables>;

/**
 * __useDeleteWorkShiftMutation__
 *
 * To run a mutation, you first call `useDeleteWorkShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkShiftMutation, { data, loading, error }] = useDeleteWorkShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkShiftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkShiftMutation, DeleteWorkShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkShiftMutation, DeleteWorkShiftMutationVariables>(DeleteWorkShiftDocument, options);
      }
export type DeleteWorkShiftMutationHookResult = ReturnType<typeof useDeleteWorkShiftMutation>;
export type DeleteWorkShiftMutationResult = Apollo.MutationResult<DeleteWorkShiftMutation>;
export type DeleteWorkShiftMutationOptions = Apollo.BaseMutationOptions<DeleteWorkShiftMutation, DeleteWorkShiftMutationVariables>;
export const DeleteWorkShiftSlotDocument = gql`
    mutation DeleteWorkShiftSlot($id: ID!) {
  deleteWorkShiftSlot(id: $id) {
    success
  }
}
    `;
export type DeleteWorkShiftSlotMutationFn = Apollo.MutationFunction<DeleteWorkShiftSlotMutation, DeleteWorkShiftSlotMutationVariables>;

/**
 * __useDeleteWorkShiftSlotMutation__
 *
 * To run a mutation, you first call `useDeleteWorkShiftSlotMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkShiftSlotMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkShiftSlotMutation, { data, loading, error }] = useDeleteWorkShiftSlotMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkShiftSlotMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkShiftSlotMutation, DeleteWorkShiftSlotMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkShiftSlotMutation, DeleteWorkShiftSlotMutationVariables>(DeleteWorkShiftSlotDocument, options);
      }
export type DeleteWorkShiftSlotMutationHookResult = ReturnType<typeof useDeleteWorkShiftSlotMutation>;
export type DeleteWorkShiftSlotMutationResult = Apollo.MutationResult<DeleteWorkShiftSlotMutation>;
export type DeleteWorkShiftSlotMutationOptions = Apollo.BaseMutationOptions<DeleteWorkShiftSlotMutation, DeleteWorkShiftSlotMutationVariables>;
export const DeleteWorkShiftTemplateDocument = gql`
    mutation DeleteWorkShiftTemplate($id: ID!) {
  deleteWorkShiftTemplate(id: $id) {
    success
  }
}
    `;
export type DeleteWorkShiftTemplateMutationFn = Apollo.MutationFunction<DeleteWorkShiftTemplateMutation, DeleteWorkShiftTemplateMutationVariables>;

/**
 * __useDeleteWorkShiftTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteWorkShiftTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkShiftTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkShiftTemplateMutation, { data, loading, error }] = useDeleteWorkShiftTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteWorkShiftTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteWorkShiftTemplateMutation, DeleteWorkShiftTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteWorkShiftTemplateMutation, DeleteWorkShiftTemplateMutationVariables>(DeleteWorkShiftTemplateDocument, options);
      }
export type DeleteWorkShiftTemplateMutationHookResult = ReturnType<typeof useDeleteWorkShiftTemplateMutation>;
export type DeleteWorkShiftTemplateMutationResult = Apollo.MutationResult<DeleteWorkShiftTemplateMutation>;
export type DeleteWorkShiftTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteWorkShiftTemplateMutation, DeleteWorkShiftTemplateMutationVariables>;
export const EndTimerDocument = gql`
    mutation EndTimer($bookingId: ID!, $input: TimerTrackingInput!) {
  endTimer(bookingId: $bookingId, input: $input) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type EndTimerMutationFn = Apollo.MutationFunction<EndTimerMutation, EndTimerMutationVariables>;

/**
 * __useEndTimerMutation__
 *
 * To run a mutation, you first call `useEndTimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndTimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endTimerMutation, { data, loading, error }] = useEndTimerMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEndTimerMutation(baseOptions?: Apollo.MutationHookOptions<EndTimerMutation, EndTimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EndTimerMutation, EndTimerMutationVariables>(EndTimerDocument, options);
      }
export type EndTimerMutationHookResult = ReturnType<typeof useEndTimerMutation>;
export type EndTimerMutationResult = Apollo.MutationResult<EndTimerMutation>;
export type EndTimerMutationOptions = Apollo.BaseMutationOptions<EndTimerMutation, EndTimerMutationVariables>;
export const ExportBookingsCsvDocument = gql`
    mutation ExportBookingsCsv($filter: BookingFilter, $sorting: SortOrdering) {
  exportBookingsCSV(filter: $filter, sorting: $sorting)
}
    `;
export type ExportBookingsCsvMutationFn = Apollo.MutationFunction<ExportBookingsCsvMutation, ExportBookingsCsvMutationVariables>;

/**
 * __useExportBookingsCsvMutation__
 *
 * To run a mutation, you first call `useExportBookingsCsvMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useExportBookingsCsvMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [exportBookingsCsvMutation, { data, loading, error }] = useExportBookingsCsvMutation({
 *   variables: {
 *      filter: // value for 'filter'
 *      sorting: // value for 'sorting'
 *   },
 * });
 */
export function useExportBookingsCsvMutation(baseOptions?: Apollo.MutationHookOptions<ExportBookingsCsvMutation, ExportBookingsCsvMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ExportBookingsCsvMutation, ExportBookingsCsvMutationVariables>(ExportBookingsCsvDocument, options);
      }
export type ExportBookingsCsvMutationHookResult = ReturnType<typeof useExportBookingsCsvMutation>;
export type ExportBookingsCsvMutationResult = Apollo.MutationResult<ExportBookingsCsvMutation>;
export type ExportBookingsCsvMutationOptions = Apollo.BaseMutationOptions<ExportBookingsCsvMutation, ExportBookingsCsvMutationVariables>;
export const HideChannelDocument = gql`
    mutation HideChannel($id: ID!) {
  hideChannel(id: $id) {
    success
  }
}
    `;
export type HideChannelMutationFn = Apollo.MutationFunction<HideChannelMutation, HideChannelMutationVariables>;

/**
 * __useHideChannelMutation__
 *
 * To run a mutation, you first call `useHideChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideChannelMutation, { data, loading, error }] = useHideChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHideChannelMutation(baseOptions?: Apollo.MutationHookOptions<HideChannelMutation, HideChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideChannelMutation, HideChannelMutationVariables>(HideChannelDocument, options);
      }
export type HideChannelMutationHookResult = ReturnType<typeof useHideChannelMutation>;
export type HideChannelMutationResult = Apollo.MutationResult<HideChannelMutation>;
export type HideChannelMutationOptions = Apollo.BaseMutationOptions<HideChannelMutation, HideChannelMutationVariables>;
export const HideRecommendationDocument = gql`
    mutation HideRecommendation($id: ID!) {
  hideRecommendation(id: $id) {
    success
  }
}
    `;
export type HideRecommendationMutationFn = Apollo.MutationFunction<HideRecommendationMutation, HideRecommendationMutationVariables>;

/**
 * __useHideRecommendationMutation__
 *
 * To run a mutation, you first call `useHideRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHideRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [hideRecommendationMutation, { data, loading, error }] = useHideRecommendationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useHideRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<HideRecommendationMutation, HideRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HideRecommendationMutation, HideRecommendationMutationVariables>(HideRecommendationDocument, options);
      }
export type HideRecommendationMutationHookResult = ReturnType<typeof useHideRecommendationMutation>;
export type HideRecommendationMutationResult = Apollo.MutationResult<HideRecommendationMutation>;
export type HideRecommendationMutationOptions = Apollo.BaseMutationOptions<HideRecommendationMutation, HideRecommendationMutationVariables>;
export const InitChatGroupChannelDocument = gql`
    mutation InitChatGroupChannel($id: ID!) {
  initChatGroupChannel(id: $id) {
    chatGroup {
      closed
      createdAt
      id
      members {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      messages
      name
      receivers
      responses
      updatedAt
    }
    createdAt
    deletedAt
    id
    lastSentAt
    messages {
      channel {
        chatGroup {
          closed
          createdAt
          id
          messages
          name
          receivers
          responses
          updatedAt
        }
        createdAt
        deletedAt
        id
        lastSentAt
        messages {
          createdAt
          id
          message
          position
          senderName
          title
          updatedAt
        }
        name
        origin
        participants {
          lastReadAt
        }
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      extra {
        ... on Booking {
          acceptWith {
            total
          }
          acceptedAt
          approvedAt
          approvedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          approvedByResourceAt
          backOfficeMainStatus
          backOfficeSubStatus
          breakMinutes
          cancelPolicyPct
          cancelledAt
          confirmedCanceled
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          declinedAt
          endTime
          extraStatus
          history {
            breakMinutes
            endTime
            startTime
            status
            suggestedEnd
            suggestedStart
            updatedAt
            updatedById
          }
          id
          invoiced
          legacyId
          pricePoint {
            hourlySalaryBase
            hourlySalaryBoost
            hours
            totalPrice
            totalSalary
          }
          resource {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          resourceCategoryData {
            verticalProfileCategory
          }
          startTime
          status
          suggestedEnd
          suggestedStart
          suggestionAcceptedAt
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShiftId
          workShiftSlot {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            createdAt
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
          }
        }
      }
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      images {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      message
      position
      senderName
      title
      updatedAt
    }
    name
    origin
    participants {
      lastReadAt
      user {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    updatedAt
    workShift {
      acceptanceTime
      bookingType
      cancelledAt
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      description
      firstStartTime
      id
      internalName
      lastEndTime
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      minutesPriorStart
      postalCode
      region
      resourceQuantity
      resourceRegionalCategories {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      salaryBoost
      sameResources
      sentAt
      slots {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      status
      street
      templateId
      temporaryAddress {
        city
        id
        latitude
        longitude
        name
        postalCode
        region
        street
      }
      tips
      totalPrice
      travelExpenseCompensation
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftResourceCategories {
        levelIds
        levels {
          createdAt
          id
          name
          updatedAt
        }
        resourceCategories {
          id
          levelName
        }
        verticalProfileCategory
      }
    }
  }
}
    `;
export type InitChatGroupChannelMutationFn = Apollo.MutationFunction<InitChatGroupChannelMutation, InitChatGroupChannelMutationVariables>;

/**
 * __useInitChatGroupChannelMutation__
 *
 * To run a mutation, you first call `useInitChatGroupChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitChatGroupChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initChatGroupChannelMutation, { data, loading, error }] = useInitChatGroupChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useInitChatGroupChannelMutation(baseOptions?: Apollo.MutationHookOptions<InitChatGroupChannelMutation, InitChatGroupChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitChatGroupChannelMutation, InitChatGroupChannelMutationVariables>(InitChatGroupChannelDocument, options);
      }
export type InitChatGroupChannelMutationHookResult = ReturnType<typeof useInitChatGroupChannelMutation>;
export type InitChatGroupChannelMutationResult = Apollo.MutationResult<InitChatGroupChannelMutation>;
export type InitChatGroupChannelMutationOptions = Apollo.BaseMutationOptions<InitChatGroupChannelMutation, InitChatGroupChannelMutationVariables>;
export const InvertBroadcastDocument = gql`
    mutation InvertBroadcast($excludedUserIds: [ID!]!, $filter: UserFilter!, $message: String!, $title: String!) {
  invertBroadcast(
    excludedUserIds: $excludedUserIds
    filter: $filter
    message: $message
    title: $title
  ) {
    closed
    createdAt
    id
    members {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    messages
    name
    receivers
    responses
    updatedAt
  }
}
    `;
export type InvertBroadcastMutationFn = Apollo.MutationFunction<InvertBroadcastMutation, InvertBroadcastMutationVariables>;

/**
 * __useInvertBroadcastMutation__
 *
 * To run a mutation, you first call `useInvertBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInvertBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [invertBroadcastMutation, { data, loading, error }] = useInvertBroadcastMutation({
 *   variables: {
 *      excludedUserIds: // value for 'excludedUserIds'
 *      filter: // value for 'filter'
 *      message: // value for 'message'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useInvertBroadcastMutation(baseOptions?: Apollo.MutationHookOptions<InvertBroadcastMutation, InvertBroadcastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InvertBroadcastMutation, InvertBroadcastMutationVariables>(InvertBroadcastDocument, options);
      }
export type InvertBroadcastMutationHookResult = ReturnType<typeof useInvertBroadcastMutation>;
export type InvertBroadcastMutationResult = Apollo.MutationResult<InvertBroadcastMutation>;
export type InvertBroadcastMutationOptions = Apollo.BaseMutationOptions<InvertBroadcastMutation, InvertBroadcastMutationVariables>;
export const LoginDeviceDocument = gql`
    mutation LoginDevice($device: DeviceInput!, $email: String!, $password: String!) {
  loginDevice(device: $device, email: $email, password: $password) {
    jwt
    message
    newAccount
    refreshToken
    success
    user {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    userId
  }
}
    `;
export type LoginDeviceMutationFn = Apollo.MutationFunction<LoginDeviceMutation, LoginDeviceMutationVariables>;

/**
 * __useLoginDeviceMutation__
 *
 * To run a mutation, you first call `useLoginDeviceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginDeviceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginDeviceMutation, { data, loading, error }] = useLoginDeviceMutation({
 *   variables: {
 *      device: // value for 'device'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginDeviceMutation(baseOptions?: Apollo.MutationHookOptions<LoginDeviceMutation, LoginDeviceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginDeviceMutation, LoginDeviceMutationVariables>(LoginDeviceDocument, options);
      }
export type LoginDeviceMutationHookResult = ReturnType<typeof useLoginDeviceMutation>;
export type LoginDeviceMutationResult = Apollo.MutationResult<LoginDeviceMutation>;
export type LoginDeviceMutationOptions = Apollo.BaseMutationOptions<LoginDeviceMutation, LoginDeviceMutationVariables>;
export const LoginEmailDocument = gql`
    mutation LoginEmail($email: String!, $password: String!) {
  loginEmail(email: $email, password: $password) {
    jwt
    message
    newAccount
    refreshToken
    success
    user {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    userId
  }
}
    `;
export type LoginEmailMutationFn = Apollo.MutationFunction<LoginEmailMutation, LoginEmailMutationVariables>;

/**
 * __useLoginEmailMutation__
 *
 * To run a mutation, you first call `useLoginEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginEmailMutation, { data, loading, error }] = useLoginEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginEmailMutation(baseOptions?: Apollo.MutationHookOptions<LoginEmailMutation, LoginEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginEmailMutation, LoginEmailMutationVariables>(LoginEmailDocument, options);
      }
export type LoginEmailMutationHookResult = ReturnType<typeof useLoginEmailMutation>;
export type LoginEmailMutationResult = Apollo.MutationResult<LoginEmailMutation>;
export type LoginEmailMutationOptions = Apollo.BaseMutationOptions<LoginEmailMutation, LoginEmailMutationVariables>;
export const ManageUserDevicesDocument = gql`
    mutation ManageUserDevices($device: DeviceInput!, $operation: DeviceOperation!) {
  manageUserDevices(device: $device, operation: $operation) {
    success
  }
}
    `;
export type ManageUserDevicesMutationFn = Apollo.MutationFunction<ManageUserDevicesMutation, ManageUserDevicesMutationVariables>;

/**
 * __useManageUserDevicesMutation__
 *
 * To run a mutation, you first call `useManageUserDevicesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useManageUserDevicesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [manageUserDevicesMutation, { data, loading, error }] = useManageUserDevicesMutation({
 *   variables: {
 *      device: // value for 'device'
 *      operation: // value for 'operation'
 *   },
 * });
 */
export function useManageUserDevicesMutation(baseOptions?: Apollo.MutationHookOptions<ManageUserDevicesMutation, ManageUserDevicesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ManageUserDevicesMutation, ManageUserDevicesMutationVariables>(ManageUserDevicesDocument, options);
      }
export type ManageUserDevicesMutationHookResult = ReturnType<typeof useManageUserDevicesMutation>;
export type ManageUserDevicesMutationResult = Apollo.MutationResult<ManageUserDevicesMutation>;
export type ManageUserDevicesMutationOptions = Apollo.BaseMutationOptions<ManageUserDevicesMutation, ManageUserDevicesMutationVariables>;
export const MessageChatGroupDocument = gql`
    mutation MessageChatGroup($id: ID!, $message: String!) {
  messageChatGroup(id: $id, message: $message) {
    chatGroup {
      closed
      createdAt
      id
      members {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      messages
      name
      receivers
      responses
      updatedAt
    }
    createdAt
    deletedAt
    id
    lastSentAt
    messages {
      channel {
        chatGroup {
          closed
          createdAt
          id
          messages
          name
          receivers
          responses
          updatedAt
        }
        createdAt
        deletedAt
        id
        lastSentAt
        messages {
          createdAt
          id
          message
          position
          senderName
          title
          updatedAt
        }
        name
        origin
        participants {
          lastReadAt
        }
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      extra {
        ... on Booking {
          acceptWith {
            total
          }
          acceptedAt
          approvedAt
          approvedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          approvedByResourceAt
          backOfficeMainStatus
          backOfficeSubStatus
          breakMinutes
          cancelPolicyPct
          cancelledAt
          confirmedCanceled
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          declinedAt
          endTime
          extraStatus
          history {
            breakMinutes
            endTime
            startTime
            status
            suggestedEnd
            suggestedStart
            updatedAt
            updatedById
          }
          id
          invoiced
          legacyId
          pricePoint {
            hourlySalaryBase
            hourlySalaryBoost
            hours
            totalPrice
            totalSalary
          }
          resource {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          resourceCategoryData {
            verticalProfileCategory
          }
          startTime
          status
          suggestedEnd
          suggestedStart
          suggestionAcceptedAt
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShiftId
          workShiftSlot {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            createdAt
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
          }
        }
      }
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      images {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      message
      position
      senderName
      title
      updatedAt
    }
    name
    origin
    participants {
      lastReadAt
      user {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    updatedAt
    workShift {
      acceptanceTime
      bookingType
      cancelledAt
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      description
      firstStartTime
      id
      internalName
      lastEndTime
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      minutesPriorStart
      postalCode
      region
      resourceQuantity
      resourceRegionalCategories {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      salaryBoost
      sameResources
      sentAt
      slots {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      status
      street
      templateId
      temporaryAddress {
        city
        id
        latitude
        longitude
        name
        postalCode
        region
        street
      }
      tips
      totalPrice
      travelExpenseCompensation
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftResourceCategories {
        levelIds
        levels {
          createdAt
          id
          name
          updatedAt
        }
        resourceCategories {
          id
          levelName
        }
        verticalProfileCategory
      }
    }
  }
}
    `;
export type MessageChatGroupMutationFn = Apollo.MutationFunction<MessageChatGroupMutation, MessageChatGroupMutationVariables>;

/**
 * __useMessageChatGroupMutation__
 *
 * To run a mutation, you first call `useMessageChatGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageChatGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageChatGroupMutation, { data, loading, error }] = useMessageChatGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useMessageChatGroupMutation(baseOptions?: Apollo.MutationHookOptions<MessageChatGroupMutation, MessageChatGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageChatGroupMutation, MessageChatGroupMutationVariables>(MessageChatGroupDocument, options);
      }
export type MessageChatGroupMutationHookResult = ReturnType<typeof useMessageChatGroupMutation>;
export type MessageChatGroupMutationResult = Apollo.MutationResult<MessageChatGroupMutation>;
export type MessageChatGroupMutationOptions = Apollo.BaseMutationOptions<MessageChatGroupMutation, MessageChatGroupMutationVariables>;
export const MessageWorkShiftDocument = gql`
    mutation MessageWorkShift($id: ID!, $message: String!) {
  messageWorkShift(id: $id, message: $message) {
    chatGroup {
      closed
      createdAt
      id
      members {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      messages
      name
      receivers
      responses
      updatedAt
    }
    createdAt
    deletedAt
    id
    lastSentAt
    messages {
      channel {
        chatGroup {
          closed
          createdAt
          id
          messages
          name
          receivers
          responses
          updatedAt
        }
        createdAt
        deletedAt
        id
        lastSentAt
        messages {
          createdAt
          id
          message
          position
          senderName
          title
          updatedAt
        }
        name
        origin
        participants {
          lastReadAt
        }
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      extra {
        ... on Booking {
          acceptWith {
            total
          }
          acceptedAt
          approvedAt
          approvedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          approvedByResourceAt
          backOfficeMainStatus
          backOfficeSubStatus
          breakMinutes
          cancelPolicyPct
          cancelledAt
          confirmedCanceled
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          declinedAt
          endTime
          extraStatus
          history {
            breakMinutes
            endTime
            startTime
            status
            suggestedEnd
            suggestedStart
            updatedAt
            updatedById
          }
          id
          invoiced
          legacyId
          pricePoint {
            hourlySalaryBase
            hourlySalaryBoost
            hours
            totalPrice
            totalSalary
          }
          resource {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          resourceCategoryData {
            verticalProfileCategory
          }
          startTime
          status
          suggestedEnd
          suggestedStart
          suggestionAcceptedAt
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShiftId
          workShiftSlot {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            createdAt
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
          }
        }
      }
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      images {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      message
      position
      senderName
      title
      updatedAt
    }
    name
    origin
    participants {
      lastReadAt
      user {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    updatedAt
    workShift {
      acceptanceTime
      bookingType
      cancelledAt
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      description
      firstStartTime
      id
      internalName
      lastEndTime
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      minutesPriorStart
      postalCode
      region
      resourceQuantity
      resourceRegionalCategories {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      salaryBoost
      sameResources
      sentAt
      slots {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      status
      street
      templateId
      temporaryAddress {
        city
        id
        latitude
        longitude
        name
        postalCode
        region
        street
      }
      tips
      totalPrice
      travelExpenseCompensation
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftResourceCategories {
        levelIds
        levels {
          createdAt
          id
          name
          updatedAt
        }
        resourceCategories {
          id
          levelName
        }
        verticalProfileCategory
      }
    }
  }
}
    `;
export type MessageWorkShiftMutationFn = Apollo.MutationFunction<MessageWorkShiftMutation, MessageWorkShiftMutationVariables>;

/**
 * __useMessageWorkShiftMutation__
 *
 * To run a mutation, you first call `useMessageWorkShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMessageWorkShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [messageWorkShiftMutation, { data, loading, error }] = useMessageWorkShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *   },
 * });
 */
export function useMessageWorkShiftMutation(baseOptions?: Apollo.MutationHookOptions<MessageWorkShiftMutation, MessageWorkShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<MessageWorkShiftMutation, MessageWorkShiftMutationVariables>(MessageWorkShiftDocument, options);
      }
export type MessageWorkShiftMutationHookResult = ReturnType<typeof useMessageWorkShiftMutation>;
export type MessageWorkShiftMutationResult = Apollo.MutationResult<MessageWorkShiftMutation>;
export type MessageWorkShiftMutationOptions = Apollo.BaseMutationOptions<MessageWorkShiftMutation, MessageWorkShiftMutationVariables>;
export const ReSendUserInviteDocument = gql`
    mutation ReSendUserInvite($id: ID!) {
  reSendUserInvite(id: $id) {
    success
  }
}
    `;
export type ReSendUserInviteMutationFn = Apollo.MutationFunction<ReSendUserInviteMutation, ReSendUserInviteMutationVariables>;

/**
 * __useReSendUserInviteMutation__
 *
 * To run a mutation, you first call `useReSendUserInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReSendUserInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reSendUserInviteMutation, { data, loading, error }] = useReSendUserInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useReSendUserInviteMutation(baseOptions?: Apollo.MutationHookOptions<ReSendUserInviteMutation, ReSendUserInviteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReSendUserInviteMutation, ReSendUserInviteMutationVariables>(ReSendUserInviteDocument, options);
      }
export type ReSendUserInviteMutationHookResult = ReturnType<typeof useReSendUserInviteMutation>;
export type ReSendUserInviteMutationResult = Apollo.MutationResult<ReSendUserInviteMutation>;
export type ReSendUserInviteMutationOptions = Apollo.BaseMutationOptions<ReSendUserInviteMutation, ReSendUserInviteMutationVariables>;
export const RecommendLocationDocument = gql`
    mutation RecommendLocation($input: UpsertRecommendation!) {
  recommendLocation(input: $input) {
    createdAt
    description
    hidden
    id
    location {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    updatedAt
  }
}
    `;
export type RecommendLocationMutationFn = Apollo.MutationFunction<RecommendLocationMutation, RecommendLocationMutationVariables>;

/**
 * __useRecommendLocationMutation__
 *
 * To run a mutation, you first call `useRecommendLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecommendLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recommendLocationMutation, { data, loading, error }] = useRecommendLocationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRecommendLocationMutation(baseOptions?: Apollo.MutationHookOptions<RecommendLocationMutation, RecommendLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecommendLocationMutation, RecommendLocationMutationVariables>(RecommendLocationDocument, options);
      }
export type RecommendLocationMutationHookResult = ReturnType<typeof useRecommendLocationMutation>;
export type RecommendLocationMutationResult = Apollo.MutationResult<RecommendLocationMutation>;
export type RecommendLocationMutationOptions = Apollo.BaseMutationOptions<RecommendLocationMutation, RecommendLocationMutationVariables>;
export const RefreshSessionDocument = gql`
    mutation RefreshSession($refreshToken: String!, $userId: String!) {
  refreshSession(refreshToken: $refreshToken, userId: $userId) {
    jwt
    message
    newAccount
    refreshToken
    success
    user {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    userId
  }
}
    `;
export type RefreshSessionMutationFn = Apollo.MutationFunction<RefreshSessionMutation, RefreshSessionMutationVariables>;

/**
 * __useRefreshSessionMutation__
 *
 * To run a mutation, you first call `useRefreshSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshSessionMutation, { data, loading, error }] = useRefreshSessionMutation({
 *   variables: {
 *      refreshToken: // value for 'refreshToken'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useRefreshSessionMutation(baseOptions?: Apollo.MutationHookOptions<RefreshSessionMutation, RefreshSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RefreshSessionMutation, RefreshSessionMutationVariables>(RefreshSessionDocument, options);
      }
export type RefreshSessionMutationHookResult = ReturnType<typeof useRefreshSessionMutation>;
export type RefreshSessionMutationResult = Apollo.MutationResult<RefreshSessionMutation>;
export type RefreshSessionMutationOptions = Apollo.BaseMutationOptions<RefreshSessionMutation, RefreshSessionMutationVariables>;
export const RemoveUsersFromResourceCategoryDocument = gql`
    mutation RemoveUsersFromResourceCategory($id: ID!, $input: UpdateResourcesInput!) {
  removeUsersFromResourceCategory(id: $id, input: $input) {
    createdAt
    defaultSalary
    id
    level {
      createdAt
      id
      name
      updatedAt
    }
    name
    regional {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    updatedAt
    vertical
  }
}
    `;
export type RemoveUsersFromResourceCategoryMutationFn = Apollo.MutationFunction<RemoveUsersFromResourceCategoryMutation, RemoveUsersFromResourceCategoryMutationVariables>;

/**
 * __useRemoveUsersFromResourceCategoryMutation__
 *
 * To run a mutation, you first call `useRemoveUsersFromResourceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUsersFromResourceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUsersFromResourceCategoryMutation, { data, loading, error }] = useRemoveUsersFromResourceCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveUsersFromResourceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<RemoveUsersFromResourceCategoryMutation, RemoveUsersFromResourceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveUsersFromResourceCategoryMutation, RemoveUsersFromResourceCategoryMutationVariables>(RemoveUsersFromResourceCategoryDocument, options);
      }
export type RemoveUsersFromResourceCategoryMutationHookResult = ReturnType<typeof useRemoveUsersFromResourceCategoryMutation>;
export type RemoveUsersFromResourceCategoryMutationResult = Apollo.MutationResult<RemoveUsersFromResourceCategoryMutation>;
export type RemoveUsersFromResourceCategoryMutationOptions = Apollo.BaseMutationOptions<RemoveUsersFromResourceCategoryMutation, RemoveUsersFromResourceCategoryMutationVariables>;
export const ReportNoShowDocument = gql`
    mutation ReportNoShow($bookingId: ID!, $undo: Boolean) {
  reportNoShow(bookingId: $bookingId, undo: $undo) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type ReportNoShowMutationFn = Apollo.MutationFunction<ReportNoShowMutation, ReportNoShowMutationVariables>;

/**
 * __useReportNoShowMutation__
 *
 * To run a mutation, you first call `useReportNoShowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportNoShowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportNoShowMutation, { data, loading, error }] = useReportNoShowMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      undo: // value for 'undo'
 *   },
 * });
 */
export function useReportNoShowMutation(baseOptions?: Apollo.MutationHookOptions<ReportNoShowMutation, ReportNoShowMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportNoShowMutation, ReportNoShowMutationVariables>(ReportNoShowDocument, options);
      }
export type ReportNoShowMutationHookResult = ReturnType<typeof useReportNoShowMutation>;
export type ReportNoShowMutationResult = Apollo.MutationResult<ReportNoShowMutation>;
export type ReportNoShowMutationOptions = Apollo.BaseMutationOptions<ReportNoShowMutation, ReportNoShowMutationVariables>;
export const ReportSickDocument = gql`
    mutation ReportSick($bookingId: ID!, $undo: Boolean, $undoOption: UndoReportSickSlotFullOptions) {
  reportSick(bookingId: $bookingId, undo: $undo, undoOption: $undoOption) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type ReportSickMutationFn = Apollo.MutationFunction<ReportSickMutation, ReportSickMutationVariables>;

/**
 * __useReportSickMutation__
 *
 * To run a mutation, you first call `useReportSickMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportSickMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportSickMutation, { data, loading, error }] = useReportSickMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      undo: // value for 'undo'
 *      undoOption: // value for 'undoOption'
 *   },
 * });
 */
export function useReportSickMutation(baseOptions?: Apollo.MutationHookOptions<ReportSickMutation, ReportSickMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportSickMutation, ReportSickMutationVariables>(ReportSickDocument, options);
      }
export type ReportSickMutationHookResult = ReturnType<typeof useReportSickMutation>;
export type ReportSickMutationResult = Apollo.MutationResult<ReportSickMutation>;
export type ReportSickMutationOptions = Apollo.BaseMutationOptions<ReportSickMutation, ReportSickMutationVariables>;
export const ResendBroadcastDocument = gql`
    mutation ResendBroadcast($id: ID!, $message: String!, $new: Boolean!, $title: String) {
  resendBroadcast(id: $id, message: $message, new: $new, title: $title) {
    closed
    createdAt
    id
    members {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    messages
    name
    receivers
    responses
    updatedAt
  }
}
    `;
export type ResendBroadcastMutationFn = Apollo.MutationFunction<ResendBroadcastMutation, ResendBroadcastMutationVariables>;

/**
 * __useResendBroadcastMutation__
 *
 * To run a mutation, you first call `useResendBroadcastMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendBroadcastMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendBroadcastMutation, { data, loading, error }] = useResendBroadcastMutation({
 *   variables: {
 *      id: // value for 'id'
 *      message: // value for 'message'
 *      new: // value for 'new'
 *      title: // value for 'title'
 *   },
 * });
 */
export function useResendBroadcastMutation(baseOptions?: Apollo.MutationHookOptions<ResendBroadcastMutation, ResendBroadcastMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendBroadcastMutation, ResendBroadcastMutationVariables>(ResendBroadcastDocument, options);
      }
export type ResendBroadcastMutationHookResult = ReturnType<typeof useResendBroadcastMutation>;
export type ResendBroadcastMutationResult = Apollo.MutationResult<ResendBroadcastMutation>;
export type ResendBroadcastMutationOptions = Apollo.BaseMutationOptions<ResendBroadcastMutation, ResendBroadcastMutationVariables>;
export const ResendVerifyEmailDocument = gql`
    mutation ResendVerifyEmail($id: ID!) {
  resendVerifyEmail(id: $id) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type ResendVerifyEmailMutationFn = Apollo.MutationFunction<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>;

/**
 * __useResendVerifyEmailMutation__
 *
 * To run a mutation, you first call `useResendVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendVerifyEmailMutation, { data, loading, error }] = useResendVerifyEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useResendVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>(ResendVerifyEmailDocument, options);
      }
export type ResendVerifyEmailMutationHookResult = ReturnType<typeof useResendVerifyEmailMutation>;
export type ResendVerifyEmailMutationResult = Apollo.MutationResult<ResendVerifyEmailMutation>;
export type ResendVerifyEmailMutationOptions = Apollo.BaseMutationOptions<ResendVerifyEmailMutation, ResendVerifyEmailMutationVariables>;
export const ResetPasswordFinalizeDocument = gql`
    mutation ResetPasswordFinalize($input: ResetPasswordFinalizeInput!) {
  resetPasswordFinalize(input: $input) {
    jwt
    message
    newAccount
    refreshToken
    success
    user {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    userId
  }
}
    `;
export type ResetPasswordFinalizeMutationFn = Apollo.MutationFunction<ResetPasswordFinalizeMutation, ResetPasswordFinalizeMutationVariables>;

/**
 * __useResetPasswordFinalizeMutation__
 *
 * To run a mutation, you first call `useResetPasswordFinalizeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordFinalizeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordFinalizeMutation, { data, loading, error }] = useResetPasswordFinalizeMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordFinalizeMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordFinalizeMutation, ResetPasswordFinalizeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordFinalizeMutation, ResetPasswordFinalizeMutationVariables>(ResetPasswordFinalizeDocument, options);
      }
export type ResetPasswordFinalizeMutationHookResult = ReturnType<typeof useResetPasswordFinalizeMutation>;
export type ResetPasswordFinalizeMutationResult = Apollo.MutationResult<ResetPasswordFinalizeMutation>;
export type ResetPasswordFinalizeMutationOptions = Apollo.BaseMutationOptions<ResetPasswordFinalizeMutation, ResetPasswordFinalizeMutationVariables>;
export const ResetPasswordInitiateDocument = gql`
    mutation ResetPasswordInitiate($email: String!) {
  resetPasswordInitiate(email: $email) {
    success
  }
}
    `;
export type ResetPasswordInitiateMutationFn = Apollo.MutationFunction<ResetPasswordInitiateMutation, ResetPasswordInitiateMutationVariables>;

/**
 * __useResetPasswordInitiateMutation__
 *
 * To run a mutation, you first call `useResetPasswordInitiateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordInitiateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordInitiateMutation, { data, loading, error }] = useResetPasswordInitiateMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordInitiateMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordInitiateMutation, ResetPasswordInitiateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordInitiateMutation, ResetPasswordInitiateMutationVariables>(ResetPasswordInitiateDocument, options);
      }
export type ResetPasswordInitiateMutationHookResult = ReturnType<typeof useResetPasswordInitiateMutation>;
export type ResetPasswordInitiateMutationResult = Apollo.MutationResult<ResetPasswordInitiateMutation>;
export type ResetPasswordInitiateMutationOptions = Apollo.BaseMutationOptions<ResetPasswordInitiateMutation, ResetPasswordInitiateMutationVariables>;
export const SendMessageDocument = gql`
    mutation SendMessage($input: MessageInput!) {
  sendMessage(input: $input) {
    success
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const SendNotificationDocument = gql`
    mutation SendNotification($admins: Boolean!, $companyId: ID, $input: NotificationInput!, $locationId: ID, $receiver: ID!) {
  sendNotification(
    admins: $admins
    companyId: $companyId
    input: $input
    locationId: $locationId
    receiver: $receiver
  ) {
    success
  }
}
    `;
export type SendNotificationMutationFn = Apollo.MutationFunction<SendNotificationMutation, SendNotificationMutationVariables>;

/**
 * __useSendNotificationMutation__
 *
 * To run a mutation, you first call `useSendNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendNotificationMutation, { data, loading, error }] = useSendNotificationMutation({
 *   variables: {
 *      admins: // value for 'admins'
 *      companyId: // value for 'companyId'
 *      input: // value for 'input'
 *      locationId: // value for 'locationId'
 *      receiver: // value for 'receiver'
 *   },
 * });
 */
export function useSendNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendNotificationMutation, SendNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendNotificationMutation, SendNotificationMutationVariables>(SendNotificationDocument, options);
      }
export type SendNotificationMutationHookResult = ReturnType<typeof useSendNotificationMutation>;
export type SendNotificationMutationResult = Apollo.MutationResult<SendNotificationMutation>;
export type SendNotificationMutationOptions = Apollo.BaseMutationOptions<SendNotificationMutation, SendNotificationMutationVariables>;
export const SendWorkShiftSlotInvitationsDocument = gql`
    mutation SendWorkShiftSlotInvitations($input: WorkShiftSlotsInvitationInput!) {
  sendWorkShiftSlotInvitations(input: $input) {
    success
  }
}
    `;
export type SendWorkShiftSlotInvitationsMutationFn = Apollo.MutationFunction<SendWorkShiftSlotInvitationsMutation, SendWorkShiftSlotInvitationsMutationVariables>;

/**
 * __useSendWorkShiftSlotInvitationsMutation__
 *
 * To run a mutation, you first call `useSendWorkShiftSlotInvitationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendWorkShiftSlotInvitationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendWorkShiftSlotInvitationsMutation, { data, loading, error }] = useSendWorkShiftSlotInvitationsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSendWorkShiftSlotInvitationsMutation(baseOptions?: Apollo.MutationHookOptions<SendWorkShiftSlotInvitationsMutation, SendWorkShiftSlotInvitationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendWorkShiftSlotInvitationsMutation, SendWorkShiftSlotInvitationsMutationVariables>(SendWorkShiftSlotInvitationsDocument, options);
      }
export type SendWorkShiftSlotInvitationsMutationHookResult = ReturnType<typeof useSendWorkShiftSlotInvitationsMutation>;
export type SendWorkShiftSlotInvitationsMutationResult = Apollo.MutationResult<SendWorkShiftSlotInvitationsMutation>;
export type SendWorkShiftSlotInvitationsMutationOptions = Apollo.BaseMutationOptions<SendWorkShiftSlotInvitationsMutation, SendWorkShiftSlotInvitationsMutationVariables>;
export const SetIgnoreLocationDocument = gql`
    mutation SetIgnoreLocation($id: ID!, $ignore: Boolean!) {
  setIgnoreLocation(id: $id, ignore: $ignore) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type SetIgnoreLocationMutationFn = Apollo.MutationFunction<SetIgnoreLocationMutation, SetIgnoreLocationMutationVariables>;

/**
 * __useSetIgnoreLocationMutation__
 *
 * To run a mutation, you first call `useSetIgnoreLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetIgnoreLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setIgnoreLocationMutation, { data, loading, error }] = useSetIgnoreLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      ignore: // value for 'ignore'
 *   },
 * });
 */
export function useSetIgnoreLocationMutation(baseOptions?: Apollo.MutationHookOptions<SetIgnoreLocationMutation, SetIgnoreLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetIgnoreLocationMutation, SetIgnoreLocationMutationVariables>(SetIgnoreLocationDocument, options);
      }
export type SetIgnoreLocationMutationHookResult = ReturnType<typeof useSetIgnoreLocationMutation>;
export type SetIgnoreLocationMutationResult = Apollo.MutationResult<SetIgnoreLocationMutation>;
export type SetIgnoreLocationMutationOptions = Apollo.BaseMutationOptions<SetIgnoreLocationMutation, SetIgnoreLocationMutationVariables>;
export const SetInvoicedDocument = gql`
    mutation SetInvoiced($bookingIds: [ID!]!) {
  setInvoiced(bookingIds: $bookingIds) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type SetInvoicedMutationFn = Apollo.MutationFunction<SetInvoicedMutation, SetInvoicedMutationVariables>;

/**
 * __useSetInvoicedMutation__
 *
 * To run a mutation, you first call `useSetInvoicedMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetInvoicedMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setInvoicedMutation, { data, loading, error }] = useSetInvoicedMutation({
 *   variables: {
 *      bookingIds: // value for 'bookingIds'
 *   },
 * });
 */
export function useSetInvoicedMutation(baseOptions?: Apollo.MutationHookOptions<SetInvoicedMutation, SetInvoicedMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetInvoicedMutation, SetInvoicedMutationVariables>(SetInvoicedDocument, options);
      }
export type SetInvoicedMutationHookResult = ReturnType<typeof useSetInvoicedMutation>;
export type SetInvoicedMutationResult = Apollo.MutationResult<SetInvoicedMutation>;
export type SetInvoicedMutationOptions = Apollo.BaseMutationOptions<SetInvoicedMutation, SetInvoicedMutationVariables>;
export const SetLocationFavouriteDocument = gql`
    mutation SetLocationFavourite($favourite: Boolean!, $id: ID!, $userId: ID!) {
  setLocationFavourite(favourite: $favourite, id: $id, userId: $userId) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type SetLocationFavouriteMutationFn = Apollo.MutationFunction<SetLocationFavouriteMutation, SetLocationFavouriteMutationVariables>;

/**
 * __useSetLocationFavouriteMutation__
 *
 * To run a mutation, you first call `useSetLocationFavouriteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLocationFavouriteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLocationFavouriteMutation, { data, loading, error }] = useSetLocationFavouriteMutation({
 *   variables: {
 *      favourite: // value for 'favourite'
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetLocationFavouriteMutation(baseOptions?: Apollo.MutationHookOptions<SetLocationFavouriteMutation, SetLocationFavouriteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLocationFavouriteMutation, SetLocationFavouriteMutationVariables>(SetLocationFavouriteDocument, options);
      }
export type SetLocationFavouriteMutationHookResult = ReturnType<typeof useSetLocationFavouriteMutation>;
export type SetLocationFavouriteMutationResult = Apollo.MutationResult<SetLocationFavouriteMutation>;
export type SetLocationFavouriteMutationOptions = Apollo.BaseMutationOptions<SetLocationFavouriteMutation, SetLocationFavouriteMutationVariables>;
export const SetLocationHiddenDocument = gql`
    mutation SetLocationHidden($hidden: Boolean!, $id: ID!, $userId: ID!) {
  setLocationHidden(hidden: $hidden, id: $id, userId: $userId) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type SetLocationHiddenMutationFn = Apollo.MutationFunction<SetLocationHiddenMutation, SetLocationHiddenMutationVariables>;

/**
 * __useSetLocationHiddenMutation__
 *
 * To run a mutation, you first call `useSetLocationHiddenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetLocationHiddenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setLocationHiddenMutation, { data, loading, error }] = useSetLocationHiddenMutation({
 *   variables: {
 *      hidden: // value for 'hidden'
 *      id: // value for 'id'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetLocationHiddenMutation(baseOptions?: Apollo.MutationHookOptions<SetLocationHiddenMutation, SetLocationHiddenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetLocationHiddenMutation, SetLocationHiddenMutationVariables>(SetLocationHiddenDocument, options);
      }
export type SetLocationHiddenMutationHookResult = ReturnType<typeof useSetLocationHiddenMutation>;
export type SetLocationHiddenMutationResult = Apollo.MutationResult<SetLocationHiddenMutation>;
export type SetLocationHiddenMutationOptions = Apollo.BaseMutationOptions<SetLocationHiddenMutation, SetLocationHiddenMutationVariables>;
export const SetMessagesAsReadDocument = gql`
    mutation SetMessagesAsRead($channelId: ID!) {
  setMessagesAsRead(channelId: $channelId) {
    success
  }
}
    `;
export type SetMessagesAsReadMutationFn = Apollo.MutationFunction<SetMessagesAsReadMutation, SetMessagesAsReadMutationVariables>;

/**
 * __useSetMessagesAsReadMutation__
 *
 * To run a mutation, you first call `useSetMessagesAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetMessagesAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setMessagesAsReadMutation, { data, loading, error }] = useSetMessagesAsReadMutation({
 *   variables: {
 *      channelId: // value for 'channelId'
 *   },
 * });
 */
export function useSetMessagesAsReadMutation(baseOptions?: Apollo.MutationHookOptions<SetMessagesAsReadMutation, SetMessagesAsReadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetMessagesAsReadMutation, SetMessagesAsReadMutationVariables>(SetMessagesAsReadDocument, options);
      }
export type SetMessagesAsReadMutationHookResult = ReturnType<typeof useSetMessagesAsReadMutation>;
export type SetMessagesAsReadMutationResult = Apollo.MutationResult<SetMessagesAsReadMutation>;
export type SetMessagesAsReadMutationOptions = Apollo.BaseMutationOptions<SetMessagesAsReadMutation, SetMessagesAsReadMutationVariables>;
export const StartTimerDocument = gql`
    mutation StartTimer($bookingId: ID!, $input: TimerTrackingInput!) {
  startTimer(bookingId: $bookingId, input: $input) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type StartTimerMutationFn = Apollo.MutationFunction<StartTimerMutation, StartTimerMutationVariables>;

/**
 * __useStartTimerMutation__
 *
 * To run a mutation, you first call `useStartTimerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTimerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTimerMutation, { data, loading, error }] = useStartTimerMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useStartTimerMutation(baseOptions?: Apollo.MutationHookOptions<StartTimerMutation, StartTimerMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<StartTimerMutation, StartTimerMutationVariables>(StartTimerDocument, options);
      }
export type StartTimerMutationHookResult = ReturnType<typeof useStartTimerMutation>;
export type StartTimerMutationResult = Apollo.MutationResult<StartTimerMutation>;
export type StartTimerMutationOptions = Apollo.BaseMutationOptions<StartTimerMutation, StartTimerMutationVariables>;
export const SuggestNewTimesDocument = gql`
    mutation SuggestNewTimes($input: [SuggestTimeInput!]!) {
  suggestNewTimes(input: $input) {
    error
    notAvailable
    success
    updated {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
  }
}
    `;
export type SuggestNewTimesMutationFn = Apollo.MutationFunction<SuggestNewTimesMutation, SuggestNewTimesMutationVariables>;

/**
 * __useSuggestNewTimesMutation__
 *
 * To run a mutation, you first call `useSuggestNewTimesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSuggestNewTimesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [suggestNewTimesMutation, { data, loading, error }] = useSuggestNewTimesMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSuggestNewTimesMutation(baseOptions?: Apollo.MutationHookOptions<SuggestNewTimesMutation, SuggestNewTimesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SuggestNewTimesMutation, SuggestNewTimesMutationVariables>(SuggestNewTimesDocument, options);
      }
export type SuggestNewTimesMutationHookResult = ReturnType<typeof useSuggestNewTimesMutation>;
export type SuggestNewTimesMutationResult = Apollo.MutationResult<SuggestNewTimesMutation>;
export type SuggestNewTimesMutationOptions = Apollo.BaseMutationOptions<SuggestNewTimesMutation, SuggestNewTimesMutationVariables>;
export const ToggleBlockUserDocument = gql`
    mutation ToggleBlockUser($id: ID!) {
  toggleBlockUser(id: $id) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type ToggleBlockUserMutationFn = Apollo.MutationFunction<ToggleBlockUserMutation, ToggleBlockUserMutationVariables>;

/**
 * __useToggleBlockUserMutation__
 *
 * To run a mutation, you first call `useToggleBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleBlockUserMutation, { data, loading, error }] = useToggleBlockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useToggleBlockUserMutation(baseOptions?: Apollo.MutationHookOptions<ToggleBlockUserMutation, ToggleBlockUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ToggleBlockUserMutation, ToggleBlockUserMutationVariables>(ToggleBlockUserDocument, options);
      }
export type ToggleBlockUserMutationHookResult = ReturnType<typeof useToggleBlockUserMutation>;
export type ToggleBlockUserMutationResult = Apollo.MutationResult<ToggleBlockUserMutation>;
export type ToggleBlockUserMutationOptions = Apollo.BaseMutationOptions<ToggleBlockUserMutation, ToggleBlockUserMutationVariables>;
export const UndoDeclineBookingsDocument = gql`
    mutation UndoDeclineBookings($input: [ID!]!) {
  undoDeclineBookings(input: $input) {
    error
    mandatoryMissing
    passedDue
    success
    updated {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
  }
}
    `;
export type UndoDeclineBookingsMutationFn = Apollo.MutationFunction<UndoDeclineBookingsMutation, UndoDeclineBookingsMutationVariables>;

/**
 * __useUndoDeclineBookingsMutation__
 *
 * To run a mutation, you first call `useUndoDeclineBookingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndoDeclineBookingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undoDeclineBookingsMutation, { data, loading, error }] = useUndoDeclineBookingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUndoDeclineBookingsMutation(baseOptions?: Apollo.MutationHookOptions<UndoDeclineBookingsMutation, UndoDeclineBookingsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UndoDeclineBookingsMutation, UndoDeclineBookingsMutationVariables>(UndoDeclineBookingsDocument, options);
      }
export type UndoDeclineBookingsMutationHookResult = ReturnType<typeof useUndoDeclineBookingsMutation>;
export type UndoDeclineBookingsMutationResult = Apollo.MutationResult<UndoDeclineBookingsMutation>;
export type UndoDeclineBookingsMutationOptions = Apollo.BaseMutationOptions<UndoDeclineBookingsMutation, UndoDeclineBookingsMutationVariables>;
export const UpdateChannelDocument = gql`
    mutation UpdateChannel($id: ID!, $input: UpdateChannelInput!) {
  updateChannel(id: $id, input: $input) {
    chatGroup {
      closed
      createdAt
      id
      members {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      messages
      name
      receivers
      responses
      updatedAt
    }
    createdAt
    deletedAt
    id
    lastSentAt
    messages {
      channel {
        chatGroup {
          closed
          createdAt
          id
          messages
          name
          receivers
          responses
          updatedAt
        }
        createdAt
        deletedAt
        id
        lastSentAt
        messages {
          createdAt
          id
          message
          position
          senderName
          title
          updatedAt
        }
        name
        origin
        participants {
          lastReadAt
        }
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      extra {
        ... on Booking {
          acceptWith {
            total
          }
          acceptedAt
          approvedAt
          approvedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          approvedByResourceAt
          backOfficeMainStatus
          backOfficeSubStatus
          breakMinutes
          cancelPolicyPct
          cancelledAt
          confirmedCanceled
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          declinedAt
          endTime
          extraStatus
          history {
            breakMinutes
            endTime
            startTime
            status
            suggestedEnd
            suggestedStart
            updatedAt
            updatedById
          }
          id
          invoiced
          legacyId
          pricePoint {
            hourlySalaryBase
            hourlySalaryBoost
            hours
            totalPrice
            totalSalary
          }
          resource {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          resourceCategoryData {
            verticalProfileCategory
          }
          startTime
          status
          suggestedEnd
          suggestedStart
          suggestionAcceptedAt
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShiftId
          workShiftSlot {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            createdAt
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
          }
        }
      }
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      images {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      message
      position
      senderName
      title
      updatedAt
    }
    name
    origin
    participants {
      lastReadAt
      user {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    updatedAt
    workShift {
      acceptanceTime
      bookingType
      cancelledAt
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      description
      firstStartTime
      id
      internalName
      lastEndTime
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      minutesPriorStart
      postalCode
      region
      resourceQuantity
      resourceRegionalCategories {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      salaryBoost
      sameResources
      sentAt
      slots {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      status
      street
      templateId
      temporaryAddress {
        city
        id
        latitude
        longitude
        name
        postalCode
        region
        street
      }
      tips
      totalPrice
      travelExpenseCompensation
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftResourceCategories {
        levelIds
        levels {
          createdAt
          id
          name
          updatedAt
        }
        resourceCategories {
          id
          levelName
        }
        verticalProfileCategory
      }
    }
  }
}
    `;
export type UpdateChannelMutationFn = Apollo.MutationFunction<UpdateChannelMutation, UpdateChannelMutationVariables>;

/**
 * __useUpdateChannelMutation__
 *
 * To run a mutation, you first call `useUpdateChannelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateChannelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateChannelMutation, { data, loading, error }] = useUpdateChannelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateChannelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateChannelMutation, UpdateChannelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateChannelMutation, UpdateChannelMutationVariables>(UpdateChannelDocument, options);
      }
export type UpdateChannelMutationHookResult = ReturnType<typeof useUpdateChannelMutation>;
export type UpdateChannelMutationResult = Apollo.MutationResult<UpdateChannelMutation>;
export type UpdateChannelMutationOptions = Apollo.BaseMutationOptions<UpdateChannelMutation, UpdateChannelMutationVariables>;
export const UpdateCompanyDocument = gql`
    mutation UpdateCompany($id: ID!, $input: UpdateCompanyInput!) {
  updateCompany(id: $id, input: $input) {
    active
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    category
    city
    commission
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    id
    latitude
    longitude
    name
    organisationNumber
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    street
    updatedAt
    web
  }
}
    `;
export type UpdateCompanyMutationFn = Apollo.MutationFunction<UpdateCompanyMutation, UpdateCompanyMutationVariables>;

/**
 * __useUpdateCompanyMutation__
 *
 * To run a mutation, you first call `useUpdateCompanyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCompanyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCompanyMutation, { data, loading, error }] = useUpdateCompanyMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCompanyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCompanyMutation, UpdateCompanyMutationVariables>(UpdateCompanyDocument, options);
      }
export type UpdateCompanyMutationHookResult = ReturnType<typeof useUpdateCompanyMutation>;
export type UpdateCompanyMutationResult = Apollo.MutationResult<UpdateCompanyMutation>;
export type UpdateCompanyMutationOptions = Apollo.BaseMutationOptions<UpdateCompanyMutation, UpdateCompanyMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($id: ID!, $input: UpdateLocationInput!) {
  updateLocation(id: $id, input: $input) {
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    city
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    latitude
    legacyCustomerId
    logo {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    longitude
    name
    organisationNumber
    postalCode
    region
    street
    updatedAt
    web
  }
}
    `;
export type UpdateLocationMutationFn = Apollo.MutationFunction<UpdateLocationMutation, UpdateLocationMutationVariables>;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationMutation, { data, loading, error }] = useUpdateLocationMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationMutation, UpdateLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
      }
export type UpdateLocationMutationHookResult = ReturnType<typeof useUpdateLocationMutation>;
export type UpdateLocationMutationResult = Apollo.MutationResult<UpdateLocationMutation>;
export type UpdateLocationMutationOptions = Apollo.BaseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const UpdateLocationFilesDocument = gql`
    mutation UpdateLocationFiles($id: ID!, $input: FilesUpdate!) {
  updateLocationFiles(id: $id, input: $input) {
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    city
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    latitude
    legacyCustomerId
    logo {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    longitude
    name
    organisationNumber
    postalCode
    region
    street
    updatedAt
    web
  }
}
    `;
export type UpdateLocationFilesMutationFn = Apollo.MutationFunction<UpdateLocationFilesMutation, UpdateLocationFilesMutationVariables>;

/**
 * __useUpdateLocationFilesMutation__
 *
 * To run a mutation, you first call `useUpdateLocationFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationFilesMutation, { data, loading, error }] = useUpdateLocationFilesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationFilesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationFilesMutation, UpdateLocationFilesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationFilesMutation, UpdateLocationFilesMutationVariables>(UpdateLocationFilesDocument, options);
      }
export type UpdateLocationFilesMutationHookResult = ReturnType<typeof useUpdateLocationFilesMutation>;
export type UpdateLocationFilesMutationResult = Apollo.MutationResult<UpdateLocationFilesMutation>;
export type UpdateLocationFilesMutationOptions = Apollo.BaseMutationOptions<UpdateLocationFilesMutation, UpdateLocationFilesMutationVariables>;
export const UpdateLocationImagesDocument = gql`
    mutation UpdateLocationImages($id: ID!, $input: UpdateLocationImages) {
  updateLocationImages(id: $id, input: $input) {
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    city
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    latitude
    legacyCustomerId
    logo {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    longitude
    name
    organisationNumber
    postalCode
    region
    street
    updatedAt
    web
  }
}
    `;
export type UpdateLocationImagesMutationFn = Apollo.MutationFunction<UpdateLocationImagesMutation, UpdateLocationImagesMutationVariables>;

/**
 * __useUpdateLocationImagesMutation__
 *
 * To run a mutation, you first call `useUpdateLocationImagesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationImagesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLocationImagesMutation, { data, loading, error }] = useUpdateLocationImagesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateLocationImagesMutation(baseOptions?: Apollo.MutationHookOptions<UpdateLocationImagesMutation, UpdateLocationImagesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateLocationImagesMutation, UpdateLocationImagesMutationVariables>(UpdateLocationImagesDocument, options);
      }
export type UpdateLocationImagesMutationHookResult = ReturnType<typeof useUpdateLocationImagesMutation>;
export type UpdateLocationImagesMutationResult = Apollo.MutationResult<UpdateLocationImagesMutation>;
export type UpdateLocationImagesMutationOptions = Apollo.BaseMutationOptions<UpdateLocationImagesMutation, UpdateLocationImagesMutationVariables>;
export const UpdateResourceCategoryDocument = gql`
    mutation UpdateResourceCategory($id: ID!, $input: UpdateResourceCategory!) {
  updateResourceCategory(id: $id, input: $input) {
    createdAt
    defaultSalary
    id
    level {
      createdAt
      id
      name
      updatedAt
    }
    name
    regional {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    updatedAt
    vertical
  }
}
    `;
export type UpdateResourceCategoryMutationFn = Apollo.MutationFunction<UpdateResourceCategoryMutation, UpdateResourceCategoryMutationVariables>;

/**
 * __useUpdateResourceCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateResourceCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceCategoryMutation, { data, loading, error }] = useUpdateResourceCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResourceCategoryMutation, UpdateResourceCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResourceCategoryMutation, UpdateResourceCategoryMutationVariables>(UpdateResourceCategoryDocument, options);
      }
export type UpdateResourceCategoryMutationHookResult = ReturnType<typeof useUpdateResourceCategoryMutation>;
export type UpdateResourceCategoryMutationResult = Apollo.MutationResult<UpdateResourceCategoryMutation>;
export type UpdateResourceCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateResourceCategoryMutation, UpdateResourceCategoryMutationVariables>;
export const UpdateResourceLevelDocument = gql`
    mutation UpdateResourceLevel($id: ID!, $input: UpsertResourceLevel!) {
  updateResourceLevel(id: $id, input: $input) {
    createdAt
    id
    name
    updatedAt
  }
}
    `;
export type UpdateResourceLevelMutationFn = Apollo.MutationFunction<UpdateResourceLevelMutation, UpdateResourceLevelMutationVariables>;

/**
 * __useUpdateResourceLevelMutation__
 *
 * To run a mutation, you first call `useUpdateResourceLevelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceLevelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceLevelMutation, { data, loading, error }] = useUpdateResourceLevelMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceLevelMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResourceLevelMutation, UpdateResourceLevelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResourceLevelMutation, UpdateResourceLevelMutationVariables>(UpdateResourceLevelDocument, options);
      }
export type UpdateResourceLevelMutationHookResult = ReturnType<typeof useUpdateResourceLevelMutation>;
export type UpdateResourceLevelMutationResult = Apollo.MutationResult<UpdateResourceLevelMutation>;
export type UpdateResourceLevelMutationOptions = Apollo.BaseMutationOptions<UpdateResourceLevelMutation, UpdateResourceLevelMutationVariables>;
export const UpdateResourceRegionalCategoryDocument = gql`
    mutation UpdateResourceRegionalCategory($id: ID!, $input: UpdateResourceRegionalCategory!) {
  updateResourceRegionalCategory(id: $id, input: $input) {
    createdAt
    id
    modifier
    price
    region
    salary
    updatedAt
  }
}
    `;
export type UpdateResourceRegionalCategoryMutationFn = Apollo.MutationFunction<UpdateResourceRegionalCategoryMutation, UpdateResourceRegionalCategoryMutationVariables>;

/**
 * __useUpdateResourceRegionalCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateResourceRegionalCategoryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateResourceRegionalCategoryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateResourceRegionalCategoryMutation, { data, loading, error }] = useUpdateResourceRegionalCategoryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateResourceRegionalCategoryMutation(baseOptions?: Apollo.MutationHookOptions<UpdateResourceRegionalCategoryMutation, UpdateResourceRegionalCategoryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateResourceRegionalCategoryMutation, UpdateResourceRegionalCategoryMutationVariables>(UpdateResourceRegionalCategoryDocument, options);
      }
export type UpdateResourceRegionalCategoryMutationHookResult = ReturnType<typeof useUpdateResourceRegionalCategoryMutation>;
export type UpdateResourceRegionalCategoryMutationResult = Apollo.MutationResult<UpdateResourceRegionalCategoryMutation>;
export type UpdateResourceRegionalCategoryMutationOptions = Apollo.BaseMutationOptions<UpdateResourceRegionalCategoryMutation, UpdateResourceRegionalCategoryMutationVariables>;
export const UpdateSlotsForWorkShiftDocument = gql`
    mutation UpdateSlotsForWorkShift($id: ID!, $workShiftSlots: [UpsertWorkShiftSlot!]!) {
  updateSlotsForWorkShift(id: $id, workShiftSlots: $workShiftSlots) {
    acceptanceTime
    bookingType
    cancelledAt
    city
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    contactPerson {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    customContactPerson
    description
    firstStartTime
    id
    internalName
    lastEndTime
    location {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    mealDeduction
    minutesPriorStart
    postalCode
    region
    resourceQuantity
    resourceRegionalCategories {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    salaryBoost
    sameResources
    sentAt
    slots {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
    status
    street
    templateId
    temporaryAddress {
      city
      id
      latitude
      longitude
      name
      postalCode
      region
      street
    }
    tips
    totalPrice
    travelExpenseCompensation
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftResourceCategories {
      levelIds
      levels {
        createdAt
        id
        name
        updatedAt
      }
      resourceCategories {
        id
        levelName
      }
      verticalProfileCategory
    }
  }
}
    `;
export type UpdateSlotsForWorkShiftMutationFn = Apollo.MutationFunction<UpdateSlotsForWorkShiftMutation, UpdateSlotsForWorkShiftMutationVariables>;

/**
 * __useUpdateSlotsForWorkShiftMutation__
 *
 * To run a mutation, you first call `useUpdateSlotsForWorkShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSlotsForWorkShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSlotsForWorkShiftMutation, { data, loading, error }] = useUpdateSlotsForWorkShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      workShiftSlots: // value for 'workShiftSlots'
 *   },
 * });
 */
export function useUpdateSlotsForWorkShiftMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSlotsForWorkShiftMutation, UpdateSlotsForWorkShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSlotsForWorkShiftMutation, UpdateSlotsForWorkShiftMutationVariables>(UpdateSlotsForWorkShiftDocument, options);
      }
export type UpdateSlotsForWorkShiftMutationHookResult = ReturnType<typeof useUpdateSlotsForWorkShiftMutation>;
export type UpdateSlotsForWorkShiftMutationResult = Apollo.MutationResult<UpdateSlotsForWorkShiftMutation>;
export type UpdateSlotsForWorkShiftMutationOptions = Apollo.BaseMutationOptions<UpdateSlotsForWorkShiftMutation, UpdateSlotsForWorkShiftMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: ID!, $input: UpdateUserInput!) {
  updateUser(id: $id, input: $input) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserEventDocument = gql`
    mutation UpdateUserEvent($id: ID!, $input: UserEventInput!) {
  updateUserEvent(id: $id, input: $input) {
    booking {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
    createdAt
    description
    endTime
    id
    startTime
    title
    updatedAt
    user {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type UpdateUserEventMutationFn = Apollo.MutationFunction<UpdateUserEventMutation, UpdateUserEventMutationVariables>;

/**
 * __useUpdateUserEventMutation__
 *
 * To run a mutation, you first call `useUpdateUserEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEventMutation, { data, loading, error }] = useUpdateUserEventMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserEventMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserEventMutation, UpdateUserEventMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserEventMutation, UpdateUserEventMutationVariables>(UpdateUserEventDocument, options);
      }
export type UpdateUserEventMutationHookResult = ReturnType<typeof useUpdateUserEventMutation>;
export type UpdateUserEventMutationResult = Apollo.MutationResult<UpdateUserEventMutation>;
export type UpdateUserEventMutationOptions = Apollo.BaseMutationOptions<UpdateUserEventMutation, UpdateUserEventMutationVariables>;
export const UpdateWorkShiftDocument = gql`
    mutation UpdateWorkShift($id: ID!, $input: UpdateWorkShiftInput!) {
  updateWorkShift(id: $id, input: $input) {
    acceptanceTime
    bookingType
    cancelledAt
    city
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    contactPerson {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    customContactPerson
    description
    firstStartTime
    id
    internalName
    lastEndTime
    location {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    mealDeduction
    minutesPriorStart
    postalCode
    region
    resourceQuantity
    resourceRegionalCategories {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    salaryBoost
    sameResources
    sentAt
    slots {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
    status
    street
    templateId
    temporaryAddress {
      city
      id
      latitude
      longitude
      name
      postalCode
      region
      street
    }
    tips
    totalPrice
    travelExpenseCompensation
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftResourceCategories {
      levelIds
      levels {
        createdAt
        id
        name
        updatedAt
      }
      resourceCategories {
        id
        levelName
      }
      verticalProfileCategory
    }
  }
}
    `;
export type UpdateWorkShiftMutationFn = Apollo.MutationFunction<UpdateWorkShiftMutation, UpdateWorkShiftMutationVariables>;

/**
 * __useUpdateWorkShiftMutation__
 *
 * To run a mutation, you first call `useUpdateWorkShiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkShiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkShiftMutation, { data, loading, error }] = useUpdateWorkShiftMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkShiftMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkShiftMutation, UpdateWorkShiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkShiftMutation, UpdateWorkShiftMutationVariables>(UpdateWorkShiftDocument, options);
      }
export type UpdateWorkShiftMutationHookResult = ReturnType<typeof useUpdateWorkShiftMutation>;
export type UpdateWorkShiftMutationResult = Apollo.MutationResult<UpdateWorkShiftMutation>;
export type UpdateWorkShiftMutationOptions = Apollo.BaseMutationOptions<UpdateWorkShiftMutation, UpdateWorkShiftMutationVariables>;
export const UpdateWorkShiftTemplateDocument = gql`
    mutation UpdateWorkShiftTemplate($id: ID!, $input: WorkShiftTemplateInput!) {
  updateWorkShiftTemplate(id: $id, input: $input) {
    company {
      active
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      category
      city
      commission
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      id
      latitude
      longitude
      name
      organisationNumber
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      street
      updatedAt
      web
    }
    createdAt
    description
    id
    locations {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    mealDeduction
    name
    salaryBoost
    slotTemplates {
      endHhMm
      resourceQuantity
      startHhMm
      workShiftTemplateId
    }
    tips
    travelExpenseCompensation
    updatedAt
    verticalProfileCategory
  }
}
    `;
export type UpdateWorkShiftTemplateMutationFn = Apollo.MutationFunction<UpdateWorkShiftTemplateMutation, UpdateWorkShiftTemplateMutationVariables>;

/**
 * __useUpdateWorkShiftTemplateMutation__
 *
 * To run a mutation, you first call `useUpdateWorkShiftTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkShiftTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkShiftTemplateMutation, { data, loading, error }] = useUpdateWorkShiftTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateWorkShiftTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdateWorkShiftTemplateMutation, UpdateWorkShiftTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateWorkShiftTemplateMutation, UpdateWorkShiftTemplateMutationVariables>(UpdateWorkShiftTemplateDocument, options);
      }
export type UpdateWorkShiftTemplateMutationHookResult = ReturnType<typeof useUpdateWorkShiftTemplateMutation>;
export type UpdateWorkShiftTemplateMutationResult = Apollo.MutationResult<UpdateWorkShiftTemplateMutation>;
export type UpdateWorkShiftTemplateMutationOptions = Apollo.BaseMutationOptions<UpdateWorkShiftTemplateMutation, UpdateWorkShiftTemplateMutationVariables>;
export const UpsertChatGroupDocument = gql`
    mutation UpsertChatGroup($id: ID, $input: UpsertChatGroupInput!) {
  upsertChatGroup(id: $id, input: $input) {
    closed
    createdAt
    id
    members {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    messages
    name
    receivers
    responses
    updatedAt
  }
}
    `;
export type UpsertChatGroupMutationFn = Apollo.MutationFunction<UpsertChatGroupMutation, UpsertChatGroupMutationVariables>;

/**
 * __useUpsertChatGroupMutation__
 *
 * To run a mutation, you first call `useUpsertChatGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertChatGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertChatGroupMutation, { data, loading, error }] = useUpsertChatGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertChatGroupMutation(baseOptions?: Apollo.MutationHookOptions<UpsertChatGroupMutation, UpsertChatGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertChatGroupMutation, UpsertChatGroupMutationVariables>(UpsertChatGroupDocument, options);
      }
export type UpsertChatGroupMutationHookResult = ReturnType<typeof useUpsertChatGroupMutation>;
export type UpsertChatGroupMutationResult = Apollo.MutationResult<UpsertChatGroupMutation>;
export type UpsertChatGroupMutationOptions = Apollo.BaseMutationOptions<UpsertChatGroupMutation, UpsertChatGroupMutationVariables>;
export const UpsertCompanyProfileDocument = gql`
    mutation UpsertCompanyProfile($id: ID!, $input: UpsertCompanyProfile!) {
  upsertCompanyProfile(id: $id, input: $input) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type UpsertCompanyProfileMutationFn = Apollo.MutationFunction<UpsertCompanyProfileMutation, UpsertCompanyProfileMutationVariables>;

/**
 * __useUpsertCompanyProfileMutation__
 *
 * To run a mutation, you first call `useUpsertCompanyProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCompanyProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCompanyProfileMutation, { data, loading, error }] = useUpsertCompanyProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertCompanyProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCompanyProfileMutation, UpsertCompanyProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCompanyProfileMutation, UpsertCompanyProfileMutationVariables>(UpsertCompanyProfileDocument, options);
      }
export type UpsertCompanyProfileMutationHookResult = ReturnType<typeof useUpsertCompanyProfileMutation>;
export type UpsertCompanyProfileMutationResult = Apollo.MutationResult<UpsertCompanyProfileMutation>;
export type UpsertCompanyProfileMutationOptions = Apollo.BaseMutationOptions<UpsertCompanyProfileMutation, UpsertCompanyProfileMutationVariables>;
export const UpsertCompanyResourceCategoriesDocument = gql`
    mutation UpsertCompanyResourceCategories($categories: [ID!]!, $id: ID!) {
  upsertCompanyResourceCategories(categories: $categories, id: $id) {
    active
    billingCity
    billingPostalCode
    billingReference
    billingStreet
    category
    city
    commission
    contactUser {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    createdAt
    description
    eInvoice
    id
    latitude
    longitude
    name
    organisationNumber
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    street
    updatedAt
    web
  }
}
    `;
export type UpsertCompanyResourceCategoriesMutationFn = Apollo.MutationFunction<UpsertCompanyResourceCategoriesMutation, UpsertCompanyResourceCategoriesMutationVariables>;

/**
 * __useUpsertCompanyResourceCategoriesMutation__
 *
 * To run a mutation, you first call `useUpsertCompanyResourceCategoriesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertCompanyResourceCategoriesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertCompanyResourceCategoriesMutation, { data, loading, error }] = useUpsertCompanyResourceCategoriesMutation({
 *   variables: {
 *      categories: // value for 'categories'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUpsertCompanyResourceCategoriesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertCompanyResourceCategoriesMutation, UpsertCompanyResourceCategoriesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertCompanyResourceCategoriesMutation, UpsertCompanyResourceCategoriesMutationVariables>(UpsertCompanyResourceCategoriesDocument, options);
      }
export type UpsertCompanyResourceCategoriesMutationHookResult = ReturnType<typeof useUpsertCompanyResourceCategoriesMutation>;
export type UpsertCompanyResourceCategoriesMutationResult = Apollo.MutationResult<UpsertCompanyResourceCategoriesMutation>;
export type UpsertCompanyResourceCategoriesMutationOptions = Apollo.BaseMutationOptions<UpsertCompanyResourceCategoriesMutation, UpsertCompanyResourceCategoriesMutationVariables>;
export const UpsertLegacyDataDocument = gql`
    mutation UpsertLegacyData($input: LegacyImport!) {
  upsertLegacyData(input: $input) {
    success
  }
}
    `;
export type UpsertLegacyDataMutationFn = Apollo.MutationFunction<UpsertLegacyDataMutation, UpsertLegacyDataMutationVariables>;

/**
 * __useUpsertLegacyDataMutation__
 *
 * To run a mutation, you first call `useUpsertLegacyDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLegacyDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLegacyDataMutation, { data, loading, error }] = useUpsertLegacyDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertLegacyDataMutation(baseOptions?: Apollo.MutationHookOptions<UpsertLegacyDataMutation, UpsertLegacyDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertLegacyDataMutation, UpsertLegacyDataMutationVariables>(UpsertLegacyDataDocument, options);
      }
export type UpsertLegacyDataMutationHookResult = ReturnType<typeof useUpsertLegacyDataMutation>;
export type UpsertLegacyDataMutationResult = Apollo.MutationResult<UpsertLegacyDataMutation>;
export type UpsertLegacyDataMutationOptions = Apollo.BaseMutationOptions<UpsertLegacyDataMutation, UpsertLegacyDataMutationVariables>;
export const UpsertRecommendationDocument = gql`
    mutation UpsertRecommendation($input: UpsertRecommendation!, $userId: ID!) {
  upsertRecommendation(input: $input, userId: $userId) {
    createdAt
    description
    hidden
    id
    location {
      billingCity
      billingPostalCode
      billingReference
      billingStreet
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactUser {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      description
      eInvoice
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      latitude
      legacyCustomerId
      logo {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      longitude
      name
      organisationNumber
      postalCode
      region
      street
      updatedAt
      web
    }
    updatedAt
  }
}
    `;
export type UpsertRecommendationMutationFn = Apollo.MutationFunction<UpsertRecommendationMutation, UpsertRecommendationMutationVariables>;

/**
 * __useUpsertRecommendationMutation__
 *
 * To run a mutation, you first call `useUpsertRecommendationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRecommendationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRecommendationMutation, { data, loading, error }] = useUpsertRecommendationMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpsertRecommendationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRecommendationMutation, UpsertRecommendationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRecommendationMutation, UpsertRecommendationMutationVariables>(UpsertRecommendationDocument, options);
      }
export type UpsertRecommendationMutationHookResult = ReturnType<typeof useUpsertRecommendationMutation>;
export type UpsertRecommendationMutationResult = Apollo.MutationResult<UpsertRecommendationMutation>;
export type UpsertRecommendationMutationOptions = Apollo.BaseMutationOptions<UpsertRecommendationMutation, UpsertRecommendationMutationVariables>;
export const UpsertResourceCategoriesForDocument = gql`
    mutation UpsertResourceCategoriesFor($categories: [ID!]!, $resourceId: ID!) {
  upsertResourceCategoriesFor(categories: $categories, resourceId: $resourceId) {
    createdAt
    defaultSalary
    id
    level {
      createdAt
      id
      name
      updatedAt
    }
    name
    regional {
      createdAt
      id
      modifier
      price
      region
      salary
      updatedAt
    }
    updatedAt
    vertical
  }
}
    `;
export type UpsertResourceCategoriesForMutationFn = Apollo.MutationFunction<UpsertResourceCategoriesForMutation, UpsertResourceCategoriesForMutationVariables>;

/**
 * __useUpsertResourceCategoriesForMutation__
 *
 * To run a mutation, you first call `useUpsertResourceCategoriesForMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertResourceCategoriesForMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertResourceCategoriesForMutation, { data, loading, error }] = useUpsertResourceCategoriesForMutation({
 *   variables: {
 *      categories: // value for 'categories'
 *      resourceId: // value for 'resourceId'
 *   },
 * });
 */
export function useUpsertResourceCategoriesForMutation(baseOptions?: Apollo.MutationHookOptions<UpsertResourceCategoriesForMutation, UpsertResourceCategoriesForMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertResourceCategoriesForMutation, UpsertResourceCategoriesForMutationVariables>(UpsertResourceCategoriesForDocument, options);
      }
export type UpsertResourceCategoriesForMutationHookResult = ReturnType<typeof useUpsertResourceCategoriesForMutation>;
export type UpsertResourceCategoriesForMutationResult = Apollo.MutationResult<UpsertResourceCategoriesForMutation>;
export type UpsertResourceCategoriesForMutationOptions = Apollo.BaseMutationOptions<UpsertResourceCategoriesForMutation, UpsertResourceCategoriesForMutationVariables>;
export const UpsertRestaurantProfileDocument = gql`
    mutation UpsertRestaurantProfile($id: ID!, $input: UpsertRestaurantProfile!) {
  upsertRestaurantProfile(id: $id, input: $input) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type UpsertRestaurantProfileMutationFn = Apollo.MutationFunction<UpsertRestaurantProfileMutation, UpsertRestaurantProfileMutationVariables>;

/**
 * __useUpsertRestaurantProfileMutation__
 *
 * To run a mutation, you first call `useUpsertRestaurantProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRestaurantProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRestaurantProfileMutation, { data, loading, error }] = useUpsertRestaurantProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRestaurantProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRestaurantProfileMutation, UpsertRestaurantProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRestaurantProfileMutation, UpsertRestaurantProfileMutationVariables>(UpsertRestaurantProfileDocument, options);
      }
export type UpsertRestaurantProfileMutationHookResult = ReturnType<typeof useUpsertRestaurantProfileMutation>;
export type UpsertRestaurantProfileMutationResult = Apollo.MutationResult<UpsertRestaurantProfileMutation>;
export type UpsertRestaurantProfileMutationOptions = Apollo.BaseMutationOptions<UpsertRestaurantProfileMutation, UpsertRestaurantProfileMutationVariables>;
export const UpsertRestaurantProfileExperienceDocument = gql`
    mutation UpsertRestaurantProfileExperience($id: ID, $input: [UpsertRestaurantWorkExperience!]!) {
  upsertRestaurantProfileExperience(id: $id, input: $input) {
    adjacentRegions
    approvedBookings
    createdAt
    description
    emergencyContactName
    emergencyContactPhone
    favouriteCount
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    languages
    rating
    region
    status
    tags {
      category
      createdAt
      id
      name
      subcategory
      updatedAt
    }
    updatedAt
    workExperience {
      category
      createdAt
      id
      otherWorkExperience
      updatedAt
      years
    }
    workReferences {
      company
      contactName
      contactPhone
      createdAt
      id
      role
      updatedAt
    }
  }
}
    `;
export type UpsertRestaurantProfileExperienceMutationFn = Apollo.MutationFunction<UpsertRestaurantProfileExperienceMutation, UpsertRestaurantProfileExperienceMutationVariables>;

/**
 * __useUpsertRestaurantProfileExperienceMutation__
 *
 * To run a mutation, you first call `useUpsertRestaurantProfileExperienceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRestaurantProfileExperienceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRestaurantProfileExperienceMutation, { data, loading, error }] = useUpsertRestaurantProfileExperienceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRestaurantProfileExperienceMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRestaurantProfileExperienceMutation, UpsertRestaurantProfileExperienceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRestaurantProfileExperienceMutation, UpsertRestaurantProfileExperienceMutationVariables>(UpsertRestaurantProfileExperienceDocument, options);
      }
export type UpsertRestaurantProfileExperienceMutationHookResult = ReturnType<typeof useUpsertRestaurantProfileExperienceMutation>;
export type UpsertRestaurantProfileExperienceMutationResult = Apollo.MutationResult<UpsertRestaurantProfileExperienceMutation>;
export type UpsertRestaurantProfileExperienceMutationOptions = Apollo.BaseMutationOptions<UpsertRestaurantProfileExperienceMutation, UpsertRestaurantProfileExperienceMutationVariables>;
export const UpsertRestaurantProfileReferencesDocument = gql`
    mutation UpsertRestaurantProfileReferences($id: ID, $input: UpsertWorkReference!) {
  upsertRestaurantProfileReferences(id: $id, input: $input) {
    adjacentRegions
    approvedBookings
    createdAt
    description
    emergencyContactName
    emergencyContactPhone
    favouriteCount
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    languages
    rating
    region
    status
    tags {
      category
      createdAt
      id
      name
      subcategory
      updatedAt
    }
    updatedAt
    workExperience {
      category
      createdAt
      id
      otherWorkExperience
      updatedAt
      years
    }
    workReferences {
      company
      contactName
      contactPhone
      createdAt
      id
      role
      updatedAt
    }
  }
}
    `;
export type UpsertRestaurantProfileReferencesMutationFn = Apollo.MutationFunction<UpsertRestaurantProfileReferencesMutation, UpsertRestaurantProfileReferencesMutationVariables>;

/**
 * __useUpsertRestaurantProfileReferencesMutation__
 *
 * To run a mutation, you first call `useUpsertRestaurantProfileReferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRestaurantProfileReferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRestaurantProfileReferencesMutation, { data, loading, error }] = useUpsertRestaurantProfileReferencesMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertRestaurantProfileReferencesMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRestaurantProfileReferencesMutation, UpsertRestaurantProfileReferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRestaurantProfileReferencesMutation, UpsertRestaurantProfileReferencesMutationVariables>(UpsertRestaurantProfileReferencesDocument, options);
      }
export type UpsertRestaurantProfileReferencesMutationHookResult = ReturnType<typeof useUpsertRestaurantProfileReferencesMutation>;
export type UpsertRestaurantProfileReferencesMutationResult = Apollo.MutationResult<UpsertRestaurantProfileReferencesMutation>;
export type UpsertRestaurantProfileReferencesMutationOptions = Apollo.BaseMutationOptions<UpsertRestaurantProfileReferencesMutation, UpsertRestaurantProfileReferencesMutationVariables>;
export const UpsertRestaurantProfileTagsDocument = gql`
    mutation UpsertRestaurantProfileTags($input: [ID!]!, $userId: ID) {
  upsertRestaurantProfileTags(input: $input, userId: $userId) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type UpsertRestaurantProfileTagsMutationFn = Apollo.MutationFunction<UpsertRestaurantProfileTagsMutation, UpsertRestaurantProfileTagsMutationVariables>;

/**
 * __useUpsertRestaurantProfileTagsMutation__
 *
 * To run a mutation, you first call `useUpsertRestaurantProfileTagsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRestaurantProfileTagsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRestaurantProfileTagsMutation, { data, loading, error }] = useUpsertRestaurantProfileTagsMutation({
 *   variables: {
 *      input: // value for 'input'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUpsertRestaurantProfileTagsMutation(baseOptions?: Apollo.MutationHookOptions<UpsertRestaurantProfileTagsMutation, UpsertRestaurantProfileTagsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertRestaurantProfileTagsMutation, UpsertRestaurantProfileTagsMutationVariables>(UpsertRestaurantProfileTagsDocument, options);
      }
export type UpsertRestaurantProfileTagsMutationHookResult = ReturnType<typeof useUpsertRestaurantProfileTagsMutation>;
export type UpsertRestaurantProfileTagsMutationResult = Apollo.MutationResult<UpsertRestaurantProfileTagsMutation>;
export type UpsertRestaurantProfileTagsMutationOptions = Apollo.BaseMutationOptions<UpsertRestaurantProfileTagsMutation, UpsertRestaurantProfileTagsMutationVariables>;
export const UpsertTagDocument = gql`
    mutation UpsertTag($input: UpsertTag) {
  upsertTag(input: $input) {
    category
    createdAt
    id
    name
    subcategory
    updatedAt
  }
}
    `;
export type UpsertTagMutationFn = Apollo.MutationFunction<UpsertTagMutation, UpsertTagMutationVariables>;

/**
 * __useUpsertTagMutation__
 *
 * To run a mutation, you first call `useUpsertTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTagMutation, { data, loading, error }] = useUpsertTagMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTagMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTagMutation, UpsertTagMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTagMutation, UpsertTagMutationVariables>(UpsertTagDocument, options);
      }
export type UpsertTagMutationHookResult = ReturnType<typeof useUpsertTagMutation>;
export type UpsertTagMutationResult = Apollo.MutationResult<UpsertTagMutation>;
export type UpsertTagMutationOptions = Apollo.BaseMutationOptions<UpsertTagMutation, UpsertTagMutationVariables>;
export const UpsertTimeReportDocument = gql`
    mutation UpsertTimeReport($bookingId: ID!, $input: TimeReportInput!) {
  upsertTimeReport(bookingId: $bookingId, input: $input) {
    acceptWith {
      bookings {
        breakMinutes
        createdAt
        declinedAt
        endTime
        id
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        startTime
        status
        updatedAt
        workShiftId
      }
      total
    }
    acceptedAt
    approvedAt
    approvedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    approvedByResourceAt
    backOfficeMainStatus
    backOfficeSubStatus
    breakMinutes
    cancelPolicyPct
    cancelledAt
    confirmedCanceled
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    declinedAt
    endTime
    extraStatus
    history {
      breakMinutes
      endTime
      startTime
      status
      suggestedEnd
      suggestedStart
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedById
    }
    id
    invoiced
    legacyId
    pricePoint {
      hourlySalaryBase
      hourlySalaryBoost
      hours
      totalPrice
      totalSalary
    }
    resource {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    resourceCategoryData {
      resourceLevel {
        createdAt
        id
        name
        updatedAt
      }
      verticalProfileCategory
    }
    startTime
    status
    suggestedEnd
    suggestedStart
    suggestionAcceptedAt
    timeReport {
      reportedBreak
      reportedEnd
      reportedStart
    }
    timerTracking {
      timerEnd
      timerLat
      timerLng
      timerStart
    }
    updatedAt
    updatedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    workShiftId
    workShiftSlot {
      bookedQuantity
      bookingStatuses
      breakMinutes
      calendarStatus
      cancelledAt
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      defaultFilter
      endTime
      fullyBooked
      id
      name
      price
      resourceQuantity
      startTime
      status
      summarizedBookingStatus
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
  }
}
    `;
export type UpsertTimeReportMutationFn = Apollo.MutationFunction<UpsertTimeReportMutation, UpsertTimeReportMutationVariables>;

/**
 * __useUpsertTimeReportMutation__
 *
 * To run a mutation, you first call `useUpsertTimeReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertTimeReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertTimeReportMutation, { data, loading, error }] = useUpsertTimeReportMutation({
 *   variables: {
 *      bookingId: // value for 'bookingId'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertTimeReportMutation(baseOptions?: Apollo.MutationHookOptions<UpsertTimeReportMutation, UpsertTimeReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertTimeReportMutation, UpsertTimeReportMutationVariables>(UpsertTimeReportDocument, options);
      }
export type UpsertTimeReportMutationHookResult = ReturnType<typeof useUpsertTimeReportMutation>;
export type UpsertTimeReportMutationResult = Apollo.MutationResult<UpsertTimeReportMutation>;
export type UpsertTimeReportMutationOptions = Apollo.BaseMutationOptions<UpsertTimeReportMutation, UpsertTimeReportMutationVariables>;
export const UpsertUserIdentificationDocument = gql`
    mutation UpsertUserIdentification($input: UserIdentification!) {
  upsertUserIdentification(input: $input) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type UpsertUserIdentificationMutationFn = Apollo.MutationFunction<UpsertUserIdentificationMutation, UpsertUserIdentificationMutationVariables>;

/**
 * __useUpsertUserIdentificationMutation__
 *
 * To run a mutation, you first call `useUpsertUserIdentificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertUserIdentificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertUserIdentificationMutation, { data, loading, error }] = useUpsertUserIdentificationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpsertUserIdentificationMutation(baseOptions?: Apollo.MutationHookOptions<UpsertUserIdentificationMutation, UpsertUserIdentificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertUserIdentificationMutation, UpsertUserIdentificationMutationVariables>(UpsertUserIdentificationDocument, options);
      }
export type UpsertUserIdentificationMutationHookResult = ReturnType<typeof useUpsertUserIdentificationMutation>;
export type UpsertUserIdentificationMutationResult = Apollo.MutationResult<UpsertUserIdentificationMutation>;
export type UpsertUserIdentificationMutationOptions = Apollo.BaseMutationOptions<UpsertUserIdentificationMutation, UpsertUserIdentificationMutationVariables>;
export const UserChangeImageDocument = gql`
    mutation UserChangeImage($id: ID!, $input: ImageUpdate) {
  userChangeImage(id: $id, input: $input) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type UserChangeImageMutationFn = Apollo.MutationFunction<UserChangeImageMutation, UserChangeImageMutationVariables>;

/**
 * __useUserChangeImageMutation__
 *
 * To run a mutation, you first call `useUserChangeImageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserChangeImageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangeImageMutation, { data, loading, error }] = useUserChangeImageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserChangeImageMutation(baseOptions?: Apollo.MutationHookOptions<UserChangeImageMutation, UserChangeImageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserChangeImageMutation, UserChangeImageMutationVariables>(UserChangeImageDocument, options);
      }
export type UserChangeImageMutationHookResult = ReturnType<typeof useUserChangeImageMutation>;
export type UserChangeImageMutationResult = Apollo.MutationResult<UserChangeImageMutation>;
export type UserChangeImageMutationOptions = Apollo.BaseMutationOptions<UserChangeImageMutation, UserChangeImageMutationVariables>;
export const UserChangePasswordDocument = gql`
    mutation UserChangePassword($input: UserChangePasswordInput!) {
  userChangePassword(input: $input) {
    success
  }
}
    `;
export type UserChangePasswordMutationFn = Apollo.MutationFunction<UserChangePasswordMutation, UserChangePasswordMutationVariables>;

/**
 * __useUserChangePasswordMutation__
 *
 * To run a mutation, you first call `useUserChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userChangePasswordMutation, { data, loading, error }] = useUserChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUserChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<UserChangePasswordMutation, UserChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserChangePasswordMutation, UserChangePasswordMutationVariables>(UserChangePasswordDocument, options);
      }
export type UserChangePasswordMutationHookResult = ReturnType<typeof useUserChangePasswordMutation>;
export type UserChangePasswordMutationResult = Apollo.MutationResult<UserChangePasswordMutation>;
export type UserChangePasswordMutationOptions = Apollo.BaseMutationOptions<UserChangePasswordMutation, UserChangePasswordMutationVariables>;
export const VerifyBankIdInitDocument = gql`
    mutation VerifyBankIdInit($socialSecurity: String!) {
  verifyBankIdInit(socialSecurity: $socialSecurity) {
    autoStartToken
    orderRef
  }
}
    `;
export type VerifyBankIdInitMutationFn = Apollo.MutationFunction<VerifyBankIdInitMutation, VerifyBankIdInitMutationVariables>;

/**
 * __useVerifyBankIdInitMutation__
 *
 * To run a mutation, you first call `useVerifyBankIdInitMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyBankIdInitMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyBankIdInitMutation, { data, loading, error }] = useVerifyBankIdInitMutation({
 *   variables: {
 *      socialSecurity: // value for 'socialSecurity'
 *   },
 * });
 */
export function useVerifyBankIdInitMutation(baseOptions?: Apollo.MutationHookOptions<VerifyBankIdInitMutation, VerifyBankIdInitMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyBankIdInitMutation, VerifyBankIdInitMutationVariables>(VerifyBankIdInitDocument, options);
      }
export type VerifyBankIdInitMutationHookResult = ReturnType<typeof useVerifyBankIdInitMutation>;
export type VerifyBankIdInitMutationResult = Apollo.MutationResult<VerifyBankIdInitMutation>;
export type VerifyBankIdInitMutationOptions = Apollo.BaseMutationOptions<VerifyBankIdInitMutation, VerifyBankIdInitMutationVariables>;
export const VerifyBankIdResolveDocument = gql`
    mutation VerifyBankIdResolve($orderRef: String!) {
  verifyBankIdResolve(orderRef: $orderRef) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type VerifyBankIdResolveMutationFn = Apollo.MutationFunction<VerifyBankIdResolveMutation, VerifyBankIdResolveMutationVariables>;

/**
 * __useVerifyBankIdResolveMutation__
 *
 * To run a mutation, you first call `useVerifyBankIdResolveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyBankIdResolveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyBankIdResolveMutation, { data, loading, error }] = useVerifyBankIdResolveMutation({
 *   variables: {
 *      orderRef: // value for 'orderRef'
 *   },
 * });
 */
export function useVerifyBankIdResolveMutation(baseOptions?: Apollo.MutationHookOptions<VerifyBankIdResolveMutation, VerifyBankIdResolveMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyBankIdResolveMutation, VerifyBankIdResolveMutationVariables>(VerifyBankIdResolveDocument, options);
      }
export type VerifyBankIdResolveMutationHookResult = ReturnType<typeof useVerifyBankIdResolveMutation>;
export type VerifyBankIdResolveMutationResult = Apollo.MutationResult<VerifyBankIdResolveMutation>;
export type VerifyBankIdResolveMutationOptions = Apollo.BaseMutationOptions<VerifyBankIdResolveMutation, VerifyBankIdResolveMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation VerifyEmail($email: String!, $nonce: String!) {
  verifyEmail(email: $email, nonce: $nonce) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *      nonce: // value for 'nonce'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const VerifyUserDocument = gql`
    mutation VerifyUser($id: ID!) {
  verifyUser(id: $id) {
    active
    approved
    availableForSlots
    blocked
    city
    createdAt
    deletedAt
    email
    emailVerified
    favourite
    firstName
    hidden
    id
    identification {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    image {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    internalComment
    lastName
    legacyId
    mobileNumber
    nextOfKinName
    nextOfKinPhone
    postalCode
    resourceCategories {
      createdAt
      defaultSalary
      id
      level {
        createdAt
        id
        name
        updatedAt
      }
      name
      regional {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      updatedAt
      vertical
    }
    role
    socialSecurity
    street
    updatedAt
    userProfile {
      ... on CompanyProfile {
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        description
        id
        locations {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
      }
      ... on RestaurantProfile {
        adjacentRegions
        approvedBookings
        createdAt
        description
        emergencyContactName
        emergencyContactPhone
        favouriteCount
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        languages
        rating
        region
        status
        tags {
          category
          createdAt
          id
          name
          subcategory
          updatedAt
        }
        updatedAt
        workExperience {
          category
          createdAt
          id
          otherWorkExperience
          updatedAt
          years
        }
        workReferences {
          company
          contactName
          contactPhone
          createdAt
          id
          role
          updatedAt
        }
      }
    }
    verificationMethod
    verified
    verifiedBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
  }
}
    `;
export type VerifyUserMutationFn = Apollo.MutationFunction<VerifyUserMutation, VerifyUserMutationVariables>;

/**
 * __useVerifyUserMutation__
 *
 * To run a mutation, you first call `useVerifyUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyUserMutation, { data, loading, error }] = useVerifyUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useVerifyUserMutation(baseOptions?: Apollo.MutationHookOptions<VerifyUserMutation, VerifyUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyUserMutation, VerifyUserMutationVariables>(VerifyUserDocument, options);
      }
export type VerifyUserMutationHookResult = ReturnType<typeof useVerifyUserMutation>;
export type VerifyUserMutationResult = Apollo.MutationResult<VerifyUserMutation>;
export type VerifyUserMutationOptions = Apollo.BaseMutationOptions<VerifyUserMutation, VerifyUserMutationVariables>;
export const ChatPrivateChannelsDocument = gql`
    subscription ChatPrivateChannels {
  chatPrivateChannels {
    chatGroup {
      closed
      createdAt
      id
      members {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      messages
      name
      receivers
      responses
      updatedAt
    }
    createdAt
    deletedAt
    id
    lastSentAt
    messages {
      channel {
        chatGroup {
          closed
          createdAt
          id
          messages
          name
          receivers
          responses
          updatedAt
        }
        createdAt
        deletedAt
        id
        lastSentAt
        messages {
          createdAt
          id
          message
          position
          senderName
          title
          updatedAt
        }
        name
        origin
        participants {
          lastReadAt
        }
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      extra {
        ... on Booking {
          acceptWith {
            total
          }
          acceptedAt
          approvedAt
          approvedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          approvedByResourceAt
          backOfficeMainStatus
          backOfficeSubStatus
          breakMinutes
          cancelPolicyPct
          cancelledAt
          confirmedCanceled
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          declinedAt
          endTime
          extraStatus
          history {
            breakMinutes
            endTime
            startTime
            status
            suggestedEnd
            suggestedStart
            updatedAt
            updatedById
          }
          id
          invoiced
          legacyId
          pricePoint {
            hourlySalaryBase
            hourlySalaryBoost
            hours
            totalPrice
            totalSalary
          }
          resource {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          resourceCategoryData {
            verticalProfileCategory
          }
          startTime
          status
          suggestedEnd
          suggestedStart
          suggestionAcceptedAt
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShiftId
          workShiftSlot {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            createdAt
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
          }
        }
      }
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      images {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      message
      position
      senderName
      title
      updatedAt
    }
    name
    origin
    participants {
      lastReadAt
      user {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    updatedAt
    workShift {
      acceptanceTime
      bookingType
      cancelledAt
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      description
      firstStartTime
      id
      internalName
      lastEndTime
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      minutesPriorStart
      postalCode
      region
      resourceQuantity
      resourceRegionalCategories {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      salaryBoost
      sameResources
      sentAt
      slots {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      status
      street
      templateId
      temporaryAddress {
        city
        id
        latitude
        longitude
        name
        postalCode
        region
        street
      }
      tips
      totalPrice
      travelExpenseCompensation
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftResourceCategories {
        levelIds
        levels {
          createdAt
          id
          name
          updatedAt
        }
        resourceCategories {
          id
          levelName
        }
        verticalProfileCategory
      }
    }
  }
}
    `;

/**
 * __useChatPrivateChannelsSubscription__
 *
 * To run a query within a React component, call `useChatPrivateChannelsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatPrivateChannelsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatPrivateChannelsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatPrivateChannelsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ChatPrivateChannelsSubscription, ChatPrivateChannelsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatPrivateChannelsSubscription, ChatPrivateChannelsSubscriptionVariables>(ChatPrivateChannelsDocument, options);
      }
export type ChatPrivateChannelsSubscriptionHookResult = ReturnType<typeof useChatPrivateChannelsSubscription>;
export type ChatPrivateChannelsSubscriptionResult = Apollo.SubscriptionResult<ChatPrivateChannelsSubscription>;
export const ChatPrivateMessagesDocument = gql`
    subscription ChatPrivateMessages {
  chatPrivateMessages {
    channel {
      chatGroup {
        closed
        createdAt
        id
        members {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        messages
        name
        receivers
        responses
        updatedAt
      }
      createdAt
      deletedAt
      id
      lastSentAt
      messages {
        channel {
          createdAt
          deletedAt
          id
          lastSentAt
          name
          origin
          updatedAt
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        extra {
          ... on Booking {
            acceptedAt
            approvedAt
            approvedByResourceAt
            backOfficeMainStatus
            backOfficeSubStatus
            breakMinutes
            cancelPolicyPct
            cancelledAt
            confirmedCanceled
            createdAt
            declinedAt
            endTime
            extraStatus
            id
            invoiced
            legacyId
            startTime
            status
            suggestedEnd
            suggestedStart
            suggestionAcceptedAt
            updatedAt
            workShiftId
          }
        }
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        images {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        message
        position
        senderName
        title
        updatedAt
      }
      name
      origin
      participants {
        lastReadAt
        user {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedAt
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    extra {
      ... on Booking {
        acceptWith {
          bookings {
            breakMinutes
            createdAt
            declinedAt
            endTime
            id
            startTime
            status
            updatedAt
            workShiftId
          }
          total
        }
        acceptedAt
        approvedAt
        approvedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        approvedByResourceAt
        backOfficeMainStatus
        backOfficeSubStatus
        breakMinutes
        cancelPolicyPct
        cancelledAt
        confirmedCanceled
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        declinedAt
        endTime
        extraStatus
        history {
          breakMinutes
          endTime
          startTime
          status
          suggestedEnd
          suggestedStart
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          updatedById
        }
        id
        invoiced
        legacyId
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        resource {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        resourceCategoryData {
          resourceLevel {
            createdAt
            id
            name
            updatedAt
          }
          verticalProfileCategory
        }
        startTime
        status
        suggestedEnd
        suggestedStart
        suggestionAcceptedAt
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        workShiftId
        workShiftSlot {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          contactPerson {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShift {
            acceptanceTime
            bookingType
            cancelledAt
            city
            createdAt
            customContactPerson
            description
            firstStartTime
            id
            internalName
            lastEndTime
            mealDeduction
            minutesPriorStart
            postalCode
            region
            resourceQuantity
            salaryBoost
            sameResources
            sentAt
            status
            street
            templateId
            tips
            totalPrice
            travelExpenseCompensation
            updatedAt
          }
        }
      }
    }
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    images {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    message
    position
    senderName
    title
    updatedAt
  }
}
    `;

/**
 * __useChatPrivateMessagesSubscription__
 *
 * To run a query within a React component, call `useChatPrivateMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatPrivateMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatPrivateMessagesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatPrivateMessagesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ChatPrivateMessagesSubscription, ChatPrivateMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatPrivateMessagesSubscription, ChatPrivateMessagesSubscriptionVariables>(ChatPrivateMessagesDocument, options);
      }
export type ChatPrivateMessagesSubscriptionHookResult = ReturnType<typeof useChatPrivateMessagesSubscription>;
export type ChatPrivateMessagesSubscriptionResult = Apollo.SubscriptionResult<ChatPrivateMessagesSubscription>;
export const ChatPublicChannelsDocument = gql`
    subscription ChatPublicChannels {
  chatPublicChannels {
    chatGroup {
      closed
      createdAt
      id
      members {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      messages
      name
      receivers
      responses
      updatedAt
    }
    createdAt
    deletedAt
    id
    lastSentAt
    messages {
      channel {
        chatGroup {
          closed
          createdAt
          id
          messages
          name
          receivers
          responses
          updatedAt
        }
        createdAt
        deletedAt
        id
        lastSentAt
        messages {
          createdAt
          id
          message
          position
          senderName
          title
          updatedAt
        }
        name
        origin
        participants {
          lastReadAt
        }
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      extra {
        ... on Booking {
          acceptWith {
            total
          }
          acceptedAt
          approvedAt
          approvedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          approvedByResourceAt
          backOfficeMainStatus
          backOfficeSubStatus
          breakMinutes
          cancelPolicyPct
          cancelledAt
          confirmedCanceled
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          declinedAt
          endTime
          extraStatus
          history {
            breakMinutes
            endTime
            startTime
            status
            suggestedEnd
            suggestedStart
            updatedAt
            updatedById
          }
          id
          invoiced
          legacyId
          pricePoint {
            hourlySalaryBase
            hourlySalaryBoost
            hours
            totalPrice
            totalSalary
          }
          resource {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          resourceCategoryData {
            verticalProfileCategory
          }
          startTime
          status
          suggestedEnd
          suggestedStart
          suggestionAcceptedAt
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShiftId
          workShiftSlot {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            createdAt
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
          }
        }
      }
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      images {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      message
      position
      senderName
      title
      updatedAt
    }
    name
    origin
    participants {
      lastReadAt
      user {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    updatedAt
    workShift {
      acceptanceTime
      bookingType
      cancelledAt
      city
      company {
        active
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        category
        city
        commission
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        id
        latitude
        longitude
        name
        organisationNumber
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        street
        updatedAt
        web
      }
      contactPerson {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      customContactPerson
      description
      firstStartTime
      id
      internalName
      lastEndTime
      location {
        billingCity
        billingPostalCode
        billingReference
        billingStreet
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactUser {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        description
        eInvoice
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        latitude
        legacyCustomerId
        logo {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        longitude
        name
        organisationNumber
        postalCode
        region
        street
        updatedAt
        web
      }
      mealDeduction
      minutesPriorStart
      postalCode
      region
      resourceQuantity
      resourceRegionalCategories {
        createdAt
        id
        modifier
        price
        region
        salary
        updatedAt
      }
      salaryBoost
      sameResources
      sentAt
      slots {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
      status
      street
      templateId
      temporaryAddress {
        city
        id
        latitude
        longitude
        name
        postalCode
        region
        street
      }
      tips
      totalPrice
      travelExpenseCompensation
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftResourceCategories {
        levelIds
        levels {
          createdAt
          id
          name
          updatedAt
        }
        resourceCategories {
          id
          levelName
        }
        verticalProfileCategory
      }
    }
  }
}
    `;

/**
 * __useChatPublicChannelsSubscription__
 *
 * To run a query within a React component, call `useChatPublicChannelsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatPublicChannelsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatPublicChannelsSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatPublicChannelsSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ChatPublicChannelsSubscription, ChatPublicChannelsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatPublicChannelsSubscription, ChatPublicChannelsSubscriptionVariables>(ChatPublicChannelsDocument, options);
      }
export type ChatPublicChannelsSubscriptionHookResult = ReturnType<typeof useChatPublicChannelsSubscription>;
export type ChatPublicChannelsSubscriptionResult = Apollo.SubscriptionResult<ChatPublicChannelsSubscription>;
export const ChatPublicMessagesDocument = gql`
    subscription ChatPublicMessages {
  chatPublicMessages {
    channel {
      chatGroup {
        closed
        createdAt
        id
        members {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        messages
        name
        receivers
        responses
        updatedAt
      }
      createdAt
      deletedAt
      id
      lastSentAt
      messages {
        channel {
          createdAt
          deletedAt
          id
          lastSentAt
          name
          origin
          updatedAt
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        extra {
          ... on Booking {
            acceptedAt
            approvedAt
            approvedByResourceAt
            backOfficeMainStatus
            backOfficeSubStatus
            breakMinutes
            cancelPolicyPct
            cancelledAt
            confirmedCanceled
            createdAt
            declinedAt
            endTime
            extraStatus
            id
            invoiced
            legacyId
            startTime
            status
            suggestedEnd
            suggestedStart
            suggestionAcceptedAt
            updatedAt
            workShiftId
          }
        }
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        images {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        message
        position
        senderName
        title
        updatedAt
      }
      name
      origin
      participants {
        lastReadAt
        user {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      updatedAt
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          createdAt
          description
          eInvoice
          id
          latitude
          legacyCustomerId
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          createdAt
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShiftResourceCategories {
          levelIds
          verticalProfileCategory
        }
      }
    }
    createdAt
    createdBy {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    extra {
      ... on Booking {
        acceptWith {
          bookings {
            breakMinutes
            createdAt
            declinedAt
            endTime
            id
            startTime
            status
            updatedAt
            workShiftId
          }
          total
        }
        acceptedAt
        approvedAt
        approvedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        approvedByResourceAt
        backOfficeMainStatus
        backOfficeSubStatus
        breakMinutes
        cancelPolicyPct
        cancelledAt
        confirmedCanceled
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        declinedAt
        endTime
        extraStatus
        history {
          breakMinutes
          endTime
          startTime
          status
          suggestedEnd
          suggestedStart
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          updatedById
        }
        id
        invoiced
        legacyId
        pricePoint {
          hourlySalaryBase
          hourlySalaryBoost
          hours
          totalPrice
          totalSalary
        }
        resource {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        resourceCategoryData {
          resourceLevel {
            createdAt
            id
            name
            updatedAt
          }
          verticalProfileCategory
        }
        startTime
        status
        suggestedEnd
        suggestedStart
        suggestionAcceptedAt
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        workShiftId
        workShiftSlot {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          contactPerson {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShift {
            acceptanceTime
            bookingType
            cancelledAt
            city
            createdAt
            customContactPerson
            description
            firstStartTime
            id
            internalName
            lastEndTime
            mealDeduction
            minutesPriorStart
            postalCode
            region
            resourceQuantity
            salaryBoost
            sameResources
            sentAt
            status
            street
            templateId
            tips
            totalPrice
            travelExpenseCompensation
            updatedAt
          }
        }
      }
    }
    files {
      contentType
      createdAt
      filename
      id
      originalName
      sizeInBytes
      uri
    }
    id
    images {
      contentType
      createdAt
      filename
      height
      id
      originalName
      sizeInBytes
      uri
      width
    }
    message
    position
    senderName
    title
    updatedAt
  }
}
    `;

/**
 * __useChatPublicMessagesSubscription__
 *
 * To run a query within a React component, call `useChatPublicMessagesSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatPublicMessagesSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatPublicMessagesSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatPublicMessagesSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ChatPublicMessagesSubscription, ChatPublicMessagesSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatPublicMessagesSubscription, ChatPublicMessagesSubscriptionVariables>(ChatPublicMessagesDocument, options);
      }
export type ChatPublicMessagesSubscriptionHookResult = ReturnType<typeof useChatPublicMessagesSubscription>;
export type ChatPublicMessagesSubscriptionResult = Apollo.SubscriptionResult<ChatPublicMessagesSubscription>;
export const ChatSubscribeDocument = gql`
    subscription ChatSubscribe {
  chatSubscribe {
    ... on Channel {
      chatGroup {
        closed
        createdAt
        id
        members {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        messages
        name
        receivers
        responses
        updatedAt
      }
      createdAt
      deletedAt
      id
      lastSentAt
      messages {
        channel {
          chatGroup {
            closed
            createdAt
            id
            messages
            name
            receivers
            responses
            updatedAt
          }
          createdAt
          deletedAt
          id
          lastSentAt
          messages {
            createdAt
            id
            message
            position
            senderName
            title
            updatedAt
          }
          name
          origin
          participants {
            lastReadAt
          }
          updatedAt
          workShift {
            acceptanceTime
            bookingType
            cancelledAt
            city
            createdAt
            customContactPerson
            description
            firstStartTime
            id
            internalName
            lastEndTime
            mealDeduction
            minutesPriorStart
            postalCode
            region
            resourceQuantity
            salaryBoost
            sameResources
            sentAt
            status
            street
            templateId
            tips
            totalPrice
            travelExpenseCompensation
            updatedAt
          }
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        extra {
          ... on Booking {
            acceptWith {
              total
            }
            acceptedAt
            approvedAt
            approvedBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
            approvedByResourceAt
            backOfficeMainStatus
            backOfficeSubStatus
            breakMinutes
            cancelPolicyPct
            cancelledAt
            confirmedCanceled
            createdAt
            createdBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
            declinedAt
            endTime
            extraStatus
            history {
              breakMinutes
              endTime
              startTime
              status
              suggestedEnd
              suggestedStart
              updatedAt
              updatedById
            }
            id
            invoiced
            legacyId
            pricePoint {
              hourlySalaryBase
              hourlySalaryBoost
              hours
              totalPrice
              totalSalary
            }
            resource {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
            resourceCategoryData {
              verticalProfileCategory
            }
            startTime
            status
            suggestedEnd
            suggestedStart
            suggestionAcceptedAt
            timeReport {
              reportedBreak
              reportedEnd
              reportedStart
            }
            timerTracking {
              timerEnd
              timerLat
              timerLng
              timerStart
            }
            updatedAt
            updatedBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
            workShiftId
            workShiftSlot {
              bookedQuantity
              bookingStatuses
              breakMinutes
              calendarStatus
              cancelledAt
              createdAt
              customContactPerson
              defaultFilter
              endTime
              fullyBooked
              id
              name
              price
              resourceQuantity
              startTime
              status
              summarizedBookingStatus
              updatedAt
            }
          }
        }
        files {
          contentType
          createdAt
          filename
          id
          originalName
          sizeInBytes
          uri
        }
        id
        images {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        message
        position
        senderName
        title
        updatedAt
      }
      name
      origin
      participants {
        lastReadAt
        user {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
      }
      updatedAt
      workShift {
        acceptanceTime
        bookingType
        cancelledAt
        city
        company {
          active
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          category
          city
          commission
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          id
          latitude
          longitude
          name
          organisationNumber
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          street
          updatedAt
          web
        }
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        customContactPerson
        description
        firstStartTime
        id
        internalName
        lastEndTime
        location {
          billingCity
          billingPostalCode
          billingReference
          billingStreet
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactUser {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          description
          eInvoice
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          latitude
          legacyCustomerId
          logo {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          longitude
          name
          organisationNumber
          postalCode
          region
          street
          updatedAt
          web
        }
        mealDeduction
        minutesPriorStart
        postalCode
        region
        resourceQuantity
        resourceRegionalCategories {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        salaryBoost
        sameResources
        sentAt
        slots {
          bookedQuantity
          bookingStatuses
          breakMinutes
          calendarStatus
          cancelledAt
          contactPerson {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          customContactPerson
          defaultFilter
          endTime
          fullyBooked
          id
          name
          price
          resourceQuantity
          startTime
          status
          summarizedBookingStatus
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShift {
            acceptanceTime
            bookingType
            cancelledAt
            city
            createdAt
            customContactPerson
            description
            firstStartTime
            id
            internalName
            lastEndTime
            mealDeduction
            minutesPriorStart
            postalCode
            region
            resourceQuantity
            salaryBoost
            sameResources
            sentAt
            status
            street
            templateId
            tips
            totalPrice
            travelExpenseCompensation
            updatedAt
          }
        }
        status
        street
        templateId
        temporaryAddress {
          city
          id
          latitude
          longitude
          name
          postalCode
          region
          street
        }
        tips
        totalPrice
        travelExpenseCompensation
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        workShiftResourceCategories {
          levelIds
          levels {
            createdAt
            id
            name
            updatedAt
          }
          resourceCategories {
            id
            levelName
          }
          verticalProfileCategory
        }
      }
    }
    ... on Message {
      channel {
        chatGroup {
          closed
          createdAt
          id
          members {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          messages
          name
          receivers
          responses
          updatedAt
        }
        createdAt
        deletedAt
        id
        lastSentAt
        messages {
          channel {
            createdAt
            deletedAt
            id
            lastSentAt
            name
            origin
            updatedAt
          }
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          extra {
            ... on Booking {
              acceptedAt
              approvedAt
              approvedByResourceAt
              backOfficeMainStatus
              backOfficeSubStatus
              breakMinutes
              cancelPolicyPct
              cancelledAt
              confirmedCanceled
              createdAt
              declinedAt
              endTime
              extraStatus
              id
              invoiced
              legacyId
              startTime
              status
              suggestedEnd
              suggestedStart
              suggestionAcceptedAt
              updatedAt
              workShiftId
            }
          }
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          images {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          message
          position
          senderName
          title
          updatedAt
        }
        name
        origin
        participants {
          lastReadAt
          user {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
        updatedAt
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          contactPerson {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          location {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          resourceRegionalCategories {
            createdAt
            id
            modifier
            price
            region
            salary
            updatedAt
          }
          salaryBoost
          sameResources
          sentAt
          slots {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            createdAt
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
          }
          status
          street
          templateId
          temporaryAddress {
            city
            id
            latitude
            longitude
            name
            postalCode
            region
            street
          }
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
          workShiftResourceCategories {
            levelIds
            verticalProfileCategory
          }
        }
      }
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          level {
            createdAt
            id
            name
            updatedAt
          }
          name
          regional {
            createdAt
            id
            modifier
            price
            region
            salary
            updatedAt
          }
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            company {
              active
              billingCity
              billingPostalCode
              billingReference
              billingStreet
              category
              city
              commission
              createdAt
              description
              eInvoice
              id
              latitude
              longitude
              name
              organisationNumber
              postalCode
              street
              updatedAt
              web
            }
            description
            id
            locations {
              billingCity
              billingPostalCode
              billingReference
              billingStreet
              city
              createdAt
              description
              eInvoice
              id
              latitude
              legacyCustomerId
              longitude
              name
              organisationNumber
              postalCode
              region
              street
              updatedAt
              web
            }
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            files {
              contentType
              createdAt
              filename
              id
              originalName
              sizeInBytes
              uri
            }
            id
            languages
            rating
            region
            status
            tags {
              category
              createdAt
              id
              name
              subcategory
              updatedAt
            }
            updatedAt
            workExperience {
              category
              createdAt
              id
              otherWorkExperience
              updatedAt
              years
            }
            workReferences {
              company
              contactName
              contactPhone
              createdAt
              id
              role
              updatedAt
            }
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          identification {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          image {
            contentType
            createdAt
            filename
            height
            id
            originalName
            sizeInBytes
            uri
            width
          }
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          resourceCategories {
            createdAt
            defaultSalary
            id
            name
            updatedAt
            vertical
          }
          role
          socialSecurity
          street
          updatedAt
          userProfile {
            ... on CompanyProfile {
              description
              id
            }
            ... on RestaurantProfile {
              adjacentRegions
              approvedBookings
              createdAt
              description
              emergencyContactName
              emergencyContactPhone
              favouriteCount
              id
              languages
              rating
              region
              status
              updatedAt
            }
          }
          verificationMethod
          verified
          verifiedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            role
            socialSecurity
            street
            updatedAt
            verificationMethod
            verified
          }
        }
      }
      extra {
        ... on Booking {
          acceptWith {
            bookings {
              breakMinutes
              createdAt
              declinedAt
              endTime
              id
              startTime
              status
              updatedAt
              workShiftId
            }
            total
          }
          acceptedAt
          approvedAt
          approvedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            identification {
              contentType
              createdAt
              filename
              height
              id
              originalName
              sizeInBytes
              uri
              width
            }
            image {
              contentType
              createdAt
              filename
              height
              id
              originalName
              sizeInBytes
              uri
              width
            }
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            resourceCategories {
              createdAt
              defaultSalary
              id
              name
              updatedAt
              vertical
            }
            role
            socialSecurity
            street
            updatedAt
            userProfile {
              ... on CompanyProfile {
                description
                id
              }
              ... on RestaurantProfile {
                adjacentRegions
                approvedBookings
                createdAt
                description
                emergencyContactName
                emergencyContactPhone
                favouriteCount
                id
                languages
                rating
                region
                status
                updatedAt
              }
            }
            verificationMethod
            verified
            verifiedBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
          }
          approvedByResourceAt
          backOfficeMainStatus
          backOfficeSubStatus
          breakMinutes
          cancelPolicyPct
          cancelledAt
          confirmedCanceled
          createdAt
          createdBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            identification {
              contentType
              createdAt
              filename
              height
              id
              originalName
              sizeInBytes
              uri
              width
            }
            image {
              contentType
              createdAt
              filename
              height
              id
              originalName
              sizeInBytes
              uri
              width
            }
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            resourceCategories {
              createdAt
              defaultSalary
              id
              name
              updatedAt
              vertical
            }
            role
            socialSecurity
            street
            updatedAt
            userProfile {
              ... on CompanyProfile {
                description
                id
              }
              ... on RestaurantProfile {
                adjacentRegions
                approvedBookings
                createdAt
                description
                emergencyContactName
                emergencyContactPhone
                favouriteCount
                id
                languages
                rating
                region
                status
                updatedAt
              }
            }
            verificationMethod
            verified
            verifiedBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
          }
          declinedAt
          endTime
          extraStatus
          history {
            breakMinutes
            endTime
            startTime
            status
            suggestedEnd
            suggestedStart
            timeReport {
              reportedBreak
              reportedEnd
              reportedStart
            }
            timerTracking {
              timerEnd
              timerLat
              timerLng
              timerStart
            }
            updatedAt
            updatedBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
            updatedById
          }
          id
          invoiced
          legacyId
          pricePoint {
            hourlySalaryBase
            hourlySalaryBoost
            hours
            totalPrice
            totalSalary
          }
          resource {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            identification {
              contentType
              createdAt
              filename
              height
              id
              originalName
              sizeInBytes
              uri
              width
            }
            image {
              contentType
              createdAt
              filename
              height
              id
              originalName
              sizeInBytes
              uri
              width
            }
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            resourceCategories {
              createdAt
              defaultSalary
              id
              name
              updatedAt
              vertical
            }
            role
            socialSecurity
            street
            updatedAt
            userProfile {
              ... on CompanyProfile {
                description
                id
              }
              ... on RestaurantProfile {
                adjacentRegions
                approvedBookings
                createdAt
                description
                emergencyContactName
                emergencyContactPhone
                favouriteCount
                id
                languages
                rating
                region
                status
                updatedAt
              }
            }
            verificationMethod
            verified
            verifiedBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
          }
          resourceCategoryData {
            resourceLevel {
              createdAt
              id
              name
              updatedAt
            }
            verticalProfileCategory
          }
          startTime
          status
          suggestedEnd
          suggestedStart
          suggestionAcceptedAt
          timeReport {
            reportedBreak
            reportedEnd
            reportedStart
          }
          timerTracking {
            timerEnd
            timerLat
            timerLng
            timerStart
          }
          updatedAt
          updatedBy {
            active
            approved
            availableForSlots
            blocked
            city
            createdAt
            deletedAt
            email
            emailVerified
            favourite
            firstName
            hidden
            id
            identification {
              contentType
              createdAt
              filename
              height
              id
              originalName
              sizeInBytes
              uri
              width
            }
            image {
              contentType
              createdAt
              filename
              height
              id
              originalName
              sizeInBytes
              uri
              width
            }
            internalComment
            lastName
            legacyId
            mobileNumber
            nextOfKinName
            nextOfKinPhone
            postalCode
            resourceCategories {
              createdAt
              defaultSalary
              id
              name
              updatedAt
              vertical
            }
            role
            socialSecurity
            street
            updatedAt
            userProfile {
              ... on CompanyProfile {
                description
                id
              }
              ... on RestaurantProfile {
                adjacentRegions
                approvedBookings
                createdAt
                description
                emergencyContactName
                emergencyContactPhone
                favouriteCount
                id
                languages
                rating
                region
                status
                updatedAt
              }
            }
            verificationMethod
            verified
            verifiedBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
          }
          workShiftId
          workShiftSlot {
            bookedQuantity
            bookingStatuses
            breakMinutes
            calendarStatus
            cancelledAt
            contactPerson {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
            createdAt
            createdBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
            customContactPerson
            defaultFilter
            endTime
            fullyBooked
            id
            name
            price
            resourceQuantity
            startTime
            status
            summarizedBookingStatus
            updatedAt
            updatedBy {
              active
              approved
              availableForSlots
              blocked
              city
              createdAt
              deletedAt
              email
              emailVerified
              favourite
              firstName
              hidden
              id
              internalComment
              lastName
              legacyId
              mobileNumber
              nextOfKinName
              nextOfKinPhone
              postalCode
              role
              socialSecurity
              street
              updatedAt
              verificationMethod
              verified
            }
            workShift {
              acceptanceTime
              bookingType
              cancelledAt
              city
              createdAt
              customContactPerson
              description
              firstStartTime
              id
              internalName
              lastEndTime
              mealDeduction
              minutesPriorStart
              postalCode
              region
              resourceQuantity
              salaryBoost
              sameResources
              sentAt
              status
              street
              templateId
              tips
              totalPrice
              travelExpenseCompensation
              updatedAt
            }
          }
        }
      }
      files {
        contentType
        createdAt
        filename
        id
        originalName
        sizeInBytes
        uri
      }
      id
      images {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      message
      position
      senderName
      title
      updatedAt
    }
  }
}
    `;

/**
 * __useChatSubscribeSubscription__
 *
 * To run a query within a React component, call `useChatSubscribeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useChatSubscribeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatSubscribeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useChatSubscribeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<ChatSubscribeSubscription, ChatSubscribeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ChatSubscribeSubscription, ChatSubscribeSubscriptionVariables>(ChatSubscribeDocument, options);
      }
export type ChatSubscribeSubscriptionHookResult = ReturnType<typeof useChatSubscribeSubscription>;
export type ChatSubscribeSubscriptionResult = Apollo.SubscriptionResult<ChatSubscribeSubscription>;
export const NotificationSubscribeDocument = gql`
    subscription NotificationSubscribe {
  notificationSubscribe {
    background
    booking {
      acceptWith {
        bookings {
          breakMinutes
          createdAt
          declinedAt
          endTime
          id
          startTime
          status
          updatedAt
          workShiftId
        }
        total
      }
      acceptedAt
      approvedAt
      approvedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      approvedByResourceAt
      backOfficeMainStatus
      backOfficeSubStatus
      breakMinutes
      cancelPolicyPct
      cancelledAt
      confirmedCanceled
      createdAt
      createdBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      declinedAt
      endTime
      extraStatus
      history {
        breakMinutes
        endTime
        startTime
        status
        suggestedEnd
        suggestedStart
        timeReport {
          reportedBreak
          reportedEnd
          reportedStart
        }
        timerTracking {
          timerEnd
          timerLat
          timerLng
          timerStart
        }
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        updatedById
      }
      id
      invoiced
      legacyId
      pricePoint {
        hourlySalaryBase
        hourlySalaryBoost
        hours
        totalPrice
        totalSalary
      }
      resource {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      resourceCategoryData {
        resourceLevel {
          createdAt
          id
          name
          updatedAt
        }
        verticalProfileCategory
      }
      startTime
      status
      suggestedEnd
      suggestedStart
      suggestionAcceptedAt
      timeReport {
        reportedBreak
        reportedEnd
        reportedStart
      }
      timerTracking {
        timerEnd
        timerLat
        timerLng
        timerStart
      }
      updatedAt
      updatedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
      workShiftId
      workShiftSlot {
        bookedQuantity
        bookingStatuses
        breakMinutes
        calendarStatus
        cancelledAt
        contactPerson {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        createdAt
        createdBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        customContactPerson
        defaultFilter
        endTime
        fullyBooked
        id
        name
        price
        resourceQuantity
        startTime
        status
        summarizedBookingStatus
        updatedAt
        updatedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
        workShift {
          acceptanceTime
          bookingType
          cancelledAt
          city
          createdAt
          customContactPerson
          description
          firstStartTime
          id
          internalName
          lastEndTime
          mealDeduction
          minutesPriorStart
          postalCode
          region
          resourceQuantity
          salaryBoost
          sameResources
          sentAt
          status
          street
          templateId
          tips
          totalPrice
          travelExpenseCompensation
          updatedAt
        }
      }
    }
    createdAt
    id
    message
    referenceId
    referenceType
    sender {
      active
      approved
      availableForSlots
      blocked
      city
      createdAt
      deletedAt
      email
      emailVerified
      favourite
      firstName
      hidden
      id
      identification {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      image {
        contentType
        createdAt
        filename
        height
        id
        originalName
        sizeInBytes
        uri
        width
      }
      internalComment
      lastName
      legacyId
      mobileNumber
      nextOfKinName
      nextOfKinPhone
      postalCode
      resourceCategories {
        createdAt
        defaultSalary
        id
        level {
          createdAt
          id
          name
          updatedAt
        }
        name
        regional {
          createdAt
          id
          modifier
          price
          region
          salary
          updatedAt
        }
        updatedAt
        vertical
      }
      role
      socialSecurity
      street
      updatedAt
      userProfile {
        ... on CompanyProfile {
          company {
            active
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            category
            city
            commission
            createdAt
            description
            eInvoice
            id
            latitude
            longitude
            name
            organisationNumber
            postalCode
            street
            updatedAt
            web
          }
          description
          id
          locations {
            billingCity
            billingPostalCode
            billingReference
            billingStreet
            city
            createdAt
            description
            eInvoice
            id
            latitude
            legacyCustomerId
            longitude
            name
            organisationNumber
            postalCode
            region
            street
            updatedAt
            web
          }
        }
        ... on RestaurantProfile {
          adjacentRegions
          approvedBookings
          createdAt
          description
          emergencyContactName
          emergencyContactPhone
          favouriteCount
          files {
            contentType
            createdAt
            filename
            id
            originalName
            sizeInBytes
            uri
          }
          id
          languages
          rating
          region
          status
          tags {
            category
            createdAt
            id
            name
            subcategory
            updatedAt
          }
          updatedAt
          workExperience {
            category
            createdAt
            id
            otherWorkExperience
            updatedAt
            years
          }
          workReferences {
            company
            contactName
            contactPhone
            createdAt
            id
            role
            updatedAt
          }
        }
      }
      verificationMethod
      verified
      verifiedBy {
        active
        approved
        availableForSlots
        blocked
        city
        createdAt
        deletedAt
        email
        emailVerified
        favourite
        firstName
        hidden
        id
        identification {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        image {
          contentType
          createdAt
          filename
          height
          id
          originalName
          sizeInBytes
          uri
          width
        }
        internalComment
        lastName
        legacyId
        mobileNumber
        nextOfKinName
        nextOfKinPhone
        postalCode
        resourceCategories {
          createdAt
          defaultSalary
          id
          name
          updatedAt
          vertical
        }
        role
        socialSecurity
        street
        updatedAt
        userProfile {
          ... on CompanyProfile {
            description
            id
          }
          ... on RestaurantProfile {
            adjacentRegions
            approvedBookings
            createdAt
            description
            emergencyContactName
            emergencyContactPhone
            favouriteCount
            id
            languages
            rating
            region
            status
            updatedAt
          }
        }
        verificationMethod
        verified
        verifiedBy {
          active
          approved
          availableForSlots
          blocked
          city
          createdAt
          deletedAt
          email
          emailVerified
          favourite
          firstName
          hidden
          id
          internalComment
          lastName
          legacyId
          mobileNumber
          nextOfKinName
          nextOfKinPhone
          postalCode
          role
          socialSecurity
          street
          updatedAt
          verificationMethod
          verified
        }
      }
    }
    title
    type
  }
}
    `;

/**
 * __useNotificationSubscribeSubscription__
 *
 * To run a query within a React component, call `useNotificationSubscribeSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNotificationSubscribeSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationSubscribeSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNotificationSubscribeSubscription(baseOptions?: Apollo.SubscriptionHookOptions<NotificationSubscribeSubscription, NotificationSubscribeSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<NotificationSubscribeSubscription, NotificationSubscribeSubscriptionVariables>(NotificationSubscribeDocument, options);
      }
export type NotificationSubscribeSubscriptionHookResult = ReturnType<typeof useNotificationSubscribeSubscription>;
export type NotificationSubscribeSubscriptionResult = Apollo.SubscriptionResult<NotificationSubscribeSubscription>;