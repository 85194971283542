import { FieldMergeFunction, InMemoryCache } from '@apollo/client';

import introspectionResult from 'graphql/introspection-result.json';

const mergeEdges: FieldMergeFunction = (existing, incoming, { args }) => {
  const offset = args?.filter.offset || 0;
  const merged = existing ? existing.edges.slice(0) : [];

  for (let index = 0; index < incoming.edges.length; index += 1) {
    merged[offset + index] = incoming.edges[index];
  }

  return {
    ...incoming,
    edges: merged,
  };
};

export const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        channels: {
          keyArgs: ['filter', ['userId', 'chatGroupId']],
          merge: mergeEdges,
        },
        messages: {
          keyArgs: ['filter', ['channelId']],
          merge: mergeEdges,
        },
      },
    },
    User: {
      merge: true,
    },
    CompanyProfile: {
      fields: {
        locations: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    RestaurantProfile: {
      fields: {
        files: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
        workReferences: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    WorkShift: {
      fields: {
        resourceRegionalCategories: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
        slots: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
        workShiftResourceCategories: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    WorkShiftSlot: {
      fields: {
        workShift: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    WorkShiftTemplate: {
      fields: {
        locations: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
        slotTemplates: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
      },
    },
    Company: {
      fields: {
        resourceCategories: {
          merge(existing, incoming: any[]) {
            return incoming;
          },
        },
      },
    },
  },
});
