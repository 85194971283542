export type Environment = {
  apiUrl: string;
  wssUrl: string;
};

export const getEnvironment = (): Environment => {
  if (typeof process.env.REACT_APP_API_URL === 'string') {
    return {
      apiUrl: process.env.REACT_APP_API_URL,
      wssUrl: process.env.REACT_APP_API_URL.replace('http', 'ws'),
    };
  }

  return {
    apiUrl: '',
    wssUrl: '',
  };
};
